import React, { useState, useEffect } from 'react'
import { Typography, Grid, Paper, Button, useTheme, Tooltip } from "@mui/material"
import useStyles from './useStyles';

//Componentes
import ClassicSelector from '../../../components/gamemode_selectors/ClassicSelector';
import ArcadeSelector from '../../../components/gamemode_selectors/ArcadeSelector';
import RushSelector from '../../../components/gamemode_selectors/RushSelector';
import DeathmatchSelector from '../../../components/gamemode_selectors/DeathmatchSelector';

//Imagenes
import classicIconWhite from '../../../assets/images/icons/classic_icon_white.svg'
import arcadeIconWhite from '../../../assets/images/icons/arcade_icon_white.svg'
import rushIconWhite from '../../../assets/images/icons/rush_icon_white.svg'
import deathmIconWhite from '../../../assets/images/icons/deathmatch_icon_white.svg'
import { getGameModesApi } from '../../../api/gamemodes';

function PlayMenu(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { match: { params: { gamemode } } } = props;
  const [section, setSection] = useState(gamemode);
  const [game, setgame] = useState(<ClassicSelector />)
  const [classicStatus, setClassicStatus] = useState(true);
  const [arcadeStatus, setArcadeStatus] = useState(true);
  const [rushStatus, setRushStatus] = useState(true);
  const [deathmatchStatus, setDeathmatchStatus] = useState(true);

  useEffect(() => {
    document.title = 'Modos de juego - Math Paradise'

    getGameModesApi().then(response => {
      const gamemodes = response.gameModes;
      setClassicStatus(gamemodes.find(g => g.name === 'classic').active);
      setArcadeStatus(gamemodes.find(g => g.name === 'arcade').active);
      setRushStatus(gamemodes.find(g => g.name === 'rush').active);
      setDeathmatchStatus(gamemodes.find(g => g.name === 'deathmatch').active);
    })
  }, []);

  useEffect(() => {
    switch (section) {
      case 'classic':
        setgame(<ClassicSelector active={classicStatus} />)
        break;
      case 'arcade':
        setgame(<ArcadeSelector active={arcadeStatus} />)
        break;
      case 'rush':
        setgame(<RushSelector active={rushStatus} />)
        break;
      case 'deathmatch':
        setgame(<DeathmatchSelector active={deathmatchStatus} />)
        break;
      default:
        setgame(<ClassicSelector active={classicStatus} />)
        break;
    }
  }, [section, classicStatus, arcadeStatus, rushStatus, deathmatchStatus]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={12}>
        <Paper sx={classes.paper} elevation={2}>
          <Typography variant="h5">Selecciona un modo de juego</Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item lg={12} md={6} sm={6} xs={12}>
              <Button onClick={() => setSection('classic')} sx={classes.classicPaper} size="large" variant="contained" fullWidth>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={12} sm={12} xs={2}>
                    <img style={{ width: 30, height: 30 }} src={classicIconWhite} alt="classic.svg"></img>
                  </Grid>
                  <Grid item lg={10} md={12} sm={12} xs={10}>
                    <Typography variant="h5">Modo Clásico</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item lg={12} md={6} sm={6} xs={12}>
              <Button onClick={() => setSection('arcade')} sx={classes.ArcadePaper} size="large" variant="contained" fullWidth>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={12} sm={12} xs={2}>
                    <img style={{ width: 30, height: 30 }} src={arcadeIconWhite} alt="classic.svg" width="80%"></img>
                  </Grid>
                  <Grid item lg={10} md={12} sm={12} xs={10}>
                    <Typography variant="h5">Modo Arcade</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item lg={12} md={6} sm={6} xs={12}>
              <Button onClick={() => setSection('rush')} sx={classes.RushPaper} size="large" variant="contained" fullWidth>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={12} sm={12} xs={2}>
                    <img style={{ width: 30, height: 30 }} src={rushIconWhite} alt="classic.svg" width="80%"></img>
                  </Grid>
                  <Grid item lg={10} md={12} sm={12} xs={10}>
                    <Typography variant="h5">Modo Rush</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item lg={12} md={6} sm={6} xs={12}>
              <Tooltip title='Próximamente'>
                <Button /* onClick={() => setSection('deathmatch')} */ sx={classes.disabled} size="large" variant="contained" fullWidth>
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={12} sm={12} xs={2}>
                      <img style={{ width: 30, height: 30 }} src={deathmIconWhite} alt="classic.svg" width="80%"></img>
                    </Grid>
                    <Grid item lg={10} md={12} sm={12} xs={10}>
                      <Typography variant="h5">Modo Deathmatch</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item lg={8} md={12}>
        {game}
      </Grid>
    </Grid>
  )
}

export default PlayMenu
