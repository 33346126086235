import React, { useState, Fragment } from 'react';
import {
  Box, Grid, TextField, Button,
  CircularProgress, Typography, Paper,
  useTheme
} from '@mui/material';
import { useStyles } from './useStyles';
import useAuth from '../../hooks/useAuth';

/**COmponents */
import DefaultSnackbar from '../common/DefaultSnackbar'

/**Icons */
import SaveIcon from '@mui/icons-material/Save'

/**API */
import { updatePasswordApi } from '../../api/user';
import { minLenghtValidation } from '../../utils/FormValidation';

function UpdatePassword() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();
  const [inputs, setInputs] = useState({
    password: '',
    repeatPassword: '',
    oldPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  /**Actualiza la contraseña */
  const updatePassword = () => {
    //Validaciones
    if (!minLenghtValidation(inputs.password, 8)) {
      setMessage('La nueva contraseña debe ser tener al menos 8 caracteres.')
      setOpenSnack(true);
      return;
    }

    //Incia la carga
    setIsLoading(true);

    updatePasswordApi(inputs, user.id).then(response => {
      if (response.status === 1) {
        setMessage(response.message);
        setOpenSnack(true);
        setIsLoading(false);

        //Limpia los campos
        setInputs({
          ...inputs,
          password: '',
          oldPassword: '',
          repeatPassword: ''
        })
      } else {
        setMessage(response.message);
        setOpenSnack(true);
        setIsLoading(false);
      }
    })
  }

  return (
    <Paper sx={classes.paper}>
      <DefaultSnackbar message={message} open={openSnack} handleClose={() => setOpenSnack(false)} />
      <Box>
        <Typography variant='h6' gutterBottom>Actualizar contraseña</Typography>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              type="password"
              name="oldPassword"
              label="*Vieja contraseña"
              variant="outlined"
              value={inputs.oldPassword}
              onChange={(e) => setInputs({ ...inputs, oldPassword: e.target.value })}
              fullWidth />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="password"
              name="password"
              label="*Nueva contraseña"
              variant="outlined"
              fullWidth
              value={inputs.password}
              onChange={(e) => setInputs({ ...inputs, password: e.target.value })} />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="password"
              name="repeatPassword"
              label="*Confirmar nueva contraseña"
              variant="outlined"
              fullWidth
              value={inputs.repeatPassword}
              onChange={(e) => setInputs({ ...inputs, repeatPassword: e.target.value })} />
          </Grid>
        </Grid>
        <Box marginTop={2}>
          {
            isLoading ?
              <CircularProgress color='primary' variant='indeterminate' /> :
              <Fragment>
                <Button
                  sx={classes.button}
                  color='primary'
                  variant='contained'
                  startIcon={<SaveIcon />}
                  onClick={updatePassword}>
                  Guardar
                </Button>
              </Fragment>
          }
        </Box>
      </Box>
    </Paper>
  )
}

export default UpdatePassword;
