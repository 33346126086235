import React, { useEffect, Fragment } from 'react'
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  useTheme,
} from "@mui/material"
import { useStyles } from '../useStyles';

/**Componentes */
import ExcercisesTable from '../../../components/info_tables/ExcercisesTable'

/**Icons */
import AddIcon from '@mui/icons-material/Add'

function Menu() {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    document.title = 'Ejercicios - Panel de administración | Math Paradise'
  }, [])

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h4" color='primary' gutterBottom>
            Gestión de Ejercicios
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Link to="/admin/excercises/create" style={classes.link}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={classes.button}
                >
                  Agregar Ejercicio
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <ExcercisesTable />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Menu
