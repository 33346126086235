import React from "react"
import { Typography, Paper, Grid2 as Grid, Box, useTheme, Button } from "@mui/material"

import { MATH_GRADIENTS } from "../../../styles/MathGradients";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MathAvatar from "../MathAvatar/MathAvatar";

function UserCard(props) {
  const theme = useTheme();
  //Props
  const { name, lastname, avatar } = props

  return (
    <Paper sx={{ p: theme.spacing(4) }}>
      <Box display={'flex'} flexDirection={'row'}>
        <Grid container spacing={4} sx={{ width: '100%' }}>
          <Grid size={{ xl: 3, lg: 4, md: 5, sm: 4, xs: 12 }}>
            <MathAvatar avatar={avatar} size={'220px'} />
          </Grid>
          <Grid size={{ xl: 9, lg: 8, md: 7, sm: 8, xs: 12 }}>
            <Box>
              <Typography variant="h4" gutterBottom>Hola, {name} {lastname}</Typography>
              <Typography color="textSecondary">¿Qué quieres jugar hoy?</Typography>
              <Grid container sx={{ mt: theme.spacing(2) }} spacing={2}>
                <Grid size={'auto'}>
                  <Link to={'/home/play/classic'}>
                    <Button fullWidth sx={{ background: MATH_GRADIENTS().classic, color: 'white', borderRadius: 16, px: theme.spacing(3) }}>
                      Modo Clásico
                    </Button>
                  </Link>
                </Grid>
                <Grid size={'auto'}>
                  <Link to={'/home/play/arcade'}>
                    <Button fullWidth sx={{ background: MATH_GRADIENTS().arcade, color: 'white', borderRadius: 16, px: theme.spacing(3) }}>
                      Modo Arcade
                    </Button>
                  </Link>
                </Grid>
                <Grid size={'auto'}>
                  <Link to={'/home/play/rush'}>
                    <Button fullWidth sx={{ background: MATH_GRADIENTS().rush, color: 'white', borderRadius: 16, px: theme.spacing(3) }}>
                      Modo Rush
                    </Button>
                  </Link>
                </Grid>
                {/* <Grid size={'auto'}>
                  <Link to={'/home/play/deathmatch'}>
                    <Button fullWidth sx={{ background: MATH_GRADIENTS().deathmatch, color: 'white', borderRadius: 16, px: theme.spacing(3) }}>
                      Modo Deathmatch
                    </Button>
                  </Link>
                </Grid> */}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default UserCard
