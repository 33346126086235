export const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  header: {
    textAlign: 'center',
    width: '100%',
    my: theme.spacing(4)
  },
  noPlaysText: {
    fontWeight: 'bold',
    textAlign: 'center'
  }
})