import { basePath, apiVersion, arcade_path } from '../config/api'
import { getAccessTokenApi } from './auth'

export function createArcadeGameApi(data) {
  const url = `${basePath}/${apiVersion}/create-arcade-game`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getArcadeGameByPinApi(pin) {
  const url = `${basePath}/${apiVersion}/get-arcade-game-pin?pin=${pin}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getArcadeGamesByNicknameApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-arcade-games-nickname/${nickname}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getArcadeLeaderboardApi(nicknames = []) {
  const url = `${basePath}/${apiVersion}/get-arcade-leaderboard`
  const params = {
    method: 'POST',
    body: JSON.stringify(nicknames),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getArcadeGameCountApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-arcade-game-count/${nickname}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function updateArcadeGameApi(data, pin) {
  const url = `${basePath}/${apiVersion}/update-arcade-game?pin=${pin}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function deleteArcadeGameApi(pin) {
  const url = `${basePath}/${apiVersion}/delete-arcade-game?pin=${pin}`
  const params = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getArcadeServerStatusApi() {
  const url = `${arcade_path}/api/status`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json"
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}