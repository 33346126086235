import React, { useState } from 'react';
import { Redirect } from 'react-router-dom'
import {
  Paper, Typography,
  Box, Grid, FormControl, Select, MenuItem, Button, InputLabel, TextField,
  CircularProgress,
  Divider,
  useTheme
} from '@mui/material';
import useStyles from './useStyles';

//Utils
import { colors } from '../../../../../utils/SelectArrays';
import { minLenghtValidation } from '../../../../../utils/FormValidation';

//Components
import DefaultSnackbar from '../../../../../components/common/DefaultSnackbar';
import Notification from '../../../../../components/common/Notification/Notification';

//Api
import { updateGroupApi, deleteGroupApi } from '../../../../../api/groups';

//Images
import defaultCover from '../../../../../assets/images/backgrounds/groups/default.svg';
import tilesCover from '../../../../../assets/images/backgrounds/groups/tiles.svg';
import slotsCover from '../../../../../assets/images/backgrounds/groups/slots.svg';
import timeCover from '../../../../../assets/images/backgrounds/groups/time.svg';

function GroupSettings(props) {
  const { group, startReload } = props;
  const theme = useTheme();
  const classes = useStyles(group.color, theme);

  const [inputs, setInputs] = useState({
    name: group.name,
    description: group.description,
    color: group.color,
    cover_image: group.cover_image
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [hasDeletedGroup, setHasDeletedGroup] = useState(false);

  //Función para modificar el grupo
  const updateGroup = () => {
    if (!minLenghtValidation(inputs.name.trim(), 1)) {
      setMessage('Agregue un nombre');
      setOpenSnack(true);
    }

    if (!minLenghtValidation(inputs.description.trim(), 1)) {
      setMessage('Agregue una descripción');
      setOpenSnack(true);
    }

    //Inicia carga
    setIsLoading(true);

    //Actualiza el grupo
    updateGroupApi(group._id, inputs).then(response => {
      if (response.status === 1) {
        setMessage(response.message);
        setOpenSnack(true);
        setIsLoading(false);

        //Recarga datos
        startReload();
      } else {
        setMessage(response.message);
        setOpenSnack(true);
        setIsLoading(false);
      }
    })
  }

  //Elimina el grupo
  const deleteGroup = () => {
    deleteGroupApi(group._id).then(response => {
      if (response.status === 1) {
        setMessage(response.message);
        setOpenSnack(true);
        setHasDeletedGroup(true);
      } else {
        setMessage(response.message);
        setOpenSnack(true);
      }
    })
  }

  if (hasDeletedGroup) {
    return <Redirect to={'/home/groups'} />
  }

  return (
    <div style={classes.root}>
      <DefaultSnackbar
        open={openSnack}
        handleClose={() => setOpenSnack(false)}
        message={message} />
      <Notification
        open={openNotif}
        onClose={() => setOpenNotif(false)}
        onAccept={deleteGroup}
        title={`Eliminar el grupo ${group.name}`}>
        ¿Estás seguro de eliminar este grupo? esta acción no se puede
        deshacer
      </Notification>
      <Paper sx={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xl={10} lg={8} md={7} sm={8} xs={7}>
            <Typography variant='h5' gutterBottom sx={{ color: group.color }}>
              Configuración
            </Typography>
          </Grid>
          <Grid item xl={2} lg={4} md={5} sm={4} xs={5} sx={classes.accessCodeBox}>
            <Typography>
              Código de acceso del grupo
            </Typography>
            <code style={classes.accessCodeLabel}>
              {group.access_code}
            </code>
          </Grid>
        </Grid>
        <Box>
          <Typography color='textSecondary' gutterBottom>
            Información del grupo, todos los campos son requeridos
          </Typography>
          <form style={classes.form}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Nombre del grupo'
                  inputProps={{
                    "maxLength": 22
                  }}
                  defaultValue={group.name}
                  onChange={(e) => setInputs({ ...inputs, name: e.target.value })} />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Descripción'
                  inputProps={{
                    "maxLength": 55
                  }}
                  defaultValue={group.description}
                  onChange={(e) => setInputs({ ...inputs, description: e.target.value })} />
              </Grid>
              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                    <FormControl
                      fullWidth
                      variant='outlined'>
                      <InputLabel id='grp-color'>Color</InputLabel>
                      <Select
                        labelId='grp-color'
                        label='Color'
                        fullWidth
                        value={inputs.color}
                        onChange={(e) => setInputs({ ...inputs, color: e.target.value })}>
                        {
                          colors.map((value, index) => (
                            <MenuItem key={index} value={value.val}>
                              {value.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Box style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: inputs.color,
                      borderRadius: 10
                    }}>
                      &nbsp;
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={8}>
                    <FormControl
                      fullWidth
                      variant='outlined'>
                      <InputLabel id='grp-imagen'>Imagen</InputLabel>
                      <Select
                        labelId='grp-imagen'
                        label='Color'
                        value={inputs.cover_image}
                        onChange={(e) => setInputs({ ...inputs, cover_image: e.target.value })}>
                        <MenuItem value={'default.svg'}>
                          Imagen por defecto
                        </MenuItem>
                        <MenuItem value={'tiles.svg'}>
                          Casillas
                        </MenuItem>
                        <MenuItem value={'slots.svg'}>
                          Tragamonedas
                        </MenuItem>
                        <MenuItem value={'time.svg'}>
                          Tiempo
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
                    <Box style={{
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${inputs.cover_image === 'default.svg' ? defaultCover :
                        inputs.cover_image === 'tiles.svg' ? tilesCover :
                          inputs.cover_image === 'slots.svg' ? slotsCover :
                            inputs.cover_image === 'time.svg' ? timeCover : defaultCover
                        })`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      borderRadius: 10
                    }}>
                      &nbsp;
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {
                  isLoading ?
                    <CircularProgress variant='indeterminate' /> :
                    <Button
                      sx={classes.btn}
                      onClick={updateGroup}>
                      Guardar cambios
                    </Button>
                }
              </Grid>
            </Grid>
          </form>
        </Box>
        <Divider variant='fullWidth' sx={classes.divider} />
        <Box>
          <Typography color='textSecondary' gutterBottom>
            Opciones
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                  <Typography>
                    Eliminar este grupo
                  </Typography>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                  <Button
                    sx={classes.deleteGroupBtn}
                    fullWidth
                    onClick={() => setOpenNotif(true)}>
                    Eliminar grupo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div >
  )
}

export default GroupSettings