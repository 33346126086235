import React from 'react'
import {
  Typography, Grid, Accordion, AccordionDetails, AccordionSummary,
  Divider, AvatarGroup,
  useTheme,
  Grid2,
  Box,
  Tooltip
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useStyles } from './useStyles'

/**Images */
import classicImage from '../../../assets/images/icons/classic_icon_1.svg'
import arcadeImage from '../../../assets/images/icons/arcade_icon_1.svg'
import rushImage from '../../../assets/images/icons/rush_icon_1.svg'

/**Utils */
import { es_DateName } from '../../../utils/DateFormat'
import DefaultAvatar from '../../user/DefaultAvatar/DefaultAvatar'
import { rush_topics } from '../../../utils/SelectArrays'

function GameHistoryAccordion(props) {
  const { historyData, myNickname } = props
  const theme = useTheme();
  const classes = useStyles(theme);
  const playerToShow = historyData.players.find(player => player.nickname === myNickname);

  const resultMap = {
    victory: {
      color: theme.palette.success.main,
      text: 'Victoria'
    },
    defeat: {
      color: theme.palette.error.main,
      text: 'Derrota'
    },
    draw: {
      color: theme.palette.text.secondary,
      text: 'Empate'
    },
    none: {
      color: theme.palette.text.secondary,
      text: ''
    }
  };

  const difficultyMap = {
    easy: 'Fácil',
    normal: 'Normal',
    hard: 'Difícil'
  }

  //Mapping de los temas del rush
  const RushTopic = (selectedTopic) => {
    let card = {};

    rush_topics.forEach(g => {
      card[g.val] = g
    });

    return card[selectedTopic];
  }

  const ClassicAccordion = () => (
    <Accordion sx={classes.paper}>
      <AccordionSummary>
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.gamemodeContainer}>
              <img src={classicImage} alt='classic_icon.svg' style={classes.gamemodeImage} />
              <Typography display={{ xs: 'none', sm: 'block' }}>
                Modo Clásico
              </Typography>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.pointsContainer}>
              <Typography>Puntuación</Typography>
              <Typography style={{ color: theme.palette.primary.main }}>
                {playerToShow.points} pts
              </Typography>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.resultContainer}>
              <Typography style={{ color: resultMap[playerToShow.result ?? 'none'].color }}>
                {
                  resultMap[playerToShow.result ?? 'none'].text
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={classes.detailsContainer}>
          <div style={classes.playersContainer}>
            <AvatarGroup max={4}>
              {
                historyData.players.map((player, index) => (
                  <DefaultAvatar key={index} nickname={player.nickname} />
                ))
              }
            </AvatarGroup>
          </div>
          <Divider sx={classes.divider} />
          <div style={classes.infoDetailsContainer}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
                <Box sx={{ mb: theme.spacing(2) }}>
                  <Typography variant='h6' gutterBottom color='classic'>Puntuaciones</Typography>
                  {
                    historyData.players.sort((a, b) => b.points - a.points).map((player, index) => (
                      <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(1) }}>
                        <DefaultAvatar nickname={player.nickname} size={'26px'} />
                        <Typography sx={{ ml: theme.spacing(1) }}>
                          <Link
                            to={`/home/profile/@${player.nickname}`}
                            style={{
                              textDecoration: 'none',
                              color: theme.palette.primary.main,
                            }}>
                            @{player.nickname}
                          </Link>
                          <br />
                          <span style={{ color: theme.palette.success.main }}>{player.points}</span> puntos.
                        </Typography>
                      </Box>
                    ))
                  }
                </Box>
                <Box>
                  <Typography variant='h6' gutterBottom color='classic'>Mis estadísticas</Typography>
                  <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.answered}</span> ejercicios contestados</Typography>
                  <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.correct}</span> aciertos</Typography>
                  <Typography><span style={{ color: theme.palette.error.main }}>{playerToShow.errors}</span> errores</Typography>
                </Box>
              </Grid2>
              <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
                <Typography variant='h6' gutterBottom color='classic'>Detalles de partida</Typography>
                <Typography>Materias</Typography>
                <Box sx={{ mb: theme.spacing(2) }}>
                  {
                    historyData.areas.map((area, index) => (
                      <Typography key={index} variant='subtitle2' color='textSecondary'>
                        {area.toString().toUpperCase().replace(/[_]/g, " ")}
                      </Typography>
                    ))
                  }
                </Box>
                <Typography>Temas</Typography>
                <Box sx={{ mb: theme.spacing(2) }}>
                  {
                    historyData.topics.map((topic, index) => (
                      <Typography key={index} variant='subtitle2' color='textSecondary'>
                        {topic.toString().toUpperCase().replace(/[_]/g, " ")}
                      </Typography>
                    ))
                  }
                </Box>
                <Typography>Subtemas</Typography>
                <Box sx={{ mb: theme.spacing(2) }}>
                  {
                    historyData.subtopics.map((subtopic, index) => (
                      <Typography key={index} variant='subtitle2' color='textSecondary'>
                        {subtopic.toString().toUpperCase().replace(/[_]/g, " ")}
                      </Typography>
                    ))
                  }
                </Box>
                <Typography>
                  Dificultad
                </Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                  {difficultyMap[historyData.difficulty] || 'Ninguna'}
                </Typography>
              </Grid2>
            </Grid2>
            <Typography sx={classes.gameDate}>{es_DateName(historyData.date)}</Typography>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  const ArcadeAccordion = () => (
    <Accordion sx={classes.paper}>
      <AccordionSummary>
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.gamemodeContainer}>
              <img src={arcadeImage} alt='arcade_icon.svg' style={classes.gamemodeImage} />
              <Typography display={{ xs: 'none', sm: 'block' }}>
                Modo Arcade
              </Typography>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.pointsContainer}>
              <Typography>Puntuación</Typography>
              <Typography style={{ color: theme.palette.primary.main }}>
                {playerToShow.points} pts
              </Typography>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.resultContainer}>
              <Typography style={{ color: resultMap[playerToShow.result ?? 'none'].color }}>
                {
                  resultMap[playerToShow.result ?? 'none'].text
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={classes.detailsContainer}>
          <div style={classes.playersContainer}>
            <AvatarGroup max={4}>
              {
                historyData.players.map((player, index) => (
                  <DefaultAvatar key={index} nickname={player.nickname} />
                ))
              }
            </AvatarGroup>
          </div>
          <Divider sx={classes.divider} />
          <div style={classes.infoDetailsContainer}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
                <Box sx={{ mb: theme.spacing(2) }}>
                  <Typography variant='h6' gutterBottom color='arcade'>Puntuaciones</Typography>
                  {
                    historyData.players.sort((a, b) => b.points - a.points).map((player, index) => (
                      <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(1) }}>
                        <DefaultAvatar nickname={player.nickname} size={'26px'} />
                        <Typography sx={{ ml: theme.spacing(1) }}>
                          <Link
                            to={`/home/profile/@${player.nickname}`}
                            style={{
                              textDecoration: 'none',
                              color: theme.palette.primary.main,
                            }}>
                            @{player.nickname}
                          </Link>
                          <br />
                          <span style={{ color: theme.palette.success.main }}>{player.points}</span> puntos.
                        </Typography>
                      </Box>
                    ))
                  }
                </Box>
                <Box>
                  <Typography variant='h6' gutterBottom color='arcade'>Mis estadísticas</Typography>
                  <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.answered}</span> ejercicios contestados</Typography>
                  <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.correct}</span> aciertos</Typography>
                  <Typography><span style={{ color: theme.palette.error.main }}>{playerToShow.errors}</span> errores</Typography>
                </Box>
              </Grid2>
              <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
                <Typography variant='h6' gutterBottom color='arcade'>Detalles de partida</Typography>
                <Typography>Materias</Typography>
                <Box sx={{ mb: theme.spacing(2) }}>
                  {
                    historyData.areas.map((area, index) => (
                      <Typography key={index} variant='subtitle2' color='textSecondary'>
                        {area.toString().toUpperCase().replace(/[_]/g, " ")}
                      </Typography>
                    ))
                  }
                </Box>
                <Typography>Temas</Typography>
                <Box sx={{ mb: theme.spacing(2) }}>
                  {
                    historyData.topics.map((topic, index) => (
                      <Typography key={index} variant='subtitle2' color='textSecondary'>
                        {topic.toString().toUpperCase().replace(/[_]/g, " ")}
                      </Typography>
                    ))
                  }
                </Box>
                <Typography>Subtemas</Typography>
                <Box sx={{ mb: theme.spacing(2) }}>
                  {
                    historyData.subtopics.map((subtopic, index) => (
                      <Typography key={index} variant='subtitle2' color='textSecondary'>
                        {subtopic.toString().toUpperCase().replace(/[_]/g, " ")}
                      </Typography>
                    ))
                  }
                </Box>
                <Typography>
                  Dificultad
                </Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                  {difficultyMap[historyData.difficulty] || 'Ninguna'}
                </Typography>
              </Grid2>
            </Grid2>
            <Typography sx={classes.gameDate}>{es_DateName(historyData.date)}</Typography>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  const RushAccordion = () => (
    <Accordion sx={classes.paper}>
      <AccordionSummary>
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.gamemodeContainer}>
              <img src={rushImage} alt='rush_icon.svg' style={classes.gamemodeImage} />
              <Typography display={{ xs: 'none', sm: 'block' }}>
                Modo Rush
              </Typography>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.pointsContainer}>
              <Typography>Puntuación</Typography>
              <Typography style={{ color: theme.palette.primary.main }}>
                {playerToShow.points} pts
              </Typography>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={classes.resultContainer}>
              <Typography style={{ color: resultMap[playerToShow.result ?? 'none'].color }}>
                {
                  resultMap[playerToShow.result ?? 'none'].text
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={classes.detailsContainer}>
          <Grid2 container spacing={2} sx={{ mb: theme.spacing(4) }}>
            <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
              {
                historyData.players.length > 1 && (
                  <Box sx={{ mb: theme.spacing(2) }}>
                    <Typography variant='h6' gutterBottom color='rush'>Jugadores</Typography>
                    {
                      historyData.players.sort((a, b) => b.points - a.points).map((player, index) => (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(1) }}>
                          <DefaultAvatar nickname={player.nickname} size={'26px'} />
                          <Typography sx={{ ml: theme.spacing(1) }}>
                            <Link
                              to={`/home/profile/@${player.nickname}`}
                              style={{
                                textDecoration: 'none',
                                color: theme.palette.primary.main,
                              }}>
                              @{player.nickname}
                            </Link>
                            <br />
                            <span style={{ color: theme.palette.success.main }}>{player.points}</span> puntos.
                          </Typography>
                        </Box>
                      ))
                    }
                  </Box>
                )
              }
              <Box>
                <Typography variant='h6' gutterBottom color='rush'>Mis estadísticas</Typography>
                <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.points}</span> puntos</Typography>
                <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.excercises}</span> ejercicios contestados</Typography>
                <Typography><span style={{ color: theme.palette.success.main }}>{playerToShow.level}</span> nivel alcanzado</Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
              <Box>
                <Typography variant='h6' gutterBottom color='rush'>Detalles de partida</Typography>
                <Typography>Temas</Typography>
                {
                  historyData.topics.map((tp, index) => (
                    <Tooltip title={RushTopic(tp).spanish_name} key={index}>
                      <img src={RushTopic(tp).path} alt="" style={{ width: 32, marginRight: theme.spacing(1) }} />
                    </Tooltip>
                  ))
                }
              </Box>
            </Grid2>
          </Grid2>
          <div style={classes.multiplierContainer}>
            <Typography>Multiplicador alcanzado</Typography>
            <div style={classes.multiplierBox}>
              <Typography sx={classes.multiplierLabel}>
                &times;{playerToShow.multiplier}
              </Typography>
            </div>
          </div>
          <div style={classes.infoDetailsContainer}>
            <Typography sx={classes.gameDate}>{es_DateName(historyData.date)}</Typography>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  if (historyData.gamemode === 'classic')
    return <ClassicAccordion />;
  else if (historyData.gamemode === 'arcade')
    return <ArcadeAccordion />;
  else if (historyData.gamemode === 'rush')
    return <RushAccordion />;
  else
    return null;
}

export default GameHistoryAccordion
