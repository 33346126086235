import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../useStyles'
import {
  Grid, Typography, Paper, Box, Divider, Button, TextField, Switch,
  FormControlLabel,
  useTheme
} from "@mui/material"

/**COmponents */
import DefaultSnackbar from '../../../components/common/DefaultSnackbar'

/**APIs */
import { createGameModeApi } from '../../../api/gamemodes'

/**Icons */
import AddIcon from '@mui/icons-material/Add'

function CreateGameMode() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [inputs, setInputs] = useState({
    name: '',
    active: false
  })
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)

  //Cambio del form
  const changeForm = (e) => {
    if (e.target.type === 'checkbox') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked
      })
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      })
    }
  }

  //Guardar los datos
  const createInstitution = () => {
    if (inputs.name.trim() === '') {
      setMessage("Todos los campos son requeridos.")
      setOpen(true)
    } else {
      createGameModeApi(inputs).then(response => {
        if (response.status === 1) {
          setMessage(response.message)
          setOpen(true)

          window.location.href = '/admin/gamemodes'
        } else {
          setMessage(response.message)
          setOpen(true)
        }
      })
    }
  }

  return (
    <Fragment>
      <DefaultSnackbar
        open={open}
        handleClose={() => setOpen(false)}
        message={message} />
      <Paper sx={classes.paper}>
        <Typography variant="h5" sx={classes.title}>
          <AddIcon fontSize="large" /> Añadir modo de juego
        </Typography>
        <Divider />
        <form style={classes.formBox} onChange={changeForm}>
          <Typography>*Todos los campos son requeridos</Typography>
          <Grid container spacing={2} sx={classes.form}>
            <Grid item lg={6}>
              <TextField
                type="text"
                name="name"
                label="*Nombre del modo de juego"
                variant="outlined"
                sx={classes.textField} />
            </Grid>
            <Grid item lg={6}>
              <FormControlLabel label="Estado (Habilitado / deshabilitado)" control={
                <Switch
                  checked={inputs.active}
                  name="active" />
              } />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <Link style={classes.link} to="/admin/gamemodes">
              <Button sx={classes.cancelButton}>
                Cancelar
              </Button>
            </Link>
            <Button onClick={createInstitution} sx={classes.okButton}>
              Guardar
            </Button>
          </Box>
        </form>
      </Paper>
    </Fragment>
  )
}

export default CreateGameMode
