import React, { useState, useEffect, useMemo } from 'react'
import PublicHeader from '../../components/navigation/public/PublicHeader/PublicHeader'
import Footer from '../../components/navigation/public/Footer/Footer'
import { Box, Button, Card, CardActions, CardContent, Container, Grid2, LinearProgress, Typography, useTheme } from '@mui/material'
import { getNewsApi } from '../../api/news';
import { es_DateName } from '../../utils/DateFormat';
import { Link } from 'react-router-dom';

import NewspaperIcon from '@mui/icons-material/Newspaper';

function News() {
  const theme = useTheme();
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getNewsApi().then(response => {
      setIsLoading(false)
      setNews(response.news);
    }).catch(e => {
      setIsLoading(false)
      setNews([]);
    })

    return () => {
      setNews([]);
    }
  }, [])

  const activeNews = useMemo(() => {
    return news.filter(n => n.active === true);
  }, [news]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <Box flex={1}>
        <PublicHeader />
        <Container maxWidth="xl" sx={{ mt: theme.spacing(6) }}>
          <Typography variant="h4" color='primary' gutterBottom>Noticias</Typography>
          <Box sx={{ height: '75vh' }}>
            {
              isLoading ?
                <LinearProgress variant='indeterminate' /> :
                <Box>
                  <Grid2 container spacing={2}>
                    {
                      activeNews.length > 0 ?
                        (
                          activeNews.map((n, index) => (
                            <Grid2 key={index} size={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 12 }}>
                              <Card>
                                <CardContent>
                                  <Typography color='textSecondary' gutterBottom>{es_DateName(n.date)}</Typography>
                                  <Typography variant='h5' color='primary'>{n.name}</Typography>
                                  <Typography variant='body2'>
                                    {n.description}
                                  </Typography>
                                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: theme.spacing(3) }}>
                                    <NewspaperIcon style={{ fontSize: 120 }} color='action' />
                                  </Box>
                                </CardContent>
                                <CardActions>
                                  <Link to={`/news/${n.path}`} style={{ width: '100%' }}>
                                    <Button variant='contained' color='primary' fullWidth>
                                      Ver más
                                    </Button>
                                  </Link>
                                </CardActions>
                              </Card>
                            </Grid2>
                          ))
                        ) :
                        <Typography color='textSecondary'>No hay noticias qué mostrar</Typography>
                    }
                  </Grid2>
                </Box>
            }
          </Box>
        </Container>
        <Footer />
      </Box>
    </div>
  )
}

export default News