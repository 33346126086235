import React, { Fragment } from 'react'
import { useTheme } from '@mui/material'

/**Components */
import PublicHeader from '../components/navigation/public/PublicHeader/PublicHeader'

/**APIs */
import { getAccessTokenApi } from '../api/auth'

/**Images */
import error_banner from '../assets/images/banners/error404_banner.svg'

const useStyle = (theme) => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    margin: theme.spacing(4),
  },
  banner: {
    width: '50%'
  }
});

function Error404() {
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <Fragment>
      {!getAccessTokenApi() ? <PublicHeader /> : null}
      <div style={classes.root}>
        <img src={error_banner} alt="error404.svg" style={classes.banner} />
      </div>
    </Fragment>
  )
}

export default Error404
