import React, { useEffect } from 'react'
import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import { player_colors } from '../../../utils/SelectArrays';
import { animated, useSpring } from '@react-spring/web'
import MathAvatar from '../../user/MathAvatar/MathAvatar';

function RushPlayerCard(props) {
  const { color, player } = props;
  const theme = useTheme();

  const [paperSpring, paperSpringApi] = useSpring(() => ({
    brightness: 1,
    config: {
      velocity: 1.5
    }
  }))

  useEffect(() => {
    if (player.timeUp) {
      paperSpringApi.start({
        brightness: 0.25
      })
    }
  }, [player, paperSpringApi])

  const colors = () => {
    let allColors = {};
    player_colors.forEach(pc => {
      allColors[pc.name] = pc.val
    })
    return allColors;
  }

  return (
    <animated.div style={{ filter: paperSpring.brightness.to(b => `brightness(${b * 100}%)`) }}>
      <Paper sx={{
        width: '100%',
        height: 240,
        mb: theme.spacing(2),
        p: theme.spacing(2),
        backgroundColor: colors()[color],
        color: 'white',
      }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(2),
        }}>
          <MathAvatar avatar={player.avatar} />
          <Typography fontSize={'2vh'} ml={theme.spacing(1)}>{player.nickname}</Typography>
        </Box>
        <Box>
          <Typography>Puntos: {player.points.toLocaleString("en-US")}</Typography>
          <Typography>Nivel: {player.level.toLocaleString("en-US")}</Typography>
          <Typography>Ejercicios: {player.excercises.toLocaleString("en-US")}</Typography>
        </Box>
        <Box mt={theme.spacing(5)} sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress size={60} variant='determinate' value={100} sx={{ color: 'white', position: 'absolute' }} />
          <Typography position={'absolute'} textAlign={'center'} fontSize={'110%'} mt={0.5}>&times;{player.multiplier}</Typography>
        </Box>
      </Paper>
    </animated.div>
  )
}

export default RushPlayerCard