import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from '@react-spring/web'
import { useStyles } from './useStyles'
import {
  Grid, Typography, Backdrop, CircularProgress, Button,
  useMediaQuery, useTheme,
  Grid2,
  Box,
  IconButton
} from '@mui/material'
import { MATH_GRADIENTS } from '../../../styles/MathGradients'
import { Howl } from 'howler';

/**Componentes */
import Tile from '../Tile'
import PlayerStatsCard from '../ClassicPlayerStatsCard/ClassicPlayerStatsCard'
import MathAvatar from '../../user/MathAvatar/MathAvatar';

/**Iconos */
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import CasinoIcon from '@mui/icons-material/Casino'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation'
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SettingsIcon from '@mui/icons-material/Settings';
import CancelIcon from '@mui/icons-material/Cancel';

/**Otros */
import { posPlayer1, posPlayer2, posPlayer3, posPlayer4 } from './tilePositions'
import { formatTime } from '../../../utils/TimeFormat'

/**Images */
import classicIcon from '../../../assets/images/icons/classic_icon_white.svg'
import challenge from '../../../assets/images/layouts/classic/challenge_icon.svg'
import event from '../../../assets/images/layouts/classic/random_event_icon.svg'
import random_exc from '../../../assets/images/layouts/classic/random_excercise_icon.svg'
import start from '../../../assets/images/layouts/classic/start_icon.svg'
import dice1 from '../../../assets/images/layouts/classic/dice/dice_side1.svg'
import dice2 from '../../../assets/images/layouts/classic/dice/dice_side2.svg'
import dice3 from '../../../assets/images/layouts/classic/dice/dice_side3.svg'
import dice4 from '../../../assets/images/layouts/classic/dice/dice_side4.svg'
import dice5 from '../../../assets/images/layouts/classic/dice/dice_side5.svg'
import dice6 from '../../../assets/images/layouts/classic/dice/dice_side6.svg'

function ClassicBoard(props) {
  //Props
  const {
    isLoading = true, //Estado que muestra el cargando de la partida por los datos desde DB.
    game = null,
    localUser = null,
    phase = 'waiting',
    sendDiceNumber = () => null,
    handleOpenMenu,
    clock = 0,
    mode = 'private'
  } = props;

  //Clases de estilo
  const theme = useTheme();
  const classes = useStyles(theme);
  //Media Query CSS
  const matches = useMediaQuery('(orientation: landscape)');
  //Hooks y estado del dado
  const [flipDice, setFlipDice] = useState(false)
  //Valor del dado
  const [dice, setDice] = useState(1);
  const [diceImage, setDiceImage] = useState(dice1);

  //Jugadores
  const player1 = game ? game.players.find(player => player.number === 1) : null;
  const player2 = game ? game.players.find(player => player.number === 2) : null;
  const player3 = game ? game.players.find(player => player.number === 3) : null;
  const player4 = game ? game.players.find(player => player.number === 4) : null;

  // ESTADOS PARA TEST
  const [testPos] = useState(0);

  //Hooks de animaciones de los jugadores
  const moveP1 = useSpring({
    config: { tension: 200 },
    marginLeft: posPlayer1[player1 ? (player1.boardPosition % 30) : (testPos % 30)].left,
    marginTop: posPlayer1[player1 ? (player1.boardPosition % 30) : (testPos % 30)].top
  })
  const moveP2 = useSpring({
    config: { tension: 200 },
    marginLeft: posPlayer2[player2 ? (player2.boardPosition % 30) : (testPos % 30)].left,
    marginTop: posPlayer2[player2 ? (player2.boardPosition % 30) : (testPos % 30)].top
  })
  const moveP3 = useSpring({
    config: { tension: 200 },
    marginLeft: posPlayer3[player3 ? (player3.boardPosition % 30) : (testPos % 30)].left,
    marginTop: posPlayer3[player3 ? (player3.boardPosition % 30) : (testPos % 30)].top
  })
  const moveP4 = useSpring({
    config: { tension: 200 },
    marginLeft: posPlayer4[player4 ? (player4.boardPosition % 30) : (testPos % 30)].left,
    marginTop: posPlayer4[player4 ? (player4.boardPosition % 30) : (testPos % 30)].top
  })
  const diceRotate = useSpring({
    config: { mass: 5, tension: 750, friction: 100 },
    transform: `rotateY(${flipDice ? 360 : 0}deg)`,
  })

  //Musica y sonidos
  const [sounds, setSounds] = useState({});

  useEffect(() => {
    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'draw', url: '/sounds/games/classic/draw.wav' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    //Musica
    const music = new Howl({
      src: ['/music/classic_theme.ogg'],
      loop: true,
      volume: 0.25
    });

    music.play();

    return () => {
      // Limpiar la instancia de Howl cuando el componente se desmonte
      music.unload();
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  //Funmción para girar el dado
  const rollDice = () => {
    sounds['draw'].play();
    //Variable para valor aleatorio
    let rand

    //Si el valor generado es igual al anterior, lo vuelve a generar
    do {
      rand = Math.floor((Math.random() * 6) + 1)
    } while (dice === rand);

    //Gira el dado
    setFlipDice(!flipDice)

    //Asigna aleatorio al dado
    showDiceImage(rand);
    setDice(rand)

    //Envía el valor del dado al juego
    sendDiceNumber(rand);
  }

  //Función que renderiza un número aleatorio al dado
  const showDiceImage = (diceNumber) => {
    if (diceNumber === 1) setDiceImage(dice1);
    else if (diceNumber === 2) setDiceImage(dice2);
    else if (diceNumber === 3) setDiceImage(dice3);
    else if (diceNumber === 4) setDiceImage(dice4);
    else if (diceNumber === 5) setDiceImage(dice5);
    else if (diceNumber === 6) setDiceImage(dice6);
    else setDiceImage(dice1);
  };

  if (!matches) {
    return (
      <div style={{ ...classes.verticalScreen, ...classes.background }}>
        <Typography style={{
          fontSize: '7vw'
        }}>
          Gire su dispositivo para poder visualizar el tablero
        </Typography>
        <ScreenRotationIcon style={{ fontSize: '7vw', marginTop: '10px' }} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={classes.background}>
        <Backdrop open={true} sx={classes.loadingScreen}>
          <CircularProgress color="inherit" />
          <Typography variant="h5">Cargando tablero</Typography>
        </Backdrop>
      </div>
    )
  }

  return (
    <Fragment>
      <Fragment>
        {/** Capa de fichas de jugadores */}
        {
          player1 && (
            <div style={classes[`player1Layer`]}>
              <animated.div style={moveP1}>
                <MathAvatar
                  avatar={player1.avatar}
                  size="5vh"
                  badgeVisible={true}
                  badgeContent={1}
                  badgeColor={`player_1`} />
              </animated.div>
            </div>
          )
        }
        {
          player2 && (
            <div style={classes[`player2Layer`]}>
              <animated.div style={moveP2}>
                <MathAvatar
                  avatar={player2.avatar}
                  size="5vh"
                  badgeVisible={true}
                  badgeContent={2}
                  badgeColor={`player_2`} />
              </animated.div>
            </div>
          )
        }
        {
          player3 && (
            <div style={classes[`player3Layer`]}>
              <animated.div style={moveP3}>
                <MathAvatar
                  avatar={player3.avatar}
                  size="5vh"
                  badgeVisible={true}
                  badgeContent={3}
                  badgeColor={`player_3`} />
              </animated.div>
            </div>
          )
        }
        {
          player4 && (
            <div style={classes[`player4Layer`]}>
              <animated.div style={moveP4}>
                <MathAvatar
                  avatar={player4.avatar}
                  size="5vh"
                  badgeVisible={true}
                  badgeContent={4}
                  badgeColor={`player_4`} />
              </animated.div>
            </div>
          )
        }
        {/** Capa de puntuaciones */}
        <div style={classes.uiLayer}>
          <div>
            <div style={classes.diceContainer}>
              <div style={classes.logoContainer}>
                <img src={classicIcon} alt="classic_icon.svg" style={classes.logo} />
                <Typography sx={classes.LogoDiceSize}>Modo Clásico</Typography>
              </div>
              <Box sx={{ width: '7vw', marginBottom: '1.5vh' }}>
                <animated.div style={diceRotate}>
                  <img src={diceImage} alt='' style={{ width: '7vw' }} />
                </animated.div>
              </Box>
              <Box>
                {
                  //Renderizado de botón de acuerdo al turno
                  mode === 'private' && localUser.nickname === game.turn ?
                    (
                      <Button
                        startIcon={<CasinoIcon style={{ fontSize: '3vh' }} />}
                        sx={classes.button}
                        style={{
                          background: MATH_GRADIENTS().default, color: 'white', mb: '1vh', marginRight: '1.2vh', width: '20vh',
                          fontSize: '1.5vh'
                        }}
                        onClick={rollDice}
                        disabled={phase === 'answering' ? true : false}>
                        Girar Dado
                      </Button>
                    ) : mode === 'private' && localUser.nickname !== game.turn ?
                      (
                        <Button
                          startIcon={<CancelIcon style={{ fontSize: '3vh' }} />}
                          sx={classes.button}
                          style={{
                            background: MATH_GRADIENTS().disabled, color: 'white', mb: '1vh', marginRight: '1.2vh', width: '20vh',
                            fontSize: '1.5vh'
                          }}>
                          Esperando Turno
                        </Button>
                      ) : mode === 'local' ? (
                        <Button
                          startIcon={<CasinoIcon style={{ fontSize: '3vh' }} />}
                          sx={classes.button}
                          style={{
                            background: MATH_GRADIENTS().default, color: 'white', mb: '1vh', marginRight: '1.2vh', width: '20vh',
                            fontSize: '1.5vh'
                          }}
                          onClick={rollDice}
                          disabled={phase === 'answering' ? true : false}>
                          Girar Dado
                        </Button>
                      ) : null
                }
                <IconButton sx={classes.button} style={{ background: theme.palette.grey[700], color: 'white', mb: '1vh' }}
                  onClick={handleOpenMenu}>
                  <SettingsIcon sx={{ fontSize: '3vh' }} />
                </IconButton>
              </Box>
              <div style={classes.messageContainer}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1vh' }}>
                  <WatchLaterIcon sx={classes.icons} />
                  <Typography fontSize={'1vw'}>Tiempo: {formatTime(clock)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <SpeakerNotesIcon sx={classes.icons} />
                  <Typography fontSize={'1vw'}>{game.message}</Typography>
                </Box>
              </div>
            </div>
          </div>
          <Box sx={{ mt: theme.spacing(1), ml: theme.spacing(4), width: '25vw' }}>
            <Grid2 container sx={{ mt: theme.spacing(0) }} spacing={1}>
              {
                player1 && (
                  <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                    <PlayerStatsCard
                      dropOff={player1.dropOff}
                      avatar={player1.avatar}
                      nickname={player1.nickname}
                      color={`player_1`}
                      points={player1.points}
                    />
                  </Grid2>
                )
              }
              {
                player2 && (
                  <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                    <PlayerStatsCard
                      dropOff={player2.dropOff}
                      avatar={player2.avatar}
                      nickname={player2.nickname}
                      color={`player_2`}
                      points={player2.points}
                    />
                  </Grid2>
                )
              }
              {
                player3 && (
                  <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                    <PlayerStatsCard
                      dropOff={player3.dropOff}
                      avatar={player3.avatar}
                      nickname={player3.nickname}
                      color={`player_3`}
                      points={player3.points}
                    />
                  </Grid2>
                )
              }
              {
                player4 && (
                  <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                    <PlayerStatsCard
                      dropOff={player4.dropOff}
                      avatar={player4.avatar}
                      nickname={player4.nickname}
                      color={`player_4`}
                      points={player4.points}
                    />
                  </Grid2>
                )
              }
            </Grid2>
          </Box>
        </div>
        {/** Capa del tablero */}
        <div style={classes.background}>
          <div style={classes.board}>
            <Grid container sx={classes.grid}>
              {/**Fila 0 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={24} position={'corner'} cornerType={"RULETA"} image={event} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} sx={classes.centerTiles}>
                <Tile number={23} position={'bottom'} subtopic={game.board[23]} />
                <Tile number={22} position={'bottom'} subtopic={game.board[22]} />
                <Tile number={21} position={'bottom'} subtopic={game.board[21]} />
                <Tile number={20} position={'bottom'} subtopic={game.board[20]} />
                <Tile number={19} position={'bottom'} subtopic={game.board[19]} />
                <Tile number={18} position={'bottom'} subtopic={game.board[18]} />
                <Tile number={17} position={'bottom'} subtopic={game.board[17]} />
                <Tile number={16} position={'bottom'} subtopic={game.board[16]} />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={15} position={'corner'} image={challenge} cornerType={"RETO"} />
              </Grid>

              {/**Fila 1 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={25} position={'left'} subtopic={game.board[25]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={14} position={'right'} subtopic={game.board[14]} />
              </Grid>

              {/**Fila 2 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={26} position={'left'} subtopic={game.board[26]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={13} position={'right'} subtopic={game.board[13]} />
              </Grid>

              {/**Fila 3 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={27} position={'left'} subtopic={game.board[27]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={12} position={'right'} subtopic={game.board[12]} />
              </Grid>

              {/**Fila 4 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={28} position={'left'} subtopic={game.board[28]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={11} position={'right'} subtopic={game.board[11]} />
              </Grid>

              {/**Fila 5 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={29} position={'left'} subtopic={game.board[29]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={10} position={'right'} subtopic={game.board[10]} />
              </Grid>

              {/**Fila 6 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={0} position={'corner'} image={start} cornerType={"INICIO"} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} sx={classes.centerTiles}>
                <Tile number={1} position={'top'} subtopic={game.board[1]} />
                <Tile number={2} position={'top'} subtopic={game.board[2]} />
                <Tile number={3} position={'top'} subtopic={game.board[3]} />
                <Tile number={4} position={'top'} subtopic={game.board[4]} />
                <Tile number={5} position={'top'} subtopic={game.board[5]} />
                <Tile number={6} position={'top'} subtopic={game.board[6]} />
                <Tile number={7} position={'top'} subtopic={game.board[7]} />
                <Tile number={8} position={'top'} subtopic={game.board[8]} />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Tile number={9} position={'corner'} image={random_exc} cornerType={"ALEATORIO"} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
}

ClassicBoard.propTypes = {
  isLoading: PropTypes.bool,
  game: PropTypes.object,
  localUser: PropTypes.object,
  phase: PropTypes.string,
  sendDiceNumber: PropTypes.func,
  players: PropTypes.array,
  clock: PropTypes.number
}

export default ClassicBoard
