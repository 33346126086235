import React from 'react'
import { Link } from "react-router-dom";
import useAuth from '../../../../hooks/useAuth'
import {
  List, ListItem, ListItemIcon, ListItemText, Divider, useTheme,
  Typography,
  ListItemButton
} from "@mui/material"
import useStyles from './useStyles';
import packageJson from '../../../../../package.json';

/**Iconos */
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ExtensionIcon from '@mui/icons-material/Extension';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ViewStreamIcon from '@mui/icons-material/ViewStream'

function MenuList(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();
  const { url } = props

  return (
    <div style={classes.root}>
      <List component="nav">
        <Link to="/home" style={classes.link}>
          <ListItem disablePadding onClick={props.close} selected={url === '/home' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <SpaceDashboardIcon sx={{ color: '#35A7FF' }} />
              </ListItemIcon>
              <ListItemText primary="Tablero" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/home/play" style={classes.link}>
          <ListItem disablePadding onClick={props.close} selected={url === '/home/play' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: '#A2D729' }} />
              </ListItemIcon>
              <ListItemText primary="Jugar" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/home/leaderboard" style={classes.link}>
          <ListItem disablePadding onClick={props.close} selected={url === '/home/leaderboard' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <EmojiEventsIcon sx={{ color: '#E3B505' }} />
              </ListItemIcon>
              <ListItemText primary="Clasificación" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/home/history" style={classes.link}>
          <ListItem disablePadding onClick={props.close} selected={url === '/home/history' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <ViewStreamIcon sx={{ color: '#7E2E84' }} />
              </ListItemIcon>
              <ListItemText primary="Historial de juegos" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/home/groups" style={classes.link}>
          <ListItem disablePadding onClick={props.close} selected={url.includes('/home/groups') ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <PeopleAltIcon sx={{ color: '#ED4D6E' }} />
              </ListItemIcon>
              <ListItemText primary="Grupos" />
            </ListItemButton>
          </ListItem>
        </Link>

        {/**<Link to="/courses" className={classes.link}>
                    <ListItem button>
                        <ListItemIcon>
                            <FunctionsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cursos de ayuda" />
                    </ListItem>
                </Link>*/}

        {/**<Link to="/config" className={classes.link}>
                    <ListItem button>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configuración" />
                    </ListItem>
                </Link>*/}

        {user.role === 'admin' || user.role === 'moderator' ?
          <div>
            <Divider variant='middle' />
            <Link to="/admin" style={classes.link}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DataUsageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Administración" />
                </ListItemButton>
              </ListItem>
            </Link>
          </div> : null
        }
        <Typography textAlign={'center'} variant="caption" color="disabled.main" sx={{ ml: theme.spacing(2) }}>
          Versión {packageJson.version}
        </Typography>
      </List>
    </div>
  )
}

export default MenuList
