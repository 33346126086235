import { MATH_GRADIENTS } from '../../../styles/MathGradients'

/**
 * font-size: calc([minimum size] + ([maximum size] - [minimum size]) * 
 * ((100vw - [minimum viewport width]) / ([maximum viewport width] -
 *  [minimum viewport width])));
 */

export const useStyles = (theme) => ({
  tile: {
    height: '100%',
    width: '100%'
  },
  paperTile: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentHorizontal: {
    height: '80%',
  },
  labelHorizontal: {
    height: '20%',
    color: theme.palette.text.main,
  },
  verticalLabelRight: {
    transform: 'rotate(-90deg)',
    color: theme.palette.text.main,
    fontSize: '1vw',
    width: '200%',
    textAlign: 'center'
  },
  verticalLabelLeft: {
    transform: 'rotate(90deg)',
    color: theme.palette.text.main,
    fontSize: '1vw',
    width: '200%',
    textAlign: 'center'
  },
  labelSize: {
    fontSize: `0.8vw`,
  },
  symbolSize: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: `1.75vh`,
    color: 'white'
  },
  corner_tile: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#FFF'
  },
  start_tile: {
    borderRadius: '0 0 0 15px',
    background: MATH_GRADIENTS(-135).start_tile
  },
  random_exc_tile: {
    borderRadius: '0 0 15px 0',
    background: MATH_GRADIENTS(-225).random_exc_tile
  },
  challenge_tile: {
    borderRadius: '0 15px 0 0',
    background: MATH_GRADIENTS(45).challenge_tile
  },
  event_tile: {
    borderRadius: '15px 0 0 0',
    background: MATH_GRADIENTS(135).event_tile
  },
  image: {
    width: '6vh',
    marginBottom: theme.spacing(1)
  },
  menuTitle: {
    textAlign: 'center',
    color: theme.palette.rush.main,
    fontSize: '6vh'
  },
  tileNumber: {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '50%',
    width: '2.5vh',
    height: '2.5vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.3vh',
    fontWeight: 'bold',
    margin: '0.5vh'
  }
});