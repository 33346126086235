import React, { Fragment } from 'react'
import useAuth from '../../../hooks/useAuth'
import { Link, Redirect } from "react-router-dom";
import { useTheme } from "@mui/material/styles"
import {
  Button, Grid, Typography, Box
} from "@mui/material"

/**Icons */
import AddIcon from '@mui/icons-material/Add'
import { MATH_GRADIENTS } from '../../../styles/MathGradients';

const useStyles = (theme) => ({
  title: {
    color: '#616161',
    marginBottom: theme.spacing(3)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary
    }
  },
  button: {
    marginBottom: theme.spacing(1),
    width: '100%',
    background: MATH_GRADIENTS().default,
    color: '#FFF'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
});

function MenuGroups() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();

  React.useEffect(() => {
    document.title = 'Grupos - Panel de administración | Math Paradise'
  }, [])

  if (user.role !== 'admin') {
    return <Redirect to="/admin" />
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h4" sx={classes.title}>
            Gestión de Grupos
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Link to="/admin/groups/create" style={classes.link}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={classes.button}
                >
                  Crear Grupo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Box>
            Lista
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default MenuGroups
