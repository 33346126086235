import { basePath, apiVersion, rush_path } from '../config/api'
import { getAccessTokenApi } from './auth'

export function createRushGameApi(data) {
  const url = `${basePath}/${apiVersion}/create-rush-game`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getRushGameByPinApi(pin) {
  const url = `${basePath}/${apiVersion}/get-rush-game-pin?pin=${pin}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getRushGamesByNicknameApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-rush-games-nickname/${nickname}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getRushGameCountApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-rush-game-count/${nickname}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getRushLeaderboardApi(nicknames = []) {
  const url = `${basePath}/${apiVersion}/get-rush-leaderboard`
  const params = {
    method: 'POST',
    body: JSON.stringify(nicknames),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function updateRushGameApi(data, pin) {
  const url = `${basePath}/${apiVersion}/update-rush-game?pin=${pin}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function deleteRushGameApi(pin) {
  const url = `${basePath}/${apiVersion}/delete-rush-game?pin=${pin}`
  const params = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getRushServerStatusApi() {
  const url = `${rush_path}/api/status`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json"
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}