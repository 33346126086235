import React from 'react'

function CreateGroup() {
    return (
        <div>

        </div>
    )
}

export default CreateGroup
