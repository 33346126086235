import React, { useState } from 'react'
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
  useTheme, CircularProgress,
  Box,
  Typography
} from '@mui/material'
import { getClassicGameByPinApi } from '../../../api/classicGame';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MATH_GRADIENTS } from '../../../styles/MathGradients';

function JoinPrivateClassic(props) {
  const { open = false, handleClose = () => { } } = props;
  const history = useHistory();
  const theme = useTheme();

  const [pin, setPin] = useState("");
  const [isGameFound, setIsGameFound] = useState(false);
  const [staticGame, setStaticGame] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const findGame = () => {

    if (pin.trim() === "") {
      return;
    }

    //Iniciamos carga
    setIsLoading(true);

    getClassicGameByPinApi(pin).then(response => {
      setIsLoading(false);
      if (response.status === 0) {
        setMessage(response.message);
      } else {
        if (response.game.status !== 'in_lobby') {
          setIsGameFound(false);
          setMessage("Esta partida ya fue iniciada o se ha terminado.")
        } else {
          setStaticGame(response.game);
          setIsGameFound(true);
        }
      }
    }).catch(e => {
      setIsLoading(false);
    });
  }

  const onCancel = () => {
    handleClose();
  }

  return (
    <Dialog open={open} onClose={onCancel} maxWidth='md'>
      <DialogTitle>Unirse a partida</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ingresa el PIN de la partida:
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="normal"
          id="name"
          name="email"
          label="PIN"
          fullWidth
          variant="outlined"
          color='classic'
          sx={{ mb: theme.spacing(2) }}
          onChange={(e) => { setPin(e.target.value) }}
          value={pin} />
        {
          isGameFound ? (
            <Box sx={{ mb: theme.spacing(2) }}>
              <Typography>Creador de la partida: @{staticGame.host}</Typography>
            </Box>
          ) : (
            <Box sx={{ mb: theme.spacing(2) }}>
              <Typography>{message}</Typography>
            </Box>
          )
        }
        {
          isLoading && (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} mb={theme.spacing(2)}>
              <CircularProgress variant='indeterminate' color='classic' />
            </Box>
          )
        }
        <DialogActions>
          {
            !isGameFound ?
              <Button variant='contained' sx={{ mr: theme.spacing(2) }} onClick={findGame}>
                Buscar partida
              </Button> :
              <Button variant='contained' sx={{ mr: theme.spacing(2), background: MATH_GRADIENTS().classic, color: 'white' }}
                onClick={() => {
                  history.push(`/private-classic-lobby/${pin}`, { game: staticGame })
                }}>
                Unirse a partida
              </Button>

          }
          <Button variant='contained' color='error' onClick={onCancel}>
            Cancelar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default JoinPrivateClassic