const path = process.env.REACT_APP_REST_URL;
export const basePath = `${path}/api`;
export const apiVersion = "v1"

export const classic_path = process.env.REACT_APP_CLASSIC_SOCKET_URL;
export const arcade_path = process.env.REACT_APP_ARCADE_SOCKET_URL;
export const rush_path = process.env.REACT_APP_RUSH_SOCKET_URL;

export const wsPrivateClassicServerBasePath = `${classic_path}/private-classic`;
export const wsPrivateArcadeServerBasePath = `${arcade_path}/private-arcade`;
export const wsPrivateRushServerBasePath = `${rush_path}/private-rush`;