import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../useStyles'
import { BlockMath, InlineMath } from "react-katex"
//import 'katex/dist/katex.min.css';
import {
  Grid, Typography, Paper, Box, Divider, Button, TextField, FormControl,
  Select, MenuItem, InputLabel, FormControlLabel, Switch,
  useTheme
} from "@mui/material"

/**Conts */
import { difficulties } from '../../../utils/SelectArrays'

//Hooks
import useAuth from '../../../hooks/useAuth';

/**APIS */
import { getAreasApi } from '../../../api/areas'
import { getTopicsApi } from '../../../api/topics'
import { getTSubtopicsApi } from '../../../api/subtopics'
import { createExcercise } from '../../../api/excercises'

/**Components */
import DefaultSnackbar from '../../../components/common/DefaultSnackbar'

/**Icons */
import AddIcon from '@mui/icons-material/Add'

function Create() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();

  const [areas, setAreas] = useState([])
  const [topics, setTopics] = useState([])
  const [subtopics, setSubtopics] = useState([])

  const [inputs, setInputs] = useState({
    label: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    answer: "",
    area: "",
    topic: "",
    subtopic: "",
    difficulty: "",
    active: false,
    user_modification: user.id
  })
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)

  //Cerrar notificación
  const handleCloseSnackbar = () => {
    setOpen(false)
  }

  useEffect(() => {
    //Areas
    getAreasApi().then(r => {
      if (r.status === 1) {
        setAreas(r.areas)
      }
    })
  }, [])

  //Efecto que reacciona al area seleccionada y muestra los temas correspondiente
  useEffect(() => {
    //Topics
    getTopicsApi(`area=${inputs.area}`).then(r => {
      if (r.status === 1) {
        setTopics(r.topics)
      }
    })
  }, [inputs.area])

  //Efecto que reacciona al area seleccionada y muestra los temas correspondiente
  useEffect(() => {
    //Topics
    getTSubtopicsApi(`area=${inputs.area}&topic=${inputs.topic}`).then(r => {
      if (r.status === 1) {
        setSubtopics(r.subtopics)
      }
    })
  }, [inputs.area, inputs.topic])

  //Cambio de valores de los states y formulario
  const changeForm = (e) => {
    if (e.target.type === 'checkbox') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked
      })
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      })
    }
  }

  const submit = (e) => {
    e.preventDefault()

    const { label, option_a, option_b, option_c, option_d, answer, area, topic,
      subtopic, difficulty } = inputs

    if (label === "" || option_a === "" || option_b === "" || option_c === "" ||
      option_d === "" || answer === "" || area === "" || topic === "" ||
      subtopic === "" || difficulty === "") {
      setMessage("Todos los campos son requeridos")
      setOpen(true)
    } else {
      createExcercise(inputs).then(r => {
        setMessage(r.message)
        setOpen(true)
        if (r.status === 1) {
          window.location.href = "/admin/excercises"
        }
      })
    }
  }

  return (
    <Fragment>
      <DefaultSnackbar
        open={open}
        handleClose={handleCloseSnackbar}
        message={message} />
      <Paper sx={classes.paper}>
        <Typography variant="h5" sx={classes.title}>
          <AddIcon fontSize="large" /> Añadir ejercicio
        </Typography>
        <Divider />
        <Box sx={classes.excerPreviewBox}>
          {inputs.label !== "" ?
            <Box>
              <BlockMath math={inputs.label} renderError={(error) => { return <span sx={classes.labelError}>Error: función desconocida o incompleta.</span> }} />
              <Typography sx={classes.previewLabel}>Ejercicio principal</Typography>
            </Box> :
            <span style={classes.previewLabel}>
              Escriba el ejercicio en funciones <a href="https://katex.org/docs/supported.html" target="_blank" rel="noreferrer">KaTex</a> en el campo inferior
            </span>
          }
        </Box>
        <Box sx={classes.optionsPreviewBox}>
          <Grid container spacing={1} >
            <Grid item lg={3} sx={classes.textCenter}>
              {inputs.option_a !== "" ?
                <Box>
                  <BlockMath math={inputs.option_a} />
                  <Typography sx={classes.previewLabel}>Opción A</Typography>
                </Box> :
                <span>
                  Escriba la opción [a] en funciones KaTex
                </span>
              }
            </Grid>
            <Grid item lg={3} sx={classes.textCenter}>
              {inputs.option_b !== "" ?
                <Box>
                  <BlockMath math={inputs.option_b} />
                  <Typography sx={classes.previewLabel}>Opción B</Typography>
                </Box> :
                <span>
                  Escriba la opción [b] en funciones KaTex
                </span>
              }
            </Grid>
            <Grid item lg={3} sx={classes.textCenter}>
              {inputs.option_c !== "" ?
                <Box>
                  <BlockMath math={inputs.option_c} />
                  <Typography sx={classes.previewLabel}>Opción C</Typography>
                </Box> :
                <span>
                  Escriba la opción [c] en funciones KaTex
                </span>
              }
            </Grid>
            <Grid item lg={3} sx={classes.textCenter}>
              {inputs.option_d !== "" ?
                <Box>
                  <BlockMath math={inputs.option_d} />
                  <Typography sx={classes.previewLabel}>Opción D</Typography>
                </Box> :
                <span>
                  Escriba la opción [d] en funciones KaTex
                </span>
              }
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={classes.formBox}>
          <form onChange={changeForm} onSubmit={submit}>
            <Typography>Todos los campos son requeridos</Typography>
            <Grid container spacing={2} sx={classes.form}>
              <Grid item lg={8}>
                <TextField
                  type="text"
                  name="label"
                  label="Ingrese el ejercicio principal (use funciones KaTex)"
                  variant="outlined"
                  sx={classes.textField} />
              </Grid>
              <Grid item lg={4}>
                {inputs.option_a !== "" && inputs.option_b !== "" && inputs.option_c !== "" && inputs.option_d !== "" ?
                  <FormControl variant="outlined" sx={classes.textField}>
                    <InputLabel id="right_answer">Seleccione la respuesta correcta</InputLabel>
                    <Select
                      name="answer"
                      labelId="right_answer"
                      label="Seleccione la respuesta correcta"
                      value={inputs.answer}
                      onChange={changeForm} >

                      <MenuItem value={inputs.option_a}>
                        {inputs.option_a !== "" ?
                          <InlineMath math={`a)\\; ${inputs.option_a}`} /> :
                          <span>
                            Opción A
                          </span>
                        }
                      </MenuItem>
                      <MenuItem value={inputs.option_b}>
                        {inputs.option_b !== "" ?
                          <InlineMath math={`b)\\; ${inputs.option_b}`} /> :
                          <span>
                            Opción B
                          </span>
                        }
                      </MenuItem>
                      <MenuItem value={inputs.option_c}>
                        {inputs.option_c !== "" ?
                          <InlineMath math={`c)\\; ${inputs.option_c}`} /> :
                          <span>
                            Opción C
                          </span>
                        }
                      </MenuItem>
                      <MenuItem value={inputs.option_d}>
                        {inputs.option_d !== "" ?
                          <InlineMath math={`d)\\; ${inputs.option_d}`} /> :
                          <span>
                            Opción D
                          </span>
                        }
                      </MenuItem>
                    </Select>
                  </FormControl> : null
                }
              </Grid>
              <Grid item lg={3}>
                <TextField
                  type="text"
                  name="option_a"
                  label="Ingrese la opción a"
                  variant="outlined"
                  sx={classes.textField} />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  type="text"
                  name="option_b"
                  label="Ingrese la opción b"
                  variant="outlined"
                  sx={classes.textField} />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  type="text"
                  name="option_c"
                  label="Ingrese la opción c"
                  variant="outlined"
                  sx={classes.textField} />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  type="text"
                  name="option_d"
                  label="Ingrese la opción d"
                  variant="outlined"
                  sx={classes.textField} />
              </Grid>
              <Grid item lg={3}>
                <FormControl variant="outlined" sx={classes.textField}>
                  <InputLabel id="lbl_area">Seleccione el área</InputLabel>
                  <Select
                    name="area"
                    labelId="lbl_area"
                    label="Seleccione el área"
                    value={inputs.area}
                    onChange={changeForm}>


                    {areas.map((values, index) =>
                      <MenuItem
                        style={{ color: !values.active ? 'red' : 'black' }}
                        key={index} value={values.code}>
                        {values.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3}>
                <FormControl variant="outlined" sx={classes.textField}>
                  <InputLabel id="lbl_topic">Seleccione el tema</InputLabel>
                  <Select
                    name="topic"
                    labelId="lbl_topic"
                    label="Seleccione el tema"
                    value={inputs.topic}
                    onChange={changeForm}>

                    {topics.map((values, index) =>
                      <MenuItem
                        style={{ color: !values.active ? 'red' : 'black' }}
                        key={index} value={values.code}>
                        {values.name}
                      </MenuItem>
                    )}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <FormControl variant="outlined" sx={classes.textField}>
                  <InputLabel id="lbl_subtopic">Seleccione el subtema</InputLabel>
                  <Select
                    name="subtopic"
                    labelId="lbl_subtopic"
                    label="Seleccione el subtema"
                    value={inputs.subtopic}
                    onChange={changeForm}>

                    {subtopics.map((values, index) =>
                      <MenuItem
                        style={{ color: !values.active ? 'red' : 'black' }}
                        key={index} value={values.code}>
                        {values.name}
                      </MenuItem>
                    )}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <FormControl variant="outlined" sx={classes.textField}>
                  <InputLabel id="lbl_dif">Seleccione la dificultad</InputLabel>
                  <Select
                    name="difficulty"
                    labelId="lbl_dif"
                    label="Seleccione la dificultad"
                    value={inputs.difficulty}
                    onChange={changeForm}>

                    {difficulties.map((values, index) =>
                      <MenuItem key={index} value={values.val}>{values.name}</MenuItem>
                    )}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <FormControlLabel label="Estado (Habilitado / deshabilitado)" control={
                  <Switch
                    checked={inputs.active}
                    name="active" />
                } />
              </Grid>
            </Grid>

            <div style={classes.formButtons}>
              <Link style={classes.link} to="/admin/excercises">
                <Button sx={classes.cancelButton}>
                  Cancelar
                </Button>
              </Link>
              <Button type="submit" sx={classes.okButton}>
                Guardar ejercicio
              </Button>
            </div>
          </form>
        </Box>
      </Paper>
    </Fragment>
  )
}

export default Create
