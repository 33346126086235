import React, { useEffect, Fragment } from 'react'
import useAuth from '../../../hooks/useAuth';
import { Link, Redirect } from "react-router-dom";
import {
  Button, Grid, Typography, Box,
  useTheme
} from "@mui/material"
import { useStyles } from '../useStyles';

/**Components */
import InstitutionsTable from '../../../components/info_tables/InstitutionsTable'

/**Icons */
import AddIcon from '@mui/icons-material/Add'

function Menu() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();

  useEffect(() => {
    document.title = 'Instituciones - Panel de administración | Math Paradise'
  }, [])

  if (user.role !== 'admin') {
    return <Redirect to="/admin" />
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h4" color='primary' gutterBottom>
            Gestión de Instituciones
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Link to="/admin/institutions/create" style={classes.link}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={classes.button}
                >
                  Agregar Institución
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Box>
            <InstitutionsTable />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Menu
