import React, { Fragment, useState } from 'react'
import { Redirect } from 'react-router-dom';
import {
  Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, TextField,
  Button, CircularProgress,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'

//APIS
import { joinToGroupApi } from '../../../api/groups';
import useAuth from '../../../hooks/useAuth'

//Components
import DefaultSnackbar from '../../common/DefaultSnackbar';

//Utils
import { minLenghtValidation } from '../../../utils/FormValidation';

function JoinToGroup(props) {
  const theme = useTheme();
  const { open, onClose } = props;
  const { user } = useAuth();
  const [accessCode, setAccessCode] = useState("");
  const [groupId, setGroupId] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isJoined, setIsJoined] = useState(false);

  const joinToGroup = () => {
    if (minLenghtValidation(accessCode.trim(), 9)) {
      setMessage("Ingrese un código válido.");
      setOpenSnack(true);
      return;
    }
    //Inicia carga
    setIsLoading(true);

    joinToGroupApi(accessCode, user.id).then(response => {
      if (response.status === 1) {
        setGroupId(response.group._id);
        setIsJoined(true);
      } else {
        setMessage(response.message);
        setOpenSnack(true);
        setIsLoading(false);
      }
    })
  }

  if (isJoined && groupId.trim() !== '') {
    return <Redirect to={`groups/${groupId}`} />
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DefaultSnackbar
        open={openSnack}
        handleClose={() => setOpenSnack(false)}
        message={message} />
      <DialogTitle>Unirse a un grupo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Escribe el código de acceso del grupo que tu tutor te ha dado. El
          código debe tener 8 dígitos.
        </DialogContentText>
        <TextField
          sx={{ mt: theme.spacing(2) }}
          autoFocus
          label="Código de acceso"
          type="text"
          variant='outlined'
          fullWidth
          onChange={(e) => setAccessCode(e.target.value)} />
      </DialogContent>
      <DialogActions>
        {
          isLoading ?
            <CircularProgress variant='indeterminate' /> :
            <Fragment>
              <Button variant='contained' color='primary' onClick={joinToGroup}>
                Unirse
              </Button>
              <Button variant='contained' color='secondary' onClick={onClose}>
                Cancelar
              </Button>
            </Fragment>
        }
      </DialogActions>
    </Dialog>
  )
}

JoinToGroup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reload: PropTypes.func,
  showConfirmation: PropTypes.func,
  children: PropTypes.element
}

export default JoinToGroup