import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getRandomExcerciseApi } from '../../../../../api/excercises'
import useAuth from '../../../../../hooks/useAuth'

import ArcadeBoard from '../../../../../components/game_elements/ArcadeBoard/ArcadeBoard'
import Menu from '../../../../../components/game_elements/Menu/Menu'
import ExcercisePanel from '../../../../../components/game_elements/ExcercisePanelArcade'
import Roulette from '../../../../../components/game_elements/Roulette'
import ArcadeResults from '../../../../../components/game_elements/results/ArcadeResults/ArcadeResults'
import { Howl } from 'howler'

function LocalArcadeGame() {
  const location = useLocation();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [game, setGame] = useState(location.state?.game);
  const [position, setPosition] = useState(0);
  const [isClockRunning, setIsClockRunning] = useState(true);
  const [clock, setClock] = useState(game.time);
  const [isChallenge, setIsChallenge] = useState(false); //Variable para indicar si es reto o no
  const [isRandom, setIsRandom] = useState(false); //Variable  para indicar si es ejercicio random o no
  // eslint-disable-next-line no-unused-vars
  const [isRoulette, setIsRoulette] = useState(false) //Variable para indicar si es panel de ruleta
  const [gameFinished, setGameFinished] = useState(false);
  const [phase, setPhase] = useState('draw');

  const [excercise, setExcercise] = useState({
    _id: "",
    label: "\\frac{s}{t}+u+v+w+x+y+z=0",
    option_a: "x+y+z=a",
    option_b: "x+y+z=b",
    option_c: "x+y+z=c",
    option_d: "x+y+z=d",
    answer: "x+y+z=a",
    area: "Area",
    topic: "Tema",
    subtopic: "Subtema",
    difficulty: "normal",
    create_date: new Date(),
    active: true
  }); //Ejercicio buscado (Con place holder)

  //Variables para paneles
  const [openExcercisePanel, setOpenExcercisePanel] = useState(false); //Variable para abrir y cerrar el pandel de ejercicios
  const [openRoulette, setOpenRoulette] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [isLoading, setIsLoading] = useState(true)
  const { user: localUser } = useAuth();

  const [sounds, setSounds] = useState(null);
  useEffect(() => {
    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'game_start', url: '/sounds/games/general/game_start.wav' },
      { name: 'game_finished', url: '/sounds/games/general/game_finished.wav' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    soundInstances['game_start'].play();

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  //Efecto para cargar la partida (usuario local) e inicia el reloj
  useEffect(() => {
    if (localUser) {
      setIsLoading(false);
    }
  }, [localUser, game]);

  //Efecto para iniciar el reloj
  useEffect(() => {
    if (isClockRunning) {
      if (clock > 0) {
        const intervalClock = setInterval(() => {
          setClock(prevClock => prevClock - 1);
        }, 1000);

        return () => clearInterval(intervalClock);
      } else {
        setIsClockRunning(false);

        //Fin de partida
        setOpenResults(true);
        setGameFinished(true);
        sounds['game_finished'].play();
      }
    }
  }, [isClockRunning, clock, sounds]);

  /**
   * Efecto que detecta si los jugadores no tienen monedas o si se acabó el tiempo
   * para finalizar la partida
   */
  useEffect(() => {
    if (game) {
      const players = game.players;
      let allCoins = 0;

      players.forEach(pl => {
        allCoins += pl.coins;
      })

      //Si se acabó el tiempo o ya nadie tiene monedas
      if ((clock === 0 || allCoins === 0) && phase === 'draw') {
        sounds['game_finished'].play();
        setGameFinished(true);
        setIsClockRunning(false);
        setOpenExcercisePanel(false);
        setOpenRoulette(false);
        setOpenResults(true);
      }
    }
  }, [clock, game, phase, sounds]);

  /**
   * Función para modificar el mensaje cuando alguien tira
   */
  const handleRoll = () => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToRoll = players.find(player => player.nickname === game.turn);

    updateGame.message = `@${playerToRoll.nickname} a tirado una moneda.`;
  }

  /**
   * Función para obtener el valor del roll e iniciar la casilla
   * @param {*} pos 
   */
  const getRollPosition = (pos) => {
    setPosition(pos);
    setPhase('answering');

    //Detectamos casillas especiales
    const slot = pos % 22;
    const randomSlot = Math.floor(Math.random() * game.board.length);

    if (slot === 0 || slot === 11) { //Ruletas
      setIsRandom(false);
      setIsChallenge(false);
      setIsRoulette(true);

      setOpenRoulette(true);
    } else if (slot === 5) { //Ejercicio Random
      setIsRandom(true);
      setIsChallenge(false);
      setIsRoulette(false);

      //Buscamos un subtema aleatorio del tablero
      const subtopic = game.board[randomSlot];

      //Buscamos el ejercicio en la BD y lo mostramos en el panel
      getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
        if (response.excercise.length > 0) {
          setExcercise(response.excercise[0]);
          setTimeout(() => {
            setOpenExcercisePanel(true);
          }, 1000);
        } else {
          changeTurn();
        }
      }).catch(e => {
        changeTurn();
      })
    } else if (slot === 16) { //Reto
      setIsRandom(false);
      setIsChallenge(true);
      setIsRoulette(false);

      //Buscamos un subtema aleatorio del tablero
      const subtopic = game.board[randomSlot];

      //Buscamos el ejercicio en la BD y lo mostramos en el panel
      getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
        if (response.excercise.length > 0) {
          setExcercise(response.excercise[0]);
          setTimeout(() => {
            setOpenExcercisePanel(true);
          }, 1000);
        } else {
          changeTurn();
        }
      }).catch(e => {
        changeTurn();
      })
    } else if (slot !== 0 || slot !== 11) { //Casilla normal y NO ruletas
      setIsRandom(false);
      setIsChallenge(false);
      setIsRoulette(false);

      //Buscamos el subtemas entre el tablero
      const subtopic = game.board[slot];

      //Buscamos el ejercicio en la BD y lo mostramos en el panel
      getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
        if (response.excercise.length > 0) {
          setExcercise(response.excercise[0]);
          setTimeout(() => {
            setOpenExcercisePanel(true);
          }, 1000);
        } else {
          changeTurn();
        }
      }).catch(e => {
        changeTurn();
      })
    }
  }

  /**
   * Función para obtener los resultados del panel de ejercicios
   * @param {*} result 
   */
  const getExcerciseResults = (results) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToRoll = players.find(player => player.nickname === game.turn);

    //sumanmos los puntos, el ejercicio y si fue correcto o no
    playerToRoll.points += results.earnedPoints;
    playerToRoll.answered += 1;
    if (results.isCorrect) {
      playerToRoll.correct += 1;
    } else {
      playerToRoll.errors += 1;
    }

    changeTurn();
  }

  /**
   * Función para obtener el dato de la ruleta
   * @param {*} result 
   */
  const getRouletteItem = (result) => {
    setOpenRoulette(false);

    const updateGame = game;
    const players = updateGame.players;
    const playerToRoll = players.find(player => player.nickname === game.turn);

    switch (result) {
      case "+10 puntos":
        playerToRoll.points += 10;
        changeTurn();
        break;
      case "Ejercicio":
        setIsRandom(true);
        getAdditionalExcercise();
        break;
      case "Reto":
        setIsChallenge(true);
        getAdditionalExcercise();
        break;
      case "Moneda Extra":
        playerToRoll.coins += 1;
        setPhase('draw');
        break;
      case "+2 Monedas Extra":
        playerToRoll.coins += 2;
        setPhase('draw');
        break;
      case "-10 puntos":
        playerToRoll.points -= 10;
        changeTurn();
        break;
      default:
        changeTurn();
        break;
    }
  }

  /**
   * Función para buscar un ejercicio a parte de la posición
   */
  const getAdditionalExcercise = (random = false) => {
    let subtopic = null;
    if (random) { //Si es random el ejercicio
      subtopic = game.board[Math.floor(Math.random() * game.board.length)]
    } else { //Ejercicioo de acuerdo a la posición
      subtopic = game.board[position % game.board.length];
    }

    //Buscamos el ejercicio en la BD y lo mostramos en el panel
    getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
      if (response.excercise.length > 0) {
        setExcercise(response.excercise[0]);
        setTimeout(() => {
          setOpenExcercisePanel(true);
        }, 500);
      }
    }).catch(e => {

    });
  }

  /**
   * Función para terminar la partida
   */
  const onEndGame = () => {
    history.push('/home/play/arcade');
  }

  /**
  * Función para cambiar turno, rotando entre los jugadores por nùmero de jugador
  */
  const changeTurn = () => {
    const updateGame = game;
    const players = game.players;
    const totalPlayers = players.length;
    let player = players.find(player => player.nickname === game.turn);
    let totalCoins = 0;

    //Quitamos una moneda al que tiró
    const playerToRoll = players.find(player => player.nickname === game.turn);
    playerToRoll.coins -= 1; //Quitamos una moneda

    //Guardamos datos en el arreglo de players
    const newPlayers = players.map(player => player.nickname === game.turn ? playerToRoll : player);

    //Calculamos el total de monedas
    players.forEach(pl => {
      totalCoins += pl.coins;
    })

    //Cambia de turno
    //Si el nuevo player ya no tiene monedas, empieza a buscar al siguiente que tenga
    do {
      let playerNumber = player.number;

      if (player.number === totalPlayers)
        playerNumber = 1;
      else
        playerNumber += 1;

      player = players.find(pl => pl.number === playerNumber);
    } while (player.coins === 0 && totalCoins > 0);

    updateGame.players = newPlayers;
    updateGame.turn = player.nickname;
    updateGame.message = `Es el turno de @${player.nickname}`;
    setPhase('draw');
  }

  return (
    <Fragment>
      <Menu open={openMenu} handleClose={() => setOpenMenu(false)} onExit={onEndGame} turn={localUser ? localUser.nickname : ''} localUser={localUser} />
      <ExcercisePanel open={openExcercisePanel}
        handleClose={() => setOpenExcercisePanel(false)}
        excercise={excercise}
        sendExcerciseResults={getExcerciseResults}
        isRandom={isRandom}
        isChallenge={isChallenge}
        iconType={game ? game.distinctive : 'gems'}
        icon={game ? game.board[position % 22].icon : 'amethyst'}
        time={
          game && game.difficulty === 'easy' ? 150 : game && game.difficulty === 'normal' ? 120 : game && game.difficulty === 'hard' ? 75 : 120
        } />
      <Menu
        open={openMenu}
        handleClose={() => {
          setIsClockRunning(true);
          setOpenMenu(false);
        }}
        onExit={() => onEndGame()}
        turn={localUser ? localUser.nickname : ''}
        localUser={localUser} />
      <Roulette
        open={openRoulette}
        rouletteItems={["+10 puntos", "Ejercicio", "Reto", "Moneda Extra", "Gira Otra Vez", "Ejercicio", "+2 Monedas Extra", "-10 puntos"]}
        sendRouletteItem={getRouletteItem} />
      <ArcadeResults open={openResults} game={game} endGame={onEndGame} gameFinished={gameFinished} />
      <ArcadeBoard
        isLoading={isLoading}
        game={game}
        phase={phase}
        localUser={localUser}
        handleRoll={handleRoll}
        sendRollPosition={getRollPosition}
        clock={clock}
        handleOpenMenu={() => {
          setOpenMenu(!openMenu);
          setIsClockRunning(false);
        }}
        handleChangePhase={(ph) => {
          setPhase(ph);
        }}
        mode='local'
      />
    </Fragment>
  )
}

export default LocalArcadeGame