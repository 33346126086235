import React, { useState, useEffect, useDeferredValue, Suspense } from 'react'
import { Box, LinearProgress, Paper, Typography, useTheme } from '@mui/material'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { getArcadeGameCountApi } from '../../../api/arcadeGame';
import { getClassicGameCountApi } from '../../../api/classicGame';
import { getRushGameCountApi } from '../../../api/rushGame';

function HistoryChart(props) {
  const { nickname } = props;
  const [data, setData] = useState([]);
  const deferredData = useDeferredValue(data);
  const theme = useTheme();

  useEffect(() => {
    const mapGamemodes = {
      classic: {
        color: '#64a730',
        label: 'Clásico'
      },
      arcade: {
        color: '#b00243',
        label: 'Arcade'
      },
      rush: {
        color: '#156bbe',
        label: 'Rush'
      },
      deathmatch: {
        color: '#730017',
        label: 'Deathmatch'
      },
    }

    const fetchGameData = async () => {
      try {
        const [classicCountResponse, arcadeCountResponse, rushCountResponse] = await Promise.all([
          getClassicGameCountApi(nickname),
          getArcadeGameCountApi(nickname),
          getRushGameCountApi(nickname)
        ]);

        const formatResponse = (response) => {
          if (response?.status === 1) {
            if (response?.count > 0) {
              return {
                id: response.gamemode,
                value: response.count,
                label: mapGamemodes[response.gamemode].label,
                color: mapGamemodes[response.gamemode].color
              }
            } else {
              return null
            }
          }
        }

        const formattedData = [
          formatResponse(classicCountResponse),
          formatResponse(arcadeCountResponse),
          formatResponse(rushCountResponse)
        ].filter(item => item !== null);

        setData(formattedData);
      } catch (error) {

      }
    }

    fetchGameData();
  }, [nickname])

  console.log(data)

  return (
    <Paper sx={{ p: theme.spacing(2), borderRadius: 4 }}>
      <Typography variant='h6' gutterBottom>Modos más jugados</Typography>
      <Suspense fallback={<LinearProgress />}>
        <Box display={'flex'} justifyContent={'center'}>
          {
            data.length === 0 ?
              <Typography textAlign={'center'}>No hay datos</Typography> :
              <PieChart
                height={250}
                series={[
                  {
                    data: deferredData,
                    arcLabel: (item) => `${item.value}`,
                    innerRadius: theme.spacing(4),
                    paddingAngle: 4,
                    cx: '40%'
                  }
                ]}
                sx={{
                  my: theme.spacing(2),
                  pl: theme.spacing(1),
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white'
                  },
                }}
                onItemClick={(e) => { }} />
          }
        </Box>
      </Suspense>
    </Paper>
  )
}

export default HistoryChart