import React, { Fragment, useEffect, useState } from 'react'
import {
  Box, Container, Paper, Typography, Grid2 as Grid,
  useTheme, TextField, Button,
  LinearProgress, Backdrop, CircularProgress, useMediaQuery,
  IconButton
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import { animated, easings, useSpring } from '@react-spring/web'

//Utils
import { MATH_GRADIENTS } from '../../../styles/MathGradients'
import { rush_topics } from '../../../utils/SelectArrays';
import { formatTime } from '../../../utils/TimeFormat';
import { useStyles } from './useStyles';

//Images
import rushIcon from '../../../assets/images/icons/rush_icon_white.svg';

//Components
import RushCompactedPlayerCard from '../rush_player_cards/RushCompactedPlayerCard';
import CircularMultiplier from '../CircularMultiplier';
import RushPlayerCard from '../rush_player_cards/RushPlayerCard';
import { Howl } from 'howler'

function RushUI(props) {
  const {
    game,
    topic,
    points,
    level,
    excercises,
    excercise,
    multiplier,
    streak,
    seconds,
    combo,
    streakSeconds,
    pointsToEarn,
    secondsToEarn,
    isCorrect,
    isLoading,
    handleAnswer = () => { },
    handleOpenMenu
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const portrait = useMediaQuery('(orientation: portrait)');

  //Animaciones
  const card1Spring = useSpring({
    to: { transform: game && game.topics[0] === topic ? 'translateY(-20px) rotateY(720deg)' : 'translateY(0px) rotateY(360deg)' },
    from: { transform: 'translateY(0px) rotateY(0deg)' }, // Posición inicial
    config: { tension: 80, friction: 10 },
    reset: false
  });
  const card2Spring = useSpring({
    to: { transform: game && game.topics[1] === topic ? 'translateY(-20px) rotateY(720deg)' : 'translateY(0px) rotateY(360deg)' },
    from: { transform: 'translateY(0px) rotateY(0deg)' }, // Posición inicial
    config: { tension: 80, friction: 10 },
    reset: false
  });
  const card3Spring = useSpring({
    to: { transform: game && game.topics[2] === topic ? 'translateY(-20px) rotateY(720deg)' : 'translateY(0px) rotateY(360deg)' },
    from: { transform: 'translateY(0px) rotateY(0deg)' }, // Posición inicial
    config: { tension: 80, friction: 10 },
    reset: false
  });
  const card4Spring = useSpring({
    to: { transform: game && game.topics[3] === topic ? 'translateY(-20px) rotateY(720deg)' : 'translateY(0px) rotateY(360deg)' },
    from: { transform: 'translateY(0px) rotateY(0deg)' }, // Posición inicial
    config: { tension: 80, friction: 10 },
    reset: false
  });
  const [comboSpring, comboSpringApi] = useSpring(() => ({
    position: 'absolute',
    top: '300%', // Valor inicial
    transform: 'rotateZ(-22.5deg)',
    config: { easing: easings.easeInElastic(0.5), },
  }));
  const [difficultySpring, difficultySpringApi] = useSpring(() => ({
    position: 'absolute',
    top: '300%',
    transform: 'rotateZ(22.5deg)',
    config: { easing: easings.easeInElastic(0.5), },
  }));
  const [addPointsSpring, addPointsSpringApi] = useSpring(() => ({
    opacity: 0,
    color: '#6DFE5A'
  }));
  const [addMultplrSpring, addMultplrSpringApi] = useSpring(() => ({
    opacity: 0,
    color: '#6DFE5A'
  }));
  const [addLevelSpring, addLevelSpringApi] = useSpring(() => ({
    opacity: 0,
    color: '#6DFE5A'
  }));
  const [addExcersSpring, addExcersSpringApi] = useSpring(() => ({
    opacity: 0,
    color: '#6DFE5A'
  }));
  const [addSecondsSpring, addSecondsSpringApi] = useSpring(() => ({
    opacity: 0,
    color: theme.palette.success.main
  }));
  const [streakBarSpring, streakBarSpringApi] = useSpring(() => ({
    opacity: 0,
  }));
  const pointsSpring = useSpring({ val: points, from: { val: points + pointsToEarn } });

  const [musicTheme, setMusicTheme] = useState(null);
  useEffect(() => {
    //Musica
    const music = new Howl({
      src: ['/music/rush_theme.ogg'],
      loop: true,
      volume: 0.25
    });

    music.play();
    setMusicTheme(music);

    return () => {
      // Limpiar la instancia de Howl cuando el componente se desmonte
      music.unload();
    };
  }, []);

  useEffect(() => {
    if (musicTheme) {
      if (level < 10) musicTheme.rate(1);
      else if (level >= 10 && level < 20) musicTheme.rate(1.1);
      else if (level >= 20 && level < 30) musicTheme.rate(1.2);
      else if (level >= 30 && level < 40) musicTheme.rate(1.3);
      else if (level >= 40 && level < 50) musicTheme.rate(1.4);
      else if (level >= 50) musicTheme.rate(1.5);
    }
  }, [level, musicTheme])

  //Efectos para controlar la animacion del combo
  useEffect(() => {
    comboSpringApi.start({
      top: combo >= 2 ? '-10%' : '300%',
    });
  }, [combo, comboSpringApi]);

  //Efecto para controlar animación de la dificultad
  useEffect(() => {
    if (level % 5 === 1 && level > 1) {
      // Aparece el elemento
      difficultySpringApi.start({ top: '-10%' });

      // Desaparece después de 3 segundos
      const timer = setTimeout(() => {
        difficultySpringApi.start({ top: '300%' });
      }, 5000);

      // Limpia el temporizador cuando el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [level, difficultySpringApi]);

  //Efecto para controlar animación del nivel
  useEffect(() => {
    if (level > 1) {
      // Aparece el elemento
      addLevelSpringApi.start({ opacity: 1 });

      // Desaparece después de 3 segundos
      const timer = setTimeout(() => {
        addLevelSpringApi.start({ opacity: 0 });
      }, 3000);

      // Limpia el temporizador cuando el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [level, addLevelSpringApi]);

  //Efecto para controlar animación del multiplciador
  useEffect(() => {
    if (multiplier > 1) {
      // Aparece el elemento
      addMultplrSpringApi.start({ opacity: 1 });

      // Desaparece después de 3 segundos
      const timer = setTimeout(() => {
        addMultplrSpringApi.start({ opacity: 0 });
      }, 3000);

      // Limpia el temporizador cuando el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [multiplier, addMultplrSpringApi]);

  //Efecto para controlar el texto de nuevos puntos
  useEffect(() => {
    if (points > 0) {
      // Aparece el elemento
      addPointsSpringApi.start({ opacity: 1 });

      // Desaparece después de 3 segundos
      const timer = setTimeout(() => {
        addPointsSpringApi.start({ opacity: 0 });
      }, 3000);

      // Limpia el temporizador cuando el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [points, pointsToEarn, addPointsSpringApi]);

  //Efecto para controlar animación de los ejercicios
  useEffect(() => {
    if (excercises > 1) {
      // Aparece el elemento
      addExcersSpringApi.start({ opacity: 1 });

      // Desaparece después de 3 segundos
      const timer = setTimeout(() => {
        addExcersSpringApi.start({ opacity: 0 });
      }, 3000);

      // Limpia el temporizador cuando el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [excercises, addExcersSpringApi]);

  //Efecto para controlar animación de los segundos
  useEffect(() => {
    if (game && points > 0 && topic === game.topics[0]) {
      // Aparece el elemento
      addSecondsSpringApi.start({ opacity: 1 });

      // Desaparece después de 3 segundos
      const timer = setTimeout(() => {
        addSecondsSpringApi.start({ opacity: 0 });
      }, 2000);

      // Limpia el temporizador cuando el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [secondsToEarn, addSecondsSpringApi, game, points, topic]);

  //Efecto para mostrar la barra de racha
  useEffect(() => {
    if (streakSeconds <= 3 && combo > 2) {
      streakBarSpringApi.start({
        opacity: 1
      })
    } else {
      streakBarSpringApi.start({
        opacity: 0
      })
    }
  }, [streakSeconds, combo, streakBarSpringApi])


  //Jugadores
  const player1 = game ? game.players.find(player => player.number === 1) : null;
  const player2 = game ? game.players.find(player => player.number === 2) : null;
  const player3 = game ? game.players.find(player => player.number === 3) : null;
  const player4 = game ? game.players.find(player => player.number === 4) : null;

  //Usuario
  const [answer, setAnswer] = useState("");

  //Mapping de los temas del rush
  const Topic = (selectedTopic) => {
    let card = {};

    rush_topics.forEach(g => {
      card[g.val] = g
    });

    return card[selectedTopic];
  }

  //Función para manejar los valores del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAnswer(answer);
    setAnswer("");
  }

  if (isLoading) {
    return (
      <div style={classes.background}>
        <Backdrop open={true} sx={classes.loadingScreen}>
          <CircularProgress sx={{ color: 'white' }} />
          <Typography variant="h5">Cargando</Typography>
        </Backdrop>
      </div>
    )
  }

  return (
    <Fragment>
      <Box sx={{
        width: '100vw',
        height: '100vh',
        background: MATH_GRADIENTS(45).rush,
        overflow: !portrait ? 'hidden' : 'auto'
      }}>
        <Container maxWidth='lg' sx={{ py: theme.spacing(2) }}>
          <Box sx={{
            display: { xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none' },
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img src={rushIcon} alt='rush_icon_white.svg' style={{ width: 40, marginRight: theme.spacing(2) }} />
            <Typography variant='h4' style={{ color: 'white' }}>Modo Rush</Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pt: theme.spacing(2)
          }}>
            <Grid container spacing={2} sx={{ mt: theme.spacing(0), width: '100%' }}>
              <Grid size={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }} display={{ xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }}>
                {
                  player1 && game && game.players.length > 1 ? <RushPlayerCard color={'player_1'} player={player1} /> : null
                }
                {
                  player3 && game && game.players.length > 1 ? <RushPlayerCard color={'player_3'} player={player3} /> : null
                }
              </Grid>
              <Grid size={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
                <Paper sx={{
                  width: '100%',
                  p: theme.spacing(4),
                }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', mb: theme.spacing(1) }}>
                    <Box sx={{ display: { xl: 'none', lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }, mr: theme.spacing(1) }}>
                      <img alt={`${Topic(topic).val}_icon.svg`} src={Topic(topic).path}
                        style={{
                          width: '4.5vh'
                        }} />
                    </Box>
                    <Typography sx={{
                      fontSize: '4.5vh',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                      {Topic(topic).spanish_name.toUpperCase()}
                    </Typography>
                  </Box>
                  <Typography fontSize={'4vh'} color='primary'>{excercise}</Typography>
                  <form onSubmit={handleSubmit}>
                    <Box sx={{
                      mt: theme.spacing('4vw'),
                      mb: theme.spacing(2), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                      <TextField
                        id='answer'
                        type={'text'}
                        variant='outlined'
                        label='Respuesta'
                        autoFocus
                        slotProps={{
                          input: {
                            style: {
                              fontSize: '22px', // Tamaño de la letra en el área de texto
                            },
                          },
                          label: {
                            style: {
                              fontSize: '22px', // Tamaño de la letra en el label
                            },
                          },
                        }}
                        sx={{ mb: theme.spacing(3), width: '75%', fontSize: '30px' }}
                        error={isCorrect === null ? false : !isCorrect}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                      />
                      <Button sx={{ background: MATH_GRADIENTS().default, color: 'white', width: '75%', height: '60px' }} type="submit">
                        RESPONDER
                      </Button>
                    </Box>
                  </form>
                  <animated.div style={{ ...streakBarSpring, display: 'flex', justifyContent: 'center', height: 10 }}>
                    <LinearProgress variant='determinate' value={streakSeconds * 20} sx={{
                      width: '75%',
                      background: 'rgba(255, 255, 255, 0.4)',
                      '& .MuiLinearProgress-bar1Determinate': {
                        background: 'linear-gradient(45deg, #51c26f, #dce956, #e98fc0)'
                      }
                    }} />
                  </animated.div>
                  <Box sx={{
                    display: { xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' },
                    mt: theme.spacing('1vh')
                  }}>
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <Typography>Puntos: <span style={{ color: theme.palette.success.main }}>{points.toLocaleString("en-US")}</span></Typography>
                      </Grid>
                      <Grid size={6} textAlign={'end'}>
                        <Typography>Multiplicador: <span style={{ color: theme.palette.success.main }}>&times;{multiplier}</span></Typography>
                      </Grid>
                      <Grid size={6}>
                        <Typography>Nivel: <span style={{ color: theme.palette.success.main }}>{level}</span></Typography>
                      </Grid>
                      <Grid size={6} textAlign={'end'}>
                        <Typography>Ejercicios: <span style={{ color: theme.palette.success.main }}>{excercises}</span></Typography>
                      </Grid>
                      <Grid size={12} textAlign={'center'}>
                        <Typography>Combo: <span style={{ color: '#e98fc0' }}>&times;{combo}</span></Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: theme.spacing('2vh') }}>
                    <Typography gutterBottom>Tiempo - {formatTime(seconds)} <animated.span style={addSecondsSpring}> +{secondsToEarn} segundos</animated.span></Typography>
                    <LinearProgress variant='determinate' value={seconds / 60 * 100} color={seconds <= 9 ? 'error' : 'primary'} />
                  </Box>
                  <Box display={'flex'} justifyContent={'center'} mt={'4px'}>
                    <IconButton onClick={handleOpenMenu}>
                      <SettingsIcon sx={{ fontSize: '3vh' }} />
                    </IconButton>
                  </Box>
                </Paper>
                <Box mt={theme.spacing(2)} display={{ xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' }}>
                  <Grid container spacing={1}>
                    <Grid size={6}>
                      {player1 && game && game.players.length > 1 ? <RushCompactedPlayerCard color={'player_1'} player={player1} /> : null}
                    </Grid>
                    <Grid size={6}>
                      {player2 && game && game.players.length > 1 ? <RushCompactedPlayerCard color={'player_2'} player={player2} /> : null}
                    </Grid>
                    <Grid size={6}>
                      {player3 && game && game.players.length > 1 ? <RushCompactedPlayerCard color={'player_3'} player={player3} /> : null}
                    </Grid>
                    <Grid size={6}>
                      {player4 && game && game.players.length > 1 ? <RushCompactedPlayerCard color={'player_4'} player={player4} /> : null}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid size={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }} display={{ xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }}>
                {
                  player2 && game && game.players.length > 1 ? <RushPlayerCard color={'player_2'} player={player2} /> : null
                }
                {
                  player4 && game && game.players.length > 1 ? <RushPlayerCard color={'player_4'} player={player4} /> : null
                }
              </Grid>
            </Grid>
            <Box
              display={{ xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(2),
                width: '100%'
              }}>
              <Grid container spacing={2} width={'100%'}>
                <Grid size={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 6 }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Typography fontSize={'2.5vh'} ml={theme.spacing(1)} sx={{ color: 'white' }}>
                    Nivel:&nbsp;
                    <span style={{ color: '#E9D66B' }}>
                      {level.toLocaleString("en-US")}
                    </span>
                    <animated.span style={addLevelSpring}> +1</animated.span>
                  </Typography>
                </Grid>
                <Grid size={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 6 }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularMultiplier value={streak} size={60} multiplier={multiplier} />
                    <Typography fontSize={'2.5vh'} ml={theme.spacing(1)} sx={{ color: 'white' }}>
                      Multiplicador <animated.span style={addMultplrSpring}> +1</animated.span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 6 }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Typography fontSize={'2.5vh'} ml={theme.spacing(1)} sx={{ color: 'white' }}>
                    Puntos:&nbsp;
                    <animated.span style={{ color: '#E9D66B' }}>
                      {pointsSpring.val.to(val => Math.floor(val).toLocaleString("en-US"))}
                    </animated.span>
                    <animated.span style={addPointsSpring}> +{pointsToEarn}</animated.span>
                  </Typography>
                </Grid>
                <Grid size={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 6 }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Typography fontSize={'2.5vh'} ml={theme.spacing(1)} sx={{ color: 'white' }}>
                    Ejercicios:&nbsp;
                    <span style={{ color: '#E9D66B' }}>
                      {excercises.toLocaleString("en-US")}
                    </span>
                    <animated.span style={addExcersSpring}> +1</animated.span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{
            display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
            flexDirection: 'row',
            justifyContent: 'center',
            mt: '4vw'
          }}>
            <Box sx={{
              position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
              mx: theme.spacing(2), width: '100%'
            }}>
              <animated.div style={comboSpring}>
                <Typography style={{
                  fontSize: '2vw',
                  fontWeight: 'bold',
                  background: 'linear-gradient(45deg, #51c26f, #dce956, #e98fc0)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                }}>
                  COMBO
                </Typography>
                <Typography style={{
                  fontSize: '4vw',
                  fontWeight: 'bold',
                  background: 'linear-gradient(45deg, #51c26f, #dce956, #e98fc0)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  marginTop: theme.spacing('-1.1vw')
                }}>
                  &times;{combo}
                </Typography>
              </animated.div>
            </Box>
            <Box sx={{ mx: theme.spacing(2) }}>
              <animated.div style={card1Spring}>
                <img alt={`${Topic(game.topics[0]).val}_icon.svg`} src={Topic(game.topics[0]).path} style={{ width: '10vh' }} />
              </animated.div>
            </Box>
            <Box sx={{ mx: theme.spacing(2) }}>
              <animated.div style={card2Spring}>
                <img alt={`${Topic(game.topics[1]).val}_icon.svg`} src={Topic(game.topics[1]).path} style={{ width: '10vh' }} />
              </animated.div>
            </Box>
            <Box sx={{ mx: theme.spacing(2) }}>
              <animated.div style={card3Spring}>
                <img alt={`${Topic(game.topics[2]).val}_icon.svg`} src={Topic(game.topics[2]).path} style={{ width: '10vh' }} />
              </animated.div>
            </Box>
            <Box sx={{ mx: theme.spacing(2) }}>
              <animated.div style={card4Spring}>
                <img alt={`${Topic(game.topics[3]).val}_icon.svg`} src={Topic(game.topics[3]).path} style={{ width: '10vh' }} />
              </animated.div>
            </Box>
            <Box sx={{
              position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
              mx: theme.spacing(2), width: '100%'
            }}>
              <animated.div style={difficultySpring}>
                <Typography style={{
                  fontSize: '2.5vw',
                  fontWeight: 'bolder',
                  background: 'linear-gradient(45deg, #ff930f, #f24389, #a478f1)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  lineHeight: '1'
                }}>
                  ¡Dificultad aumentada!
                </Typography>
              </animated.div>
            </Box>
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default RushUI