import React from 'react'
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button
} from '@mui/material'
import PropTypes from 'prop-types'

function Notification(props) {
  const { open, onClose, onAccept, title, children } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={'sm'}>
      <DialogTitle style={{ fontWeight: 'bold' }}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="primary" variant='contained' onClick={onAccept}>Aceptar</Button>
        <Button color="error" variant='contained' onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  )
}

Notification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node
}

export default Notification
