import { basePath, apiVersion } from '../config/api'
import { getAccessTokenApi } from './auth'

export function getTopicsApi(query = '') {
  const url = `${basePath}/${apiVersion}/get-topics?${query}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function createTopicApi(data) {
  const url = `${basePath}/${apiVersion}/create-topic`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function deleteTopicApi(id) {
  const url = `${basePath}/${apiVersion}/delete-topic/${id}`
  const params = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function getTopicByIdApi(id) {
  const url = `${basePath}/${apiVersion}/get-topic?id=${id}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function getTopicsByCodesApi(codes) {
  const url = `${basePath}/${apiVersion}/get-topics-by-codes`
  const params = {
    method: 'POST',
    body: JSON.stringify(codes),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function updateTopicApi(data, id) {
  const url = `${basePath}/${apiVersion}/update-topic/${id}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}