import React, { Fragment, useState } from 'react'
import { Redirect } from 'react-router-dom';
import {
  Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, TextField,
  Button, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem, Box
} from '@mui/material'
import PropTypes from 'prop-types'

//APIS
import { createGroupApi } from '../../../api/groups';
import useAuth from '../../../hooks/useAuth'

//Components
import DefaultSnackbar from '../../common/DefaultSnackbar';

//Utils
import { colors } from '../../../utils/SelectArrays';
import { minLenghtValidation } from '../../../utils/FormValidation';

//Images
import defaultCover from '../../../assets/images/backgrounds/groups/default.svg';
import tilesCover from '../../../assets/images/backgrounds/groups/tiles.svg';
import slotsCover from '../../../assets/images/backgrounds/groups/slots.svg';
import timeCover from '../../../assets/images/backgrounds/groups/time.svg';

function CreateGroup(props) {
  const { open, onClose } = props;
  const { user } = useAuth();

  const [openSnack, setOpenSnack] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [inputs, setInputs] = useState({
    name: '',
    description: '',
    color: '#2A55FF',
    cover_image: 'default.svg',
    creator: user.id,
    members: []
  });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const createGroup = () => {
    if (!minLenghtValidation(inputs.name.trim(), 1)) {
      setMessage('Agregue un nombre');
      setOpenSnack(true);
    }

    if (!minLenghtValidation(inputs.description.trim(), 1)) {
      setMessage('Agregue una descripción');
      setOpenSnack(true);
    }

    //Inicia la carga
    setIsLoading(true);

    createGroupApi(inputs).then(response => {
      if (response.status === 1) {
        setIsCreated(true);
        setGroupId(response.group._id);
      } else {
        setIsLoading(false);
        setMessage(response.message);
        setOpenSnack(true);
      }
    })
  }

  if (isCreated && groupId.trim() !== '') {
    return <Redirect to={`groups/${groupId}`} />
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'>
      <DefaultSnackbar
        open={openSnack}
        handleClose={() => setOpenSnack(false)}
        message={message} />
      <DialogTitle>Crear un grupo</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          Crear un grupo y añade posteriormente a estudiantes.
        </DialogContentText>
        <form>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='Nombre del grupo'
                inputProps={{
                  "maxLength": 22
                }}
                onChange={(e) => setInputs({ ...inputs, name: e.target.value })} />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant='outlined'
                label='Descripción'
                inputProps={{
                  "maxLength": 55
                }}
                onChange={(e) => setInputs({ ...inputs, description: e.target.value })} />
            </Grid>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={9} lg={9} md={9} sm={8} xs={8}>
                  <FormControl
                    fullWidth
                    variant='outlined'>
                    <InputLabel id='grp-color'>Color</InputLabel>
                    <Select
                      labelId='grp-color'
                      label='Color'
                      value={inputs.color}
                      onChange={(e) => setInputs({ ...inputs, color: e.target.value })}>
                      {
                        colors.map((value, index) => (
                          <MenuItem key={index} value={value.val}>
                            {value.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
                  <Box style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: inputs.color,
                    borderRadius: 10
                  }}>
                    &nbsp;
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={9} lg={9} md={9} sm={8} xs={8}>
                  <FormControl
                    fullWidth
                    variant='outlined'>
                    <InputLabel id='grp-imagen'>Imagen</InputLabel>
                    <Select
                      labelId='grp-imagen'
                      label='Color'
                      value={inputs.cover_image}
                      onChange={(e) => setInputs({ ...inputs, cover_image: e.target.value })}>
                      <MenuItem value={'default.svg'}>
                        Imagen por defecto
                      </MenuItem>
                      <MenuItem value={'tiles.svg'}>
                        Casillas
                      </MenuItem>
                      <MenuItem value={'slots.svg'}>
                        Tragamonedas
                      </MenuItem>
                      <MenuItem value={'time.svg'}>
                        Tiempo
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
                  <Box style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${inputs.cover_image === 'default.svg' ? defaultCover :
                      inputs.cover_image === 'tiles.svg' ? tilesCover :
                        inputs.cover_image === 'slots.svg' ? slotsCover :
                          inputs.cover_image === 'time.svg' ? timeCover : defaultCover
                      })`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    borderRadius: 10
                  }}>
                    &nbsp;
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {
          isLoading ?
            <CircularProgress variant='indeterminate' /> :
            <Fragment>
              <Button variant='contained' color='primary' onClick={createGroup}>
                Crear grupo
              </Button>
              <Button variant='contained' onClick={onClose} color='error'>
                Cancelar
              </Button>
            </Fragment>
        }
      </DialogActions>
    </Dialog>
  )
}

CreateGroup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reload: PropTypes.func,
  showConfirmation: PropTypes.func,
  children: PropTypes.element
}

export default CreateGroup