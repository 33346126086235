import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material'
import packageJson from '../../../../../package.json';

/**Imagenes */
import nd_logo from '../../../../assets/images/logos/ND_logo_vertical.svg'
import { MATH_GRADIENTS } from '../../../../styles/MathGradients';

function Footer() {
  const theme = useTheme();
  const linkColor = '#FFFFFF';

  return (
    <Paper square elevation={0} sx={{
      background: MATH_GRADIENTS().default,
      px: theme.spacing(2),
      pb: theme.spacing(2),
      marginTop: theme.spacing(6),
      width: '100%',
      color: 'white'
    }}>
      <Box sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}>
        <Paper elevation={0} sx={{ borderRadius: '0 0 8px 8px', p: theme.spacing(2), backgroundColor: 'white' }}>
          <img src={nd_logo} alt="ND_logo_vertical.svg" width={'160px'} style={{ marginInline: theme.spacing(2) }} />
        </Paper>
      </Box>
      <Grid container spacing={2} justifyContent='center' sx={{ my: theme.spacing(4) }}>
        <Grid item lg={3} md={3} sm={6} xs={6}>
          <Box textAlign={'center'}>
            <Typography variant="h6" gutterBottom>Social</Typography>
            <a style={{ textDecoration: 'none', color: linkColor }} href="https://www.facebook.com/Math-Paradise-2132810540284835" target="_blank" rel="noopener noreferrer">Facebook</a>
            <br />
            <a style={{ textDecoration: 'none', color: linkColor }} href="https://www.instagram.com/mathparadise.juega/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={6}>
          <Box textAlign={'center'}>
            <Typography variant="h6" gutterBottom>Más información</Typography>
            <Link style={{ textDecoration: 'none', color: linkColor }} to={"/privacy-policies"}>
              Políticas de privacidad
            </Link>
            <br />
            <Link style={{ textDecoration: 'none', color: linkColor }} to={'/credits'}>
              Créditos
            </Link>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={6}>
          <Box textAlign={'center'}>
            <Typography variant="h6" gutterBottom>Plataforma</Typography>
            <Link style={{ textDecoration: 'none', color: linkColor }} to={'/help'}>
              Ayuda
            </Link>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={6}>
          <Box textAlign={'center'}>
            <Typography variant="h6" gutterBottom>Contacto</Typography>
            <a style={{ textDecoration: 'none', color: linkColor }} href="mailto:mathparadise.juega@gmail.com" rel="noopener noreferrer">
              Feedback
            </a>
          </Box>
        </Grid>
      </Grid>

      <Box textAlign={'center'}>
        <Typography>
          Math Paradise&reg; {packageJson.version} - Todos los derechos reservados
        </Typography>
        <Typography>{new Date().getFullYear().toString()}</Typography>
      </Box>
    </Paper>
  )
}

export default Footer