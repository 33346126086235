const useStyles = (groupColor, theme) => ({
  root: {
    '& h5': {
      color: groupColor
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  btn: {
    background: groupColor,
    color: 'white',
    '&:hover': {
      background: groupColor
    }
  },
  form: {
    paddingBlock: theme.spacing(2)
  },
  accessCodeBox: {
    textAlign: 'end'
  },
  accessCodeLabel: {
    fontSize: 22,
    color: groupColor
  },
  deleteGroupBtn: {
    color: '#FFF',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  divider: {
    marginBlock: theme.spacing(2)
  }
});

export default useStyles;