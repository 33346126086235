import cornSrc from '../assets/images/vegies/corn.svg';
import avocadoSrc from '../assets/images/vegies/avocado.svg';
import onionSrc from '../assets/images/vegies/onion.svg';
import radishSrc from '../assets/images/vegies/radish.svg';
import pumpkinSrc from '../assets/images/vegies/pumpkin.svg';
import lettuceSrc from '../assets/images/vegies/lettuce.svg';
import pitayaSrc from '../assets/images/vegies/pitaya.svg';

import cloverSrc from '../assets/images/poker/clover.png';
import diamondsSrc from '../assets/images/poker/diamonds.png';
import heartSrc from '../assets/images/poker/heart.png';
import kingSrc from '../assets/images/poker/king.png';
import queenSrc from '../assets/images/poker/queen.png';
import spadesSrc from '../assets/images/poker/spades.png';
import valetSrc from '../assets/images/poker/valet.png';

import agateSrc from '../assets/images/gems/agate.png';
import amethystSrc from '../assets/images/gems/amethyst.png';
import aquamarineSrc from '../assets/images/gems/aquamarine.png';
import diamondSrc from '../assets/images/gems/diamond.png';
import emeraldSrc from '../assets/images/gems/emerald.png';
import quartzSrc from '../assets/images/gems/quartz.png';
import rubySrc from '../assets/images/gems/ruby.png';

import defaultSrc from '../assets/images/avatars/default.svg';
import unicornSrc from '../assets/images/avatars/unicorn.jpeg';
import shoeSrc from '../assets/images/avatars/shoe.jpeg';
import axolotlSrc from '../assets/images/avatars/axolotl.jpeg';
import skullySrc from '../assets/images/avatars/skully.jpeg';
import mouseSrc from '../assets/images/avatars/mouse.jpeg';
import applepenSrc from '../assets/images/avatars/applepen.jpeg';
import pineapplepenSrc from '../assets/images/avatars/pineapplepen.jpeg';
import avocadoFaceSrc from '../assets/images/avatars/avocadoface.png';
import tacorabbitSrc from '../assets/images/avatars/tacorabbit.jpeg';
import tortuburgerSrc from '../assets/images/avatars/tortuburger.png';
import burrizardSrc from '../assets/images/avatars/burrizard.png';
import catSrc from '../assets/images/avatars/cat.png';
import dogSrc from '../assets/images/avatars/dog.png';
import juicebearSrc from '../assets/images/avatars/juicebear.png';

import sumSrc from '../assets/images/layouts/rush/sum_icon.svg';
import restaSrc from '../assets/images/layouts/rush/resta_icon.svg';
import multSrc from '../assets/images/layouts/rush/mult_icon.svg';
import divSrc from '../assets/images/layouts/rush/div_icon.svg';
import potSrc from '../assets/images/layouts/rush/pot_icon.svg';
import raizSrc from '../assets/images/layouts/rush/raiz_icon.svg';
import negSrc from '../assets/images/layouts/rush/neg_icon.svg';
import decSrc from '../assets/images/layouts/rush/dec_icon.svg';
import combSrc from '../assets/images/layouts/rush/comb_icon.svg';
import mcmSrc from '../assets/images/layouts/rush/mcm_icon.svg';
import mcdSrc from '../assets/images/layouts/rush/mcd_icon.svg';

export const institutionTypes = [
  { val: "kindergarden", label: "Preescolar" },
  { val: "elementary_school", label: "Primaria" },
  { val: "junior_highschool", label: "Secundaria" },
  { val: "highschool", label: "Bachillerato" },
  { val: "college", label: "Universidad" },
]

export const grades = [
  { val: 1, name: '1er' },
  { val: 2, name: '2do' },
  { val: 3, name: '3ro' },
  { val: 4, name: '4to' },
  { val: 5, name: '5to' },
  { val: 6, name: '6to' },
  { val: 7, name: '7mo' },
  { val: 8, name: '8vo' },
  { val: 9, name: '9no' },
  { val: 10, name: '10mo' },
  { val: 11, name: '11vo' }
]

export const difficulties = [
  /* { val: 'easy', name: 'Fácil' }, */
  { val: 'normal', name: 'Normal' },
  /* { val: 'hard', name: 'Difícil' }, */
]

export const seconds_for_game = [
  { val: 300, name: '5 minutos' },
  { val: 420, name: '7 minutos' },
  { val: 600, name: '10 minutos' },
  { val: 900, name: '15 minutos' },
  { val: 1200, name: '20 minutos' },
  { val: 1500, name: '25 minutos' },
]

export const colors = [
  { val: '#3461ae', name: 'Azulmático' },
  { val: '#51C8E3', name: 'Cielo Animado' },
  { val: '#5C51A2', name: 'Espacio' },
  { val: '#404E7C', name: 'Púrpura Marina' },
  { val: '#A35FA6', name: 'Rosa Purpúrea' },
  { val: '#AD3460', name: 'Rojo Rosa' },
  { val: '#34AD69', name: 'Jade' },
]

//Colores de jugadores
export const player_colors = [
  { val: '#FF004F', name: 'player_1' },
  { val: '#468FEA', name: 'player_2' },
  { val: '#E6A817', name: 'player_3' },
  { val: '#9ACD32', name: 'player_4' },
]

//AVATARES
//M.A.T.H. Mathematical Assistant for Teaching and Helping
export const avatars = [
  { val: 'default', photo: 'default.svg', path: defaultSrc, spanish_name: 'M.A.T.H.' },
  { val: 'unicorn', photo: 'unicorn.jpeg', path: unicornSrc, spanish_name: 'Unicornio' },
  { val: 'shoe', photo: 'shoe.jpeg', path: shoeSrc, spanish_name: 'Zapato' },
  { val: 'skully', photo: 'skully.jpeg', path: skullySrc, spanish_name: 'Skully' },
  { val: 'axolotl', photo: 'axolotl.jpeg', path: axolotlSrc, spanish_name: 'Ajolote' },
  { val: 'mouse', photo: 'mouse.jpeg', path: mouseSrc, spanish_name: 'Ratón' },
  { val: 'applepen', photo: 'applepen.jpeg', path: applepenSrc, spanish_name: 'Manzana-Pen' },
  { val: 'pineapplepen', photo: 'pineapplepen.jpeg', path: pineapplepenSrc, spanish_name: 'Piña-Pen' },
  { val: 'avocadoface', photo: 'avocadoface.png', path: avocadoFaceSrc, spanish_name: 'Aguacate' },
  { val: 'tacorabbit', photo: 'tacorabbit.jpeg', path: tacorabbitSrc, spanish_name: 'Taconejo' },
  { val: 'tortuburger', photo: 'tortuburger.png', path: tortuburgerSrc, spanish_name: 'Tortuguesa' },
  { val: 'burrizard', photo: 'burrizard.png', path: burrizardSrc, spanish_name: 'Burrigarto' },
  { val: 'cat', photo: 'cat.png', path: catSrc, spanish_name: 'Gatobanana' },
  { val: 'dog', photo: 'dog.png', path: dogSrc, spanish_name: 'Perro Helado' },
  { val: 'juicebear', photo: 'juicebear.png', path: juicebearSrc, spanish_name: 'Jugoso de Manzana' },
]

//Distintivos modo arcade
export const arcade_vegies = [
  { val: 'onion', photo: 'onion.svg', path: onionSrc, points: 100, spanish_name: 'Cebolla' },
  { val: 'corn', photo: 'corn.svg', path: cornSrc, points: 125, spanish_name: 'Maíz' },
  { val: 'avocado', photo: 'avocado.svg', path: avocadoSrc, points: 175, spanish_name: 'Aguacate' },
  { val: 'radish', photo: 'radish.svg', path: radishSrc, points: 200, spanish_name: 'Rábano' },
  { val: 'lettuce', photo: 'lettuce.svg', path: lettuceSrc, points: 275, spanish_name: 'Lechuga' },
  { val: 'pumpkin', photo: 'pumpkin.svg', path: pumpkinSrc, points: 300, spanish_name: 'Calabaza' },
  { val: 'pitaya', photo: 'pitaya.svg', path: pitayaSrc, points: 500, spanish_name: 'Fruta de Dragón' },
]

export const arcade_poker = [
  { val: 'spades', photo: 'spades.png', path: spadesSrc, points: 100, spanish_name: 'Picas' },
  { val: 'clover', photo: 'clover.png', path: cloverSrc, points: 125, spanish_name: 'Tréboles' },
  { val: 'heart', photo: 'heart.png', path: heartSrc, points: 175, spanish_name: 'Corazones' },
  { val: 'diamonds', photo: 'diamonds.png', path: diamondsSrc, points: 200, spanish_name: 'Diamantes' },
  { val: 'valet', photo: 'valet.png', path: valetSrc, points: 275, spanish_name: 'Sota' },
  { val: 'king', photo: 'king.png', path: kingSrc, points: 300, spanish_name: 'Rey' },
  { val: 'queen', photo: 'queen.png', path: queenSrc, points: 500, spanish_name: 'Reina' },
]

export const arcade_gems = [
  { val: 'amethyst', photo: 'amethyst.svg', path: amethystSrc, points: 100, spanish_name: 'Amatista' },
  { val: 'aquamarine', photo: 'aquamarine.svg', path: aquamarineSrc, points: 125, spanish_name: 'Aguamarina' },
  { val: 'agate', photo: 'agate.svg', path: agateSrc, points: 175, spanish_name: 'Ágata' },
  { val: 'ruby', photo: 'ruby.svg', path: rubySrc, points: 200, spanish_name: 'Rubí' },
  { val: 'quartz', photo: 'quartz.svg', path: quartzSrc, points: 250, spanish_name: 'Cuarzo' },
  { val: 'emerald', photo: 'emerald.svg', path: emeraldSrc, points: 300, spanish_name: 'Esmeralda' },
  { val: 'diamond', photo: 'diamond.svg', path: diamondSrc, points: 500, spanish_name: 'Diamante' },
]

//Temas del modo Rush
export const rush_topics = [
  { val: 'sum', path: sumSrc, spanish_name: 'Suma' },
  { val: 'res', path: restaSrc, spanish_name: 'Resta' },
  { val: 'mult', path: multSrc, spanish_name: 'Multiplicación' },
  { val: 'div', path: divSrc, spanish_name: 'División' },
  { val: 'neg', path: negSrc, spanish_name: 'Negativos' },
  { val: 'dec', path: decSrc, spanish_name: 'Decimales' },
  { val: 'pot', path: potSrc, spanish_name: 'Potencia' },
  { val: 'raiz', path: raizSrc, spanish_name: 'Raíz cuadrada' },
  { val: 'comb', path: combSrc, spanish_name: 'Combinadas' },
  { val: 'mcm', path: mcmSrc, spanish_name: 'Mínimo Común Múltiplo' },
  { val: 'mcd', path: mcdSrc, spanish_name: 'Máximo Común Divisor' },
]