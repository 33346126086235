import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { useStyles } from './useStyles'

function Dashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);

  React.useEffect(() => {
    document.title = 'Resumen - Panel de administración | Math Paradise'
  }, [])

  return (
    <div>
      <Typography variant='h4' sx={classes.title} gutterBottom>
        Panel general
      </Typography>
    </div>
  )
}

export default Dashboard
