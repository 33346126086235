import React, { useState, useMemo, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Backdrop, Button, Typography, useTheme } from '@mui/material';
import { MATH_GRADIENTS } from '../../../styles/MathGradients';

//Icons
import LoopIcon from '@mui/icons-material/Loop';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Roulette = (props) => {
  const { rouletteItems = [], open = false, sendRouletteItem } = props;

  const items = useMemo(() => rouletteItems, [rouletteItems]);
  const theme = useTheme();
  const [angle, setAngle] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anglePerItem, setAnglePerItem] = useState(0);
  const [spinned, setSpinned] = useState(false);

  const { rotation } = useSpring({
    from: { rotation: 0 },
    to: { rotation: angle },
    config: { tension: 200, friction: 150 },
    onRest: () => {
      setIsSpinning(false);
      detectPrize(angle);
    },
  });

  const spinWheel = () => {
    if (!isSpinning) {
      setIsSpinning(true);
      const randomAngle = 360 + Math.floor(Math.random() * (6000 + 10000) + 10000);
      setAngle(randomAngle);
    }
  };

  // Detect prize based on final angle
  const detectPrize = (finalAngle) => {
    const totalItems = items.length;
    const degreesPerItem = 360 / totalItems;
    const adjustedAngle = finalAngle % 360;
    const selectedIndex = Math.floor(adjustedAngle / degreesPerItem);
    setSelectedItem(items[totalItems - 1 - selectedIndex]); // Reverse index for clockwise rotation
  };

  //Efecto para detectar una opcion y cambiar el botón
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem === 'Gira Otra Vez') {
        setSpinned(false);
      } else {
        setSpinned(true);
      }
    }
  }, [selectedItem])

  // Calculate style for wheel segments
  const segments = useMemo(() => {
    const totalItems = items.length;
    const anglePerItem = 360 / totalItems;
    setAnglePerItem(anglePerItem);
    return items.map((item, index) => ({
      item,
      style: {
        transform: `rotate(${index * anglePerItem}deg) skewY(-${90 - anglePerItem}deg)`,
        //background: `hsl(${index * (360 / totalItems)}, 100%, 50%)`
        background: index % 2 === 0 ? "#0D001A" : "#330063"
      },
    }));
  }, [items]);

  /**
   * Función para enviar qué resultado dio la ruleta
   */
  const sendResults = () => {
    sendRouletteItem(selectedItem);
    setSpinned(false);
  }

  return (
    <Backdrop open={open} sx={{ zIndex: 2000, color: 'white', background: 'rgba(0, 0, 0, 0.8)' }}>
      <div style={{ textAlign: 'center' }}>
        <ArrowDropDownCircleIcon sx={{ fontSize: '3vw', color: '#FFBF00' }} />
        <div style={{
          position: 'relative', width: '30vw', height: '30vw', borderRadius: '50%', border: '0.8vw solid #FFBE0B', overflow: 'hidden',
        }}>
          <animated.div style={{ width: '100%', height: '100%', borderRadius: '50%', transform: rotation.to((r) => `rotate(${r}deg)`) }}>
            {segments.map((segment, index) => (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  width: '50%',
                  height: '50%',
                  top: '0',
                  left: '50%',
                  transformOrigin: '0 100%',
                  ...segment.style,
                }}
              >
                <div style={{
                  position: 'absolute',
                  top: '60%',
                  left: '-76%',
                  transform: `rotate(130deg) skewY(-${60 - anglePerItem}deg)`,
                  textAlign: 'center',
                  width: '200%'
                }}>
                  <Typography sx={{ transform: `rotate(106deg) rotateX(65deg) rotateZ(-${135 - anglePerItem}deg)`, fontSize: '1.8vw' }}>
                    {segment.item}
                  </Typography>
                </div>
              </div>
            ))}
          </animated.div>
        </div>
        {
          !spinned ?
            (
              <Button
                variant='contained'
                onClick={spinWheel}
                disabled={isSpinning}
                startIcon={<LoopIcon style={{ fontSize: '2.5vh' }} />}
                style={{
                  marginTop: theme.spacing(2),
                  background: MATH_GRADIENTS().default,
                  color: 'white',
                  width: '16vw',
                  height: '6vh',
                  fontSize: '2vh'
                }}>
                Girar ruleta
              </Button>
            ) :
            (
              <Button
                variant='contained'
                onClick={sendResults}
                disabled={isSpinning}
                startIcon={<CheckCircleIcon style={{ fontSize: '2.5vh' }} />}
                style={{
                  marginTop: theme.spacing(2),
                  background: MATH_GRADIENTS().classic,
                  color: 'white',
                  width: '16vw',
                  height: '6vh',
                  fontSize: '2vh'
                }}>
                Aceptar
              </Button>
            )
        }
        {selectedItem &&
          <Typography sx={{ mt: theme.spacing(1), fontSize: '1.5vw' }}>
            {selectedItem}
          </Typography>}
      </div >
    </Backdrop >
  );
};

export default Roulette;
