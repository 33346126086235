export const useStyles = (theme) => ({
  paper: {
    width: '100%'
  },
  gamemodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  pointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'end'
  },
  gamemodeImage: {
    width: '40px',
    marginRight: theme.spacing(2)
  },
  detailsContainer: {
    width: '100%'
  },
  playersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nicknameContainer: {
    textAlign: 'center',
    width: '100%',
    marginInline: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nickname: {
    fontSize: '1em',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '150px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px',
    }
  },
  points: {
    color: theme.palette.success.main,
    fontSize: '0.8em',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '150px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '70px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px'
    }
  },
  avatar: {
    marginInline: theme.spacing(0.8)
  },
  vsLabel: {
    color: theme.palette.error.main,
    fontSize: 28
  },
  divider: {
    marginBlock: theme.spacing(2)
  },
  infoDetailsContainer: {
    textAlign: 'start'
  },
  numbers: {
    color: theme.palette.success.main
  },
  gameDate: {
    color: theme.palette.text.primary,
    mt: theme.spacing(2),
    textAlign: 'center'
  },
  multiplierContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  multiplierBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '72px',
    minHeight: '72px',
    border: '4px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginBlock: theme.spacing(1)
  },
  multiplierLabel: {
    color: theme.palette.primary.main,
    fontSize: 24
  }
})
