import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LinearProgress, IconButton, Typography, Box
} from "@mui/material"
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
//import { DataGrid, GridToolbar } from 'material-react-table'

/**Componentes */
import Notification from '../common/Notification'

/**Iconos */
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

/**APIs */
import { getGameModesApi, deleteGameModeApi } from "../../api/gamemodes"

function GameModesTable() {
  let gameList = []
  const [gamemodes, setGamemodes] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [reload, setReload] = useState(false)

  const columns = useMemo(() => (
    [
      { accessorKey: 'id', header: 'ID' },
      { accessorKey: 'name', header: 'Nombre' },
      {
        accessorKey: 'active',
        header: 'Estado',
        Cell: ({ rValue, row }) => (
          row.original.active ?
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <CheckCircleIcon style={{ color: '#00B76F' }} />
              <span style={{ marginLeft: 5 }}>Activo</span>
            </div> :
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <CancelIcon style={{ color: '#FF2942' }} />
              <span style={{ marginLeft: 5 }}>Inactivo</span>
            </div>
        )
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        Cell: ({ rValue, row }) => (
          <Fragment>
            <Link to={`/admin/gamemodes/update/${row.original.name}`}>
              <IconButton>
                <CreateIcon style={{ fontSize: 16 }} />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                setOpen(true)
                setSelectedId(row.original.name)
              }}>
              <DeleteIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Fragment>
        )
      }
    ]
  ), [])

  useEffect(() => {
    getGameModesApi().then(response => {
      response.gameModes.forEach(value => {
        gameList.push({ ...value, id: value._id })
      })

      setGamemodes(gameList)
      setIsLoading(false)
    })

    setReload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const data = useMemo(() => gamemodes, [gamemodes]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact'
    }
  });

  return (
    <Fragment>
      <Notification
        open={open}
        onClose={() => setOpen(false)}
        title="Eliminar modo de juego"
        onAccept={() => {
          deleteGameModeApi(selectedId).then()
          setOpen(false)
          setReload(true)
        }}>
        <Typography>¿Estás seguro de querer eliminar este elemento?</Typography>
      </Notification>
      <Box sx={{ height: 400, p: 0, m: 0, maxWidth: '82vw' }}>
        {isLoading ? <LinearProgress /> :
          gamemodes.length > 0 ?
            <MaterialReactTable table={table} />
            : <Typography>No hay registros</Typography>
        }
      </Box>
    </Fragment>
  )
}

export default GameModesTable
