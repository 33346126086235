import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { Typography, Backdrop, CircularProgress } from '@mui/material';
import { io } from 'socket.io-client';
import useAuth from '../../../../../hooks/useAuth';
import { Howl } from 'howler'

//Componentes
import ClassicBoard from '../../../../../components/game_elements/ClassicBoard/ClassicBoard'
import Roulette from '../../../../../components/game_elements/Roulette'
import ExcercisePanel from '../../../../../components/game_elements/ExcercisePanelClassic';
import Notification from '../../../../../components/common/Notification/Notification';
import ClassicResults from '../../../../../components/game_elements/results/ClassicResults/ClassicResults';
import Menu from '../../../../../components/game_elements/Menu/Menu';

//Apis
import { wsPrivateClassicServerBasePath } from '../../../../../config/api';
import { deleteClassicGameApi, getClassicGameByPinApi, updateClassicGameApi } from '../../../../../api/classicGame';
import { getRandomExcerciseApi } from '../../../../../api/excercises';
import { updateUserClassicStatsApi } from '../../../../../api/user';

function PrivateClassicGame(props) {
  const { match: { params: { pin } } } = props;
  const history = useHistory();
  const location = useLocation();
  const staticGame = location.state?.game; //Juego enviado desde el Lobby (No Cambia)
  let socket = useRef(null); //Manejador del Socket
  const { user: localUser } = useAuth(); //Usuario local
  const [game, setGame] = useState(null); //Juego del socket
  const [clock, setClock] = useState(staticGame.time); //Variable para mostrar los segundos del cronómetro del servidor
  const [gameFinished, setGameFinished] = useState(false);
  const [excercise, setExcercise] = useState({
    _id: "",
    label: "\\frac{s}{t}+u+v+w+x+y+z=0",
    option_a: "x+y+z=a",
    option_b: "x+y+z=b",
    option_c: "x+y+z=c",
    option_d: "x+y+z=d",
    answer: "x+y+z=a",
    area: "Area",
    topic: "Tema",
    subtopic: "Subtema",
    difficulty: "normal",
    create_date: new Date(),
    active: true
  }); //Ejercicio buscado (Con place holder)

  //Variables para paneles
  const [openExcercisePanel, setOpenExcercisePanel] = useState(false); //Variable para abrir y cerrar el pandel de ejercicios
  const [openRoulette, setOpenRoulette] = useState(false);
  const [openNotification, setOpenNotification] = useState(false); //Variable para abrir la notificación
  const [openResults, setOpenResults] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  /**
   * draw: esperando tirar
   * answering: contestando un ejercicio
   * waiting: esperando turno
   */
  const [phase, setPhase] = useState('draw');
  const [isChallenge, setIsChallenge] = useState(false); //Variable para indicar si es reto o no
  const [isRandom, setIsRandom] = useState(false); //Variable  para indicar si es ejercicio random o no
  const [isRoulette, setIsRoulette] = useState(false) //Variable para indicar si es panel de ruleta
  const [isLoading, setIsLoading] = useState(true); //Carga del juego de la BD
  const [isSavingHistory, setIsSavingHistory] = useState(false); //Indicación de guardado de resultados

  //Sonidos
  const [sounds, setSounds] = useState({});

  useEffect(() => {
    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'game_start', url: '/sounds/games/general/game_start.wav' },
      { name: 'game_finished', url: '/sounds/games/general/game_finished.wav' },
      { name: 'my_turn', url: '/sounds/games/general/my_turn.mp3' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    soundInstances['game_start'].play();

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  //Efecto que inicializa la partida.
  useEffect(() => {
    //Carga el juego de la BD la primera vez
    getClassicGameByPinApi(pin).then(response => {
      //Si no se encontró partida en la BD
      if (response.status === 0) {
        history.push('/home/play');
      } else {
        const g = response.game;
        //Si el juego fue concluído, redirige al menú
        if (g.status === 'finished' || g.status === 'in_lobby') {
          history.push('/home/play');
        }
      }
    }).catch(e => {
      history.push('/home/play');
    })

    //Guardamos el staticGame del localStorage si es que se recarga la página
    if (!staticGame) {
      setGame(localStorage.getItem("game"));
    }

    //Conexión al WebSocker
    socket.current = io(`${wsPrivateClassicServerBasePath}/${pin}`, {
      transports: ["websocket"],
      withCredentials: true
    });

    //empieza el juego con los invitados, enviando el juego estático por primera vez.
    //Solo el host envía el juego base para todos
    if (staticGame /* && localUser.nickname === staticGame.host */) {
      socket.current.emit('game', staticGame);
    }

    //Asigna el mensaje del socket al hook del juego
    socket.current.on('game', (data) => {
      localStorage.setItem("game", JSON.stringify(data));
      setIsLoading(false); //Detiene la carga
      setGame(data); //Guarda el mensaje del socket
    });

    //Asigna el mensaje del socket al hook del reloj
    socket.current.on('clock', (sconds) => {
      setClock(sconds); //Guarda el mensaje del socket tiempo
    });

    return () => {
      socket.current.disconnect();
      socket.current.off('game');
      socket.current.off('clock');
    }
  }, [staticGame, pin, localUser, history]);

  /**
   * TO DO: Añadir música y sonidos
   */

  //Efecto para manejar el panel de ejercicios, las fases y las casillas especiales
  useEffect(() => {
    if (game) {
      //Obtenemos la info del jugador con el turno actual
      const playerToDraw = game.players.find(player => player.nickname === game.turn);

      //Función en el efecto para cambiar turno si es que no se encontró ejercicio
      const changeTurnOnNotFound = () => {
        const updateGame = game;
        let oldPlayer = game.players.find(player => player.nickname === game.turn);
        let newPlayer = null;

        let playerNumber = oldPlayer.number;

        // Usamos un bucle para encontrar el siguiente jugador válido
        do {
          // Incrementamos el número del jugador, con reinicio al inicio si es el último
          playerNumber = playerNumber === game.players.length ? 1 : playerNumber + 1;

          // Buscamos el jugador con el número actual
          // eslint-disable-next-line no-loop-func
          newPlayer = game.players.find(player => player.number === playerNumber);

          // Continuamos el bucle si el jugador tiene dropOff en true
        } while (newPlayer.dropOff);

        updateGame.turn = newPlayer.nickname;
        updateGame.message = `Es el turno de @${newPlayer.nickname}`;

        setPhase('waiting');
        sendMessageToSocket(updateGame);
      }

      if (phase === 'answering') {
        //obtenemos la posición
        const position = playerToDraw.boardPosition;

        if (position % 30 === 9) { //Si el ejercicio es random
          setIsRandom(true);
          setIsChallenge(false);
          setIsRoulette(false);

          //Buscamos un subtema aleatorio de todo el tablero
          const subtopic = staticGame.board[Math.floor(Math.random() * staticGame.board.length)];

          //Buscamos el ejercicio en la BD y lo mostramos en el panel
          getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
            if (response.excercise.length > 0) {
              setExcercise(response.excercise[0]);
              setTimeout(() => {
                setOpenExcercisePanel(true);
              }, 1000);
            } else {
              changeTurnOnNotFound();
            }
          }).catch(e => {
            changeTurnOnNotFound();
          })
        } else if (position % 30 === 15) { //Si es reto
          setIsRandom(false);
          setIsChallenge(true);
          setIsRoulette(false);

          //Buscamos un subtema aleatorio de todo el tablero
          const subtopic = staticGame.board[Math.floor(Math.random() * staticGame.board.length)];

          //Buscamos el ejercicio en la BD y lo mostramos en el panel
          getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
            if (response.excercise.length > 0) {
              setExcercise(response.excercise[0]);
              setTimeout(() => {
                setOpenExcercisePanel(true);
              }, 1000);
            } else {
              changeTurnOnNotFound();
            }
          }).catch(e => {
            changeTurnOnNotFound();
          })
        } else if (position % 30 === 24) { //Ruleta
          setIsRandom(false);
          setIsChallenge(false);
          setIsRoulette(true);

          setOpenExcercisePanel(false);
          setTimeout(() => {
            setOpenRoulette(true);
          }, 1000);
        } else if (position % 30 !== 0 && position > 0) { //Casilla normal y que no sea inicio
          setIsRandom(false);
          setIsChallenge(false);
          setIsRoulette(false);

          //Buscamos el subtemas entre el tablero
          const subtopic = staticGame.board[position % staticGame.board.length];

          //Buscamos el ejercicio en la BD y lo mostramos en el panel
          getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
            if (response.excercise.length > 0) {
              setExcercise(response.excercise[0]);
              setTimeout(() => {
                setOpenExcercisePanel(true);
              }, 1000);
            } else {
              changeTurnOnNotFound();
            }
          }).catch(e => {
            changeTurnOnNotFound();
          })
        } else { //Inicio o vuelta
          setOpenNotification(true);
        }
      }
    }
  }, [phase, staticGame, isRandom, isChallenge, isRoulette, game, sounds])

  /**
   * Si se acaba el tiempo, muestra los resultados de 
   * la partida  y cierra todos los componentes
   */
  useEffect(() => {
    if (clock === 0) {
      sounds['game_finished'].play();
      setGameFinished(true);
      setOpenExcercisePanel(false);
      setOpenRoulette(false);
      setOpenNotification(false);
      setOpenResults(true);
    }
  }, [clock, sounds]);

  //Efecto para sonido de turno
  useEffect(() => {
    if (game?.turn === localUser?.nickname && phase === 'waiting') {
      sounds['my_turn']?.play();
    }
  }, [game?.turn, sounds, localUser, phase]);

  /**
   * Función para obtener el valor del dado y mover la ficha desde el web socket de acuerdo al 
   * jugador
   * @param {*} diceNumber 
   */
  const handleDrawDice = (diceNumber) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === game.turn);

    //Sumamos el dado al valor anterior y al valor local
    playerToDraw.boardPosition += diceNumber;
    /* playerToDraw.boardPosition += 24; */

    //Guardamos datos en el arreglo de players
    const newPlayers = players.map(player => player.nickname === game.turn ? playerToDraw : player);
    updateGame.players = newPlayers;
    updateGame.message = `@${playerToDraw.nickname} a girado el dado.`;

    sendMessageToSocket(updateGame);
    setPhase('answering'); //Cambia la fase para abrir el panel de ejercicios
  }

  /**
   * Función para recibir los resultados y actualizar al jugador que contestó
   * @param {*} results 
   */
  const getExcerciseResults = (results) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === game.turn);

    //sumanmos los puntos, el ejercicio y si fue correcto o no
    playerToDraw.points += results.earnedPoints;
    playerToDraw.answered += 1;
    if (results.isCorrect) {
      playerToDraw.correct += 1;
    } else {
      playerToDraw.errors += 1;
    }

    //Guardamos datos en el arreglo de players
    const newPlayers = players.map(player => player.nickname === game.turn ? playerToDraw : player);
    updateGame.players = newPlayers;

    changeTurn();
    setPhase('waiting');
  }

  /**
   * Función que ejecuta la acción de la ruleta
   */
  const getRouletteItem = (item) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === game.turn);
    setOpenRoulette(false); //Cerramos ruleta

    switch (item) {
      case '+50 puntos':
        playerToDraw.points += 50;
        changeTurn();
        setPhase('waiting');
        break;

      case 'Ejercicio':
        getAdditionalExcercise();
        break;

      case '+3 Casillas':
        playerToDraw.boardPosition += 3;
        changeTurn();
        setPhase('waiting');
        break;

      case '-3 Casillas':
        playerToDraw.boardPosition -= 3;
        changeTurn();
        setPhase('waiting');
        break;

      case 'Regresa al inicio':
        playerToDraw.boardPosition = 0;
        changeTurn();
        setPhase('waiting');
        break;

      case '+1 Turno':
        setPhase('draw');
        break;

      case '-50 puntos':
        playerToDraw.points -= 50;
        changeTurn();
        setPhase('waiting');
        break;
      default:
        break;
    }
  }

  /**
   * Función para actualizar puntos del jugador que caiga en la casilla de inicio
   * después de dar una vuelta
   */
  const startTileBonus = () => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === updateGame.turn);
    //Cerramos notif
    setOpenNotification(false);

    playerToDraw.points += 50;

    changeTurn();
    setPhase('waiting');
  }

  /**
   * Función para buscar un ejercicio a parte de la posición
   */
  const getAdditionalExcercise = (random = false) => {
    let subtopic = null;
    const playerToDraw = game.players.find(player => player.nickname === game.turn);

    if (random) { //Si es random el ejercicio
      subtopic = staticGame.board[Math.floor(Math.random() * staticGame.board.length)]
    } else { //Ejercicio de acuerdo a la posición
      subtopic = staticGame.board[playerToDraw.boardPosition % staticGame.board.length];
    }

    //Buscamos el ejercicio en la BD y lo mostramos en el panel
    getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
      if (response.excercise.length > 0) {
        setExcercise(response.excercise[0]);
        setTimeout(() => {
          setOpenExcercisePanel(true);
        }, 500);
      } else {
        changeTurn();
      }
    }).catch(e => {
      changeTurn();
    });
  }

  /**
   * Función para manejar el fin del juego desde el 
   * panel de resultados de partida
   */
  // eslint-disable-next-line no-unused-vars
  const onEndGame = (drawedPlayers, sortedPlayers) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToUpdate = players.find(player => player.nickname === localUser.nickname);
    let result = '';

    //Verificamos si hay un ganador
    if (sortedPlayers[0].points > sortedPlayers[1].points) {
      const winner = sortedPlayers[0];

      if (winner.nickname === localUser.nickname) { //Verificamos si es ganador o no el jugador
        result = 'victory';
      } else {
        result = 'defeat';
      }
    } else { //Hay un empate
      if (drawedPlayers.length === players.length) { //Verificamos si todos empataron
        result = 'draw';
      } else if (drawedPlayers > 0) { //Verificamos si hay empates entre menos jugadores a los totales
        if (drawedPlayers.some(tied => tied.nickname === localUser.nickname)) { //Verificamos si el jugador está entre los empates
          result = 'draw';
        } else {
          result = 'defeat';
        }
      }
    }

    // Función que clasifica los resultados
    function classifyPlayers() {
      // Encuentra la puntuación más alta
      const maxPoints = Math.max(...sortedPlayers.map(player => player.points));

      // Filtra a los jugadores con la puntuación más alta
      const winners = sortedPlayers.filter(player => player.points === maxPoints);

      // Clasifica los resultados de todos los jugadores
      return sortedPlayers.map(player => {
        if (player.points === maxPoints) {
          // Si hay más de un jugador con el puntaje más alto, es empate
          return winners.length > 1
            ? { ...player, result: 'draw' }
            : { ...player, result: 'victory' }; // Si es único, es victoria
        } else {
          return { ...player, result: 'defeat' }; // Los demás pierden
        }
      });
    }

    const playerStats = {
      ...playerToUpdate,
      result: result
    }

    //Iniciamos carga
    setIsSavingHistory(true);

    //Actualizamos las estadísticas
    updateUserClassicStatsApi(playerToUpdate.nickname, playerStats).then(response => {
      //Cambiamos el status del juego
      updateClassicGameApi({ ...game, players: classifyPlayers(), status: 'finished' }, pin).then(response => {
        disconnectEverything();
      }).catch(e => {
        disconnectEverything();
      })
    }).catch(e => {
      disconnectEverything();
    })

    //Desconectamos los web sockets y regresamos al menú
    disconnectEverything();
  }

  /**
   * Función para manejar el retiro de jugadores de
   * la partida
   */
  const onExit = () => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDropOff = players.find(player => player.nickname === localUser.nickname);

    //Cambiamos la propiedad a drop off, que indica que salió del juego
    playerToDropOff.dropOff = true;

    // Verificamos si solo queda un jugador activo
    const activePlayers = players.filter(player => !player.dropOff);

    // Si hay más de un jugador activo, ejecutamos changeTurn o bajamos player
    if (activePlayers.length > 0) {
      if (game.turn === localUser.nickname) {
        changeTurn();
      } else {
        sendMessageToSocket(game);
      }
    } else {
      //Cancelamos o eliminamos el juego desde que queda un jugador
      deleteClassicGameApi(pin).then(response => {

      }).catch(e => {

      })
    }

    disconnectEverything();
  }

  /**
   * Función para desconectar todo y regresar al menú
   */
  const disconnectEverything = () => {
    localStorage.removeItem("game");
    socket.current.off('game');
    socket.current.off('clock');
    socket.current.disconnect();
    history.push('/home/play/classic');
  }

  /**
  * Función para cambiar turno, rotando entre los jugadores por nùmero de jugador
  */
  const changeTurn = () => {
    const updateGame = game;
    let oldPlayer = game.players.find(player => player.nickname === game.turn);
    let newPlayer = null;

    let playerNumber = oldPlayer.number;

    // Usamos un bucle para encontrar el siguiente jugador válido
    do {
      // Incrementamos el número del jugador, con reinicio al inicio si es el último
      playerNumber = playerNumber === game.players.length ? 1 : playerNumber + 1;

      // Buscamos el jugador con el número actual
      // eslint-disable-next-line no-loop-func
      newPlayer = game.players.find(player => player.number === playerNumber);

      // Continuamos el bucle si el jugador tiene dropOff en true
    } while (newPlayer.dropOff);

    updateGame.turn = newPlayer.nickname;
    updateGame.message = `Es el turno de @${newPlayer.nickname}`;

    sendMessageToSocket(updateGame);
  }

  /**
   * Función para enviar mensajes generales al socket
   * @param {*} game 
   */
  const sendMessageToSocket = (gameToSend) => {
    socket.current.emit('game', gameToSend);
  }

  // Bloquear la recarga de página
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Prevenir la recarga y abrir el diálogo
      event.preventDefault();
      setOpenMenu(true); // Activa el diálogo sin recargar
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Bloquear el botón de "Atrás"
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        setOpenMenu(true); // Abre el Menu antes de navegar hacia atrás
        return false;
      }
    });

    return () => unblock();
  }, [history]);

  return (
    <Fragment>
      <Backdrop open={isSavingHistory} sx={{ zIndex: 100000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <Menu open={openMenu} handleClose={() => setOpenMenu(false)} onExit={onExit} turn={game ? game.turn : ''} localUser={localUser} />
      <ExcercisePanel
        open={openExcercisePanel}
        handleClose={() => setOpenExcercisePanel(false)}
        excercise={excercise}
        sendExcerciseResults={getExcerciseResults}
        isRandom={isRandom}
        isChallenge={isChallenge}
        time={
          game && game.difficulty === 'easy' ? 150 : game && game.difficulty === 'normal' ? 120 : game && game.difficulty === 'hard' ? 75 : 120
        } />
      <Roulette
        open={openRoulette}
        rouletteItems={["+50 puntos", "Ejercicio", "+3 Casillas", "-3 Casillas", "Gira Otra Vez", "Regresa al inicio", "+1 Turno", "-50 puntos"]}
        sendRouletteItem={getRouletteItem} />
      <Notification open={openNotification} onAccept={startTileBonus} title={'Bonus'}>
        <Typography>
          Has completado una vuelta exacta al tablero.
        </Typography>
        <Typography color='success'>
          Obtienes 50 puntos.
        </Typography>
      </Notification>
      <ClassicResults open={openResults} game={game} endGame={onEndGame} gameFinished={gameFinished} />
      <ClassicBoard
        isLoading={isLoading}
        game={game}
        players={staticGame.players}
        localUser={localUser}
        phase={phase}
        sendDiceNumber={handleDrawDice}
        clock={clock}
        handleOpenMenu={() => setOpenMenu(!openMenu)} />
    </Fragment>
  )
}

export default PrivateClassicGame