const useStyles = (theme) => ({
  leader_icon: {
    width: '60px'
  },
  userPaperInfo: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  medal: {
    width: '50px'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    }
  },
  label: {
    color: theme.palette.text.secondary
  },
  points: {
    color: theme.palette.success.main
  }
});

export default useStyles;