import React, { useState, useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import { Typography } from '@mui/material';
import { Howl } from 'howler';

import ClassicBoard from '../../../../../components/game_elements/ClassicBoard/ClassicBoard';
import ClassicResults from '../../../../../components/game_elements/results/ClassicResults/ClassicResults';
import ExcercisePanelClassic from '../../../../../components/game_elements/ExcercisePanelClassic';
import Roulette from '../../../../../components/game_elements/Roulette';
import Notification from '../../../../../components/common/Notification/Notification';
import Menu from '../../../../../components/game_elements/Menu/Menu';

import { getRandomExcerciseApi } from '../../../../../api/excercises';

function LocalClassicGame(props) {
  const location = useLocation();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [game, setGame] = useState(location.state?.game);
  const [position, setPosition] = useState(0);
  const [clock, setClock] = useState(game.time);
  const [isClockRunning, setIsClockRunning] = useState(false);
  const [isChallenge, setIsChallenge] = useState(false); //Variable para indicar si es reto o no
  const [isRandom, setIsRandom] = useState(false); //Variable  para indicar si es ejercicio random o no
  // eslint-disable-next-line no-unused-vars
  const [isRoulette, setIsRoulette] = useState(false) //Variable para indicar si es panel de ruleta
  const [gameFinished, setGameFinished] = useState(false);
  const [phase, setPhase] = useState('draw');

  const [excercise, setExcercise] = useState({
    _id: "",
    label: "\\frac{s}{t}+u+v+w+x+y+z=0",
    option_a: "x+y+z=a",
    option_b: "x+y+z=b",
    option_c: "x+y+z=c",
    option_d: "x+y+z=d",
    answer: "x+y+z=a",
    area: "Area",
    topic: "Tema",
    subtopic: "Subtema",
    difficulty: "normal",
    create_date: new Date(),
    active: true
  }); //Ejercicio buscado (Con place holder)

  //Variables para paneles
  const [openExcercisePanel, setOpenExcercisePanel] = useState(false); //Variable para abrir y cerrar el pandel de ejercicios
  const [openRoulette, setOpenRoulette] = useState(false);
  const [openNotification, setOpenNotification] = useState(false); //Variable para abrir la notificación
  const [openResults, setOpenResults] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [isLoading, setIsLoading] = useState(true)
  const { user: localUser } = useAuth();

  //Sonidos
  const [sounds, setSounds] = useState({});

  useEffect(() => {
    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'game_start', url: '/sounds/games/general/game_start.wav' },
      { name: 'game_finished', url: '/sounds/games/general/game_finished.wav' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    soundInstances['game_start'].play();

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  // Efecto para manejar el estado de localUser
  useEffect(() => {
    if (localUser) {
      setIsLoading(false);
      setIsClockRunning(true);
    }
  }, [localUser]);

  //Efecto que iniciar el reloj de la partida
  useEffect(() => {
    if (isClockRunning) {
      if (clock > 0) {
        const intervalClock = setInterval(() => {
          setClock(prevClock => prevClock - 1);
        }, 1000);

        return () => clearInterval(intervalClock);
      } else {
        setIsClockRunning(false);

        //Fin de partida
        setOpenResults(true);
        setGameFinished(true);
        sounds['game_finished'].play();
      }
    }
  }, [isClockRunning, clock, sounds]);

  //Efecto para detectar las fases, la posicion y los ejercicios
  useEffect(() => {
    const changeTurnOnNotFound = () => {
      const updateGame = game;
      const players = game.players;
      const totalPlayers = players.length;
      let oldPlayer = players.find(player => player.nickname === game.turn);
      let playerNumber = oldPlayer.number;

      if (oldPlayer.number === totalPlayers)
        playerNumber = 1;
      else
        playerNumber += 1;

      let newPlayer = players.find(player => player.number === playerNumber);

      updateGame.turn = newPlayer.nickname;
      updateGame.message = `Es el turno de @${newPlayer.nickname}`;
      setPhase('draw');
    }

    if (phase === 'answering') {
      let pos = position % 30;
      let posRandom = Math.floor(Math.random() * game.board.length)

      if (pos === 0 && position > 0) { //Si la casilla es inicio y ya empezó partida. (Bonus)
        setIsRandom(false);
        setIsChallenge(false);
        setIsRoulette(false);

        setOpenNotification(true);
      } else if (pos === 9) { //Si el ejercicio es random
        setIsRandom(true);
        setIsChallenge(false);
        setIsRoulette(false);

        //Buscamos el subtemas random entre el tablero
        const subtopic = game.board[posRandom];

        //Buscamos el ejercicio en la BD y lo mostramos en el panel
        getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
          if (response.excercise.length > 0) {
            setExcercise(response.excercise[0]);
            setTimeout(() => {
              setOpenExcercisePanel(true);
            }, 1000);
          } else {
            changeTurnOnNotFound();
          }
        }).catch(e => {
          changeTurnOnNotFound();
        })
      } else if (pos === 15) { //Si el ejercicio es reto
        setIsRandom(false);
        setIsChallenge(true);
        setIsRoulette(false);

        //Buscamos el subtemas random entre el tablero
        const subtopic = game.board[posRandom];

        //Buscamos el ejercicio en la BD y lo mostramos en el panel
        getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
          if (response.excercise.length > 0) {
            setExcercise(response.excercise[0]);
            setTimeout(() => {
              setOpenExcercisePanel(true);
            }, 1000);
          } else {
            changeTurnOnNotFound();
          }
        }).catch(e => {
          changeTurnOnNotFound();
        })
      } else if (pos === 24) { //Ruleta
        setIsRandom(false);
        setIsChallenge(false);
        setIsRoulette(true);

        setOpenRoulette(true);
      } else { //Casilla normal
        setIsRandom(false);
        setIsChallenge(false);
        setIsRoulette(false);

        //Buscamos el subtemas entre el tablero
        const subtopic = game.board[pos];

        //Buscamos el ejercicio en la BD y lo mostramos en el panel
        getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
          if (response.excercise.length > 0) {
            setExcercise(response.excercise[0]);
            setTimeout(() => {
              setOpenExcercisePanel(true);
            }, 1000);
          } else {
            changeTurnOnNotFound();
          }
        }).catch(e => {
          changeTurnOnNotFound();
        })
      }

    }
  }, [phase, position, game]);

  /**
   * Función para manejar
   * @param {*} diceNumber 
   */
  const handleDrawDice = (diceNumber) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === game.turn);

    playerToDraw.boardPosition += diceNumber;
    //playerToDraw.boardPosition += 24;

    //Guardamos datos en el arreglo de players
    const newPlayers = players.map(player => player.nickname === game.turn ? playerToDraw : player);
    updateGame.players = newPlayers;
    updateGame.message = `@${playerToDraw.nickname} a girado el dado.`;

    //Cambiamos fase
    setPosition(playerToDraw.boardPosition);
    setPhase('answering');
  }

  /**
   * Función para recibir los resultados y actualizar al jugador que contestó
   * @param {*} results 
   */
  const getExcerciseResults = (results) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === game.turn);

    //sumanmos los puntos, el ejercicio y si fue correcto o no
    playerToDraw.points += results.earnedPoints;
    playerToDraw.answered += 1;
    if (results.isCorrect) {
      playerToDraw.correct += 1;
    } else {
      playerToDraw.errors += 1;
    }

    //Guardamos datos en el arreglo de players
    const newPlayers = players.map(player => player.nickname === game.turn ? playerToDraw : player);
    updateGame.players = newPlayers;

    changeTurn();
    setPhase('draw');
  }

  /**
   * Función que ejecuta la acción de la ruleta
   */
  const getRouletteItem = (item) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === game.turn);
    setOpenRoulette(false); //Cerramos ruleta

    switch (item) {
      case '+50 puntos':
        playerToDraw.points += 50;
        changeTurn();
        break;

      case 'Ejercicio':
        getAdditionalExcercise();
        break;

      case '+3 Casillas':
        playerToDraw.boardPosition += 3;
        changeTurn();
        break;

      case '-3 Casillas':
        playerToDraw.boardPosition -= 3;
        changeTurn();
        break;

      case 'Regresa al inicio':
        playerToDraw.boardPosition = 0;
        changeTurn();
        break;

      case '+1 Turno':
        setPhase('draw');
        break;

      case '-50 puntos':
        playerToDraw.points -= 50;
        changeTurn();
        break;
      default:
        break;
    }
  }

  /**
   * Función para actualizar puntos del jugador que caiga en la casilla de inicio
   * después de dar una vuelta
   */
  const startTileBonus = () => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDraw = players.find(player => player.nickname === updateGame.turn);
    //Cerramos notif
    setOpenNotification(false);

    playerToDraw.points += 50;

    changeTurn(updateGame);
    setPhase('waiting');
  }

  /**
   * Función para buscar un ejercicio a parte de la posición
   */
  const getAdditionalExcercise = (random = false) => {
    let subtopic = null;
    if (random) { //Si es random el ejercicio
      subtopic = game.board[Math.floor(Math.random() * game.board.length)]
    } else { //Ejercicioo de acuerdo a la posición
      subtopic = game.board[position % game.board.length];
    }

    //Buscamos el ejercicio en la BD y lo mostramos en el panel
    getRandomExcerciseApi(subtopic.area, subtopic.code, game.difficulty).then(response => {
      if (response.excercise.length > 0) {
        setExcercise(response.excercise[0]);
        setTimeout(() => {
          setOpenExcercisePanel(true);
        }, 500);
      } else {
        changeTurn();
      }
    }).catch(e => {
      changeTurn();
    });
  }

  /**
   * Función para fin de partida.
   */
  const onEndGame = () => {
    history.push('/home/play/classic');
  }

  /**
   * Función para cambiar de turno
   */
  const changeTurn = () => {
    const updateGame = game;
    const players = game.players;
    const totalPlayers = players.length;
    let oldPlayer = players.find(player => player.nickname === game.turn);
    let playerNumber = oldPlayer.number;

    if (oldPlayer.number === totalPlayers)
      playerNumber = 1;
    else
      playerNumber += 1;

    let newPlayer = players.find(player => player.number === playerNumber);

    updateGame.turn = newPlayer.nickname;
    updateGame.message = `Es el turno de @${newPlayer.nickname}`;
    setPhase('draw');
  }

  return (
    <Fragment>
      <ExcercisePanelClassic
        open={openExcercisePanel}
        handleClose={() => setOpenExcercisePanel(false)}
        excercise={excercise}
        sendExcerciseResults={getExcerciseResults}
        isRandom={isRandom}
        isChallenge={isChallenge}
        time={
          game && game.difficulty === 'easy' ? 150 : game && game.difficulty === 'normal' ? 120 : game && game.difficulty === 'hard' ? 75 : 120
        } />
      <Roulette
        open={openRoulette}
        rouletteItems={["+50 puntos", "Ejercicio", "+3 Casillas", "-3 Casillas", "Gira Otra Vez", "Regresa al inicio", "+1 Turno", "-50 puntos"]}
        sendRouletteItem={getRouletteItem} />
      <Notification open={openNotification} onAccept={startTileBonus} title={'Bonus'}>
        <Typography>
          Has completado una vuelta exacta al tablero.
        </Typography>
        <Typography color='success'>
          Obtienes 50 puntos.
        </Typography>
      </Notification>
      <Menu
        open={openMenu}
        handleClose={() => {
          setIsClockRunning(true);
          setOpenMenu(false);
        }}
        onExit={() => onEndGame()}
        turn={localUser ? localUser.nickname : ''}
        localUser={localUser} />
      <ClassicResults open={openResults} game={game} endGame={onEndGame} gameFinished={gameFinished} />
      <ClassicBoard
        game={game}
        phase={phase}
        players={game.players}
        isLoading={isLoading}
        localUser={localUser}
        clock={clock}
        sendDiceNumber={handleDrawDice}
        mode='local'
        handleOpenMenu={() => {
          setOpenMenu(!openMenu);
          setIsClockRunning(false);
        }}
      />
    </Fragment>
  )
}

export default LocalClassicGame