const useStyles = (theme) => ({
    root: {
        width: '100%'
    },
    description: {
        height: 'auto'
    },
    media: {
        height: 140
    },
    creatorCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.primary.dark,
        }
    }
});

export default useStyles;