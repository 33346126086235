import { basePath, apiVersion } from '../config/api'
import { getAccessTokenApi } from './auth'

/**Dar de alta a usuario */
export function signUpApi(data) {
  const url = `${basePath}/${apiVersion}/sign-up`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    if (result.user) {
      return {
        ok: true,
        message: 'Cuenta creada correctamente'
      }
    }
    return {
      ok: false,
      message: result.message
    }
  }).catch(err => {
    return {
      ok: false,
      message: err.message
    }
  })
}

export function createUserApi(data) {
  const url = `${basePath}/${apiVersion}/create-user`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function updatePasswordApi(data, id) {
  const url = `${basePath}/${apiVersion}/update-password/${id}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

/**Inicio de sesión */
export function loginApi(data) {
  const url = `${basePath}/${apiVersion}/login`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })

}

/**Obtener datos de un usuario */
export function getUserApi(token, id) {
  const url = `${basePath}/${apiVersion}/get-user/${id}`
  const params = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

/**Buscar usuario por nickname */
export function getUserByNicknameApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-user-nickname/${nickname}`
  const params = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function getUserByIdApi(id) {
  const url = `${basePath}/${apiVersion}/get-user-id/${id}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}


export function getAllUsersApi() {
  const url = `${basePath}/${apiVersion}/get-all-users`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function searchUsersApi(field = "") {
  const url = `${basePath}/${apiVersion}/search-users?field=${field}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

/**Modificar datos de un usuario */
export function updateUserApi(token, userData, userId) {
  const url = `${basePath}/${apiVersion}/update-user/${userId}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

/**Modificar datos con pass de un usuario */
export function updateFullUserApi(userData, userId) {
  const url = `${basePath}/${apiVersion}/update-full-user/${userId}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function getAvatarApi(avatarName) {
  const url = `${basePath}/${apiVersion}/get-avatar/${avatarName}`

  return fetch(url)
    .then(response => {
      return response.url
    }).catch(err => {
      return err.message
    })
}

/**
 * Función REST para modificar las stats del modo clásico de los usuarios si
 * es que obtuvieron nuevos records.
 * @param {*} nickname 
 * @param {*} playerResults
 * @returns 
 */
export function updateUserClassicStatsApi(nickname, playerResults) {
  const url = `${basePath}/${apiVersion}/update-user-classic-stats/${nickname}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(playerResults),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

/**
 * Función REST para modificar las stats del modo arcade de los usuarios si
 * es que obtuvieron nuevos records.
 * @param {*} nickname 
 * @param {*} playerResults
 * @returns 
 */
export function updateUserArcadeStatsApi(nickname, playerResults) {
  const url = `${basePath}/${apiVersion}/update-user-arcade-stats/${nickname}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(playerResults),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

/**
 * Función REST para modificar las stats del modo rush de los usuarios si
 * es que obtuvieron nuevos records.
 * @param {*} nickname 
 * @param {*} playerResults
 * @returns 
 */
export function updateUserRushStatsApi(nickname, playerResults) {
  const url = `${basePath}/${apiVersion}/update-user-rush-stats/${nickname}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(playerResults),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function deleteUserApi(id) {
  const url = `${basePath}/${apiVersion}/delete-user/${id}`
  const params = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function createToken(data) {
  const url = `${basePath}/${apiVersion}/create-token`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function passwordReset(data) {
  const url = `${basePath}/${apiVersion}/password-reset`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function addBadgeUserApi(data, id) {
  const url = `${basePath}/${apiVersion}/add-badge-user/${id}`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function deleteBadgeUserApi(data, id) {
  const url = `${basePath}/${apiVersion}/delete-badge-user/${id}`
  const params = {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function addAchievementUserApi(data, id) {
  const url = `${basePath}/${apiVersion}/add-achievement-user/${id}`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}

export function deleteAchievementUserApi(data, id) {
  const url = `${basePath}/${apiVersion}/delete-achievement-user/${id}`
  const params = {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }).catch(err => {
    return err.message
  })
}