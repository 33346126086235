import React, { useState, useEffect } from 'react'
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, styled, Typography, useTheme,
} from '@mui/material'
import MathAvatar from '../../../user/MathAvatar/MathAvatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { MATH_GRADIENTS } from '../../../../styles/MathGradients';
import oneIconSrc from '../../../../assets/images/icons/1st_icon.svg';
import twoIconSrc from '../../../../assets/images/icons/2nd_icon.svg';
import threeIconSrc from '../../../../assets/images/icons/3rd_icon.svg';
import fourIconSrc from '../../../../assets/images/icons/4th_icon.svg';

const DialogTransparent = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    color: 'white'
  }
}))

function ArcadeResults(props) {
  const { game, open, endGame, gameFinished } = props;
  const [sortedPlayers, setSortedPlayers] = useState(null);
  const [drawedPlayers, setDrawedPlayers] = useState(null);
  const [winner, setWinner] = useState(false);
  const [draw, setDraw] = useState(false);
  const theme = useTheme();

  //Jugadores
  const player1 = game ? game.players.find(player => player.number === 1) : null;
  const player2 = game ? game.players.find(player => player.number === 2) : null;
  const player3 = game ? game.players.find(player => player.number === 3) : null;
  const player4 = game ? game.players.find(player => player.number === 4) : null;


  useEffect(() => {
    if (game && gameFinished) {
      const players = game.players;
      const sorted = players.sort((a, b) => b.points - a.points);
      let tiedPlayers = [];

      //Verificamos si hay ganador
      if (sorted[0].points > sorted[1].points) {
        setDraw(false);
        setWinner(true);
      } else { //Si hay un empate
        setDraw(true);
        setWinner(false);

        // Crear un objeto para contar los puntos
        const pointsCount = players.reduce((acc, player) => {
          acc[player.points] = (acc[player.points] || 0) + 1;
          return acc;
        }, {});

        // Encontrar los puntos que están empatados
        const ties = Object.keys(pointsCount).filter(points => pointsCount[points] > 1);

        // Filtrar los jugadores que están empatados
        tiedPlayers = players.filter(player => ties.includes(String(player.points)));
      }

      setDrawedPlayers(tiedPlayers); //Guardamos los jugadores empatados aunque no haya ninguno
      setSortedPlayers(sorted); //Sorteamos los jugadores por puntos
    }
  }, [game, gameFinished]);

  if (!game || !sortedPlayers) {
    return null;
  }

  return (
    <DialogTransparent open={open} maxWidth='md' fullWidth fullScreen={true} style={{ background: 'rgba(0, 0, 0, 0.5)', zIndex: 90000 }}>
      <DialogTitle variant='h5'>Resultados de partida</DialogTitle>
      <DialogContent>
        {
          winner && (
            <Box>
              <Typography textAlign={'center'} fontSize={'2.5vw'}>
                Ganador
              </Typography>
              <Typography textAlign={'center'} fontSize={'1.5vw'} sx={{ color: '#FFBF00' }}>
                @{sortedPlayers[0].nickname}
              </Typography>
              <Box sx={{ mt: theme.spacing('2vw'), display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={2} sx={{ width: '50%' }}>
                  {
                    player3 && (
                      <Grid size={12 / game.players.length} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <MathAvatar avatar={sortedPlayers[2].avatar} size={'6vw'} />
                        <img src={threeIconSrc} alt='3rd_icon.svg' style={{ width: '5vw', marginTop: '1vw' }} />
                      </Grid>
                    )
                  }
                  {
                    player1 && (
                      <Grid size={12 / game.players.length} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <MathAvatar avatar={sortedPlayers[0].avatar} size={'12vw'} />
                        <img src={oneIconSrc} alt='1st_icon.svg' style={{ width: '5vw', marginTop: '1vw' }} />
                      </Grid>
                    )
                  }
                  {
                    player2 && (
                      <Grid size={12 / game.players.length} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <MathAvatar avatar={sortedPlayers[1].avatar} size={'8vw'} />
                        <img src={twoIconSrc} alt='2nd_icon.svg' style={{ width: '5vw', marginTop: '1vw' }} />
                      </Grid>
                    )
                  }
                  {
                    player4 && (
                      <Grid size={12 / game.players.length} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <MathAvatar avatar={sortedPlayers[3].avatar} size={'6vw'} />
                        <img src={fourIconSrc} alt='4th_icon.svg' style={{ width: '4vw', marginTop: '1vw' }} />
                      </Grid>
                    )
                  }
                </Grid>
              </Box>
            </Box>
          )
        }
        {
          draw && (
            <Box>
              <Typography textAlign={'center'} fontSize={'2.5vw'}>
                Empate
              </Typography>
              <Box sx={{ mt: theme.spacing('2vw'), display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={2} sx={{ width: '45%' }}>
                  {
                    drawedPlayers.map((player, index) => (
                      <Grid key={index} size={12 / game.players.length} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <MathAvatar avatar={player.avatar} size={'8vw'} />
                      </Grid>
                    ))
                  }
                </Grid>
              </Box>
            </Box>
          )
        }
        <Box sx={{ my: theme.spacing(5) }}>
          <Typography variant='h6' textAlign={'center'} gutterBottom>Estadísticas</Typography>
          <Box sx={{ mt: theme.spacing(2), display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={2} sx={{ width: '80%' }}>
              {
                sortedPlayers.map((player, index) => (
                  <Grid key={index} size={12 / game.players.length}
                    sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgba(255, 255, 255, 0.2)', p: theme.spacing(2),
                      borderRadius: 4, textAlign: 'center'
                    }}>
                    <MathAvatar avatar={player.avatar} size={'4vw'} sx={{ mb: theme.spacing(1) }} />
                    <Typography fontSize={'1.5vw'}>
                      @{player.nickname}
                    </Typography>
                    <Typography variant='subtitle2'>
                      Puntos: <span style={{ color: theme.palette.success.main }}>{player.points}</span>
                    </Typography>
                    <Typography variant='subtitle2'>
                      Ejercicios contestados: <span style={{ color: theme.palette.success.main }}>{player.answered}</span>
                    </Typography>
                    <Typography variant='subtitle2'>
                      Aciertos: <span style={{ color: theme.palette.success.main }}>{player.correct}</span>
                    </Typography>
                    <Typography variant='subtitle2'>
                      Erorres: <span style={{ color: theme.palette.error.main }}>{player.errors}</span>
                    </Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant='contained' sx={{ background: MATH_GRADIENTS().arcade, width: '35%', color: 'white' }} startIcon={<CheckCircleIcon />}
          onClick={() => { endGame(drawedPlayers, sortedPlayers) }}>
          Aceptar y volver al menú
        </Button>
      </DialogActions>
    </DialogTransparent>
  )
}

export default ArcadeResults