/**Degradados */

import theme from "./MathThemes";

export function MATH_GRADIENTS(deg = 45) {
  return {
    default: `linear-gradient(${deg}deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    error: `linear-gradient(${deg}deg, #73000D, #FF0008)`,
    disabled: `linear-gradient(${deg}deg, #515151, #A1A1A1)`,
    classic: `linear-gradient(${deg}deg, #388659, #98CE00)`,
    arcade: `linear-gradient(${deg}deg, #6A041D, #FF006E)`,
    rush: `linear-gradient(${deg}deg, #00487C, #2A8EFF)`,
    deathmatch: `linear-gradient(${deg}deg, #730017, #ff0009)`,
    start_tile: `linear-gradient(${deg}deg, #CF71AF, #9F4576)`,
    random_exc_tile: `linear-gradient(${deg}deg, #FFBF00, #E09540)`,
    challenge_tile: `linear-gradient(${deg}deg, #29CBD1, #2A7BD1)`,
    challenge_tile_arcade: `linear-gradient(${deg}deg, #9966CC, #4B0082)`,
    event_tile: `linear-gradient(${deg}deg, #0D001A, #330063)`,
    winner: `linear-gradient(${deg}deg, #FFBE0B, #FFE80A)`
  }
}