export function minLenghtValidation(inputData, minLength) {
  if (inputData.length >= minLength) {
    return true
  } else {
    return false
  }
}

export function maxLenghtValidation(inputData, minLength) {
  if (inputData.length > minLength) {
    return false
  } else {
    return true
  }
}

export function emailValidation(inputData) {
  const emailValid = /^([a-zA-Z0-9_.])+@(([a-zA-Z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const { value } = inputData

  const resultValidation = emailValid.test(value)

  if (resultValidation) {
    return true
  } else {
    return false
  }
}

export function nicknameValidation(inputData) {
  const nicknameRegex = /^([a-zA-Z0-9_.])+([[a-zA-Z0-9_.])+([a-zA-Z0-9_.])+$/
  const { value } = inputData

  const result = nicknameRegex.test(value)

  if (!result) {
    return false
  } else {
    //Máximo de escritura
    if (value.length > 14) {
      return false
    } else {
      return true
    }
  }
}

export function emailValueValidation(email) {
  const emailValid = /^([a-zA-Z0-9_.])+@(([a-zA-Z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const resultValidation = emailValid.test(email)

  if (resultValidation) {
    return true
  } else {
    return false
  }
}

export function nicknameValueValidation(nick) {
  const nicknameRegex = /^([a-zA-Z0-9_.])+([[a-zA-Z0-9_.])+([a-zA-Z0-9_.])+$/

  const result = nicknameRegex.test(nick);

  if (!result) {
    return false
  } else {
    //Máximo de escritura
    if (nick.length > 14) {
      return false
    } else {
      return true
    }
  }
}