import React, { useEffect } from 'react'
import { useHistory, Redirect, Link } from 'react-router-dom';
import { Box, Grid2 as Grid, Typography, Button, Container, useTheme, Paper } from '@mui/material'

//API
import useAuth from '../../hooks/useAuth';

/**Componentes */
import PublicHeader from '../../components/navigation/public/PublicHeader/PublicHeader'
import Footer from '../../components/navigation/public/Footer/Footer'
import { MATH_GRADIENTS } from '../../styles/MathGradients';

/**Imágenes */
import classicIcon from '../../assets/images/icons/classic_icon_1.svg'
import arcadeIcon from '../../assets/images/icons/arcade_icon_1.svg'
import rushIcon from '../../assets/images/icons/rush_icon_1.svg'
import lapClassicSrc from '../../assets/images/landing/lap-classic.png'
import phoneRushSrc from '../../assets/images/landing/phone-rush.png'
import mathIconSrc from '../../assets/images/logos/math_icon.svg'
import classicBoardSrc from '../../assets/images/landing/classic-board.svg'
import classic1Src from '../../assets/images/landing/classic1.png'
import classic2Src from '../../assets/images/landing/classic2.png'
import classic3Src from '../../assets/images/landing/classic3.png'
import arcadeBoardSrc from '../../assets/images/landing/arcade-board.svg'
import arcade1Src from '../../assets/images/landing/arcade1.png'
import arcade2Src from '../../assets/images/landing/arcade2.png'
import arcade3Src from '../../assets/images/landing/arcade3.png'
import rushLayoutSrc from '../../assets/images/landing/rush-layout.svg'
import rush1Src from '../../assets/images/landing/rush1.png'
import rush2Src from '../../assets/images/landing/rush2.png'
import rush3Src from '../../assets/images/landing/rush3.png'
import lapDashboardSrc from '../../assets/images/landing/lap-dashboard.png'
import groupIconSrc from '../../assets/images/landing/group_icon.svg'
import leaderIconSrc from '../../assets/images/landing/leader_icon.svg'
import clockIconSrc from '../../assets/images/landing/clock_icon.svg'

function Home() {
  const theme = useTheme();
  const history = useHistory();

  //Si el usuario está logueado
  const { user } = useAuth()

  useEffect(() => {
    document.title = 'Math Paradise - Una nueva forma de practicar matemáticas'
  }, [])

  /**Si el usuario está logueado */
  if (user) {
    return <Redirect to="/home" />
  }

  return (
    <div>
      <PublicHeader />
      <Container maxWidth="xl" sx={{
        my: theme.spacing(6)
      }}>
        <Paper sx={{
          width: '100%',
          height: '548px',
          background: MATH_GRADIENTS().default,
          color: 'white',
          borderRadius: 8,
          p: theme.spacing(4),
          mb: { xs: theme.spacing(8), md: theme.spacing(30) }
        }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }} height={'100%'}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                  <Typography sx={{ fontSize: 56, letterSpacing: 1.5 }}>Math Paradise</Typography>
                  <Typography sx={{ fontSize: 34 }}>
                    Ven a jugar con las Matemáticas
                  </Typography>
                  <Button sx={{
                    background: 'white', color: theme.palette.primary.dark, mt: theme.spacing(3), px: theme.spacing(4),
                    mb: theme.spacing(1)
                  }}
                    onClick={() => history.push('/sign-up')}>
                    Únete ya
                  </Button>
                  <Link to='/login' style={{ textDecoration: 'none', color: 'white' }}>
                    <Typography>¿Ya tienes una cuenta? Inicia Sesión</Typography>
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <img src={classicIcon} alt='' style={{ height: '7vh', marginRight: theme.spacing(2) }} />
                  <img src={arcadeIcon} alt='' style={{ height: '7vh', marginRight: theme.spacing(2) }} />
                  <img src={rushIcon} alt='' style={{ height: '7vh', marginRight: theme.spacing(2) }} />
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
              <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'flex' }, justifyContent: 'center' }}>
                <img src={lapClassicSrc} alt='' style={{ position: 'absolute', bottom: '22%', width: '45vw' }} />
              </Box>
            </Grid>
          </Grid>
          <div id='what-we-are'></div>
        </Paper>
        <Box sx={{ mb: { xs: theme.spacing(8), md: theme.spacing(12) } }}>
          <Grid container spacing={6}>
            <Grid size={{ xl: 8, lg: 8, md: 8, sm: 12, xs: 12 }}>
              <img src={mathIconSrc} alt='' style={{ width: '82px', marginBottom: theme.spacing(2) }} />
              <Typography color='textSecondary' fontSize={24}>
                <span style={{ color: theme.palette.primary.main }}>Math Paradise</span> es una nueva plataforma para
                practicar y poner a pruebas tus habilidades
                lógico - matemáticas en distintas modalidades.
              </Typography>
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 12, xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={phoneRushSrc} alt='' style={{ width: '312px' }} />
            </Grid>
          </Grid>
          <div id='game-modes'></div>
        </Box>
        <Box sx={{ mb: { xs: theme.spacing(8), md: theme.spacing(12) } }}>
          <Paper sx={{
            width: '100%',
            background: MATH_GRADIENTS().classic,
            color: 'white',
            borderRadius: 8,
            p: theme.spacing(4),
            mb: theme.spacing(6)
          }}>
            <Typography sx={{ fontSize: '5vh' }}>Modo Clásico</Typography>
            <Typography sx={{ fontSize: '2.5vh' }}>
              Sumérgete con amigos en este modo de juego inspirado en los juegos de mesa,
              y resuelve todos los ejercicios que el dado te arroje.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: theme.spacing(10) }}>
              <img src={classicBoardSrc} alt='' style={{ width: '36vw' }} />
            </Box>
          </Paper>
          <Grid container spacing={2}>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
              <img src={classic1Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
              <img src={classic2Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 12, xs: 12 }}>
              <img src={classic3Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: { xs: theme.spacing(8), md: theme.spacing(12) } }}>
          <Paper sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: { xs: '560px', md: '700px' },
            background: MATH_GRADIENTS().arcade,
            color: 'white',
            borderRadius: 8,
            pt: theme.spacing(4),
            px: theme.spacing(4),
            mb: theme.spacing(6)
          }}>
            <Box>
              <Typography sx={{ fontSize: '5vh' }}>Modo Arcade</Typography>
              <Typography sx={{ fontSize: '2.5vh' }}>
                Gana muchos puntos a tu suerte en este modo de juego basado en las máquinas tragamonedas.
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center' }}>
              <img src={arcadeBoardSrc} alt='' style={{ width: '30vh' }} />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
              <img src={arcadeBoardSrc} alt='' style={{ width: '36vw' }} />
            </Box>
          </Paper>
          <Grid container spacing={2}>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
              <img src={arcade1Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
              <img src={arcade2Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 12, xs: 12 }}>
              <img src={arcade3Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: { xs: theme.spacing(8), md: theme.spacing(12) } }}>
          <Paper sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: { xs: '500px', md: '700px' },
            background: MATH_GRADIENTS().rush,
            color: 'white',
            borderRadius: 8,
            p: theme.spacing(4),
            mb: theme.spacing(6)
          }}>
            <Box>
              <Typography sx={{ fontSize: '5vh' }}>Modo Rush</Typography>
              <Typography sx={{ fontSize: '2.5vh' }}>
                Pon a prueba tu destreza al resolver ejercicios aritméticos a contrarreloj y
                compite contra tus amigos para ver quién es el más rápido.
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center' }}>
              <img src={rushLayoutSrc} alt='' style={{ width: '30vh' }} />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
              <img src={rushLayoutSrc} alt='' style={{ width: '36vw' }} />
            </Box>
          </Paper>
          <Grid container spacing={2}>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
              <img src={rush1Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
              <img src={rush2Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
            <Grid size={{ xl: 4, lg: 4, md: 4, sm: 12, xs: 12 }}>
              <img src={rush3Src} alt='' style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
          </Grid>
          <div id='platform'></div>
        </Box>
        <Box sx={{ mb: theme.spacing(6) }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: theme.spacing(4) }}>
            <img src={mathIconSrc} alt='' style={{ width: '82px', marginBottom: theme.spacing(2) }} />
            <Typography color='textSecondary' fontSize={24} textAlign={'center'}>
              Ven a <span style={{ color: theme.palette.primary.main }}>Math Paradise</span> y sigue
              el progreso de tus partidas
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            <img src={lapDashboardSrc} alt='' style={{ width: '50vw' }} />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
            <img src={lapDashboardSrc} alt='' style={{ width: '100%' }} />
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Grid container spacing={2} sx={{ mt: theme.spacing(8), width: '60%' }}>
              <Grid size={{ xl: 4, lg: 4, md: 6, sm: 12, xs: 12 }}>
                <Paper sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '408px',
                  p: theme.spacing(4),
                  borderRadius: 8,
                }}>
                  <Typography variant='h5' textAlign={'center'}>
                    Crea y únete a grupos
                  </Typography>
                  <img src={groupIconSrc} alt='' style={{ width: '12vh' }} />
                </Paper>
              </Grid>
              <Grid size={{ xl: 4, lg: 4, md: 6, sm: 12, xs: 12 }}>
                <Paper sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '408px',
                  p: theme.spacing(4),
                  borderRadius: 8,
                }}>
                  <Typography variant='h5' textAlign={'center'}>
                    Clasifica tus puntuaciones
                  </Typography>
                  <img src={leaderIconSrc} alt='' style={{ width: '12vh' }} />
                </Paper>
              </Grid>
              <Grid size={{ xl: 4, lg: 4, md: 12, sm: 12, xs: 12 }}>
                <Paper sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '408px',
                  p: theme.spacing(4),
                  borderRadius: 8,
                }}>
                  <Typography variant='h5' textAlign={'center'}>
                    Sigue los detalles de tus partidas y ejercicios
                  </Typography>
                  <img src={clockIconSrc} alt='' style={{ width: '12vh' }} />
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: theme.spacing(16) }}>
            <img src={mathIconSrc} alt='' style={{ width: '82px', marginBottom: theme.spacing(2) }} />
            <Typography variant='h5' color='primary'>Math Paradise</Typography>
            <Typography color='textSecondary'>{new Date().getFullYear().toString()}</Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </div >
  )
}

export default Home
