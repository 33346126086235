import { alpha } from '@mui/material'

const useStyles = theme => ({
  requestBox: {
    py: theme.spacing(2),
    borderRadius: 6,
    '&:hover': {
      backgroundColor: alpha(theme.palette.divider, 0.05),
      cursor: 'pointer'
    }
  },
  requestTextBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    px: theme.spacing(2)
  },
  btn: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
});

export default useStyles;