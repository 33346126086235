import React from "react";
import { Link } from 'react-router-dom'
import { Drawer, Typography, useTheme, styled } from "@mui/material";
import useStyles from "./useStyles";
import packageJson from '../../../../../package.json';

//Components
import MenuList from "../MenuList"
import Logo from "../../../Logo"

//Img
import logoSrc from '../../../../assets/images/logos/MathParadiseLogo _vertical.svg';

const MathDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '300px'
  }
}))

function NavMenu(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MathDrawer
      sx={classes.drawer}
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      anchor="left"
    >
      <div style={classes.toolbar}>
        <div style={classes.logo}>
          <Link to="/admin">
            <Logo src={logoSrc} style={classes.logoSize} />
          </Link>
        </div>
      </div>
      <Typography textAlign={'center'} variant="body2" color="primary">
        Administración
      </Typography>
      <Typography textAlign={'center'} variant="caption" color="disabled.main">
        Versión {packageJson.version}
      </Typography>
      <MenuList url={props.url} />
    </MathDrawer>
  );
}

export default NavMenu;