import React from 'react'
import { Paper, Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material';
import { player_colors } from '../../../utils/SelectArrays';
import MathAvatar from '../../user/MathAvatar/MathAvatar';

const ArcadePlayerStatsCard = (props) => {
  const { color = 'player_1', avatar = 'default.svg', nickname = '', points = 0, coins, dropOff } = props;
  const theme = useTheme();

  const colors = () => {
    let allColors = {};
    player_colors.forEach(pc => {
      allColors[pc.name] = pc.val
    })
    return allColors;
  }

  return (
    <Paper sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: colors()[color],
      padding: '1.5vw',
      borderRadius: 2,
      color: 'white',
      filter: dropOff ? 'brightness(25%)' : 'none'
    }}>
      <MathAvatar avatar={avatar} size={'3vw'} sx={{ mb: theme.spacing(0.5) }} />
      <Box textAlign={'center'}>
        <Typography fontSize={'1.1vw'} fontWeight={'bold'}>{nickname}</Typography>
        <Typography fontSize={'0.9vw'} gutterBottom>
          Puntos: <span style={{ fontWeight: 'bolder' }}>{points}</span>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{
            width: '2vw',
            height: '2vw',
            backgroundColor: '#D4AF37', // Fondo amarillo
            borderRadius: '50%',       // Forma circular
            border: '0.15vw solid white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <Typography sx={{
              color: '#906908',           // Texto en naranja
              fontSize: '0.9vw',        // Ajusta el tamaño de fuente
              fontWeight: 'bold',
            }}>
              ${coins}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
};

export default ArcadePlayerStatsCard