import React, { useEffect } from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material'

//Componentes
import PublicHeader from '../components/navigation/public/PublicHeader/PublicHeader'
import Footer from '../components/navigation/public/Footer/Footer'

//Icons
import InstagramIcon from '@mui/icons-material/Instagram';

function Credits() {
  const theme = useTheme();

  useEffect(() => {
    document.title = `Créditos - Math Paradise`
  }, [])

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <Box flex={1}>
        <PublicHeader />
        <Container maxWidth="xl" sx={{ mt: theme.spacing(6) }}>
          <Typography variant="h4" color='primary' gutterBottom>Créditos</Typography>
          <Typography variant="h6">Idea original y concepto</Typography>
          <Typography color="textSecondary" gutterBottom>Angel Palomar</Typography>
          <br />
          <Typography variant="h6">Diseño</Typography>
          <Typography color="textSecondary" gutterBottom>Angel Palomar</Typography>
          <br />
          <Typography variant="h6">Musicalización</Typography>
          <Typography color="textSecondary" gutterBottom>Math Slot Machine (Arcade Mode Theme)</Typography>
          <Typography color="textSecondary" gutterBottom>Number Rusher (Rush Mode Theme)</Typography>
          <Typography color="primary" variant='subtitle1'>
            Compuesto por: OCCAMY
          </Typography>
          <a href='https://www.instagram.com/ocammyofficial/' target='_blank' rel='noreferrer'><InstagramIcon /></a>
          <br />
          <br />
          <Typography color="textSecondary" gutterBottom>Hypnothis</Typography>
          <Typography color="textSecondary" gutterBottom>Eternity</Typography>
          <Typography color="primary" variant='subtitle1'>
            Compuesto por: Kevin MacLeod
          </Typography>
          <br />
          <br />
          <Typography variant="h6">Agradecimientos especiales</Typography>
          <Typography color="textSecondary" gutterBottom>Gabriel Alberto Martín Vega</Typography>
          <Typography color="textSecondary" gutterBottom>Ma. del Carmen Zambrano Canales</Typography>
          <Typography color="textSecondary" gutterBottom>Alexis Zambrano</Typography>
          <Typography color="textSecondary" gutterBottom>Gonzalo Benítez Jiménez</Typography>
          <Typography color="textSecondary" gutterBottom>Evelyn Aislinn Zapien Quintanar</Typography>
          <Typography color="textSecondary" gutterBottom>José Suárez Méndez</Typography>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}

export default Credits;
