import React, { useState, useEffect } from 'react'
import { LinearProgress, useTheme } from '@mui/material'
import { Paper, Typography, Grid, Box } from '@mui/material'
import { useStyles } from './useStyles'

//Components
import LeaderboardPaper from '../../../components/lists/LeaderboardPaper';

//API
import { getClassicLeaderboardApi } from '../../../api/classicGame';
import { getArcadeLeaderboardApi } from '../../../api/arcadeGame';
import { getRushLeaderboardApi } from '../../../api/rushGame';

//Images
import classicIconWhite from '../../../assets/images/icons/classic_icon_white.svg'
import arcadeIconWhite from '../../../assets/images/icons/arcade_icon_white.svg'
import rushIconWhite from '../../../assets/images/icons/rush_icon_white.svg'
import { es_DateName_noYear } from '../../../utils/DateFormat';

function Leaderboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [fromDay, setFromDay] = useState('');
  const [toDay, setToDay] = useState('')
  //Variables para almacenar las respuestas
  const [rushTable, setRushTable] = useState([])
  const [classicTable, setclassicTable] = useState([])
  const [arcadeTable, setArcadeTable] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  //Titulo del documento
  useEffect(() => {
    document.title = 'Clasificaciones - Math Paradise'

    const fetchLeaderboard = async () => {
      try {
        const [classicResponse, arcadeResponse, rushResponse] = await Promise.all([
          getClassicLeaderboardApi(),
          getArcadeLeaderboardApi(),
          getRushLeaderboardApi()
        ])

        const formatHistory = (response, gamemode) => {
          if (response?.status === 1) {
            return response.leaderboard.map((game) => ({ ...game, gamemode }))
          } else {
            return []
          }
        }

        const getDateFilter = (response) => {
          if (response?.status === 1) {
            setFromDay(response.dates.from);
            setToDay(response.dates.to);
          }
        }

        getDateFilter(classicResponse);
        setclassicTable(formatHistory(classicResponse, 'classic'));
        setArcadeTable(formatHistory(arcadeResponse, 'arcade'));
        setRushTable(formatHistory(rushResponse, 'rush'));
      } catch (error) {
        console.error('Error fetching game leaderboard:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchLeaderboard();

    return () => {
      setRushTable([])
      setclassicTable([])
      setArcadeTable([])
    }
  }, []);

  if (isLoading) {
    return <LinearProgress variant='indeterminate' />
  }

  return (
    <div>
      <Box sx={{ my: theme.spacing(4) }}>
        <Typography variant="h4" gutterBottom sx={classes.title} color='primary'>
          Clasificación global
        </Typography>
        <Typography color='textSecondary' textAlign={'center'} gutterBottom>
          Ve el top 10 de jugadores semanal de todos los modos de juego.
        </Typography>
      </Box>
      <Box >
        <Typography variant='h6'>Puntajes semanales</Typography>
        <Typography color='textSecondary'>
          Del <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{es_DateName_noYear(fromDay)}</span> al <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{es_DateName_noYear(toDay)}</span>.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={classes.grid}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Paper sx={classes.classic_board}>
            <Box sx={classes.header_board}>
              <img src={classicIconWhite} style={classes.classic_icon} alt="rush_icon.svg" />
              <Typography variant="h6">Modo Clásico</Typography>
              <Typography varint="subtitle1">Top 10</Typography>
            </Box>

            <Box mt={2}>
              {
                classicTable.length > 0 ?
                  (
                    classicTable.map((values, index) =>
                      <LeaderboardPaper
                        key={index}
                        nickname={values.player.nickname}
                        points={values.player.points}
                        place={index} />
                    )
                  ) :
                  (
                    <Typography sx={{ mt: theme.spacing(4), textAlign: 'center' }}>No hay puntuaciones.</Typography>
                  )
              }
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Paper sx={classes.arcade_board}>
            <Box sx={classes.header_board}>
              <img src={arcadeIconWhite} style={classes.classic_icon} alt="rush_icon.svg" />
              <Typography variant="h6">Modo Arcade</Typography>
              <Typography varint="subtitle1">Top 10</Typography>
            </Box>

            <Box mt={2}>
              {
                arcadeTable.length > 0 ?
                  (
                    arcadeTable.map((values, index) =>
                      <LeaderboardPaper
                        key={index}
                        nickname={values.player.nickname}
                        points={values.player.points}
                        place={index} />
                    )
                  ) :
                  (
                    <Typography sx={{ mt: theme.spacing(4), textAlign: 'center' }}>No hay puntuaciones.</Typography>
                  )
              }
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Paper sx={classes.rush_board}>
            <Box sx={classes.header_board}>
              <img src={rushIconWhite} style={classes.rush_icon} alt="rush_icon.svg" />
              <Typography variant="h6">Modo Rush</Typography>
              <Typography varint="subtitle1">Top 10</Typography>
            </Box>

            <Box mt={2}>
              {
                rushTable.length > 0 ?
                  (
                    rushTable.map((values, index) =>
                      <LeaderboardPaper
                        key={index}
                        nickname={values.player.nickname}
                        points={values.player.points}
                        place={index} />
                    )
                  ) :
                  (
                    <Typography sx={{ mt: theme.spacing(4), textAlign: 'center' }}>No hay puntuaciones.</Typography>
                  )
              }
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Leaderboard

