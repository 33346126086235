/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  Button, Dialog, DialogContent, DialogTitle, Grid2, Slider,
  Box, useTheme,
  Typography
} from '@mui/material'
import { Howler } from 'howler'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

function Menu(props) {
  const { open, handleClose, onExit, turn, localUser } = props;
  const theme = useTheme();

  /**Control del volumen */
  const [volume, setVolume] = useState(100)

  //Función para controlar el volumen
  const changeVolume = (event, newValue) => {
    setVolume(newValue)
  }

  useEffect(() => {
    Howler.volume(volume / 100)
  }, [volume])

  if (!localUser) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Menú de configuración</DialogTitle>
      <DialogContent>
        <Grid2 container spacing={2} sx={{ mb: theme.spacing(3) }}>
          <Grid2 size={8}>
            <Button variant='contained' size='large' color='success' fullWidth startIcon={<PlayArrowIcon />} sx={{ color: 'white' }}
              onClick={handleClose}>
              Continuar
            </Button>
          </Grid2>
          <Grid2 size={4}>
            <Button variant='contained' size='large' color='error' fullWidth startIcon={<ArrowBackIcon />} sx={{ color: 'white' }}
              onClick={onExit}>
              Salir
            </Button>
          </Grid2>
        </Grid2>
        <Typography>Volumen</Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}>
          <VolumeDown />
          <Slider style={{
            marginInline: theme.spacing(2)
          }}
            value={volume}
            onChange={changeVolume} />
          <VolumeUp />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default Menu