import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LinearProgress, IconButton, Typography, Box
} from "@mui/material"
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
//import { DataGrid, GridToolbar } from 'material-react-table'

/**Componentes */
import Notification from '../common/Notification'

/**Iconos */
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'

/**APIs */
import { getInstitutionsApi, deleteInstitutionApi } from "../../api/institution"

function InstitutionsTable() {

  let instList = []
  const [instData, setTnstData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [reload, setReload] = useState(false)

  const columns = useMemo(() => (
    [
      { accessorKey: 'id', header: 'ID', size: 100 },
      { accessorKey: 'name', header: 'Nombre', size: 300, },
      { accessorKey: 'abbrev', header: 'Abrebiatura', size: 120 },
      { accessorKey: 'type', header: 'Tipo', size: 150 },
      { accessorKey: 'city', header: 'Ciudad', size: 150 },
      { accessorKey: 'country', header: 'País', size: 150 },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 150,
        Cell: ({ rValue, row }) => (
          <Fragment>
            <Link to={`/admin/institutions/update/${row.original.id}`}>
              <IconButton color='primary'>
                <CreateIcon style={{ fontSize: 16 }} />
              </IconButton>
            </Link>
            <IconButton
              color='error'
              onClick={() => {
                setOpen(true)
                setSelectedId(row.original.id)
              }}>
              <DeleteIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Fragment>
        )
      }
    ]
  ), [])

  useEffect(() => {
    getInstitutionsApi().then(response => {
      response.institution.forEach(value => {
        instList.push({ ...value, id: value._id })
      })

      setTnstData(instList)
      setIsLoading(false)
    })

    setReload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const data = useMemo(() => instData, [instData]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact'
    }
  });

  return (
    <Fragment>
      <Notification
        open={open}
        onClose={() => setOpen(false)}
        title="Eliminar institución"
        onAccept={() => {
          deleteInstitutionApi(selectedId).then()
          setOpen(false)
          setReload(true)
        }}>
        <Typography>¿Estás seguro de querer eliminar este elemento?</Typography>
      </Notification>
      <Box sx={{ height: 400, p: 0, m: 0, maxWidth: '82vw' }}>
        {isLoading ? <LinearProgress /> :
          instData.length > 0 ?
            <MaterialReactTable table={table} />
            : <Typography>No hay registros</Typography>
        }
      </Box>
    </Fragment>
  )
}

export default InstitutionsTable
