import React, { useState, useEffect } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { MATH_GRADIENTS } from '../../../../styles/MathGradients';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MathAvatar from '../../../user/MathAvatar/MathAvatar';

import GoldIcon from '../../../../assets/images/icons/1st_icon.svg'
import SilverIcon from '../../../../assets/images/icons/2nd_icon.svg'
import BronzeIcon from '../../../../assets/images/icons/3rd_icon.svg'
import ironIcon from '../../../../assets/images/icons/4th_icon.svg'

function RushResults(props) {
  const theme = useTheme();
  const { open, game, seconds, localUser, endGame } = props;

  //Jugador
  const player = game ? game.players.find(player => player.nickname === localUser.nickname) : null;
  const [position, setPosition] = useState(-1);
  const [positionObtained, setPositionObtained] = useState(false);
  const statsColor = theme.palette.success.main;

  //Efecto para obtener el lugar leaderboard
  useEffect(() => {
    if (game && seconds === 0 && !positionObtained) {
      const players = game.players;
      let timeUps = 0;

      //Buscamos cuantos timeUp hay
      players.forEach(p => {
        if (p.timeUp)
          timeUps += 1;
      })

      //obtenemos posición
      setPosition(players.length - timeUps);
      setPositionObtained(true); //Indicamos que el lugar ya fue obtenido para que no se vuelva a calcular
    }
  }, [game, seconds, localUser, positionObtained]);

  if (!game) {
    return null;
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle textAlign={'center'} variant='h4'>Se acabó el tiempo ¡Juego Terminado!</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', }}>
        <Box sx={{ my: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MathAvatar avatar={player.avatar} size={'12vh'} />
          <img src={
            position === 1 ? GoldIcon :
              position === 2 ? SilverIcon :
                position === 3 ? BronzeIcon :
                  position === 4 ? ironIcon : null
          } alt='place-svg' style={{ marginTop: theme.spacing(-2.5), width: 64, zIndex: 1000 }} />
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>Estadísticas de partida</Typography>
          <Typography color='rush' variant='h6'><span style={{ color: statsColor }}>{player.points}</span> Puntos</Typography>
          <Typography color='rush' variant='h6'><span style={{ color: statsColor }}>{player.excercises}</span> Ejercicios</Typography>
          <Typography color='rush' variant='h6'>Nivel <span style={{ color: statsColor }}>{player.level}</span> alcanzado</Typography>
          <Typography color='rush' variant='h6'><span style={{ color: statsColor }}>&times;{player.multiplier}</span> Multiplicador</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' sx={{ background: MATH_GRADIENTS().rush, width: '100%', color: 'white' }} startIcon={<CheckCircleIcon />}
          onClick={() => endGame(position)}>
          Aceptar y volver al menú
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RushResults