export const useStyles = (theme) => ({
  rushIconBox: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  rushIcon: {
    width: '80px',
    margin: theme.spacing(2)
  },
  title: {
    textAlign: 'center'
  },
  textContent: {
    color: theme.palette.text.primary
  },
  titleContent: {
    textAlign: 'center',
    fontSize: '22px',
    color: theme.palette.text.primary
  }

});