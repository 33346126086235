import React from 'react'
import { PropTypes } from 'prop-types'
import { Box, Paper, Typography, useTheme } from '@mui/material'
import { animated, useSpring } from '@react-spring/web'
import { useStyles } from './useStyles'
//import { InlineMath } from 'react-katex'
import { MATH_GRADIENTS } from '../../../styles/MathGradients'
import { arcade_vegies, arcade_gems, arcade_poker } from '../../../utils/SelectArrays'

/**Images */
import challenge from '../../../assets/images/layouts/classic/challenge_icon.svg'
import random_exc from '../../../assets/images/layouts/classic/random_excercise_icon.svg'
import event from '../../../assets/images/layouts/classic/random_event_icon.svg'

function Slot(props) {
  const {
    pos,
    type,
    isSelected = false,
    iconType = 'gems',
    subtopic = {
      area: 'area',
      topic: 'topic',
      code: 'code_subtopic',
      displayLabel: 'SUBTOPIC',
      symbol: 'symbol',
      difficulty: 'difficulty',
      icon: 'diamond'
    },
  } = props
  const theme = useTheme();
  const classes = useStyles(theme);

  const selectionColor = '#DAA520';
  const slotSpring = useSpring({
    transform: isSelected ? 'scale(1.1)' : 'scale(1.0)',
    height: '100%',
    width: '100%',
    config: { tension: 200 }
  });

  const Vegie = (selectedVegie, size = '4vh') => {
    let veg = {};

    arcade_vegies.forEach(v => {
      veg[v.val] = () => <img alt={v.photo} src={v.path} style={{ width: size }} />
    });

    const SelectedVegieComponent = veg[selectedVegie] ? veg[selectedVegie]() : null;
    return SelectedVegieComponent;
  }

  const Gem = (selectedGem, size = '4vh') => {
    let gem = {};

    arcade_gems.forEach(g => {
      gem[g.val] = () => <img alt={g.photo} src={g.path} style={{ width: size }} />
    });

    const SelectedGemComponent = gem[selectedGem] ? gem[selectedGem]() : null;
    return SelectedGemComponent;
  }

  const Poker = (selectedPoker, size = '4vh') => {
    let poker = {};

    arcade_poker.forEach(g => {
      poker[g.val] = () => <img alt={g.photo} src={g.path} style={{ width: size }} />
    });

    const SelectedPokerComponent = poker[selectedPoker] ? poker[selectedPoker]() : null;
    return SelectedPokerComponent;
  }

  switch (type) {
    default:
    case "normal":
      return (
        <animated.div style={slotSpring}>
          <Paper
            elevation={3}
            square
            sx={{ ...classes.normalSlot, ...classes.paperSlot, background: isSelected ? selectionColor : colorSlot(pos) }}>
            <Box mb={'1vh'}>
              {iconType === 'vegies' ? Vegie(subtopic.icon) : iconType === 'gems' ? Gem(subtopic.icon) : iconType === 'poker' ? Poker(subtopic.icon) : null}
            </Box>
            {/* <div style={classes.symbolSize}>
              <InlineMath math={`${subtopic ? subtopic.symbol : 'symbol'}`} />
            </div> */}
            <Typography sx={classes.symbolSize} fontWeight={'bold'}>{subtopic ? subtopic.displayLabel : 'LABEL'}</Typography>
          </Paper>
        </animated.div>
      )
    case "RULETA-BOTTOM":
      return (
        <animated.div style={slotSpring}>
          <Paper
            elevation={3}
            square
            sx={{ background: isSelected ? selectionColor : MATH_GRADIENTS().event_tile, ...classes.normalSlot, ...classes.paperSlot, height: '100%', borderBottomLeftRadius: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '5px' }}>
              <Box>
                {iconType === 'vegies' ? Vegie(subtopic.icon, '3.5vh') : iconType === 'gems' ? Gem(subtopic.icon, '3.5vh') : iconType === 'poker' ? Poker(subtopic.icon) : null}
              </Box>
              <img src={event} style={{ marginLeft: '6px', height: '3vh' }} alt="corner_tile.svg" />
            </Box>
            <Typography style={{ fontSize: '1.5vh' }}>RULETA</Typography>
          </Paper>
        </animated.div>
      )
    case "EXCER. RANDOM":
      return (
        <animated.div style={slotSpring}>
          <Paper
            elevation={3}
            square
            sx={{ background: isSelected ? selectionColor : MATH_GRADIENTS().classic, ...classes.normalSlot, ...classes.paperSlot, height: '100%', borderBottomRightRadius: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '5px' }}>
              <Box>
                {iconType === 'vegies' ? Vegie(subtopic.icon, '4vh') : iconType === 'gems' ? Gem(subtopic.icon, '4vh') : iconType === 'poker' ? Poker(subtopic.icon) : null}
              </Box>
              <img src={random_exc} style={{ marginLeft: '6px', height: '3vh' }} alt="corner_tile.svg" />
            </Box>
            <Typography style={{ fontSize: '1.5vh' }}>EXCER. RANDOM</Typography>
          </Paper>
        </animated.div>
      )

    case "RULETA-TOP":
      return (
        <animated.div style={slotSpring}>
          <Paper
            elevation={3}
            square
            sx={{ background: isSelected ? selectionColor : MATH_GRADIENTS().event_tile, ...classes.normalSlot, ...classes.paperSlot, height: '100%', borderTopRightRadius: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '5px' }}>
              <Box>
                {iconType === 'vegies' ? Vegie(subtopic.icon, '4vh') : iconType === 'gems' ? Gem(subtopic.icon, '4vh') : iconType === 'poker' ? Poker(subtopic.icon) : null}
              </Box>
              <img src={event} style={{ marginLeft: '6px', height: '3vh' }} alt="corner_tile.svg" />
            </Box>
            <Typography style={{ fontSize: '1.5vh' }}>RULETA</Typography>
          </Paper>
        </animated.div>
      )

    case "RETO":
      return (
        <animated.div style={slotSpring}>
          <Paper
            elevation={3}
            square
            style={{}}
            sx={{ background: isSelected ? selectionColor : MATH_GRADIENTS(-45).challenge_tile, ...classes.normalSlot, ...classes.paperSlot, height: '100%', borderTopLeftRadius: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '5px' }}>
              <Box>
                {iconType === 'vegies' ? Vegie(subtopic.icon, '4vh') : iconType === 'gems' ? Gem(subtopic.icon, '4vh') : iconType === 'poker' ? Poker(subtopic.icon) : null}
              </Box>
              <img src={challenge} style={{ marginLeft: '6px', height: '3vh' }} alt="corner_tile.svg" />
            </Box>
            <Typography style={{ fontSize: '1.5vh' }}>RETO</Typography>
          </Paper>
        </animated.div>
      )
  }

  function colorSlot(pos) {
    /* return pos % 2 === 0 ? '#D4AF37' : '#FFBF00' */
    /* return pos % 2 === 0 ? '#00BFFF' : '#6DDBFF' */
    return pos % 2 === 0 ? '#da568a' : '#de89aa'
  }
}

Slot.propTypes = {
  number: PropTypes.number,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'corner']),
  type: PropTypes.oneOf(['normal', 'RULETA-TOP', 'EXCER. RANDOM', 'RULETA-BOTTOM', 'RETO']),
  subtopic: PropTypes.object,
  isSelected: PropTypes.bool,
  iconType: PropTypes.oneOf(['vegies', 'gems', 'poker'])
}

export default Slot
