import React, { useEffect } from 'react'
import { useParams, useHistory, Redirect } from "react-router-dom";
import { getAccessTokenApi } from '../../../api/auth';
import { getClassicGameByPinApi } from '../../../api/classicGame';
import { getArcadeGameByPinApi } from '../../../api/arcadeGame';
import { getRushGameByPinApi } from '../../../api/rushGame';
import { Backdrop, CircularProgress } from '@mui/material';
import { MATH_GRADIENTS } from '../../../styles/MathGradients';

function JoinByQr(props) {
  const { mode, pin } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (mode === 'classic') {
      getClassicGameByPinApi(pin).then(response => {
        if (response.status === 1) {
          if (response.game.status === 'in_lobby') {
            history.push(`/private-classic-lobby/${pin}`, { game: response.game });
          } else {
            history.push('/home/play');
          }
        } else {
          history.push('/home/play')
        }
      }).catch(e => {
        history.push('/home/play');
      })
    } else if (mode === 'arcade') {
      getArcadeGameByPinApi(pin).then(response => {
        if (response.status === 1) {
          if (response.game.status === 'in_lobby') {
            history.push(`/private-arcade-lobby/${pin}`, { game: response.game });
          } else {
            history.push('/home/play');
          }
        } else {
          history.push('/home/play')
        }
      }).catch(e => {
        history.push('/home/play');
      })
    } else if (mode === 'rush') {
      getRushGameByPinApi(pin).then(response => {
        if (response.status === 1) {
          if (response.game.status === 'in_lobby') {
            history.push(`/private-rush-lobby/${pin}`, { game: response.game });
          } else {
            history.push('/home/play');
          }
        } else {
          history.push('/home/play')
        }
      }).catch(e => {
        history.push('/home/play');
      })
    } else {
      history.push('/home/play');
    }
  }, [mode, pin, history]);

  if (!mode || !pin || !getAccessTokenApi()) {
    return <Redirect to='/login' />
  }

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      background: MATH_GRADIENTS().default
    }}>
      <Backdrop open={true}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
    </div>
  )
}

export default JoinByQr