import React, { useState, useEffect } from 'react'
import { Redirect, Link } from 'react-router-dom'
import {
  Typography, Paper, Container, Button, Box, TextField,
  FormControl, CircularProgress, useTheme,
  Grid2
} from '@mui/material'
import { MATH_GRADIENTS } from '../../styles/MathGradients'
import useAuth from '../../hooks/useAuth'

/**Componentes */
import Logo from '../../components/Logo'
import PublicHeader from '../../components/navigation/public/PublicHeader/PublicHeader'
import DefaultSnackbar from '../../components/common/DefaultSnackbar'
import ResetPassword from '../../components/forms/ResetPassword'

/**APIs */
import { loginApi } from "../../api/user"
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../utils/Constants";

/**Origen de imágenes */
import logoSrc from '../../assets/images/logos/MathParadiseLogo _vertical.svg'
import loginIcon from '../../assets/images/decos/login_icon.svg';
import logoWhiteSrc from '../../assets/images/logos/MathParadiseLogo _vertical_textwhite.svg'
import { emailValueValidation, nicknameValueValidation } from '../../utils/FormValidation'

function Login(props) {
  const theme = useTheme();
  const [openResetPass, setOpenResetPass] = useState(false)
  const [inputs, setInputs] = useState({
    credential: '',
    password: ''
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    document.title = 'Iniciar sesión - Math Paradise'
  }, [])

  /**Mensajes y alertas */
  const [alertMessage, setAlertMessage] = useState('')
  const [alertOpen, setAlertOpen] = React.useState(false)

  //Si el usuario está logueado
  const { user } = useAuth()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false)
  }

  /**Actualizaciones del formulario */
  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }

  /**
   * async y await para ver el contenido de la promesa devuelta por el fetch
   */
  const login = async (e) => {
    e.preventDefault()

    let credentialType = '';

    if (inputs.credential.trim() === '' || inputs.password === '') {
      setAlertOpen(true)
      setAlertMessage('Todos los campos son requeridos');
      return;
    }

    //Validamos si es email o nickname
    if (emailValueValidation(inputs.credential)) {
      credentialType = 'email';
    } else if (nicknameValueValidation(inputs.credential)) {
      credentialType = 'nickname';
    } else {
      setAlertOpen(true);
      setAlertMessage('Ingresa un correo electrónico o tu alias.');
      return;
    }

    try {
      //Activamos carga
      setIsLoading(true);

      const result = await loginApi({ ...inputs, credentialType: credentialType });
      if (result) {
        if (result.status === 0) {
          setAlertOpen(true);
          setAlertMessage(result.message);
        } else if (result.status === 1) {
          const { accessToken, refreshToken } = result;
          if (!accessToken || !refreshToken) {
            setAlertOpen(true);
            setAlertMessage('Error del servidor, vuelva a intentarlo.');
          } else {
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            window.location.href = '/home'; // Redireccionar al home
          }
        }
      } else {
        setAlertOpen(true);
        setAlertMessage('Error del servidor, vuelva a intentarlo.');
      }
    } catch (e) {
      setAlertOpen(true);
      setAlertMessage('Error del servidor, vuelva a intentarlo.');
    } finally {
      setIsLoading(false);
    }
  }

  /**Si el usuario está logueado */
  if (user) {
    return <Redirect to="/home" />
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <Box flex={1}>
        <ResetPassword open={openResetPass} handleClose={() => setOpenResetPass(false)} />
        <DefaultSnackbar
          open={alertOpen}
          handleClose={handleClose}
          message={alertMessage} />
        <PublicHeader />
        <Container maxWidth="lg" sx={{ mt: theme.spacing(6) }}>
          <Paper sx={{ padding: theme.spacing(4) }}>
            <Grid2 container rowSpacing={4} columnSpacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Logo src={theme.palette.mode === 'light' ? logoSrc : logoWhiteSrc} style={{ width: '156px' }} />
                  <Box sx={{ display: { xs: 'none', md: 'block' }, mt: theme.spacing(6) }}>
                    <img src={loginIcon} alt='' style={{ width: 180 }} />
                  </Box>
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <form onChange={changeForm} onSubmit={login}>
                  <Box sx={{ mb: theme.spacing(2) }}>
                    <Typography variant="h4" color="primary" gutterBottom>Iniciar sesión</Typography>
                    <Typography gutterBottom>Todos los campos son requeridos</Typography>
                  </Box>
                  <FormControl fullWidth>
                    <Box sx={{ mb: theme.spacing(2) }}>
                      <TextField
                        name="credential"
                        value={inputs.credential}
                        label="Correo electrónico o Alias"
                        variant="outlined"
                        fullWidth />
                    </Box>
                  </FormControl>
                  <FormControl fullWidth>
                    <Box sx={{ mb: theme.spacing(2) }}>
                      <TextField
                        name="password"
                        value={inputs.password}
                        type="password"
                        label="Contraseña"
                        variant="outlined"
                        fullWidth />
                    </Box>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
                    <Box>
                      {
                        !isLoading ?
                          <Button type="submit" variant="contained" sx={{
                            display: "flex",
                            margin: "auto",
                            width: '100%',
                            padding: theme.spacing(2),
                            color: "#FFFFFF",
                            background: MATH_GRADIENTS().default,
                            textAlign: "center",
                          }}>
                            <Typography variant="h6">INGRESAR</Typography>
                          </Button> :
                          <CircularProgress />
                      }
                    </Box>
                  </FormControl>
                  <Link to='/sign-up' style={{ textDecoration: 'none', color: theme.palette.info.main }}>
                    <Typography>¿No tienes una cuenta? Regístrate</Typography>
                  </Link>
                </form>
              </Grid2>
            </Grid2>
          </Paper>
        </Container>
      </Box>
    </div>
  )
}

export default Login