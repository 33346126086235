//import { MATH_GRADIENTS } from "../../../../styles/MathGradients";

const drawerWidth = 300
const useStyles = (theme) => ({
  appBar: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  },
  title: {
    flexGrow: 10,
    display: 'block',
    color: theme.palette.primary.main
  },
  searchBox: {
    flexGrow: 2
  },
  btnBox: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    color: 'white'
  },
  requestsPaper: {
    marginTop: theme.spacing(2),
    position: 'absolute',
    [theme.breakpoints.down('xl')]: {
      left: '70%',
    },
    [theme.breakpoints.down('lg')]: {
      left: '54%',
    },
    [theme.breakpoints.down('md')]: {
      left: '32%',
    },
    [theme.breakpoints.down('sm')]: {
      left: '50%',
      width: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      left: '0',
      width: '100%',
    },
    width: '500px',
    padding: theme.spacing(2),
    maxHeight: 500,
    overflowY: 'auto'
  },
  inline: {
    display: 'inline'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    }
  },
  alignIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  logoutLabel: {
    color: theme.palette.error.main
  },
  basicLabel: {
    color: theme.palette.primary.main
  }
});

export default useStyles;