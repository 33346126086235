const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%'
  },
  paperStats: {
    height: '100%'
  },
  paperProfile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  boxInfo: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  fullnameLabel: {
    textAlign: 'center'
  },
  nicknameLabel: {
    textAlign: 'center'
  },
  editProfileBtn: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  role: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1)
  },
  alignIconRole: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    background: theme.palette.primary.main,
    color: '#FFF',
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
})

export default useStyles;