import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import {
  Typography,
  LinearProgress,
  useTheme,
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useStyles } from './useStyles';
import GameHistoryAccordion from '../../../components/common/GameHistoryAccordion';
import { getRushGamesByNicknameApi, } from '../../../api/rushGame';
import { getClassicGamesByNicknameApi, } from '../../../api/classicGame';
import { getArcadeGamesByNicknameApi, } from '../../../api/arcadeGame';

function History() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [gamemodeFilter, setGamemodeFilter] = useState('all');
  const [gameHistory, setGameHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const nickname = user?.nickname;

  useEffect(() => {
    document.title = 'Historial de partidas - Math Paradise';

    if (!nickname) return;

    const fetchGameHistory = async () => {
      try {
        const [classicResponse, arcadeResponse, rushResponse] = await Promise.all([
          getClassicGamesByNicknameApi(nickname),
          getArcadeGamesByNicknameApi(nickname),
          getRushGamesByNicknameApi(nickname),
        ]);

        const formatGames = (response, gamemode) =>
          response?.status === 1
            ? response.games.map((game) => ({ ...game, gamemode }))
            : [];

        const classicGames = formatGames(classicResponse, 'classic');
        const arcadeGames = formatGames(arcadeResponse, 'arcade');
        const rushGames = formatGames(rushResponse, 'rush');

        setGameHistory([...classicGames, ...arcadeGames, ...rushGames]);
      } catch (error) {
        console.error('Error fetching game history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGameHistory();

    return () => setGameHistory([]);
  }, [nickname]);

  const filteredGames =
    gamemodeFilter === 'all'
      ? gameHistory
      : gameHistory.filter((game) => game.gamemode === gamemodeFilter);

  if (isLoading) {
    return <LinearProgress variant="indeterminate" />;
  }

  return (
    <div style={classes.root}>
      <Container maxWidth="md">
        <Box sx={classes.header}>
          <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
            Historial de juegos
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Desglose de partidas jugadas recientemente, presiona para ver más detalles.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <FormControl variant="outlined" sx={{ width: '75%' }}>
            <InputLabel id="lbl_gamemode">Modo de juego</InputLabel>
            <Select
              name="gamemode"
              label="Modo de juego"
              labelId="lbl_gamemode"
              value={gamemodeFilter}
              onChange={(e) => setGamemodeFilter(e.target.value)}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="classic">Clásico</MenuItem>
              <MenuItem value="arcade">Arcade</MenuItem>
              <MenuItem value="rush">Rush</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Container>
      <Container maxWidth="md" sx={{ mt: theme.spacing(2) }}>
        {filteredGames.length === 0 ? (
          <Typography color="textPrimary" sx={classes.noPlaysText}>
            No hay partidas registradas.
          </Typography>
        ) : (
          filteredGames
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((game, index) => (
              <GameHistoryAccordion
                key={index}
                historyData={game}
                myNickname={nickname}
              />
            ))
        )}
      </Container>
    </div>
  );
}

export default History;
