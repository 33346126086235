import React from 'react'
import {
  Card, CardActionArea, CardMedia, CardContent,
  Typography,
  Box,
  useTheme,
  Button,
  CardActions
} from '@mui/material'
import { Link } from 'react-router-dom';
import useStyles from './useStyles'
import PropTypes from 'prop-types';
import DefaultAvatar from '../../user/DefaultAvatar';
import { basePath, apiVersion } from '../../../config/api';
//import MoreVertIcon from '@mui/icons-material/MoreVert';

function GroupCard(props) {

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Card sx={classes.root} >
      <CardActionArea>
        <CardMedia
          component="img"
          sx={classes.media}
          image={`${basePath}/${apiVersion}/get-cover-image/${props.cover_image}`}
          title={props.name} />
      </CardActionArea>
      <CardContent>
        <Link
          to={`groups/${props.id}`}
          style={{ color: props.color, textDecoration: 'none' }}>
          <Typography gutterBottom variant="h5" component="h2" noWrap>
            {props.name}
          </Typography>
        </Link>
        <Box mt={2} sx={classes.creatorCard}>
          <DefaultAvatar sx={classes.avatar} nickname={props.creator.nickname} />
          <Box>
            <Typography>{`${props.creator.name} ${props.creator.lastname}`}</Typography>
            <Typography variant="body2" color="textSecondary">@{props.creator.nickname}</Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Link
          to={`groups/${props.id}`}
          style={{ width: '100%' }}>
          <Button variant='contained' sx={{ color: 'white', background: props.color, width: '100%' }}>
            Ver grupo
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

GroupCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  cover_image: PropTypes.string,
  creator: PropTypes.object
};

export default GroupCard