import React, { useState, useEffect, useMemo } from "react"
import useAuth from '../../hooks/useAuth'
import { useTheme } from "@mui/material/styles"
import {
  Typography,
  Grid,
  LinearProgress,
  Paper,
  Box,
  Button,
  Grid2
} from "@mui/material"

//Icons
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { getUserByIdApi } from '../../api/user'

/**Componentes */
import UserCard from "../../components/user/UserCard"
import GameStats from "../../components/user/GameStats/GameStats"
import { MATH_GRADIENTS } from "../../styles/MathGradients"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HistoryChart from "../../components/user/HistoryChart/HistoryChart";

function Dashboard() {
  const theme = useTheme();
  const [userData, setUserData] = useState({})
  const [isLoading, setisLoading] = useState(true)
  const { user } = useAuth();
  const id = useMemo(() => user.id, [user]);

  useEffect(() => {
    document.title = 'Menú principal - Math Paradise'

    //Trae datos del usuario
    getUserByIdApi(id).then(response => {
      setUserData(response.user)
      setisLoading(false)
    })
  }, [id])

  if (isLoading) {
    return <LinearProgress variant='indeterminate' />
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <UserCard name={userData.name} lastname={userData.lastname} avatar={userData.avatar} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <Paper sx={{ p: theme.spacing(2), height: '100%' }}>
            <Typography variant="h6" gutterBottom>Tus Herramientas</Typography>
            <Box>
              <Link to={'/home/settings'}>
                <Button variant="contained"
                  sx={{ background: MATH_GRADIENTS().default, color: 'white', mb: theme.spacing(1), width: '100%' }}
                  startIcon={<EditIcon />}
                  size="large">
                  Editar Perfil
                </Button>
              </Link>
              <Link to={'/home/groups'}>
                <Button variant="contained"
                  sx={{ background: MATH_GRADIENTS().default, color: 'white', mb: theme.spacing(1), width: '100%' }}
                  startIcon={<PeopleAltIcon />}
                  size="large">
                  Ver tus grupos
                </Button>
              </Link>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Typography color='textSecondary' sx={{ marginTop: theme.spacing(3) }} variant="h5">
        Estadísticas de juego
      </Typography>

      <Grid2 container spacing={2} sx={{ paddingTop: theme.spacing(1), }}>
        <Grid2 size={{ xl: 4, lg: 5, md: 12, sm: 12, xs: 12 }}>
          <HistoryChart nickname={userData.nickname} />
        </Grid2>
        <Grid2 size={{ xl: 8, lg: 7, md: 12, sm: 12, xs: 12 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <GameStats gamemode='classic' stats={userData.classic} />
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <GameStats gamemode='arcade' stats={userData.arcade} />
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <GameStats gamemode='rush' stats={userData.rush} />
            </Grid>
          </Grid>
        </Grid2>
      </Grid2>
    </div>
  )
}

export default Dashboard
