import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth'
import PropTypes from 'prop-types'
import {
  AppBar, Dialog, IconButton, Toolbar, Typography, Slide,
  Box, useTheme, Grid2 as Grid, FormGroup, FormControlLabel, Checkbox,
  FormLabel, Accordion, AccordionSummary, AccordionDetails, Switch,
  FormControl, RadioGroup, Radio, InputLabel, Select, MenuItem, Button,
  Backdrop,
  CircularProgress
} from '@mui/material';
import DefaultSnackbar from '../../common/DefaultSnackbar/DefaultSnackbar';

//Icons & Colors
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { MATH_GRADIENTS } from '../../../styles/MathGradients';

//Api
import { getAreasApi } from '../../../api/areas';
import { getTopicsByCodesApi } from '../../../api/topics';
import { generateSubtopicBoardApi, getSubtopicsByCodesApi } from '../../../api/subtopics';
import { difficulties, seconds_for_game } from '../../../utils/SelectArrays';
import { createClassicGameApi, getClassicServerStatusApi } from '../../../api/classicGame';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ClassicConfigDialog(props) {
  const history = useHistory();
  const theme = useTheme();
  const { open = false, onClose = () => { }, mode } = props;

  //const [board, setBoard] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [areas, setAreas] = useState([]);
  const [originalTopics, setOriginalTopics] = useState([]);
  const [originalSubtopics, setOriginalSubtopics] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [selectAllAreas, setSelectAllAreas] = useState(false);
  const [selectAllTopics, setSelectAllTopics] = useState(false);
  const [selectAllSubtopics, setSelectAllSubtopics] = useState(false);
  const [difficulty, setDifficulty] = useState('normal');
  const [time, setTime] = useState(300);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const { user: localUser } = useAuth();
  const [isCreatingGame, setIsCreatingGame] = useState(false);

  //Trae materias
  useEffect(() => {
    getAreasApi().then(response => {
      if (response.status === 1) {
        setAreas(response.areas.filter(item => item.active !== false));
      }
    }).catch(error => {
    });
  }, []);

  //Traer temas de acuerdo a las materias seleccionadas
  useEffect(() => {
    //Limpia todo por error de renderizado
    setSelectedTopics([]);
    setTopics([]);
    setSelectedSubtopics([]);
    setSubtopics([]);
    setSelectAllTopics(false);

    if (selectedAreas.length > 0) {
      selectedAreas.forEach(area => {
        getTopicsByCodesApi(selectedAreas).then(response => {
          setTopics(groupBy(response.topics.filter(item => item.active !== false), 'area'));
          setOriginalTopics(response.topics.filter(item => item.active !== false));
        }).catch(error => {
        })
      });
    } else {
      //Borrar todo si se desmarcan las materias
      setSelectedTopics([]);
      setTopics([]);
      setSelectedSubtopics([]);
      setSubtopics([]);
      setSelectAllAreas(false);
      setSelectAllTopics(false);
      setSelectAllSubtopics(false);
    }
  }, [selectedAreas]);

  //Traer subtemas de acuerdo a los temas seleccionadas
  useEffect(() => {
    if (selectedTopics.length > 0) {
      //Limpia todo por error de renderizado
      setSelectedSubtopics([]);
      setSubtopics([]);
      setSelectAllSubtopics(false);

      selectedTopics.forEach(topic => {
        let querySubtopics = [];

        //Arma arreglo para el query
        selectedTopics.forEach(sT => {
          querySubtopics.push(sT.topic);
        });

        getSubtopicsByCodesApi(querySubtopics).then(response => {
          setSubtopics(groupBy(response.subtopics.filter(item => item.active !== false), 'topic'));
          setOriginalSubtopics(response.subtopics.filter(item => item.active !== false));
        }).catch(error => {
        })
      });
    } else {
      setSelectedSubtopics([]);
      setSubtopics([]);
      setSelectAllSubtopics(false);
    }
  }, [selectedTopics]);

  //Efecto que guarda las materias seleccionadas
  const handleAreas = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      // Si está seleccionado, añadimos el valor al estado
      setSelectedAreas([...selectedAreas, value]);
    } else {
      // Si se desmarca, eliminamos el valor del estado
      setSelectedAreas(selectedAreas.filter((item) => item !== value));
    }
  }

  //Efecto que guarda TODAS las materias seleccionadas
  const handleSelectAllAreas = (e) => {
    const checked = e.target.checked;
    setSelectAllAreas(e.target.checked)

    if (checked) {
      let areasToParse = [];
      areas.forEach(ar => {
        areasToParse.push(ar.code);
      });

      setSelectedAreas(areasToParse);
    } else {
      setSelectedAreas([]);
    }
  }

  //Efecto que guarda los temas seleccionadas
  const handleTopics = (e) => {
    const value = JSON.parse(e.target.value);
    if (e.target.checked) {
      // Si está seleccionado, añadimos el valor al estado
      setSelectedTopics((prevSelected) => [...prevSelected, value]);
    } else {
      //Eliminamos todos los subtemas que coincidan con la sección desmarcada
      setSelectedSubtopics((prevs) => prevs.filter(item => item.topic !== value.topic));

      // Si se desmarca, eliminamos el valor del estado comparando su contenido
      setSelectedTopics((prevSelected) =>
        prevSelected.filter(
          (item) => item.topic !== value.topic // Compara por propiedad única (como id)
        )
      );
    }
  }

  //Efecto que guarda TODAS los temas seleccionadas
  const handleSelectAllTopics = (e) => {
    const checked = e.target.checked;
    setSelectAllTopics(e.target.checked)

    if (checked) {
      let topicsToParse = [];
      originalTopics.forEach(tp => {
        topicsToParse.push({ area: tp.area, topic: tp.code });
      });

      setSelectedTopics(topicsToParse);
    } else {
      setSelectedTopics([]);
    }
  }

  //Efecto que guarda los subtemas seleccionadas
  const handleSubtopics = (e) => {
    const value = JSON.parse(e.target.value);
    if (e.target.checked) {
      // Si está seleccionado, añadimos el valor al estado
      setSelectedSubtopics((prevSelected) => [...prevSelected, value]);
    } else {
      // Si se desmarca, eliminamos el valor del estado comparando su contenido
      setSelectedSubtopics((prevSelected) =>
        prevSelected.filter(
          (item) => item.subtopic !== value.subtopic // Compara por propiedad única (como id)
        )
      );
    }
  }

  //Efecto que guarda TODAS los temas seleccionadas
  const handleSelectAllSubtopics = (e) => {
    const checked = e.target.checked;
    setSelectAllSubtopics(e.target.checked)

    if (checked) {
      let subtopicsToParse = [];
      originalSubtopics.forEach(tp => {
        subtopicsToParse.push({ topic: tp.topic, subtopic: tp.code });
      });

      setSelectedSubtopics(subtopicsToParse);
    } else {
      setSelectedSubtopics([]);
    }
  }

  // Función para agrupar los objetos y devolver un arreglo
  const groupBy = (array, key) => {
    const grouped = array.reduce((result, currentValue) => {
      // Buscar si ya existe un grupo para esa categoría
      let group = result.find(group => group.key === currentValue[key]);
      if (!group) {
        // Si no existe, crear un nuevo grupo
        group = { key: currentValue[key], items: [] };
        result.push(group);
      }
      // Agregar el objeto actual al grupo correspondiente
      group.items.push(currentValue);
      return result;
    }, []);

    return grouped;
  };

  //Funciòn para generar configuración de partida.
  const generateGame = () => {
    //Destructura los subtemas elegidos para generar la query
    const areasQuery = selectedAreas;
    let topicsQuery = [];
    let subtopicsQuery = [];

    //Validaciones
    if (selectedSubtopics.length === 0) {
      setMessage("Seleccione al menos un subtema desde los filtros.");
      setOpenSnackbar(true);
      setIsCreatingGame(false);
      return;
    }

    //Organiza los temas solo para info
    selectedTopics.forEach(st => {
      topicsQuery.push(st.topic);
    });

    //Genera la query para generar el tablero desde el servidor
    selectedSubtopics.forEach(sst => {
      subtopicsQuery.push(sst.subtopic);
    });

    //Incia carga
    setIsCreatingGame(true);

    if (mode === 'private') {
      //Genera el tablero de los subtemas elejidos desde los subtemas traídos
      generateSubtopicBoardApi(subtopicsQuery, 30).then(response => {
        let config = {
          pin: null,
          time: time,
          difficulty: difficulty,
          host: localUser.nickname,
          areas: areasQuery,
          topics: topicsQuery,
          subtopics: subtopicsQuery,
          players: [{
            nickname: localUser.nickname,
            number: 1,
            avatar: localUser.avatar,
            points: 0,
            boardPosition: 0,
            answered: 0,
            correct: 0,
            errors: 0,
            dropOff: false,
            result: 'none',
          }],
          turn: "",
          status: 'game_generated',
          message: '',
          board: response.board
        }

        //Guardamos la partida en el servidor en la BD para registro
        getClassicServerStatusApi().then(response => {
          if (response) {
            createClassicGameApi(config).then(response => {
              //Redirige al lobby
              history.push(`/private-classic-lobby/${response.game.pin}`, { game: response.game });
              setIsCreatingGame(false);
            }).catch(e => {
              setIsCreatingGame(false);
            });
          } else {
            setIsCreatingGame(false);
            setOpenSnackbar(true);
            setMessage("Servidor no disponible, inténtalo de nuevo.");
          }
        }).catch(e => {
          setIsCreatingGame(false);
          setOpenSnackbar(true);
          setMessage("Servidor no disponible, inténtalo de nuevo.");
        })
      }).catch(e => {
        setIsCreatingGame(false);
      });
    } else if (mode === 'local') {
      //Genera el tablero de los subtemas elejidos desde los subtemas traídos
      generateSubtopicBoardApi(subtopicsQuery, 30).then(response => {
        let config = {
          pin: null,
          time: time,
          difficulty: difficulty,
          host: localUser.nickname,
          areas: areasQuery,
          topics: topicsQuery,
          subtopics: subtopicsQuery,
          players: null,
          turn: "",
          status: 'game_generated',
          message: '',
          board: response.board
        }

        history.push(`/local-classic-lobby`, { game: config });
        setIsCreatingGame(false);
      });
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted>
      <Backdrop open={isCreatingGame} sx={{ zIndex: 5000 }} >
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <DefaultSnackbar open={openSnackbar} handleClose={() => setOpenSnackbar(false)} message={message} />
      <AppBar sx={{ position: 'relative', background: MATH_GRADIENTS().classic }}>
        <Toolbar
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close">
          <IconButton>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Crear partida
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: theme.spacing(3) }}>
        <Typography variant='h4' gutterBottom>Configuración del Modo Clásico</Typography>
        <Grid container spacing={2}>
          <Grid size={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Grid container spacing={2}>
              <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
                <Box>
                  <FormControl fullWidth color='classic'>
                    <FormLabel>Dificultad</FormLabel>
                    <RadioGroup row id='difficulty' value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
                      {
                        difficulties.map((df, index) => (
                          <FormControlLabel key={index} value={df.val} control={<Radio color='classic' />} label={df.name} />
                        ))
                      }
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
                <FormControl fullWidth color='classic'>
                  <InputLabel id='tiempo-label'>Tiempo</InputLabel>
                  <Select id='time' labelId='tiempo-label' label="Tiempo" value={time} onChange={(e) => setTime(e.target.value)}>
                    {
                      seconds_for_game.map((scs, index) => (
                        <MenuItem key={index} value={scs.val}>{scs.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
                <Button variant='contained'
                  fullWidth style={{ background: MATH_GRADIENTS().classic, color: 'white' }} startIcon={<SportsEsportsIcon />}
                  onClick={() => { generateGame() }}>
                  Generar partida
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
            <Typography variant='h6'>Materias</Typography>
            <Box>
              <FormControlLabel control={
                <Switch
                  color='classic'
                  checked={selectAllAreas}
                  onChange={(e) => handleSelectAllAreas(e)} />
              }
                label='Seleccionar todas las materias' />
            </Box>
            <FormGroup>
              <FormLabel>Selecciona una o más materias</FormLabel>
              {
                areas.map((area, index) =>
                  area.active && (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox color='classic' onChange={handleAreas}
                          checked={selectedAreas.includes(area.code)}
                          value={area.code} />
                      }
                      label={area.name} />
                  )
                )
              }
            </FormGroup>
          </Grid>
          <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
            <Typography variant='h6'>Temas</Typography>
            <Box>
              <FormControlLabel control={
                <Switch
                  color='classic'
                  checked={selectAllTopics}
                  onChange={(e) => handleSelectAllTopics(e)}
                  disabled={selectedAreas.length > 0 ? false : true} />
              }
                label='Seleccionar todas los temas' />
            </Box>
            <FormGroup>
              <FormLabel>Selecciona uno o más temas</FormLabel>
              {
                topics.map((topic, index) => (
                  <Accordion key={index} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Temas de {topic.key.toUpperCase().replace("_", " ")}
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        topic.items.map((topicItems, index) =>
                          topicItems.active && (
                            <FormControlLabel
                              disabled={selectedSubtopics.some(ss => ss.topic === topicItems.code)}
                              key={index}
                              control={
                                <Checkbox
                                  color='classic'
                                  onChange={handleTopics}
                                  checked={selectedTopics.some(item => item.topic.includes(topicItems.code))}
                                  value={`{"area": "${topic.key}", "topic": "${topicItems.code}"}`} />
                              }
                              name={topicItems.code}
                              label={topicItems.name} />
                          )
                        )
                      }
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </FormGroup>
          </Grid>
          <Grid size={{ xl: 4, lg: 4, md: 4, sm: 6, xs: 12 }}>
            <Typography variant='h6'>Subtemas (Casillas)</Typography>
            <Box>
              <FormControlLabel control={
                <Switch
                  color='classic'
                  checked={selectAllSubtopics}
                  onChange={(e) => handleSelectAllSubtopics(e)}
                  disabled={selectedTopics.length > 0 ? false : true} />
              }
                label='Seleccionar todas los subtemas' />
            </Box>
            <FormGroup>
              <FormLabel>Selecciona uno o más subtemas</FormLabel>
              {
                subtopics.map((subtopic, index) => (
                  <Accordion key={index} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Subtemas de {subtopic.key.toUpperCase().replace("_", " ")}
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        subtopic.items.map((subtopicItems, index) =>
                          subtopicItems.active && (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color='classic'
                                  name={subtopicItems.code}
                                  onChange={handleSubtopics}
                                  checked={selectedSubtopics.some(item => item.subtopic.includes(subtopicItems.code))}
                                  value={`{"topic": "${subtopic.key}", "subtopic": "${subtopicItems.code}"}`} />
                              }
                              label={subtopicItems.name} />
                          )
                        )
                      }
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

ClassicConfigDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default ClassicConfigDialog