export const useStyles = (theme) => ({
  paperSlot: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  normalSlot: {
    color: "#FFF",
    width: '100%',
    height: '100%'
  },
  pointsLabel: {
    height: '20%'
  },
  symbolPaper: {
    color: '#FFF'
  },
  labelSize: {
    fontSize: `1.7vh`,
  },
  symbolSize: {
    fontSize: `1.75vh`,
  },
  corner_tile: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#FFF'
  }
});