import React, { useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Typography, Paper, Box, List, ListItem, ListItemText,
  ListItemAvatar, ListItemIcon,
  Grid,
  Button,
  IconButton,
  LinearProgress,
  useTheme
} from '@mui/material';
import useStyles from './useStyles';

//Components
import DefaultAvatar from '../../../../../components/user/DefaultAvatar/DefaultAvatar';
import AddGroupMember from '../../../../../components/forms/AddGroupMember';
import Notification from '../../../../../components/common/Notification/Notification';
import DefaultSnackbar from '../../../../../components/common/DefaultSnackbar';

//Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

//Hooks
import useAuth from '../../../../../hooks/useAuth'

//APis
import { deleteMemberApi } from '../../../../../api/groups';

function GroupMembers(props) {
  const { group, startReload } = props;
  const { user } = useAuth();
  const theme = useTheme();
  const classes = useStyles(group.color, theme);

  const [selectedMember, setSelectedMember] = useState({
    id: '',
    nickname: ''
  });

  const [openAddMember, setOpenAddMember] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [openDeleteNotif, setOpenDeleteNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [exitGroup, setExitGroup] = useState(false);
  const [redirectToGroups, setRedirectToGroups] = useState(false);

  const deleteMember = () => {
    //Inicia carga
    //Cierra notificcación
    setOpenDeleteNotif(false);

    //Inicia carga
    setIsLoading(true);

    //Api
    deleteMemberApi(group._id, [selectedMember.id]).then(response => {
      if (response.status === 1) {
        //Para carfa
        setIsLoading(false);

        //Recarga
        startReload();

        //Muestra mensaje
        setMessage(response.message);
        setOpenSnack(true);

        if (exitGroup) {
          setRedirectToGroups(true);
        }
      } else {
        setMessage(response.message);
        setOpenSnack(true);
      }
    })
  }

  if (redirectToGroups) {
    return <Redirect to={'/home/groups'} />
  }

  return (
    <div style={classes.root}>
      {
        isLoading && (
          <LinearProgress variant='indeterminate' />
        )
      }
      <DefaultSnackbar
        open={openSnack}
        handleClose={() => setOpenSnack(false)}
        message={message} />
      <Notification
        open={openDeleteNotif}
        onClose={() => setOpenDeleteNotif(!openDeleteNotif)}
        onAccept={deleteMember}
        title='Eliminar miembro'>
        <Typography>
          {notifMessage}
        </Typography>
      </Notification>
      <AddGroupMember
        group={group}
        open={openAddMember}
        onClose={() => setOpenAddMember(!openAddMember)} />
      <Paper sx={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xl={8} lg={9} md={7} sm={8} xs={7}>
            <Typography variant='h5' gutterBottom sx={{ color: group.color }}>
              Miembros
            </Typography>
          </Grid>
          <Grid item xl={4} lg={3} md={5} sm={4} xs={5}>
            {
              user.id === group.idCreator ? (
                <Button
                  fullWidth
                  sx={classes.btn}
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddMember(true)}>
                  Invitar miembro
                </Button>
              ) :
                <Button
                  fullWidth
                  sx={classes.btn}
                  startIcon={<ExitToAppIcon />}
                  onClick={() => {
                    //Define mismo usuario
                    setSelectedMember({
                      ...selectedMember,
                      id: user.id,
                      nickname: user.nickname
                    });

                    //Elimina usuario
                    setOpenDeleteNotif(true);
                    setExitGroup(true);
                    setNotifMessage(`¿Estás seguro de salir de este grupo?
                      \nPodrás volver a unirte por medio del código de acceso o si tu tutor te vuelve a invitar.`
                    );
                  }}>
                  Salir del grupo
                </Button>
            }
          </Grid>
        </Grid>
        <Box>
          <Typography variant='h6'>Tutor</Typography>
          <List>
            {
              group.creator && (
                <ListItem>
                  <ListItemAvatar>
                    <DefaultAvatar nickname={group.creator.nickname} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Fragment>
                      <Link style={classes.link} to={`/home/profile/@${group.creator.nickname}`}>
                        <Typography>
                          {`${group.creator.name} ${group.creator.lastname}`}
                        </Typography>
                      </Link>
                    </Fragment>}
                    secondary={`@${group.creator.nickname}`} />
                </ListItem>
              )
            }
          </List>
          <Typography variant='h6'>Estudiantes</Typography>
          <List>
            {
              group.members && group.members.length === 0 ?
                <Typography>
                  No hay miembros
                </Typography> :
                <Fragment>
                  {
                    group.members && group.members.map((value, index) => {
                      if (value) {
                        return (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <DefaultAvatar nickname={value.nickname} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Fragment>
                                  <Link style={classes.link} to={`/home/profile/@${value.nickname}`}>
                                    <Typography>
                                      {`${value.name} ${value.lastname}`}
                                    </Typography>
                                  </Link>
                                </Fragment>
                              }
                              secondary={`@${value.nickname}`} />
                            {
                              user.id === group.idCreator ?
                                <ListItemIcon>
                                  <IconButton
                                    onClick={() => {
                                      //Selecciona mienbro
                                      setSelectedMember({
                                        ...selectedMember,
                                        id: value._id,
                                        nickname: value.nickname
                                      });

                                      //Abre notif
                                      setOpenDeleteNotif(true);
                                      setNotifMessage(`¿Estás seguro de eliminar a @${value.nickname}`);
                                    }}>
                                    <CloseIcon />
                                  </IconButton>
                                </ListItemIcon> : null
                            }
                          </ListItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
                </Fragment>
            }
          </List>
        </Box>
      </Paper>
    </div>
  )
}

export default GroupMembers