import React from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import {
  Button, AppBar, Toolbar, Menu, MenuItem,
  Container,
  Box, IconButton, Typography
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

//import HomeIcon from '@mui/icons-material/Home';
import logoSource from '../../../../assets/images/logos/MathParadiseLogo.svg'
import logoWhiteSource from '../../../../assets/images/logos/MathParadiseLogo_textwhite.svg'
import { MATH_GRADIENTS } from '../../../../styles/MathGradients';

const useStyles = (theme) => ({
  title: {
    padding: theme.spacing(1)
  },
  logo: {
    width: '185px',
  },
  colorLabels: {
    color: theme.palette.primary.main
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    }
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

function PublicHeader() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const pages = [
    { id: 'what-we-are', name: '¿Qué somos?' },
    { id: 'game-modes', name: 'Modos de juego' },
    { id: 'platform', name: 'Plataforma' },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position="sticky" color="inherit" elevation={1} sx={{ height: 84, display: 'flex', justifyContent: 'center' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: theme.spacing(3) }}>
            <Link to="/">
              <img
                src={theme.palette.mode === 'light' ? logoSource : logoWhiteSource}
                alt={"mp_logo.svg"}
                width={200}>
              </img>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon color='primary' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, index) => (
                <a
                  key={index}
                  href={`/#${page.id}`}
                  style={{ textDecoration: 'none', color: theme.palette.text.secondary }}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                  </MenuItem>
                </a>
              ))}
              <Link
                to={`/news`}
                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography sx={{ textAlign: 'center' }}>Noticias</Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, mr: 1 }}>
            <Link to="/">
              <img
                src={theme.palette.mode === 'light' ? logoSource : logoWhiteSource}
                alt={"mp_logo.svg"}
                width={185}>
              </img>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
              pages.map((page, index) => (
                <a
                  key={index}
                  href={`/#${page.id}`}
                  style={{ textDecoration: 'none', color: theme.palette.text.secondary }}>
                  <Button
                    onClick={handleCloseNavMenu}
                    color="inherit"
                    sx={{ display: 'block' }}>
                    {page.name}
                  </Button>
                </a>
              ))
            }
            <Link
              to={`/news`}
              style={{ textDecoration: 'none', color: theme.palette.text.secondary }}>
              <Button
                onClick={handleCloseNavMenu}
                color="inherit"
                sx={{ display: 'block' }}>
                Noticias
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button onClick={handleMenu} startIcon={<AccountCircleIcon />}
              sx={{
                background: MATH_GRADIENTS().default,
                color: 'white',
                minWidth: '16vh',
                borderRadius: 30,
                display: { xs: 'none', md: 'flex' }
              }}>
              Acceder
            </Button>
            <IconButton sx={{
              background: MATH_GRADIENTS().default,
              color: 'white',
              borderRadius: 30,
              display: { xs: 'flex', md: 'none' }
            }}
              onClick={handleMenu}>
              <AccountCircleIcon />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={open}
              onClose={handleClose}>

              <Link to={"/login"} style={classes.link}>
                <MenuItem>Iniciar sesión</MenuItem>
              </Link>
              <Link to={"/sign-up"} style={classes.link}>
                <MenuItem>Registrarse</MenuItem>
              </Link>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  )
}

export default PublicHeader
