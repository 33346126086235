import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LinearProgress,
  IconButton,
  Typography,
  Box
} from "@mui/material"
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
//import { DataGrid, GridToolbar } from 'material-react-table'

/**Componentes */
import Notification from '../common/Notification'

/**Iconos */
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

/**APIs */
import { getAreasApi, deleteAreaApi } from "../../api/areas"

function AreasTable() {
  let areaList = []

  const [areasData, setAreaData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [reload, setReload] = useState(false)

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID', width: 100 },
    { accessorKey: 'name', header: 'Nombre', width: 500 },
    { accessorKey: 'code', header: 'Código', width: 100 },
    {
      accessorKey: 'active',
      header: 'Estado',
      Cell: ({ rValue, row }) => (
        row.original.active ?
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <CheckCircleIcon style={{ color: '#00B76F' }} />
            <span style={{ marginLeft: 5 }}>Activo</span>
          </div> :
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <CancelIcon style={{ color: '#FF2942' }} />
            <span style={{ marginLeft: 5 }}>Inactivo</span>
          </div>
      ),
      width: 150
    },
    {
      accessorKey: 'actions',
      header: 'Acciones',
      width: 150,
      Cell: ({ rValue, row }) => (
        <Fragment>
          <Link to={`/admin/areas/update/${row.original.id}`}>
            <IconButton>
              <CreateIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setOpen(true)
              setSelectedId(row.original.id)
            }}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </Fragment>
      )
    }
  ], []);

  useEffect(() => {
    getAreasApi().then(response => {
      response.areas.forEach(value => {
        areaList.push({ ...value, id: value._id })
        return null
      })

      setAreaData(areaList)
      setIsLoading(false)
    })

    setReload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const data = useMemo(() => areasData, [areasData]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact'
    }
  });

  return (
    <Fragment>
      <Notification
        open={open}
        onClose={() => setOpen(false)}
        title="Eliminar área"
        onAccept={() => {
          deleteAreaApi(selectedId).then()
          setOpen(false)
          setReload(true)
        }}>
        <Typography>¿Estás seguro de querer eliminar este elemento?</Typography>
      </Notification>
      <Box sx={{ height: 400, p: 0, m: 0, maxWidth: '82vw' }}>
        {isLoading ? <LinearProgress /> :
          areasData.length > 0 ?
            <MaterialReactTable table={table} />
            : <Typography>No hay registros</Typography>
        }
      </Box>
    </Fragment>
  )
}

export default AreasTable
