const useStyles = (theme) => ({
  root: {

  },
  header: {
    marginBottom: theme.spacing(2)
  },
  button: {
    width: '100%',
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  noGroupsLabel: {
    fontWeight: 'bold'
  }
});

export default useStyles;