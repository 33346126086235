import React from 'react'
import { Link } from 'react-router-dom'
import { Paper, Grid, Typography, Box, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import useStyles from './useStyles';

//Images
import GoldIcon from '../../../assets/images/icons/1st_icon.svg'
import SilverIcon from '../../../assets/images/icons/2nd_icon.svg'
import BronzeIcon from '../../../assets/images/icons/3rd_icon.svg'
import DefaultAvatar from '../../user/DefaultAvatar/DefaultAvatar';

function LeaderboardPaper(props) {
  const { nickname, points, place } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper sx={classes.userPaperInfo}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item lg={3} md={2} sm={2} xs={2} sx={classes.avatar}>
          <DefaultAvatar nickname={nickname} size="40px" />
        </Grid>
        <Grid item lg={6} md={7} sm={7} xs={7} >
          <Box>
            <Link style={classes.link} to={"/home/profile/@" + nickname}>
              <Typography>{nickname}</Typography>
            </Link>
            <Typography variant="subtitle2">Puntos: <span style={classes.points}>{points.toLocaleString("en-US")}</span></Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3} sx={classes.avatar}>
          {
            place < 3 ?
              <img src={
                place === 0 ? GoldIcon : place === 1 ? SilverIcon : place === 2 ? BronzeIcon : null
              } style={classes.medal} alt="medal.svg" /> :
              <Typography>{place + 1}</Typography>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

LeaderboardPaper.propTypes = {
  nickname: PropTypes.string,
  points: PropTypes.number,
  place: PropTypes.number
}

export default LeaderboardPaper