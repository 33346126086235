import React from 'react'
import { Paper, Grid, Typography, useTheme } from '@mui/material'
import { useStyles } from './useStyles'
import PropTypes from 'prop-types'


function Tile(props) {
  const {
    number,
    position,
    cornerType,
    image,
    subtopic = {
      area: 'area',
      topic: 'topic',
      code: 'code_subtopic',
      displayLabel: 'SUBTOPIC',
      symbol: 'symbol',
      difficulty: 'difficulty',
      name: 'NOMBRE'
    },
  } = props
  const theme = useTheme();
  const classes = useStyles(theme);

  //Renderiza el tipo casilla que se le indique
  switch (position) {
    case 'bottom':
      return (
        <div style={classes.tile}>
          <Paper
            elevation={3}
            sx={{ height: '80%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            style={{ background: colorTile(number) }}
            square>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '0.8vw', letterSpacing: 1, }}>
              {subtopic.name.toUpperCase()}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ ...classes.paperTile, ...classes.labelHorizontal }} square>
            <Typography sx={classes.labelSize}>
              {number + 1}
            </Typography>
          </Paper>
        </div>
      )
    case 'top':
      return (
        <div style={classes.tile} >
          <Paper elevation={3} sx={{ ...classes.paperTile, ...classes.labelHorizontal }} square>
            <Typography sx={classes.labelSize}>
              {number + 1}
            </Typography>
          </Paper>
          <Paper
            elevation={3}
            sx={{ height: '80%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            style={{ background: colorTile(number) }}
            square>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '0.8vw', letterSpacing: 1, }}>
              {subtopic.name.toUpperCase()}
            </Typography>
          </Paper>
        </div>
      )
    case 'left':
      return (
        <Grid container sx={classes.tile}>
          <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
            <Paper
              elevation={3}
              sx={{ height: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              style={{ background: colorTile(number) }}
              square>
              <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '0.8vw', letterSpacing: 1, px: '2vw' }}>
                {subtopic.name.toUpperCase()}
              </Typography>
            </Paper>
          </Grid>
          <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
            <Paper elevation={3} sx={{ ...classes.tile, ...classes.paperTile }} square>
              <Typography sx={{ ...classes.labelSize, ...classes.verticalLabelLeft }}>
                {number + 1}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )
    case 'right':
      return (
        <Grid container sx={classes.tile}>
          <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
            <Paper elevation={3} sx={{ ...classes.tile, ...classes.paperTile }} square>
              <Typography sx={{ ...classes.labelSize, ...classes.verticalLabelRight }}>
                {number + 1}
              </Typography>
            </Paper>
          </Grid>
          <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
            <Paper
              elevation={3}
              sx={{ height: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              style={{ background: colorTile(number) }}
              square>
              <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '0.8vw', letterSpacing: 1, px: '2vw' }}>
                {subtopic.name.toUpperCase()}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )
    case 'corner':
      return (
        <Paper elevation={3} sx={{ ...classes.tile, ...classes.corner_tile, ...colorCorner(cornerType) }}>
          <img src={image} style={classes.image} alt="corner_tile.svg" />
          <Typography sx={classes.labelSize}>
            {cornerType}
          </Typography>
        </Paper >
      )
    default:
      return null
  }

  /**Función que renderiza la clase de acuerdo al tipo de casilla de las esquinas */
  function colorCorner(cornerType) {
    switch (cornerType) {
      case "INICIO":
        return classes.start_tile

      case "ALEATORIO":
        return classes.random_exc_tile

      case "RETO":
        return classes.challenge_tile

      case "RULETA":
        return classes.event_tile

      default:
        return null
    }
  }

  function colorTile(number) {
    if ((number > 0 && number < 5) || (number > 26 && number <= 29)) {
      return number % 2 === 0 ? '#9F4576' : '#CF71AF'
    } else if ((number > 4 && number < 9) || (number > 9 && number < 12)) {
      return number % 2 === 0 ? '#E09540' : '#FFBF00'
    } else if ((number > 11 && number < 15) || (number > 15 && number < 20)) {
      return number % 2 === 0 ? '#2A7BD1' : '#2A97D1'
    } else {
      return number % 2 === 0 ? '#1E003C' : '#2F005F'
    }
  }
}

Tile.propTypes = {
  number: PropTypes.number,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'corner']),
  cornerType: PropTypes.oneOf(['INICIO', 'ALEATORIO', 'RETO', 'RULETA']),
  type: PropTypes.oneOf(['excercise', 'corner']),
  image: PropTypes.string,
  subtopic: PropTypes.object
}

export default Tile
