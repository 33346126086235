import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  IconButton, LinearProgress, Typography, Box
} from "@mui/material"
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { InlineMath } from 'react-katex'

/**Componentes */
import Notification from '../common/Notification'

/**Iconos */
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

/**APIs */
import { getTSubtopicsApi, deleteTSubtopicApi } from "../../api/subtopics"

function SubtopicsTable() {
  let subtList = []
  const [subtopics, setSubtopics] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [reload, setReload] = useState(false)

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID', size: 100 },
    { accessorKey: 'name', header: 'Nombre', size: 212 },
    { accessorKey: 'topic', header: 'Tema', size: 110 },
    { accessorKey: 'area', header: 'Área', size: 110 },
    { accessorKey: 'code', header: 'Código', size: 110 },
    { accessorKey: 'displayLabel', header: 'Texto de casilla', size: 120 },
    {
      accessorKey: 'symbol',
      header: 'Símbolo',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.symbol}`} />
      ),
      size: 150
    },
    {
      accessorKey: 'active',
      header: 'Estado',
      Cell: ({ rValue, row }) => (
        row.original.active ?
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <CheckCircleIcon style={{ color: '#00B76F' }} />
            <span style={{ marginLeft: 5 }}>Activo</span>
          </div> :
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <CancelIcon style={{ color: '#FF2942' }} />
            <span style={{ marginLeft: 5 }}>Inactivo</span>
          </div>
      ),
      size: 150
    },
    {
      accessorKey: 'actions',
      header: 'Acciones',
      size: 130,
      Cell: ({ rValue, row }) => (
        <Fragment>
          <Link to={`/admin/subtopics/update/${row.original.id}`}>
            <IconButton>
              <CreateIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setOpen(true)
              setSelectedId(row.original.id)
            }}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </Fragment>
      )
    }
  ], []);

  useEffect(() => {
    getTSubtopicsApi().then(response => {
      response.subtopics.forEach(value => {
        subtList.push({ ...value, id: value._id })
      })

      setSubtopics(subtList)
      setIsLoading(false)
    })

    setReload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const data = useMemo(() => subtopics, [subtopics]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact'
    }
  });

  return (
    <Fragment>
      <Notification
        open={open}
        onClose={() => setOpen(false)}
        title="Eliminar subtema"
        onAccept={() => {
          deleteTSubtopicApi(selectedId).then()
          setOpen(false)
          setReload(true)
        }}>
        <Typography>¿Estás seguro de querer eliminar este elemento?</Typography>
      </Notification>
      <Box sx={{ height: 400, p: 0, m: 0, maxWidth: '82vw' }}>
        {isLoading ? <LinearProgress /> :
          subtopics.length > 0 ?
            <MaterialReactTable table={table} />
            : <Typography>No hay registros</Typography>
        }
      </Box>
    </Fragment>
  )
}

export default SubtopicsTable
