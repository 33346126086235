import React from 'react';
//import ReactDOM from 'react-dom';
import './styles/MathStyles.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <App />
);

/* ReactDOM.render(
  <App />,
  document.getElementById('root'),
  () => {
    if (true) {
      document.body.style.backgroundColor = '#EFE9F4'
    } else {
      document.body.style.backgroundColor = '#000E29'
    }
  }
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
