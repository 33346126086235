import React, { useState, useEffect } from 'react'
import {
  Typography, Grid, Button, LinearProgress, useTheme
} from '@mui/material'
import useStyles from './useStyles'

//Components
import JoinToGroup from '../../../components/groups/JoinToGroup';
import CreateGroup from '../../../components/groups/CreateGroup';
import GroupCard from '../../../components/groups/GroupCard/GroupCard';

//Iconos
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from '@mui/icons-material/Add';

//API
import { getCreatorGroups, getStudentGroupsApi } from '../../../api/groups';

//Hooks
import useAuth from '../../../hooks/useAuth';

export default function Groups() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();

  const [myGroups, setMyGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openJoinForm, setOpenJoinForm] = useState(false);
  const [openCreateForm, setOpenCreateForm] = useState(false);

  useEffect(() => {
    document.title = 'Mis grupos - Math Paradise'

    //Obtiene los grupos a los que pertenece
    if (user.role === 'student') {
      getStudentGroupsApi(user.id).then(response => {
        if (response.status === 1) {
          setMyGroups(response.groups);
          setIsLoading(false);
        } else {
          setMyGroups([]);
          setIsLoading(false);
        }
      })
    } else {
      //Trae grupos creados
      getCreatorGroups(user.id).then(response => {
        if (response.status === 1) {
          setMyGroups(response.groups);
          setIsLoading(false);
        } else {
          setMyGroups([]);
          setIsLoading(false);
        }
      })
    }
  }, [user.id, user.role]);

  if (isLoading) {
    return <LinearProgress variant='indeterminate' />
  }

  return (
    <div>
      <CreateGroup
        open={openCreateForm}
        onClose={() => setOpenCreateForm(false)} />
      <JoinToGroup
        open={openJoinForm}
        onClose={() => setOpenJoinForm(false)} />
      <Grid container spacing={2} sx={classes.header}>
        <Grid item xl={9} lg={9} md={6} sm={12} xs={12}>
          <Typography variant="h4" gutterBottom color='primary' fontWeight={'bold'}>
            Grupos
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
          {
            // eslint-disable-next-line no-mixed-operators
            user.role === 'tutor' || user.role === 'moderator' || user.role === 'admin' ? (
              <Button
                variant='contained'
                sx={classes.button}
                size='medium'
                color='primary'
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateForm(!openCreateForm)}>
                Crear un grupo
              </Button>
            ) : user.role === 'student' ? (
              <Button
                variant='contained'
                sx={classes.button}
                size='medium'
                color='primary'
                startIcon={<GroupAddIcon />}
                onClick={() => setOpenJoinForm(!openJoinForm)}>
                Unirse a un grupo
              </Button>
            ) : null
          }
        </Grid>
      </Grid>
      <div>
        <Typography gutterBottom color='textSecondary'>
          Lista de grupos donde puedes ver las estadísticas de juego de sus integrantes.
        </Typography>
        <Typography color='textPrimary' variant='h6' gutterBottom>Mis grupos</Typography>
        {
          myGroups.length === 0 &&
          (
            <Typography color='textPrimary' sx={classes.noGroupsLabel}>
              No hay grupos para mostrar.
            </Typography>
          )
        }
        <Grid container spacing={2}>
          {
            myGroups.map((value, index) => {
              if (value.creator) {
                return (
                  <Grid item xl={3} lg={3} md={6} sm={6} xs={12} key={index}>
                    <GroupCard
                      id={value._id}
                      name={value.name}
                      description={value.description}
                      cover_image={value.cover_image}
                      creator={value.creator}
                      color={value.color}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })
          }
        </Grid>
      </div>
    </div>
  )
}

