import React, { Fragment, useEffect, useState } from 'react'
import { Paper, Typography, Grid, Box, useTheme } from '@mui/material';
import useStyles from './useStyles'

//Iamges
import classicIconWhite from '../../../../../assets/images/icons/classic_icon_white.svg';
import arcadeIconWhite from '../../../../../assets/images/icons/arcade_icon_white.svg';
import rushIconWhite from '../../../../../assets/images/icons/rush_icon_white.svg';

//API
import { getClassicLeaderboardApi } from '../../../../../api/classicGame';
import { getArcadeLeaderboardApi } from '../../../../../api/arcadeGame';
import { getRushLeaderboardApi } from '../../../../../api/rushGame';

//Components
import LeaderboardPaper from '../../../../../components/lists/LeaderboardPaper/LeaderboardPaper';
import { es_DateName_noYear } from '../../../../../utils/DateFormat';

function GroupLeaderboard(props) {
  const { group } = props;
  const theme = useTheme();
  const classes = useStyles(group.color, theme);
  const [fromDay, setFromDay] = useState('');
  const [toDay, setToDay] = useState('')
  const [classicBoard, setClassicBoard] = useState([]);
  const [arcadeBoard, setArcadeBoard] = useState([]);
  const [rushBoard, setRushBoard] = useState([]);

  useEffect(() => {
    let membersNicknames = group.members?.map((member) => (
      member.nickname
    ));

    if (group.members?.length > 0) {
      const fetchLeaderboard = async () => {
        try {
          const [classicResponse, arcadeResponse, rushResponse] = await Promise.all([
            getClassicLeaderboardApi(membersNicknames),
            getArcadeLeaderboardApi(membersNicknames),
            getRushLeaderboardApi(membersNicknames)
          ])

          const formatHistory = (response, gamemode) => {
            if (response?.status === 1) {
              return response.leaderboard.map((game) => ({ ...game, gamemode }))
            } else {
              return []
            }
          }

          const getDateFilter = (response) => {
            if (response?.status === 1) {
              setFromDay(response.dates.from);
              setToDay(response.dates.to);
            }
          }

          getDateFilter(classicResponse);
          setClassicBoard(formatHistory(classicResponse, 'classic'));
          setArcadeBoard(formatHistory(arcadeResponse, 'arcade'));
          setRushBoard(formatHistory(rushResponse, 'rush'));
        } catch (error) {
          console.error('Error fetching game leaderboard:', error);
        }
      }

      fetchLeaderboard();
    }

    return () => {
      setClassicBoard([]);
      setArcadeBoard([]);
      setRushBoard([]);
    }
  }, [group]);

  return (
    <div style={classes.root}>
      <Paper sx={classes.paper}>
        <Typography variant='h5' sx={{ color: group.color }}>
          Clasificación grupal
        </Typography>
        <Typography gutterBottom>
          Tabla de clasificación semanal por modo de juego de todos los integrantes
        </Typography>
        <Typography color='textSecondary'>
          Del <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{es_DateName_noYear(fromDay)}</span> al <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{es_DateName_noYear(toDay)}</span>.
        </Typography>
        <Grid container spacing={1} sx={classes.grid}>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <Paper sx={classes.classicPaper}>
              <Box sx={classes.headerBox}>
                <img
                  src={classicIconWhite}
                  style={classes.classic_icon}
                  alt="classic_icon.svg" />
                <Typography variant='h6'>
                  Modo Clásico
                </Typography>
              </Box>
              {
                classicBoard.length === 0 ?
                  <Typography>
                    No hay puntuaciones...
                  </Typography> :
                  <Fragment>
                    {
                      classicBoard.map((value, index) => (
                        <LeaderboardPaper
                          key={index}
                          nickname={value.player.nickname}
                          points={value.player.points}
                          place={index} />
                      ))
                    }
                  </Fragment>
              }
            </Paper>
          </Grid>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <Paper sx={classes.arcadePaper}>
              <Box sx={classes.headerBox}>
                <img
                  src={arcadeIconWhite}
                  style={classes.classic_icon}
                  alt="classic_icon.svg" />
                <Typography variant='h6'>
                  Modo Arcade
                </Typography>
              </Box>
              {
                arcadeBoard.length === 0 ?
                  <Typography>
                    No hay puntuaciones...
                  </Typography> :
                  <Fragment>
                    {
                      arcadeBoard.map((value, index) => (
                        <LeaderboardPaper
                          key={index}
                          nickname={value.player.nickname}
                          points={value.player.points}
                          place={index} />
                      ))
                    }
                  </Fragment>
              }
            </Paper>
          </Grid>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <Paper sx={classes.rushPaper}>
              <Box sx={classes.headerBox}>
                <img
                  src={rushIconWhite}
                  style={classes.rush_icon}
                  alt="classic_icon.svg" />
                <Typography variant='h6'>
                  Modo Rush
                </Typography>
              </Box>
              {
                rushBoard.length === 0 ?
                  <Typography>
                    No hay puntuaciones...
                  </Typography> :
                  <Fragment>
                    {
                      rushBoard.map((value, index) => (
                        <LeaderboardPaper
                          key={index}
                          nickname={value.player.nickname}
                          points={value.player.points}
                          place={index} />
                      ))
                    }
                  </Fragment>
              }
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default GroupLeaderboard