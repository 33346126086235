import React from 'react'
import { Avatar, Badge, styled, useTheme } from "@mui/material"
import PropTypes from 'prop-types'
import { avatars, player_colors } from '../../../utils/SelectArrays';

function MathAvatar(props) {
  const theme = useTheme();

  //Props
  const { avatar = 'default.svg', size, sx, badgeColor = theme.palette.primary.main, badgeVisible = false, badgeContent = 0 } = props;

  const colors = () => {
    let allColors = {};
    player_colors.forEach(pc => {
      allColors[pc.name] = pc.val
    })
    return allColors;
  }

  const ColorPlayerBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: colors()[badgeColor],
      fontSize: '1.5vh',
      minWidth: '2.4vh',
      height: '2.4vh',
      color: 'white'
    },
  }));

  const mAvatar = (selectedAvatar) => {
    let avt = {};

    avatars.forEach(v => {
      avt[v.photo] = () => (
        <Avatar
          src={v.path}
          style={{ width: size, height: size, background: "#FFF" }}
          sx={sx}>
        </Avatar>
      )
    });

    const SelectedAvatarComponent = avt[selectedAvatar] ? avt[selectedAvatar]() : null;
    return SelectedAvatarComponent;
  }

  return (
    <ColorPlayerBadge
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      invisible={!badgeVisible}
      badgeContent={badgeContent}
      showZero
      variant='standard'>
      {mAvatar(avatar)}
    </ColorPlayerBadge>
  )
}

MathAvatar.propTypes = {
  size: PropTypes.string,
  avatar: PropTypes.string,
  sx: PropTypes.object,
  badgeColor: PropTypes.any,
  badgeVisible: PropTypes.bool,
  badgeContent: PropTypes.number
}

export default MathAvatar
