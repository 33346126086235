export const posPlayer1 = [
  { left: '3vw', top: '80vh' }, //0
  { left: '20vw', top: '83vh' }, //1
  { left: '28vw', top: '83vh' }, //2
  { left: '36vw', top: '83vh' }, //3
  { left: '44vw', top: '83vh' }, //4
  { left: '52.5vw', top: '83vh' }, //5
  { left: '61vw', top: '83vh' }, //6
  { left: '69vw', top: '83vh' }, //7
  { left: '77vw', top: '83vh' }, //8
  { left: '85vw', top: '80vh' }, //9
  { left: '87vw', top: '70vh' }, //10
  { left: '87vw', top: '58vh' }, //11
  { left: '87vw', top: '45.5vh' }, //12
  { left: '87vw', top: '34.5vh' }, //13
  { left: '87vw', top: '22.5vh' }, //14
  { left: '85vw', top: '4vh' }, //15
  { left: '77vw', top: '2vh' }, //16
  { left: '69vw', top: '2vh' }, //17
  { left: '61vw', top: '2vh' }, //18
  { left: '52.5vw', top: '2vh' }, //19
  { left: '44vw', top: '2vh' }, //20
  { left: '36vw', top: '2vh' }, //21
  { left: '28vw', top: '2vh' }, //22
  { left: '20vw', top: '2vh' }, //23
  { left: '3vw', top: '4vh' }, //24
  { left: '3vw', top: '22.5vh' }, //25
  { left: '3vw', top: '34.5vh' }, //26
  { left: '3vw', top: '45.5vh' }, //27
  { left: '3vw', top: '58vh' }, //28
  { left: '3vw', top: '70vh' }, //29
]

export const posPlayer2 = [
  { left: '12vw', top: '80vh' }, //0
  { left: '20vw', top: '86vh' }, //1
  { left: '28vw', top: '86vh' }, //2
  { left: '36vw', top: '86vh' }, //3
  { left: '44vw', top: '86vh' }, //4
  { left: '52.5vw', top: '86vh' }, //5
  { left: '61vw', top: '86vh' }, //6
  { left: '69vw', top: '86vh' }, //7
  { left: '77vw', top: '86vh' }, //8
  { left: '95vw', top: '80vh' }, //9
  { left: '89vw', top: '70vh' }, //10
  { left: '89vw', top: '58vh' }, //11
  { left: '89vw', top: '45.5vh' }, //12
  { left: '89vw', top: '34.5vh' }, //13
  { left: '89vw', top: '22.5vh' }, //14
  { left: '95vw', top: '4vh' }, //15
  { left: '77vw', top: '4vh' }, //16
  { left: '69vw', top: '4vh' }, //17
  { left: '61vw', top: '4vh' }, //18
  { left: '52.5vw', top: '4vh' }, //19
  { left: '44vw', top: '4vh' }, //20
  { left: '36vw', top: '4vh' }, //21
  { left: '28vw', top: '4vh' }, //22
  { left: '20vw', top: '4vh' }, //23
  { left: '12vw', top: '4vh' }, //24
  { left: '5vw', top: '22.5vh' }, //25
  { left: '5vw', top: '34.5vh' }, //26
  { left: '5vw', top: '45.5vh' }, //27
  { left: '5vw', top: '58vh' }, //28
  { left: '5vw', top: '70vh' }, //29
]

export const posPlayer3 = [
  { left: '3vw', top: '88vh' }, //0
  { left: '20vw', top: '88vh' }, //1
  { left: '28vw', top: '88vh' }, //2
  { left: '36vw', top: '88vh' }, //3
  { left: '44vw', top: '88vh' }, //4
  { left: '52.5vw', top: '88vh' }, //5
  { left: '61vw', top: '88vh' }, //6
  { left: '69vw', top: '88vh' }, //7
  { left: '77vw', top: '88vh' }, //8
  { left: '95vw', top: '88vh' }, //9
  { left: '91vw', top: '70vh' }, //10
  { left: '91vw', top: '58vh' }, //11
  { left: '91vw', top: '45.5vh' }, //12
  { left: '91vw', top: '34.5vh' }, //13
  { left: '91vw', top: '22.5vh' }, //14
  { left: '95vw', top: '12vh' }, //15
  { left: '77vw', top: '6vh' }, //16
  { left: '69vw', top: '6vh' }, //17
  { left: '61vw', top: '6vh' }, //18
  { left: '52.5vw', top: '6vh' }, //19
  { left: '44vw', top: '6vh' }, //20
  { left: '36vw', top: '6vh' }, //21
  { left: '28vw', top: '6vh' }, //22
  { left: '20vw', top: '6vh' }, //23
  { left: '12vw', top: '12vh' }, //24
  { left: '7vw', top: '22.5vh' }, //25
  { left: '7vw', top: '34.5vh' }, //26
  { left: '7vw', top: '45.5vh' }, //27
  { left: '7vw', top: '58vh' }, //28
  { left: '7vw', top: '70vh' }, //29
]

export const posPlayer4 = [
  { left: '12vw', top: '88vh' }, //0
  { left: '20vw', top: '90vh' }, //1
  { left: '28vw', top: '90vh' }, //2
  { left: '36vw', top: '90vh' }, //3
  { left: '44vw', top: '90vh' }, //4
  { left: '52.5vw', top: '90vh' }, //5
  { left: '61vw', top: '90vh' }, //6
  { left: '69vw', top: '90vh' }, //7
  { left: '77vw', top: '90vh' }, //8
  { left: '85vw', top: '88vh' }, //9
  { left: '93vw', top: '70vh' }, //10
  { left: '93vw', top: '58vh' }, //11
  { left: '93vw', top: '45.5vh' }, //12
  { left: '93vw', top: '34.5vh' }, //13
  { left: '93vw', top: '22.5vh' }, //14
  { left: '85vw', top: '12vh' }, //15
  { left: '77vw', top: '8vh' }, //16
  { left: '69vw', top: '8vh' }, //17
  { left: '61vw', top: '8vh' }, //18
  { left: '52.5vw', top: '8vh' }, //19
  { left: '44vw', top: '8vh' }, //20
  { left: '36vw', top: '8vh' }, //21
  { left: '28vw', top: '8vh' }, //22
  { left: '20vw', top: '8vh' }, //23
  { left: '3vw', top: '12vh' }, //24
  { left: '9vw', top: '22.5vh' }, //25
  { left: '9vw', top: '34.5vh' }, //26
  { left: '9vw', top: '45.5vh' }, //27
  { left: '9vw', top: '58vh' }, //28
  { left: '9vw', top: '70vh' }, //29
]