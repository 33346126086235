import React from "react";
import { Link } from 'react-router-dom'
import { Divider, Drawer, useTheme, styled } from "@mui/material";
import MenuList from "../MenuList"
import Logo from "../../../Logo"
import useStyles from "./useStyles";
import logoSrc from '../../../../assets/images/logos/MathParadiseLogo _vertical.svg'
import logoWhiteSrc from '../../../../assets/images/logos/MathParadiseLogo _vertical_textwhite.svg'

const MathDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '300px'
  }
}))

function NavMenu(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MathDrawer
      sx={classes.drawer}
      style={{ width: '300px' }}
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      anchor="left"
    >
      <div style={classes.toolbar}>
        <div style={classes.logo}>
          <Link to="/home">
            <Logo src={theme.palette.mode === 'light' ? logoSrc : logoWhiteSrc} style={classes.logoSize} />
          </Link>
        </div>
      </div>
      <Divider variant='middle' sx={{ mt: theme.spacing(1) }} />
      <MenuList close={props.onClose} url={props.url} />
    </MathDrawer>
  );
}

export default NavMenu;