import React, { useState } from 'react'
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio,
  Grid2 as Grid, useTheme,
  RadioGroup
} from '@mui/material'
import { avatars } from '../../../utils/SelectArrays';

function AvatarPopUp(props) {
  const { open, handleClose, onSelectAvatar } = props;
  const theme = useTheme();
  const [selectedAvatar, setSelectedAvatar] = useState('default.svg');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
      <DialogTitle>Seleccionar avatar</DialogTitle>
      <DialogContent>
        <RadioGroup value={selectedAvatar} onChange={(e) => setSelectedAvatar(e.target.value)}>
          <Grid container spacing={2}>
            {
              avatars.sort().map((avatar, index) => (
                <Grid key={index} size={{ xl: 2, lg: 3, md: 4, sm: 4, xs: 4 }}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ mx: theme.spacing(2) }} textAlign={'center'}>
                    <img src={avatar.path} alt={avatar.photo} style={{ width: '64px', borderRadius: '50%' }} />
                    <FormControlLabel value={avatar.photo} control={<Radio />} label={avatar.spanish_name} labelPlacement='bottom' />
                  </Box>
                </Grid>
              ))
            }
          </Grid>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' sx={{ mr: '8px' }} onClick={() => onSelectAvatar(selectedAvatar)}>
          Seleccionar
        </Button>
        <Button color='error' variant='contained' onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AvatarPopUp