import React, { useState, useEffect, Fragment, useMemo } from 'react'
import {
  Typography, Paper, Grid2 as Grid, Box, Button, LinearProgress,
  useTheme
} from '@mui/material'
import useAuth from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { MATH_GRADIENTS } from '../../../styles/MathGradients';
import useStyles from './useStyles';

/**Iconos */
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School'; //student
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**Componentes */
import Error404 from '../../Error404'
import GameStats from '../../../components/user/GameStats'

/**APIs */
import { getUserByNicknameApi } from "../../../api/user"
import { es_DateName } from '../../../utils/DateFormat';
import DefaultAvatar from '../../../components/user/DefaultAvatar';

function Profile(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useAuth();
  const { match: { params: { nickname } } } = props

  const [userData, setUserData] = useState(null)
  const [isFound, setIsFound] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const memoizedNickname = useMemo(() => nickname, [nickname]);

  useEffect(() => {
    document.title = memoizedNickname + ' - Math Paradise'

    getUserByNicknameApi(memoizedNickname).then(response => {
      if (!response.message) {
        const { user } = response;
        setUserData(user);
        setIsLoading(false);
      } else if (response.message === "No se ha encontrado a ningun usuario.") {
        setIsFound(false);
        setIsLoading(false);
      }
    });
  }, [memoizedNickname])

  if (isLoading) {
    return <LinearProgress variant='indeterminate' />
  }

  if (!isFound && !userData) {
    return (
      <Error404 />
    )
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid size={{ xl: 8, lg: 8, md: 12, sm: 12, xs: 12, }}>
          <Paper sx={{ p: theme.spacing(4), mb: theme.spacing(2) }}>
            <Grid container spacing={4}>
              <Grid size={{ xl: 3, lg: 4, md: 4, sm: 4, xs: 12 }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}>
                  <DefaultAvatar nickname={userData.nickname} size="75%" sx={{ mb: theme.spacing(2) }} />
                  {
                    user.nickname === userData.nickname ?
                      <Box>
                        <Link to='/home/settings'>
                          <Button
                            sx={{
                              background: MATH_GRADIENTS().default,
                              color: 'white',
                              borderRadius: 16
                            }}
                            variant="contained"
                            startIcon={<EditIcon />}>
                            Editar Perfil
                          </Button>
                        </Link>
                      </Box> : null
                  }
                </Box>
              </Grid>
              <Grid size={{ xl: 9, lg: 8, md: 8, sm: 8, xs: 12 }}>
                <Box sx={{ mb: theme.spacing(2) }}>
                  <Typography variant="h4">
                    {userData.name + " " + userData.lastname}
                  </Typography>
                  <Typography color='textSecondary' variant="h6">
                    @{userData.nickname}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(0) }}>
                  <SchoolIcon sx={{ mr: theme.spacing(1) }} color='primary' />
                  <Typography color='textPrimary'>
                    {userData.institution.length === 0 ? 'No pertenece a ninguna institución' : userData.institution}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(3) }}>
                  <CalendarMonthIcon sx={{ mr: theme.spacing(1) }} color='primary' />
                  <Typography color='textPrimary'>
                    Se unió el {es_DateName(userData.sign_up_date)}
                  </Typography>
                </Box>
                <Typography color='textSecondary'>
                  {userData.description || '¡Hola, soy nuevx en Math Paradise!'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={2}>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <Paper sx={{ p: theme.spacing(3) }}>
                <Typography variant='h6'>Insignias</Typography>
                <Box sx={{ my: theme.spacing(2) }}>
                  {
                    !userData.badges || userData.badges.length === 0 ?
                      <Typography color='textSecondary'>No hay insignias qué mostrar.</Typography> : null
                  }
                </Box>
              </Paper>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <Paper sx={{ p: theme.spacing(3) }}>
                <Typography variant='h6'>Logros</Typography>
                <Box sx={{ my: theme.spacing(2) }}>
                  {
                    !userData.achievements || userData.achievements.length === 0 ?
                      <Typography color='textSecondary'>No hay logros qué mostrar.</Typography> : null
                  }
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xl: 4, lg: 4, md: 12, sm: 12, xs: 12, }}>
          <Box sx={classes.paperStats}>
            <Typography variant="h5" gutterBottom>Puntuaciones</Typography>
            <Grid container spacing={2}>
              <Grid size={{ xl: 12, lg: 12, md: 6, sm: 12, xs: 12 }}>
                <GameStats gamemode='classic' summary stats={userData.classic} />
              </Grid>
              <Grid size={{ xl: 12, lg: 12, md: 6, sm: 12, xs: 12 }}>
                <GameStats gamemode='arcade' summary stats={userData.arcade} />
              </Grid>
              <Grid size={{ xl: 12, lg: 12, md: 6, sm: 12, xs: 12 }}>
                <GameStats gamemode='rush' summary stats={userData.rush} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Profile
