import React, { useState, useEffect, Fragment } from 'react'
import { MATH_GRADIENTS } from '../../../../styles/MathGradients'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Box, Container, Typography, Grid2 as Grid, useTheme,
  Paper, Button, TextField, styled, FormGroup, FormControlLabel, Switch
} from '@mui/material';
import { Howl } from 'howler'

import classicIcon from '../../../../assets/images/icons/classic_icon_white.svg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import { player_colors } from '../../../../utils/SelectArrays';
import MathAvatar from '../../../../components/user/MathAvatar/MathAvatar';
import AvatarPopUp from '../../../../components/user/AvatarPopUp/AvatarPopUp';
import { minLenghtValidation } from '../../../../utils/FormValidation';
import DefaultSnackbar from '../../../../components/common/DefaultSnackbar';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
});

function LocalClassicLobby(props) {
  const location = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const [game, setGame] = useState(location.state?.game);
  const [player1, setPlayer1] = useState({
    nickname: '',
    number: 1,
    avatar: 'default.svg',
    points: 0,
    boardPosition: 0,
    answered: 0,
    correct: 0,
    errors: 0
  });
  const [player2, setPlayer2] = useState({
    nickname: '',
    number: 2,
    avatar: 'default.svg',
    points: 0,
    boardPosition: 0,
    answered: 0,
    correct: 0,
    errors: 0
  });
  const [player3, setPlayer3] = useState({
    nickname: '',
    number: 3,
    avatar: 'default.svg',
    points: 0,
    boardPosition: 0,
    answered: 0,
    correct: 0,
    errors: 0
  });
  const [player4, setPlayer4] = useState({
    nickname: '',
    number: 4,
    avatar: 'default.svg',
    points: 0,
    boardPosition: 0,
    answered: 0,
    correct: 0,
    errors: 0
  });
  const [activePlayer3, setActivePlayer3] = useState(false);
  const [activePlayer4, setActivePlayer4] = useState(false);
  const [openSelectAvatar, setOpenSelectAvatar] = useState(false);
  const [playerToAsign, setPlayerToAsign] = useState('player1');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  //Musica y sonidos
  useEffect(() => {
    const music = new Howl({
      src: ['/music/lobby_theme.ogg'],
      loop: true,
      volume: 0.25
    });

    music.play();

    return () => {
      // Limpiar la instancia de Howl cuando el componente se desmonte
      music.unload();
    };
  }, [])

  const colors = () => {
    let allColors = {};
    player_colors.forEach(pc => {
      allColors[pc.name] = pc.val
    })
    return allColors;
  }

  const handlePlayer1Nickname = (nick) => {
    setPlayer1({ ...player1, nickname: nick })
  }

  const handlePlayer2Nickname = (nick) => {
    setPlayer2({ ...player2, nickname: nick })
  }

  const handlePlayer3Nickname = (nick) => {
    setPlayer3({ ...player3, nickname: nick })
  }

  const handlePlayer4Nickname = (nick) => {
    setPlayer4({ ...player4, nickname: nick })
  }

  //Efecto que desactiva el jugador 4 si se desactiva el jugador 3
  useEffect(() => {
    if (!activePlayer3) {
      setActivePlayer4(false);
    }
  }, [activePlayer3])

  /**
   * Función para asignar el avatar de acuerdo al MathAvatar que se le dio click
   * @param {*} avatar 
   */
  const handleSelectAvatar = (avatar) => {
    setOpenSelectAvatar(false);
    if (playerToAsign === 'player1') {
      setPlayer1({ ...player1, avatar: avatar });
    } else if (playerToAsign === 'player2') {
      setPlayer2({ ...player2, avatar: avatar });
    } else if (playerToAsign === 'player3') {
      setPlayer3({ ...player3, avatar: avatar });
    } else if (playerToAsign === 'player4') {
      setPlayer4({ ...player4, avatar: avatar });
    }
  }

  /**
   * Función que inicia el juego, después de hacer validaciones
   */
  const startGame = () => {
    //Validamos nicknames para ver si se ingresaron
    if (!minLenghtValidation(player1.nickname.trim(), 1)) {
      setSnackMessage("Ingresa un nombre al Jugador 1");
      setOpenSnackbar(true);
      return;
    }

    if (!minLenghtValidation(player2.nickname.trim(), 1)) {
      setSnackMessage("Ingresa un nombre al Jugador 2");
      setOpenSnackbar(true);
      return;
    }

    if (activePlayer3 && !minLenghtValidation(player3.nickname.trim(), 1)) {
      setSnackMessage("Ingresa un nombre al Jugador 3");
      setOpenSnackbar(true);
      return;
    }

    if (activePlayer4 && !minLenghtValidation(player4.nickname.trim(), 1)) {
      setSnackMessage("Ingresa un nombre al Jugador 3");
      setOpenSnackbar(true);
      return;
    }

    //Validamos si los nombres no se repiten
    if (player1.nickname === player2.nickname) {
      setSnackMessage("Los nombres de jugador no deben repetirse");
      setOpenSnackbar(true);
      return;
    }
    if (activePlayer3 && (player1.nickname === player3.nickname || player2.nickname === player3.nickname)) {
      setSnackMessage("Los nombres de jugador no deben repetirse");
      setOpenSnackbar(true);
      return;
    }
    if (activePlayer4 && (player1.nickname === player4.nickname || player2.nickname === player4.nickname || player3.nickname === player4.nickname)) {
      setSnackMessage("Los nombres de jugador no deben repetirse");
      setOpenSnackbar(true);
      return;
    }

    //Guardamos el arreglo de jugadores
    let players = [player1, player2];
    if (activePlayer3) players.push(player3);
    if (activePlayer4) players.push(player4);

    //Asignamos turno aleatorio
    let turn = players[Math.floor(Math.random() * players.length)].nickname;

    //Guardamos a los jugadores en la configuración del juego
    setGame(prevGame => ({
      ...prevGame,
      players: players,
      turn: turn,
      message: `Partida iniciada, es el turno de @${turn}`,
      status: 'in_game'
    }));
  }

  //Efecto que detecta cuando se seleccionan los jugadores
  useEffect(() => {
    if (game && game.players) {
      history.push(`/classic/local`, { game: game });
    }
  }, [game, history]);

  return (
    <Fragment>
      <div style={{ background: MATH_GRADIENTS(90).classic, width: '100vw', minHeight: '100vh', color: 'white' }}>
        <DefaultSnackbar open={openSnackbar} handleClose={() => setOpenSnackbar(false)} message={snackMessage} />
        <AvatarPopUp open={openSelectAvatar} handleClose={() => setOpenSelectAvatar(false)} onSelectAvatar={handleSelectAvatar} />
        <Container sx={{ py: theme.spacing(3) }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ mr: theme.spacing(2) }}>
              <img src={classicIcon} alt='' style={{ width: 64 }} />
            </Box>
            <Box>
              <Typography variant='h4'>Modo Clásico - Partida Local</Typography>
              <Typography variant='h6'>Creación de jugadores</Typography>
            </Box>
          </Box>
          <Grid container spacing={2} sx={{ mt: theme.spacing(4) }}>
            <Grid size={{ xl: 3, lg: 3, md: 6, sm: 12, xs: 12 }}>
              <Paper sx={{ p: theme.spacing(2), backgroundColor: colors()['player_1'], color: 'white' }}>
                <Typography variant='h6' gutterBottom>Jugador 1</Typography>
                <div onClick={() => {
                  setOpenSelectAvatar(true);
                  setPlayerToAsign('player1');
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: theme.spacing(2),
                  }}>
                    <MathAvatar avatar={player1.avatar} size={'64px'} sx={{ cursor: 'pointer' }} />
                  </Box>
                  <Typography textAlign={'center'} mb={theme.spacing(2)}>Selecciona un avatar</Typography>
                </div>
                <Box>
                  <Paper sx={{ backgroundColor: 'white' }}>
                    <CssTextField
                      variant='outlined'
                      placeholder='Nombre del jugador'
                      fullWidth
                      onChange={(e) => handlePlayer1Nickname(e.target.value)} />
                  </Paper>
                </Box>
              </Paper>
            </Grid>
            <Grid size={{ xl: 3, lg: 3, md: 6, sm: 12, xs: 12 }}>
              <Paper sx={{ p: theme.spacing(2), backgroundColor: colors()['player_2'], color: 'white' }}>
                <Typography variant='h6' gutterBottom>Jugador 2</Typography>
                <div onClick={() => {
                  setOpenSelectAvatar(true);
                  setPlayerToAsign('player2');
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: theme.spacing(2),
                  }}>
                    <MathAvatar avatar={player2.avatar} size={'64px'} sx={{ cursor: 'pointer' }} />
                  </Box>
                  <Typography textAlign={'center'} mb={theme.spacing(2)}>Selecciona un avatar</Typography>
                </div>
                <Box>
                  <Paper sx={{ backgroundColor: 'white' }}>
                    <CssTextField
                      variant='outlined'
                      placeholder='Nombre del jugador'
                      fullWidth
                      onChange={(e) => handlePlayer2Nickname(e.target.value)} />
                  </Paper>
                </Box>
              </Paper>
            </Grid>
            <Grid size={{ xl: 3, lg: 3, md: 6, sm: 12, xs: 12 }}>
              <Paper sx={{ p: theme.spacing(2), backgroundColor: colors()['player_3'], color: 'white', filter: activePlayer3 ? 'none' : 'brightness(12%)' }}>
                <Typography variant='h6' gutterBottom>Jugador 3</Typography>
                <div onClick={() => {
                  setOpenSelectAvatar(true);
                  setPlayerToAsign('player3');
                }} style={{ pointerEvents: activePlayer3 ? 'all' : 'none' }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: theme.spacing(2),
                  }}>
                    <MathAvatar avatar={player3.avatar} size={'64px'} sx={{ cursor: 'pointer' }} />
                  </Box>
                  <Typography textAlign={'center'} mb={theme.spacing(2)}>Selecciona un avatar</Typography>
                </div>
                <Box>
                  <Paper sx={{ backgroundColor: 'white' }}>
                    <CssTextField
                      variant='outlined'
                      placeholder='Nombre del jugador'
                      fullWidth
                      disabled={activePlayer3 ? false : true}
                      onChange={(e) => handlePlayer3Nickname(e.target.value)} />
                  </Paper>
                </Box>
              </Paper>
              <FormGroup sx={{ mt: theme.spacing(2) }}>
                <FormControlLabel control={<Switch color='default' value={activePlayer3} onChange={(e) => setActivePlayer3(e.target.checked)} />}
                  label='Activar Jugador 3' />
              </FormGroup>
            </Grid>
            <Grid size={{ xl: 3, lg: 3, md: 6, sm: 12, xs: 12 }}>
              <Paper sx={{ p: theme.spacing(2), backgroundColor: colors()['player_4'], color: 'white', filter: activePlayer4 ? 'none' : 'brightness(12%)' }}>
                <Typography variant='h6' gutterBottom>Jugador 4</Typography>
                <div onClick={() => {
                  setOpenSelectAvatar(true);
                  setPlayerToAsign('player4');
                }} style={{ pointerEvents: activePlayer4 ? 'all' : 'none' }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: theme.spacing(2),
                  }}>
                    <MathAvatar avatar={player4.avatar} size={'64px'} sx={{ cursor: 'pointer' }} />
                  </Box>
                  <Typography textAlign={'center'} mb={theme.spacing(2)}>Selecciona un avatar</Typography>
                </div>
                <Box>
                  <Paper sx={{ backgroundColor: 'white' }}>
                    <CssTextField
                      variant='outlined'
                      placeholder='Nombre del jugador'
                      fullWidth
                      disabled={activePlayer4 ? false : true}
                      onChange={(e) => handlePlayer4Nickname(e.target.value)} />
                  </Paper>
                </Box>
              </Paper>
              <FormGroup sx={{ mt: theme.spacing(2) }}>
                <FormControlLabel control={
                  <Switch
                    color='default'
                    checked={activePlayer4}
                    disabled={activePlayer3 ? false : true}
                    onChange={(e) => setActivePlayer4(e.target.checked)} />
                }
                  label='Activar Jugador 4' />
              </FormGroup>
            </Grid>
          </Grid>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: theme.spacing(4)
          }}>
            <Button sx={{
              background: MATH_GRADIENTS().default,
              color: 'white',
              px: theme.spacing(6),
              mx: theme.spacing(1)
            }}
              startIcon={<PlayArrowIcon />}
              onClick={startGame}>
              Iniciar partida
            </Button>
            <Button sx={{
              background: MATH_GRADIENTS().error,
              color: 'white',
              px: theme.spacing(6),
              mx: theme.spacing(1)
            }}
              startIcon={<CloseIcon />}
              onClick={() => { history.push('/home/play/classic') }}>
              Cancelar
            </Button>
          </Box>
        </Container>
      </div>
    </Fragment>
  )
}

export default LocalClassicLobby