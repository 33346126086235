import { basePath, apiVersion } from '../config/api'
import { getAccessTokenApi } from './auth'

export function createSubtopicApi(data) {
  const url = `${basePath}/${apiVersion}/create-subtopic`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function getTSubtopicsApi(query = '') {
  const url = `${basePath}/${apiVersion}/get-subtopics?${query}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function deleteTSubtopicApi(id) {
  const url = `${basePath}/${apiVersion}/delete-subtopic/${id}`
  const params = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}


export function getSubtopicByIdApi(id) {
  const url = `${basePath}/${apiVersion}/get-subtopic?id=${id}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function getSubtopicsByCodesApi(codes) {
  const url = `${basePath}/${apiVersion}/get-subtopics-by-codes`
  const params = {
    method: 'POST',
    body: JSON.stringify(codes),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function updateSubtopicApi(data, id) {
  const url = `${basePath}/${apiVersion}/update-subtopic/${id}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

/**
 * 
 * @param {*} subtopics Arreglo de strings con los subtemas elegidos
 * @param {*} size Cantidad de casillas
 * @returns 
 */
export function generateSubtopicBoardApi(subtopics, size = 26) {
  const url = `${basePath}/${apiVersion}/generate-subtopic-board?size=${size}`;
  const params = {
    method: 'POST',
    body: JSON.stringify(subtopics),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}
