import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import {
  Box, Grid, LinearProgress, Paper, Typography,
  List, ListItem, ListItemIcon, ListItemText,
  useTheme
} from '@mui/material'
import useStyles from './useStyles';
import { basePath, apiVersion } from '../../../../config/api'

//Components
import GroupLeaderboard from './leaderboard/GroupLeaderboard';
import GroupMembers from './members/GroupMembers';
import GroupSettings from './settings/GroupSettings';

//API
import { getGroupByIdApi } from '../../../../api/groups';

//Hooks
import useAuth from '../../../../hooks/useAuth';

//Icons
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

function LayoutGroup(props) {
  const { match: { params: { id } } } = props;

  const [group, setGroup] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [section, setSection] = useState('leaderboard');
  const [screen, setScreen] = useState(null);
  const theme = useTheme();
  const classes = useStyles(group.color, theme);
  const { user } = useAuth();

  useEffect(() => {
    getGroupByIdApi(id).then(response => {
      if (response.status === 1) {
        setGroup({
          ...response.group,
          idCreator: response.group.creator._id
        });
        setIsLoading(false);
      } else {
        setGroup(false);
      }
    })

    //Para recarga
    setReload(false);

    return () => {
      setGroup({});
    }
  }, [id, reload])

  useEffect(() => {
    switch (section) {
      case 'leaderboard':
        setScreen(<GroupLeaderboard group={group} />);
        break;

      case 'members':
        setScreen(<GroupMembers group={group} startReload={() => startReload()} />);
        break;

      case 'settings':
        setScreen(<GroupSettings group={group} startReload={() => startReload()} />);
        break;

      default:
        setScreen(<GroupLeaderboard group={group} />);
        break;
    }
  }, [section, group])

  //Función para recargar los datos del grupo
  const startReload = () => {
    setReload(true);
  }

  if (isLoading) {
    return <LinearProgress variant='indeterminate' />
  }

  if (!group) {
    return <Redirect to={'/groups'} />
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={4} sm={5} xs={12}>
          <Paper sx={classes.paper}>
            <img
              src={`${basePath}/${apiVersion}/get-cover-image/${group.cover_image}`}
              alt={group.cover_image}
              style={{ width: '100%' }} />
            <Box sx={classes.infoBox}>
              <Typography variant='h4'>
                {group.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p">
                {group.description}
              </Typography>
              <List>
                <ListItem
                  sx={{ cursor: 'pointer' }}
                  button
                  selected={section === 'leaderboard' ? true : false}
                  onClick={() => setSection('leaderboard')}>
                  <ListItemIcon>
                    <EmojiEventsIcon sx={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Clasificación' />
                </ListItem>
                <ListItem
                  sx={{ cursor: 'pointer' }}
                  button
                  selected={section === 'members' ? true : false}
                  onClick={() => setSection('members')}>
                  <ListItemIcon>
                    <PeopleIcon sx={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Miembros' />
                </ListItem>
                {
                  user.id === group.idCreator && (
                    <ListItem
                      sx={{ cursor: 'pointer' }}
                      button
                      selected={section === 'settings' ? true : false}
                      onClick={() => setSection('settings')}>
                      <ListItemIcon>
                        <SettingsIcon sx={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Configuración' />
                    </ListItem>
                  )
                }
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xl={9} lg={9} md={8} sm={7} xs={12}>
          <Box>
            {screen}
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default LayoutGroup