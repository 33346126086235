import { Box, Paper, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import DefaultAvatar from '../../user/DefaultAvatar/DefaultAvatar';

function PlayerCard(props) {
  const { color = '#000', nickname = '[Disponible]' } = props;
  const theme = useTheme();

  return (
    <Paper sx={{
      p: theme.spacing(2),
      backgroundColor: color,
      color: 'white'
    }}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <DefaultAvatar nickname={nickname} sx={{ mr: theme.spacing(2) }} />
        <Typography variant='h6'>{nickname}</Typography>
      </Box>
    </Paper>
  )
}

PlayerCard.propTypes = {
  color: PropTypes.string
}

export default PlayerCard