import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LinearProgress, IconButton, Typography, Box
} from "@mui/material"
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
//import { DataGrid, GridToolbar } from 'material-react-table'
import { InlineMath } from 'react-katex'
//import 'katex/dist/katex.min.css';

/**Componentes */
import Notification from '../common/Notification'

/**Iconos */
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

/**APIs */
import { getExcercisesApi, deleteExcerciseApi } from "../../api/excercises"
import { es_DateName } from '../../utils/DateFormat'

function ExcercisesTable() {
  let excList = []
  const [excers, setExcers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [reload, setReload] = useState(false)

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID', size: 150 },
    {
      accessorKey: 'label',
      header: 'Ejercicio',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.label}`} />
      ),
      size: 300
    },
    {
      accessorKey: 'option_a',
      header: 'Opción A',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.option_a}`} />
      ),
      size: 150
    },
    {
      accessorKey: 'option_b',
      header: 'Opción B',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.option_b}`} />
      ),
      size: 150
    },
    {
      accessorKey: 'option_c',
      header: 'Opción C',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.option_c}`} />
      ),
      size: 150
    },
    {
      accessorKey: 'option_d',
      header: 'Opción D',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.option_d}`} />
      ),
      size: 150
    },
    {
      accessorKey: 'answer',
      header: 'Respuesta',
      Cell: ({ rValue, row }) => (
        <InlineMath math={`${row.original.answer}`} />
      ),
      size: 150
    },
    { accessorKey: 'subtopic', header: 'Subtema', size: 100 },
    { accessorKey: 'topic', header: 'Tema', size: 100 },
    { accessorKey: 'area', header: 'Área', size: 100 },
    { accessorKey: 'difficulty', header: 'Dificultad', size: 100, hide: true },
    {
      accessorKey: 'active',
      header: 'Estado',
      Cell: ({ rValue, row }) => (
        row.original.active ?
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <CheckCircleIcon style={{ color: '#00B76F' }} />
            <span style={{ marginLeft: 5 }}>Activo</span>
          </div> :
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <CancelIcon style={{ color: '#FF2942' }} />
            <span style={{ marginLeft: 5 }}>Inactivo</span>
          </div>
      ),
      size: 100
    },
    {
      accessorKey: 'userNickname',
      header: 'Ult. Usuario Mod.',
      size: 100,
      Cell: ({ rValue, row }) => (
        <Link to={`/home/profile/@${row.original.userNickname}`}>
          @{row.original.userNickname}
        </Link>
      )
    },
    {
      accessorKey: 'create_date',
      header: 'Fecha de creación',
      size: 100,
      type: 'date',
      Cell: ({ rValue, row }) => (
        <Typography variant='body2'>
          {es_DateName(row.original.create_date)}
        </Typography>
      )
    },
    {
      accessorKey: 'actions',
      header: 'Acciones',
      size: 100,
      Cell: ({ rValue, row }) => (
        <div>
          <Link to={`/admin/excercises/update/${row.original.id}`}>
            <IconButton>
              <CreateIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setOpen(true)
              setSelectedId(row.original.id)
            }}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </div>
      )
    }
  ], []);

  useEffect(() => {
    getExcercisesApi().then(response => {
      response.excercises.forEach(value => {
        excList.push({
          ...value,
          id: value._id,
          userNickname: value.user_modification ? value.user_modification.nickname : null
        })
      })

      setExcers(excList)
      setIsLoading(false)
    })

    setReload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const data = useMemo(() => excers, [excers]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact'
    }
  });

  return (
    <Fragment>
      <Notification
        open={open}
        onClose={() => setOpen(false)}
        title="Eliminar ejercicio"
        onAccept={() => {
          deleteExcerciseApi(selectedId).then()
          setOpen(false)
          setReload(true)
        }}>
        <Typography>¿Estás seguro de querer eliminar este elemento?</Typography>
      </Notification>
      <Box sx={{ height: 400, p: 0, m: 0, maxWidth: '82vw' }}>
        {isLoading ? <LinearProgress /> :
          excers.length > 0 ?
            <MaterialReactTable table={table} />
            : <Typography>No hay registros</Typography>
        }
      </Box>
    </Fragment>
  )
}

export default ExcercisesTable
