import React, { Fragment } from 'react'
import {
  Typography,
  Paper,
  Box,
  useTheme
} from "@mui/material"

/**Images */
import ClassicSrc from '../../../assets/images/icons/classic_icon_white.svg'
import ArcadeSrc from '../../../assets/images/icons/arcade_icon_white.svg'
import RushSrc from '../../../assets/images/icons/rush_icon_white.svg'
import { MATH_GRADIENTS } from '../../../styles/MathGradients'

function GameStats(props) {
  const { stats, gamemode, summary } = props
  const theme = useTheme();

  const ClassicDetails = () => (
    <Box sx={{ py: theme.spacing(2), display: summary ? 'none' : 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Aciertos</Typography>
        <Typography variant='subtitle1' color='success'>{stats.right_excercises}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Errores</Typography>
        <Typography variant='subtitle1' color='error'>{stats.mistakes}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Total de victorias</Typography>
        <Typography variant='subtitle1' color='success'>{stats.victories}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Total de derrotas</Typography>
        <Typography variant='subtitle1' color='success'>{stats.defeats}</Typography>
      </Box>
    </Box>
  )

  const ArcadeDetails = () => (
    <Box sx={{ py: theme.spacing(2), display: summary ? 'none' : 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Aciertos</Typography>
        <Typography variant='subtitle1' color='success'>{stats.right_excercises}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Errores</Typography>
        <Typography variant='subtitle1' color='error'>{stats.mistakes}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Total de victorias</Typography>
        <Typography variant='subtitle1' color='success'>{stats.victories}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Total de derrotas</Typography>
        <Typography variant='subtitle1' color='success'>{stats.defeats}</Typography>
      </Box>
    </Box>
  )

  const RushDetails = () => (
    <Box sx={{ py: theme.spacing(2), display: summary ? 'none' : 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Multiplicador máximo</Typography>
        <Typography variant='subtitle1' color='success'>{stats.multiplier}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Nivel máximo</Typography>
        <Typography variant='subtitle1' color='success'>{stats.level}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: theme.spacing(2) }}>
        <Typography variant='body1'>Récord de ejercicios contestados</Typography>
        <Typography variant='subtitle1' color='success'>{stats.excercises}</Typography>
      </Box>
    </Box>
  )

  const mapGamemodes = {
    classic: {
      color: MATH_GRADIENTS().classic,
      text: 'Modo Clásico',
      icon: ClassicSrc,
      details: <ClassicDetails />
    },
    arcade: {
      color: MATH_GRADIENTS().arcade,
      text: 'Modo Arcade',
      icon: ArcadeSrc,
      details: <ArcadeDetails />
    },
    rush: {
      color: MATH_GRADIENTS().rush,
      text: 'Modo Rush',
      icon: RushSrc,
      details: <RushDetails />
    },
  }

  return (
    <Fragment>
      <Paper sx={{ borderRadius: 4, }}>
        <Box sx={{
          background: mapGamemodes[gamemode].color,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          height: 60,
          color: 'white',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img src={mapGamemodes[gamemode].icon} alt='gamemode_icon.svg' style={{
              width: gamemode === 'rush' ? '28px' : '40px',
              marginRight: theme.spacing(2)
            }} />
            <Typography variant='h6'>{mapGamemodes[gamemode].text}</Typography>
          </Box>
        </Box>
        <Box sx={{ py: theme.spacing(1), textAlign: 'center' }}>
          <Typography variant='h6'>Puntuación Máxima</Typography>
          <Typography variant='h6' color='success'>{stats.points} pts.</Typography>
        </Box>
        {mapGamemodes[gamemode].details}
      </Paper>
    </Fragment >
  )
}

export default GameStats
