import { MATH_GRADIENTS } from '../../../styles/MathGradients'

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
  },
  classicPaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().classic,
    height: '100%'
  },
  ArcadePaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().arcade,
    height: '100%'
  },
  RushPaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().rush,
    height: '100%'
  },
  DeathmatchPaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().deathmatch,
    height: '100%'
  },
  disabled: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().disabled,
    height: '100%'
  }
});

export default useStyles;