import React from 'react'

function Logo(props) {
    return (
        <img
            src={props.src}
            className={props.className}
            style={props.style}
            alt={props.alt}>
        </img>
    )
}

export default Logo
