import { MATH_GRADIENTS } from '../../../styles/MathGradients'

export const useStyles = (theme) => ({
  background: {
    paddingTop: theme.spacing(1.3),
    paddingLeft: theme.spacing(1.3),
    paddingRight: theme.spacing(1.3),
    background: MATH_GRADIENTS().classic,
    overflow: "auto",
    position: "bottom",
    height: "100vh"
  },
  verticalScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    textAlign: 'center',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 8,
    width: '100vw'
  },
  loadingScreen: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    height: '100%'
  },
  board: {
    width: '100%',
    height: '97%',
    zIndex: theme.zIndex.drawer + 1,
  },
  player1Layer: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 2,
    height: '100vh',
    width: '100vw'
  },
  player2Layer: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 3,
    height: '100vh',
    width: '100vw'
  },
  player3Layer: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 4,
    height: '100vh',
    width: '100vw'
  },
  player4Layer: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 5,
    height: '100vh',
    width: '100vw'
  },
  uiLayer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 6,
    top: '23%',
    left: '19%',
    fontSize: '1.75vh'
  },
  scoresPaper: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
    height: '51vh',
    minWidth: '26vw',
    margin: '10px',
    padding: theme.spacing(1.5)
  },
  scoreTitle: {
    textAlign: 'center',
    fontSize: '3.2vh',
    marginBottom: theme.spacing(1.5)
  },
  playerNickname: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  playerScore: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.3)
  },
  nickname: {
    marginLeft: theme.spacing(1),
    fontSize: '2.3vh'
  },
  pointsLabel: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(3),
    fontWeight: 'bold',
    fontSize: '2.3vh'
  },
  roundTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '3vh'
  },
  roundLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '2.5vh'
  },
  difLabel: {
    textAlign: 'center',
    color: theme.palette.rush.main,
    fontSize: '3vh'
  },
  centerTiles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  diceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginLeft: '1vh',
    width: '18vw'
  },
  diceImg: {
    width: '10vh',
    marginBottom: '10px'
  },
  button: {
    color: '#FFF',
    fontSize: '2.3vh',
    marginBottom: '2vh'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#FFF',
    marginBottom: '2vh'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#FFF'
  },
  logo: {
    width: '5vh',
    marginRight: '10px'
  },
  LogoDiceSize: {
    fontSize: '3vh'
  },
  icons: {
    color: '#FFF',
    marginRight: '1vh',
    fontSize: '2vw'
  },
  messageSize: {
    fontSize: '2.5vh'
  },
  pauseContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px'
  },
  buttonPause: {
    color: "#FFF",
    background: theme.palette.disabled.main,
    '&:hover': {
      background: theme.palette.disabled.dark,
    },
    textAlign: "center"
  }
});