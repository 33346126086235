import { MATH_GRADIENTS } from "../../../styles/MathGradients";

export const useStyles = (theme) => ({
  background: {
    background: MATH_GRADIENTS().rush,
    overflow: "auto",
    position: "bottom",
    height: "100vh"
  },
  loadingScreen: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
})