import React, { useState, useEffect, Fragment } from 'react'
import useAuth from '../../hooks/useAuth';
import {
  Grid, Grid2, TextField, Button, FormControl, InputLabel, Select, MenuItem,
  CircularProgress, Box, LinearProgress, Typography, Paper,
  useTheme, RadioGroup, FormControlLabel, Radio, Accordion,
  AccordionDetails, AccordionSummary
} from "@mui/material"
import { useStyles } from './useStyles'

/**Icons */
//import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**Components */
import DefaultSnackbar from '../common/DefaultSnackbar'
import DefaultAvatar from '../user/DefaultAvatar'

/**Utils */
import { maxLenghtValidation, minLenghtValidation } from '../../utils/FormValidation'
import { avatars, grades } from '../../utils/SelectArrays'

/**APIs */
import { getInstitutionsApi } from '../../api/institution'
import { getUserByIdApi, updateUserApi } from '../../api/user'
import { getAccessTokenApi } from '../../api/auth'

function UpdateProfile(props) {
  const theme = useTheme();
  const classes = useStyles(theme)
  const [institutionsList, setInstitutionsList] = useState([])
  const { user } = useAuth();

  const [inputs, setInputs] = useState({})
  const [oldAvatar, setOldAvatar] = useState('');
  const [isUpdating, setIsUpdating] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(true)

  //Snackbar
  const [messageNotification, setMessageNotification] = useState('')
  const [openNotification, setOpenNotification] = useState(false)

  useEffect(() => {
    getUserByIdApi(user.id).then(response => {
      const userToUpdate = response.user

      setInputs({
        name: userToUpdate.name,
        lastname: userToUpdate.lastname,
        nickname: userToUpdate.nickname,
        email: userToUpdate.email,
        institution: userToUpdate.institution,
        school_grade: userToUpdate.school_grade,
        avatar: userToUpdate.avatar,
        description: userToUpdate.description || ''
      })
      setOldAvatar(user.nickname);

      //Para la carga
      setIsLoadingData(false)
    })

    //Traer institutciones
    getInstitutionsApi().then(response => {
      setInstitutionsList(response.institution)
    })
  }, [user])

  //Función para guardar los datos editados 
  const changeForm = (e) => {
    if (e.target.type === "text" || e.target.type === "email") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      })
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      })
    }
  }

  //Actualizar perfil
  const updateProfile = () => {
    //Validaciones
    if (!minLenghtValidation(inputs.name.trim(), 1)) {
      setMessageNotification('Ingrese un nombre.');
      setOpenNotification(true);
      return;
    }

    if (!minLenghtValidation(inputs.lastname.trim(), 1)) {
      setMessageNotification('Ingrese un apellido.');
      setOpenNotification(true);
      return;
    }

    if (!maxLenghtValidation(inputs.description.trim(), 200)) {
      setMessageNotification('La descripción no debe pasar de 200 caracteres.');
      setOpenNotification(true);
      return;
    }

    //Inicia carga
    setIsUpdating(true);

    //Si subio imagen
    updateUserApi(getAccessTokenApi(), inputs, user.id).then(response => {
      if (response.status === 1) {
        setMessageNotification(response.message);
        setOpenNotification(true);
        setIsUpdating(false);
        window.location.reload();
      } else {
        setMessageNotification(response.message);
        setOpenNotification(true);
        setIsUpdating(false);
      }
    })
  }

  if (isLoadingData) {
    return <LinearProgress variant='indeterminate' color='primary' />
  }

  return (
    <Paper sx={classes.paper}>
      <DefaultSnackbar message={messageNotification} open={openNotification} handleClose={() => setOpenNotification(false)} />
      <form onChange={changeForm}>
        <Box>
          <Typography variant='h6' gutterBottom>Editar perfil</Typography>
          <Box sx={classes.container}>
            <DefaultAvatar nickname={oldAvatar} size={"16vh"} />
          </Box>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ my: theme.spacing(2) }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color='primary'>Escoge un avatar</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup value={inputs.avatar} name="avatar">
                  <Grid2 container spacing={2}>
                    {
                      avatars.sort().map((avatar, index) => (
                        <Grid2 key={index} size={{ xl: 2, lg: 3, md: 4, sm: 4, xs: 4 }}>
                          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ mx: theme.spacing(2) }} textAlign={'center'}>
                            <img src={avatar.path} alt={avatar.photo} style={{ width: '6vh', borderRadius: '50%' }} />
                            <FormControlLabel value={avatar.photo} control={<Radio />} label={avatar.spanish_name} labelPlacement='bottom' />
                          </Box>
                        </Grid2>
                      ))
                    }
                  </Grid2>
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                type="text"
                name="name"
                label="*Nombres"
                variant="outlined"
                fullWidth
                value={inputs.name} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                type="text"
                name="lastname"
                label="*Apellidos"
                variant="outlined"
                fullWidth
                value={inputs.lastname} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                type="text"
                label="*Alias"
                variant="outlined"
                fullWidth
                disabled
                defaultValue={inputs.nickname} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="email"
                label="*Correo electrónico"
                variant="outlined"
                fullWidth
                disabled
                value={inputs.email} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" sx={classes.select}>
                <InputLabel id="lbl_inst">Institución</InputLabel>
                <Select
                  name="institution"
                  value={inputs.institution}
                  defaultValue={inputs.institution}
                  label="Institución"
                  labelId="lbl_inst"
                  onChange={changeForm}>

                  <MenuItem value={'Ninguna'}>Ninguna</MenuItem>

                  {institutionsList.map((values, index) =>
                    <MenuItem key={index} value={values.name}>{values.name}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" sx={classes.select}>
                <InputLabel id="lbl_school">Año/Semestre/Cuatrimestre</InputLabel>
                <Select
                  name="school_grade"
                  value={inputs.school_grade}
                  defaultValue={inputs.school_grade}
                  label="Año/Semestre/Cuatrimestre"
                  labelId="lbl_school"
                  onChange={changeForm}>

                  <MenuItem value={'Ninguno'}>Ninguno</MenuItem>

                  {grades.map((values, index) =>
                    <MenuItem key={index} value={values.val}>{values.name}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name='description'
                type='text'
                multiline
                fullWidth
                label="Descripción"
                variant="outlined"
                rows={4}
                value={inputs.description} />
            </Grid>
          </Grid>

          <Box marginTop={2}>
            {
              isUpdating ?
                <CircularProgress color='primary' variant='indeterminate' /> :
                <Fragment>
                  <Button
                    sx={classes.button}
                    color='primary'
                    variant='contained'
                    startIcon={<SaveIcon />}
                    onClick={updateProfile}>
                    Guardar
                  </Button>
                </Fragment>
            }
          </Box>
        </Box>
      </form>
    </Paper>
  )
}
export default UpdateProfile
