import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  List, ListItem, ListItemText, ListItemAvatar,
  LinearProgress, Typography, Dialog, DialogTitle, DialogContent, useTheme,
  Box,
  ListItemButton,
  TextField,
  DialogActions,
  Button, Grid2 as Grid
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

//Componentes
import DefaultAvatar from '../../user/DefaultAvatar/DefaultAvatar'

//API
import { searchUsersApi } from '../../../api/user'

function SearchUserInput(props) {
  const { open, handleClose } = props;
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [openSearch, setOpenSearch] = React.useState(true);
  const [usersList, setUsersList] = useState([])
  const [startSearching, setStartSearching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const searchUsersList = (field) => {
    //Inicia carga
    setIsLoading(true)

    //Empieza a buscar (mensaje de búsqueda)
    setStartSearching(true);

    //Resetea mensaje
    if (field === "") {
      setUsersList([])
      setStartSearching(false)
    }

    searchUsersApi(field).then(response => {
      if (response.status === 1) {
        setUsersList(response.users);
        setIsLoading(false);
      } else {
        setUsersList([]);
        setIsLoading(false);
      }
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose} fullWidth>
      <DialogTitle>Buscar Usuarios</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={{ xl: 8, lg: 8, md: 8, sm: 8, xs: 12 }}>
            <TextField
              id="search-user"
              label="Ingresa nombres o aliases"
              variant='outlined'
              fullWidth
              onChange={(e) => setSearchQuery(e.target.value.trim())}
            />
          </Grid>
          <Grid size={{ xl: 4, lg: 4, md: 4, sm: 4, xs: 12 }}>
            <Button color='primary' variant='contained' fullWidth onClick={() => searchUsersList(searchQuery)}
              startIcon={<SearchIcon />}>
              Buscar
            </Button>
          </Grid>
        </Grid>
        {
          openSearch && (
            <Box sx={{ p: theme.spacing(2), maxHeight: 400 }}>
              {
                isLoading ? <LinearProgress variant='indeterminate' /> :
                  !startSearching ?
                    <Typography>Busca a un jugador con su nombre o alias</Typography> :
                    startSearching && usersList.length > 0 ?
                      <Fragment>
                        <Typography variant='body2'>
                          Mostrando los primeros 20 jugadores
                        </Typography>
                        <List>
                          {
                            usersList.map((value, index) => (
                              <div onClick={handleClose} key={index}>
                                <Link to={`/home/profile/@${value.nickname}`} style={{
                                  textDecoration: 'none',
                                  color: theme.palette.primary.main,
                                  '&:hover': {
                                    textDecoration: 'none',
                                    color: theme.palette.primary.dark,
                                  }
                                }}>
                                  <ListItem disablePadding>
                                    <ListItemButton>
                                      <ListItemAvatar>
                                        <DefaultAvatar nickname={value.nickname} />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={`${value.name} ${value.lastname}`}
                                        secondary={`@${value.nickname}`} />
                                    </ListItemButton>
                                  </ListItem>
                                </Link>
                              </div>
                            ))
                          }
                        </List>
                      </Fragment> : <Typography>No hay resultados</Typography>
              }
            </Box>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button color='error' variant='contained' onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SearchUserInput