import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LinearProgress, IconButton, Typography,
  Box,
} from "@mui/material"
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

/**Componentes */
import Notification from '../common/Notification'

/**Iconos */
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

/**APIs */
import { getAllUsersApi, deleteUserApi } from "../../api/user"

function UsersTable() {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [selectedElement, setSelectedElement] = useState('');
  const [reload, setReload] = useState(false)

  useEffect(() => {
    getAllUsersApi().then(response => {
      let usersList = []

      response.users.forEach(value => {
        usersList.push({ ...value, id: value._id })
      })

      setUsers(usersList)
      setIsLoading(false)
    })

    setReload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const columns = useMemo(
    () => [
      { accessorKey: 'id', header: 'ID', size: 200 },
      { accessorKey: 'name', header: 'Nombre', size: 200 },
      { accessorKey: 'lastname', header: 'Apellido', size: 200 },
      {
        accessorFn: (row) => `${row.name} ${row.lastname}`,
        header: 'Nombre Completo',
        size: 200
      },
      {
        accessorKey: 'nickname',
        header: 'Nickname',
        size: 200,
        Cell: ({ rValue, row }) => (
          <Link to={`/home/profile/@${row.original.nickname}`}>
            @{row.original.nickname}
          </Link>
        )
      },
      { accessorKey: 'email', header: 'Correo electrónico', size: 200 },
      { accessorKey: 'institution', header: 'Institución', size: 200 },
      { accessorKey: 'school_grade', header: 'Grado escolar', size: 200 },
      { accessorKey: 'role', header: 'Rol', size: 200 },
      {
        accessorKey: 'active',
        header: 'Estado',
        size: 200,
        Cell: ({ rValue, row }) => (
          <Fragment>
            {row.original.active ?
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <CheckCircleIcon style={{ color: '#00B76F' }} />
                <span style={{ marginLeft: 5 }}>Activo</span>
              </div> :
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <CancelIcon style={{ color: '#FF2942' }} />
                <span style={{ marginLeft: 5 }}>Inactivo</span>
              </div>}
          </Fragment>
        )
      },
      {
        accessorKey: 'actions', header: 'Acciones', size: 200,
        Cell: ({ rValue, row }) => (
          <div>
            <Link to={`/admin/users/update/${row.original.id}`}>
              <IconButton color='primary'>
                <CreateIcon style={{ fontSize: 16 }} />
              </IconButton>
            </Link>
            <IconButton
              color='error'
              onClick={() => {
                setOpen(true)
                setSelectedId(row.original.id)
                setSelectedElement(row.original.nickname)
              }}>
              <DeleteIcon style={{ fontSize: 16 }} />
            </IconButton>
          </div>
        )
      },
    ], []
  );

  const data = useMemo(() => users, [users]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact'
    }
  });

  return (
    <Fragment>
      <Notification
        open={open}
        onClose={() => setOpen(false)}
        title="Eliminar usuario"
        onAccept={() => {
          deleteUserApi(selectedId).then()
          setOpen(false)
          setReload(true)
        }}>
        <Typography>¿Estás seguro de querer eliminar este elemento? <Typography color='primary'>{selectedElement}</Typography></Typography>
      </Notification>
      <Box sx={{ height: 400, p: 0, m: 0, maxWidth: '82vw' }}>
        {isLoading ? <LinearProgress /> :
          users.length > 0 ?
            <MaterialReactTable table={table} />
            : <Typography>No hay registros</Typography>
        }
      </Box>
    </Fragment>
  )
}

export default UsersTable
