import { createTheme } from '@mui/material'

const theme = createTheme({
  colorSchemes: {
    dark: true
  },
  typography: {
    fontFamily: "DM Sans, sans-serif",
    button: {
      textTransform: "none",
      fontSize: "1rem"
    }
  },
  mixins: {
    toolbar: {
      minHeight: '64px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          backgroundColor: theme.palette.mode === 'light' ? '#F5F5F5' : theme.palette.background.default,
        },
      }),
    },
  },
  palette: {
    primary: {
      light: "#7296D5",
      main: "#3461ae",
      dark: "#213E6F",
      contrastText: "#FFFFFF"
    },
    secondary: {
      light: "#82D7EB",
      main: "#51C8E3",
      dark: "#1C8FA9",
      contrastText: '#000000'
    },
    disabled: {
      light: "#939495",
      main: "#818284",
      dark: "#6E6F71"
    },
    error: {
      light: "#ED8E9C",
      main: "#E25066",
      dark: "#BC1F36"
    },
    success: {
      light: "#8AD062",
      main: "#60AD34",
      dark: "#457B25"
    },
    warning: {
      light: "#D08362",
      main: "#AD5834",
      dark: "#7B3F25"
    },
    info: {
      light: "#8981C0",
      main: "#5C51A2",
      dark: "#423B74",
    },
    classic: {
      light: "#98CE00",
      main: "#64a730",
      dark: "#388659"
    },
    arcade: {
      light: "#FF006E",
      main: "#b00243",
      dark: "#6A041D"
    },
    rush: {
      light: "#2A8EFF",
      main: "#156bbe",
      dark: "#00487C"
    },
    deathmatch: {
      light: "#ff0009",
      main: "#730017",
      dark: "#540011"
    },
  },
})

export default theme