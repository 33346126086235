import React, { useState, useEffect, Fragment } from 'react'
import {
  Box, Grid, LinearProgress, Typography, Button,
  useTheme, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import useStyles from './useStyles';
import PropTypes from 'prop-types'

//Components
import DefaultSnackbar from '../../common/DefaultSnackbar';
import DefaultAvatar from '../../user/DefaultAvatar';

//API
import { getReceivedRequestsApi, updateRequestApi } from '../../../api/requests';
import { addMemberApi } from '../../../api/groups';

//Hooks
import useAuth from '../../../hooks/useAuth';

function RequestsList(props) {
  const { user } = useAuth();
  const { open, handleClose } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  //Solicitudes
  const [requests, setRequests] = useState([]);
  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const [reload, setReload] = useState(false);

  //Snacks
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");

  //Efecto para traer las solicitudes
  useEffect(() => {
    if (open) {
      //Inicia carga
      setIsLoadingRequests(true);

      getReceivedRequestsApi(user.id).then(response => {
        if (response.status === 1) {
          setRequests(response.requests);
          setIsLoadingRequests(false);
        } else {
          setIsLoadingRequests(false);
        }
      })
    }

    //Para recarfa
    setReload(false);

    return () => {
      setRequests([]);
      setReload(false);
    }
  }, [open, user.id, reload])

  //Función para aceptar unión a grupo
  const acceptGroupRequest = (group, request) => {
    //Lo añadimos a grupo
    addMemberApi(group._id, [request.userRequested]).then(response => {
      if (response.status === 1) {
        //Cambia de estado a la solicitud
        request.status = 'accepted';

        //Modifica solicitud
        updateRequestApi(request._id, request).then(response => {
          if (response.status === 1) {
            setMessage("Solicitud aceptada");
            setOpenSnack(true);

            //Recarga datos
            setReload(true);
          } else {
            setMessage(response.message);
            setOpenSnack(true);
          }
        })
      } else {
        setMessage(response.message);
        setOpenSnack(true);
      }
    })
  }

  //Función para rechazar solicitud
  const rejectRequest = (request) => {
    //Cambia de estado a la solicitud
    request.status = 'rejected';

    //Modifica solicitud
    updateRequestApi(request._id, request).then(response => {
      if (response.status === 1) {
        setMessage("Solicitud rechazada");
        setOpenSnack(true);

        //Recarga datos
        setReload(true);
      } else {
        setMessage(response.message);
        setOpenSnack(true);
      }
    })
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Solicitudes</DialogTitle>
      <DialogContent>
        <DefaultSnackbar
          open={openSnack}
          handleClose={() => setOpenSnack(false)}
          message={message} />
        {
          isLoadingRequests ?
            <LinearProgress variant='indeterminate' /> :
            <Fragment>
              {
                requests.length === 0 ?
                  <Typography>
                    No hay solicitudes
                  </Typography> :
                  <Fragment>
                    {
                      requests.map((value, index) => {
                        if (value.userRequester) {
                          switch (value.type) {
                            case 'group':
                              return (
                                <Box sx={classes.requestBox} key={index}>
                                  <Grid container spacing={1} alignItems='center'>
                                    <Grid item lg={12}>
                                      <Box sx={classes.requestTextBox}>
                                        <DefaultAvatar sx={classes.avatar} nickname={value.userRequester.nickname} />
                                        <Box>
                                          <Typography component='p'>
                                            Nueva invitación a un grupo
                                          </Typography>
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color='primary'
                                            sx={classes.inline}>
                                            {`${value.userRequester.name} ${value.userRequester.lastname}`}
                                          </Typography>
                                          <Typography component='span' variant='body2' color='textSecondary'>
                                            {` te ha invitado a unirte al grupo `}
                                          </Typography>
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color='primary'
                                            sx={classes.inline}>
                                            {`${value.group.name}`}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                      {
                                        value.status === 'requested' ?
                                          <Box sx={{ px: theme.spacing(2) }}>
                                            <Button
                                              variant='contained'
                                              size='small'
                                              color='primary'
                                              sx={classes.btn}
                                              onClick={() => acceptGroupRequest(value.group, value)}>
                                              Aceptar y unirse
                                            </Button>
                                            <Button
                                              variant='contained'
                                              size='small'
                                              color='error'
                                              onClick={() => rejectRequest(value)}>
                                              Rechazar
                                            </Button>
                                          </Box> :
                                          value.status === 'accepted' ?
                                            <Typography
                                              variant='body2'
                                              color='textSecondary'
                                              sx={{ px: theme.spacing(2) }}>
                                              Solicitud aceptada
                                            </Typography> :
                                            value.status === 'rejected' ?
                                              <Typography
                                                variant='body2'
                                                color='textSecondary'
                                                sx={{ px: theme.spacing(2) }}>
                                                Solicitud rechazada
                                              </Typography> : null
                                      }
                                    </Grid>
                                  </Grid>
                                </Box>
                              );

                            default:
                              return null;
                          }
                        } else {
                          return null;
                        }
                      })
                    }
                  </Fragment>
              }
            </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button color='error' variant='contained' onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

RequestsList.propTypes = {
  openRequestList: PropTypes.bool
}

export default RequestsList