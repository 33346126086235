import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { Container, Typography, Grid2 as Grid, Paper, Box, useTheme, Button, CircularProgress } from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import PlayerCard from '../../../../components/lobbies_elements/PlayerCard/PlayerCard';
import { io } from 'socket.io-client';
import { Howl } from 'howler'

//Images
import rushIconSrc from '../../../../assets/images/icons/rush_icon_1.svg'
import { MATH_GRADIENTS } from '../../../../styles/MathGradients'

//Icons & API
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DefaultSnackbar from '../../../../components/common/DefaultSnackbar/DefaultSnackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { wsPrivateRushServerBasePath } from '../../../../config/api';
import { deleteRushGameApi, getRushGameByPinApi, updateRushGameApi } from '../../../../api/rushGame';
import { getAccessTokenApi } from '../../../../api/auth';

function PrivateRushLobby(props) {
  const { match: { params: { pin } } } = props;
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const staticGame = location.state?.game;
  const { user: localUser } = useAuth();

  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [game, setGame] = useState(staticGame);
  const [exitPlayer, setExitPlayer] = useState(false);
  let socket = useRef(null);

  //Musica
  useEffect(() => {
    const music = new Howl({
      src: ['/music/lobby_theme.ogg'],
      loop: true,
      volume: 0.25
    });

    music.play();

    return () => {
      // Limpiar la instancia de Howl cuando el componente se desmonte
      music.unload();
    };
  }, [])

  useEffect(() => {
    //Verifica que la partida está en la BD
    getRushGameByPinApi(pin).then(response => {
      const status = response.status;
      if (status === 0) {
        history.push('/home/play/rush');
      } else {
        const g = response.game;
        if (g.status === 'finished' || g.status === 'in_game') {
          history.push('/home/play/rush');
        }
      }
    }).catch(e => {
      history.push('/home/play/rush');
    });

    //Socket
    socket.current = io(`${wsPrivateRushServerBasePath}/${pin}`, {
      transports: ["websocket"],
      withCredentials: true
    });

    //empieza el matching con los invitados, enviando el juego estático por primera vez
    if (staticGame && localUser.nickname === staticGame.host) {
      socket.current.emit('lobby', staticGame)
    }

    //Asigna el mensaje del socket al estado mutable
    socket.current.on('lobby', (data) => {
      setGame({ ...data, players: data.players.sort((a, b) => a.number - b.number) });
    });

    return () => {
      socket.current.disconnect();
      socket.current.off('lobby');
    }
  }, [staticGame, pin, localUser.nickname, history]);

  //Efecto que añade un jugador a la lista si no está incluído
  useEffect(() => {
    if (game && !exitPlayer) {
      //Si el nuevo jugador no está en la lista, lo agrega
      if (!game.players.some(player => player.nickname.includes(localUser.nickname)) && game.players.length <= 4) {
        let gameWithNewPlayer = game;
        const newPlayer = {
          nickname: localUser.nickname,
          avatar: localUser.avatar,
          number: 1,
          timeUp: false,
          level: 1,
          excercises: 0,
          points: 0,
          multiplier: 1,
          dropOff: false,
          result: 'none',
        };
        gameWithNewPlayer.players.push(newPlayer);

        //Envía el nuevo game
        socket.current.emit('lobby', gameWithNewPlayer);
      }

      //si el juego se cancela, se redirige
      if (game.status === 'canceled') {
        socket.current.disconnect();
        history.push('/home/play/rush');
      }

      //si el juego empezó por el host, redirige al tablero
      if (game.status === 'in_game') {
        let updateGame = game;
        let players = updateGame.players;
        let playersWithNumber = [];

        //Asigna numeros de jugador
        playersWithNumber = players.map((player, index) => ({
          ...player,
          number: index + 1
        }))

        updateGame.players = playersWithNumber;
        updateGame.status = 'in_game'; //Cambia el estatus
        updateGame.message = `Partida iniciada`;

        //Actualiza el estatus de la partida en la BD
        updateRushGameApi(updateGame, game.pin).then(response => {
          //Redirigimos el juego
          history.push(`/rush/private/${game.pin}`, { game: game });
        }).catch(e => {
          history.push(`/home/play/rush`);
        })
      }
    }
  }, [game, localUser, history, exitPlayer])

  //Función que borra la partida de la BD y retorna al menú de juegos
  const cancelGame = () => {
    let socketGame = game;
    deleteRushGameApi(pin).then(response => {
      setMessage('Partida Cancelada');
      setOpenSnack(true);
    }).catch(e => {
      setMessage('Error al eliminar la partida.');
      setOpenSnack(true);
    })

    socketGame.status = 'canceled';
    socket.current.emit('lobby', socketGame);
    socket.current.disconnect();
    history.push('/home/play/rush');
  }

  //Función que elimina un jugador cuando sale
  const exitGame = () => {
    let socketGame = game;
    //Eliminamos al jugador
    socketGame.players = socketGame.players.filter(player => player.nickname !== localUser.nickname);

    setExitPlayer(true);
    socket.current.emit('lobby', socketGame);
    history.push('/home/play/rush');
    socket.current.disconnect();
  }

  //Función para iniciar el juego
  const startGame = () => {
    let socketGame = game;
    socketGame.status = 'in_game';
    socket.current.emit('lobby', socketGame);
  }

  //Función que copia el código al clipboard
  const handleCopyPinCode = () => {
    navigator.clipboard.writeText(pin).then().catch();
    setMessage("PIN copiado al portapapeles.");
    setOpenSnack(true);
  }

  if (!getAccessTokenApi() || !staticGame) {
    return <Redirect to="/" />
  }

  return (
    <div style={{ background: MATH_GRADIENTS(90).rush, width: '100vw', minHeight: '100vh' }}>
      <DefaultSnackbar open={openSnack} handleClose={() => setOpenSnack(false)} message={message} />
      <Container maxWidth='lg' sx={{ py: theme.spacing(3) }}>
        <Grid container spacing={2} >
          <Grid size={{ xl: 7, lg: 7, md: 12, sm: 12, xs: 12 }}>
            <Paper sx={{ p: theme.spacing(4) }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: theme.spacing(4) }}>
                <img src={rushIconSrc} alt='rush_icon.svg' style={{ width: '56px' }} />
                <Typography variant='h4' sx={{ ml: theme.spacing(2) }}>Sala de espera - Modo Rush</Typography>
              </Box>
              <Typography variant='h5' gutterBottom>Información</Typography>
              <Typography>Son necesarios al menos 2 jugadores para iniciar la partida.</Typography>
              <Box sx={{
                my: theme.spacing(4),
                textAlign: 'center'
              }}>
                <Box mb={theme.spacing(2)}>
                  <center>
                    <img
                      src={`https://qrcode.tec-it.com/API/QRCode?data=${window.location.origin}/join-by-qr/rush/${pin}`} alt='QR Code.png'
                      style={{
                        borderRadius: 20,
                        border: '20px solid white',
                        width: 180
                      }} />
                  </center>
                </Box>
                <Typography fontSize={'28px'} color='textSecondary'>PIN de acceso</Typography>
                <Typography fontSize={'56px'} color='rush'>
                  {pin}
                </Typography>
                <Button color='inherit' variant='contained' startIcon={<ContentCopyIcon />} onClick={handleCopyPinCode}>
                  Copiar PIN
                </Button>
              </Box>
              <Box sx={{
                my: theme.spacing(4),
                display: 'flex',
                justifyContent: 'center'
              }}>
                {
                  localUser.nickname !== game.host ?
                    (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress variant='indeterminate' color='rush' sx={{ mb: theme.spacing(2) }} />
                        <Typography variant='h6'>
                          Esperando a que el anfitrión inicie la partida...
                        </Typography>
                      </Box>
                    ) : game.players.length > 1 ? (
                      <Button variant='contained' sx={{
                        background: MATH_GRADIENTS().default,
                        color: 'white',
                        width: '75%',
                        height: '60px',
                        fontSize: '150%'
                      }}
                        startIcon={<PlayArrowIcon style={{ fontSize: 30 }} />}
                        onClick={startGame}>
                        Iniciar Partida
                      </Button>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CircularProgress variant='indeterminate' color='rush' sx={{ mb: theme.spacing(2) }} />
                        <Typography textAlign={'center'} variant='h6'>
                          Esperando a más jugadores...
                        </Typography>
                      </Box>
                    )
                }
              </Box>
              <Box sx={{ my: theme.spacing(2), textAlign: 'center' }}>
                {
                  staticGame.host === localUser.nickname ?
                    <Button variant='contained' color='error' onClick={cancelGame}>
                      Cancelar partida
                    </Button> :
                    <Button variant='contained' color='error' onClick={exitGame}>
                      Salir de la partida
                    </Button>
                }
              </Box>
            </Paper>
          </Grid>
          <Grid size={{ xl: 5, lg: 5, md: 12, sm: 12, xs: 12 }}>
            <Paper sx={{ padding: theme.spacing('2vh') }}>
              <Box>
                <Typography variant='h6' gutterBottom>Jugadores en espera...</Typography>
                {
                  game.players.map((player, index) => (
                    <Box key={index} sx={{ mb: theme.spacing(2) }}>
                      <PlayerCard color={`#536878`} nickname={player.nickname} />
                    </Box>
                  ))
                }
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default PrivateRushLobby