import {
  Box, FormControl, FormControlLabel, Paper, Radio, RadioGroup,
  Typography,
  useTheme, useColorScheme
} from '@mui/material'
import { useStyles } from './useStyles';

function Options() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { mode, setMode } = useColorScheme();

  return (
    <Paper sx={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Opciones
      </Typography>
      <Box>
        <Typography>
          Tema de color
        </Typography>
        <Typography variant='body2' gutterBottom>
          Ajusta el aspecto de Math Paradise para cambiar la paleta
          de colores.
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-label="gender"
            name="colorPalette"
            value={mode}
            onChange={(event) => setMode(event.target.value)}>
            <FormControlLabel value="system" control={<Radio />} label="Sistema" />
            <FormControlLabel value="light" control={<Radio />} label="Claro" />
            <FormControlLabel value="dark" control={<Radio />} label="Oscuro" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Paper>
  )
}

export default Options