const drawerWidth = 300;
const useStyles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logoSize: {
    width: '50%'
  }
});

export default useStyles;