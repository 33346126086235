import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../useStyles'
import {
  Grid, Typography, Paper, Box, Divider, Button, TextField,
  CircularProgress, FormControlLabel, Switch,
  useTheme
} from "@mui/material"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/**COmponents */
import DefaultSnackbar from '../../../components/common/DefaultSnackbar'

/**APIs */
import { getNewByPath, updateNewApi } from '../../../api/news'

/**Icons */
import AddIcon from '@mui/icons-material/Add'
import { pathGenerator } from '../../../utils/PathGenerator';

function UpdateNew(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  //Traigo el id del documento
  const { match: { params: { path } } } = props

  const [inputs, setInputs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)

  useEffect(() => {
    getNewByPath(path).then(response => {
      if (response.status === 1) {
        setInputs(response.newData);
        setIsLoading(false)
      } else {
        window.location.href = '/admin/news'
      }
    })
  }, [path])

  //Cambio del form
  const changeForm = (e) => {
    if (e.target.type === 'checkbox') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked
      })
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      })
    }
  }

  //Guardar los datos
  const updateNew = () => {
    const fields = inputs;

    if (fields.name.trim() === '' || fields.article.trim() === '' || fields.description.trim() === '') {
      setMessage("Todos los campos son requeridos.")
      setOpen(true)
    } else {
      //Generamos path/code
      fields.path = pathGenerator(fields.name);

      updateNewApi(path, inputs).then(response => {
        if (response.status === 1) {
          setMessage(response.message)
          setOpen(true)

          window.location.href = '/admin/news'
        } else {
          setMessage(response.message)
          setOpen(true)
        }
      })
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Fragment>
      <DefaultSnackbar
        open={open}
        handleClose={() => setOpen(false)}
        message={message} />
      <Paper sx={classes.paper}>
        <Typography variant="h5" sx={classes.title}>
          <AddIcon fontSize="large" /> Modificar Noticia
        </Typography>
        <Divider />
        <form style={classes.formBox} onChange={changeForm}>
          <Typography>*Todos los campos son requeridos</Typography>
          <Grid container spacing={2} sx={classes.form}>
            <Grid item lg={8}>
              <TextField
                type="text"
                name="name"
                label="*Nombre del artículo"
                variant="outlined"
                value={inputs.name}
                sx={classes.textField} />
            </Grid>
            <Grid item lg={4}>
              <FormControlLabel label="Estado (Habilitado / deshabilitado)" control={
                <Switch
                  checked={inputs.active}
                  defaultChecked={inputs.active}
                  name="active" />
              } />
            </Grid>
            <Grid item lg={12}>
              <TextField
                type="text"
                name="description"
                label="*Descripción"
                variant="outlined"
                value={inputs.description}
                sx={classes.textField} />
            </Grid>
            <Grid item lg={12}>
              <Typography variant='h6' gutterBottom>Artículo</Typography>
              <ReactQuill
                theme="snow"
                value={inputs.article}
                style={{
                  height: 400,
                  marginBottom: theme.spacing(4)
                }}
                onChange={(content) => setInputs({ ...inputs, article: content })}
              />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <Link style={classes.link} to="/admin/news">
              <Button sx={classes.cancelButton}>
                Cancelar
              </Button>
            </Link>
            <Button onClick={updateNew} sx={classes.okButton}>
              Guardar
            </Button>
          </Box>
        </form>
      </Paper>
    </Fragment>
  )
}

export default UpdateNew
