import React, { useState, Fragment } from 'react'
import { useStyles } from './useStyles'
import {
  Typography, Box, Grid, Paper, Button,
  useTheme
} from "@mui/material"

/**Iconos */
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';

/**Imagenes */
import classicIconWhite from '../../assets/images/icons/classic_icon_white.svg'

import globalIcon from '../../assets/images/decos/global_icon.svg'
import localIcon from '../../assets/images/decos/local_icon.svg'
import privateIcon from '../../assets/images/decos/private_icon.svg'

/**Components */
import DefaultSnackbar from '../common/DefaultSnackbar'
import HowtoPlayClassicMode from '../game_information_slides/HowtoPlayClassicMode'
import ClassicConfigDialog from '../gamemode_configurations/ClassicConfigDialog/ClassicConfigDialog';
import JoinPrivateClassic from '../lobbies_elements/join_dialogs/JoinPrivateClassicDialog';

function ClassicSelector(props) {
  const { active } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const [message] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openHowTo, setOpenHowTo] = useState(false)
  const [openGameConfig, setOpenGameConfig] = useState(false);
  const [openJoinDialog, setOpenJoinDialog] = useState(false);
  const [mode, setMode] = useState('local');

  return (
    <Fragment>
      <HowtoPlayClassicMode isOpen={openHowTo} handleOnClose={() => setOpenHowTo(false)} />
      <ClassicConfigDialog open={openGameConfig} onClose={() => setOpenGameConfig(false)} mode={mode} />
      <JoinPrivateClassic open={openJoinDialog} handleClose={() => setOpenJoinDialog(false)} />
      <DefaultSnackbar
        open={openSnackbar}
        message={message}
        handleClose={() => setOpenSnackbar(false)}
      />
      <Paper sx={classes.classicPaper} color="primary" elevation={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src={classicIconWhite} alt="classic.svg" style={{ width: 64 }} />
          <Typography variant="h4" sx={{ mt: theme.spacing(2) }}>Modo Clásico</Typography>
        </Box>
        <br />
        <Typography textAlign={'center'}>
          Compite contra uno o tres amigos en este modo basado en los juegos de mesa y consigue
          muchos puntos mientras avanzas por todo el tablero, respondiendo todos los ejercicios matemáticos que el dado te arroje.
        </Typography>
        <Typography textAlign={'center'} fontWeight={'bold'}>
          De 2 a 4 jugadores
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Paper sx={{
              ...classes.paperConfig, minHeight: '520px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }} elevation={0}>
              <Box>
                <Typography variant='h5' gutterBottom textAlign={'center'}>Partida Privada</Typography>
                <Typography textAlign={'center'}>
                  Crear una partida personalizada e invitar a uno o más jugadores a la partida, mediante un PIN de acceso.
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: theme.spacing(3) }}>
                <img src={privateIcon} alt='private_icon.svg' style={{ width: '120px' }} />
              </Box>
              <Box sx={{ ...classes.box }}>
                {
                  active ? (
                    <Button startIcon={<AddCircleIcon />} sx={{ ...classes.playButton, mb: theme.spacing(2) }}
                      size="large" variant="contained" fullWidth
                      onClick={() => {
                        setMode('private')
                        setOpenGameConfig(true)
                      }}>
                      Crear partida
                    </Button>
                  ) :
                    <Button startIcon={<AddCircleIcon />} sx={{ ...classes.playButtonDisabled, mb: theme.spacing(2) }}
                      size="large" variant="contained" fullWidth>
                      Modo deshabilitado
                    </Button>
                }
                <Button startIcon={<SearchIcon />} sx={{ ...classes.joinButton }} size="large" variant="contained" fullWidth
                  onClick={() => setOpenJoinDialog(true)}>
                  Buscar y unirse a partida
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Paper sx={{
              ...classes.paperConfig, minHeight: '520px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }} elevation={0}>
              <Box>
                <Typography variant='h5' gutterBottom textAlign={'center'}>Modo Local</Typography>
                <Typography textAlign={'center'}>
                  Crear partida entre dos a cuatro jugadores, que se controlan desde el mismo dispositivo, ideal para salones escolares.
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: theme.spacing(3) }}>
                <img src={localIcon} alt='local_icon.svg' style={{ width: '120px' }} />
              </Box>
              <Box sx={{ ...classes.box }}>
                {
                  active ? (
                    <Button startIcon={<AddCircleIcon />} sx={{ ...classes.playButton }}
                      size="large" variant="contained" fullWidth
                      onClick={() => {
                        setMode('local')
                        setOpenGameConfig(true)
                      }}>
                      Crear partida
                    </Button>
                  ) :
                    <Button startIcon={<AddCircleIcon />} sx={{ ...classes.playButtonDisabled }}
                      size="large" variant="contained" fullWidth>
                      Modo deshabilitado
                    </Button>
                }
              </Box>
            </Paper>
          </Grid>
          <Grid item xl={4} lg={6} md={6} sm={12} xs={12} display={'none'}>
            <Paper sx={{
              ...classes.paperConfig, minHeight: '520px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }} elevation={0}>
              <Box>
                <Typography variant='h5' gutterBottom textAlign={'center'}>Multijugador</Typography>
                <Typography textAlign={'center'}>Buscar e ingresar a una partida multijugador de manera global.</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: theme.spacing(3) }}>
                <img src={globalIcon} alt='global_icon.svg' style={{ width: '120px' }} />
              </Box>
              <Box sx={{ ...classes.box }}>
                <Button startIcon={<PlayArrowIcon />} sx={{ ...classes.playButton }} size="large" variant="contained" fullWidth>
                  Jugar
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  )
}

export default ClassicSelector