import { MATH_GRADIENTS } from '../../../styles/MathGradients'

export const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  rush_board: {
    background: MATH_GRADIENTS().rush,
    padding: theme.spacing(2),
    color: '#FFF'
  },
  classic_board: {
    background: MATH_GRADIENTS().classic,
    padding: theme.spacing(2),
    color: '#FFF'
  },
  arcade_board: {
    background: MATH_GRADIENTS().arcade,
    padding: theme.spacing(2),
    color: '#FFF'
  },
  header_board: {
    display: 'block',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    alignContent: 'center'
  },
  classic_icon: {
    width: '42px'
  },
  rush_icon: {
    width: '30px'
  }
})