import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../useStyles'
import {
  Grid, Typography, Paper, Box, Divider, Button, TextField, FormControl,
  Select, MenuItem, InputLabel, FormControlLabel, Switch,
  useTheme
} from "@mui/material"

/**APIS */
import { getAreasApi } from '../../../api/areas'
import { createTopicApi } from '../../../api/topics'

/**Components */
import DefaultSnackbar from '../../../components/common/DefaultSnackbar'

/**Icons */
import AddIcon from '@mui/icons-material/Add'
import { codeGenerator } from '../../../utils/CodeGenerator'

function CreateTopic() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [areas, setAreas] = useState([])
  const [inputs, setInputs] = useState({
    name: '',
    area: '',
    code: '',
    active: false
  })
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)

  //Cerrar notificación
  const handleCloseSnackbar = () => {
    setOpen(false)
  }

  useEffect(() => {
    getAreasApi().then(r => {
      if (r.status === 1) {
        setAreas(r.areas)
      }
    })
  }, [])

  const changeForm = (e) => {
    if (e.target.type === 'checkbox') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked
      })
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      })
    }
  }

  const submitForm = (e) => {
    e.preventDefault()

    if (inputs.name !== '' && inputs.area !== '') {
      //Genera code
      inputs.code = codeGenerator(inputs.name);

      createTopicApi(inputs).then(r => {
        setOpen(true)
        setMessage(r.message)
        if (r.status === 1) {
          window.location.href = '/admin/topics'
        }
      })
    } else {
      setOpen(true)
      setMessage('todos los campos son requeridos')
    }
  }

  return (
    <Fragment>
      <DefaultSnackbar
        open={open}
        handleClose={handleCloseSnackbar}
        message={message} />
      <Paper sx={classes.paper}>
        <Typography variant="h5" sx={classes.title}>
          <AddIcon fontSize="large" /> Añadir tema
        </Typography>
        <Divider />
        <form style={classes.formBox} onChange={changeForm} onSubmit={submitForm}>
          <Typography>*Todos los campos son requeridos</Typography>
          <Grid container spacing={2} sx={classes.form}>
            <Grid item lg={6}>
              <TextField
                type="text"
                name="name"
                label="*Nombre del tema"
                variant="outlined"
                sx={classes.textField} />
            </Grid>
            <Grid item lg={6}>
              <FormControl variant="outlined" sx={classes.textField}>
                <InputLabel id="lbl_area">*Area</InputLabel>
                <Select
                  name="area"
                  label="*Area"
                  labelId="lbl_area"
                  onChange={changeForm}>

                  {areas.map((values, index) =>
                    <MenuItem
                      style={{ color: !values.active ? 'red' : 'black' }}
                      key={index}
                      value={values.code}>
                      {values.name}
                    </MenuItem>
                  )}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControlLabel label="Estado (Habilitado / deshabilitado)" control={
                <Switch
                  checked={inputs.active}
                  name="active" />
              } />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <Link style={classes.link} to="/admin/topics">
              <Button sx={classes.cancelButton}>
                Cancelar
              </Button>
            </Link>
            <Button type="submit" sx={classes.okButton}>
              Guardar
            </Button>
          </Box>
        </form>
      </Paper>
    </Fragment>
  )
}

export default CreateTopic
