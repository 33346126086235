export function excerciseGenerator(topic, level) {
  const configs = {
    sum: [
      { maxLevel: 5, ranges: [[10, 5], [10, 1]] },
      { maxLevel: 10, ranges: [[25, 10], [15, 5]] },
      { maxLevel: 15, ranges: [[25, 10], [15, 5], [10, 1]] },
      { maxLevel: 20, ranges: [[30, 15], [20, 10], [15, 5]] },
      { maxLevel: 25, ranges: [[50, 20], [30, 15], [20, 10]] },
      { maxLevel: 30, ranges: [[75, 25], [50, 20], [30, 15]] },
      { maxLevel: 35, ranges: [[100, 50], [75, 50], [50, 25]] },
      { maxLevel: 40, ranges: [[100, 50], [80, 60], [50, 40]] },
      { maxLevel: 45, ranges: [[200, 100], [150, 75], [100, 50]] },
      { maxLevel: 50, ranges: [[300, 200], [200, 100], [100, 75], [50, 20]] },
      { maxLevel: Infinity, ranges: [[1000, 500], [500, 250], [250, 150], [150, 100]] },
    ],
    res: [
      { maxLevel: 5, ranges: [[10, 5], [5, 1]] },
      { maxLevel: 10, ranges: [[25, 10], [10, 1]] },
      { maxLevel: 15, ranges: [[30, 20], [10, 5]] },
      { maxLevel: 20, ranges: [[40, 20], [15, 10]] },
      { maxLevel: 25, ranges: [[50, 30], [20, 10]] },
      { maxLevel: 30, ranges: [[50, 30], [10, 5], [5, 1]] },
      { maxLevel: 35, ranges: [[75, 50], [20, 10], [10, 5]] },
      { maxLevel: 40, ranges: [[100, 75], [50, 20], [15, 10]] },
      { maxLevel: 45, ranges: [[300, 100], [75, 30], [25, 15]] },
      { maxLevel: 50, ranges: [[500, 250], [200, 100], [50, 20], [20, 10]] },
      { maxLevel: Infinity, ranges: [[1000, 600], [500, 200], [100, 50], [35, 20]] },
    ],
    mult: [
      { maxLevel: 5, ranges: [[10, 1], [5, 1]] },
      { maxLevel: 10, ranges: [[10, 1], [10, 1]] },
      { maxLevel: 15, ranges: [[12, 1], [10, 1]] },
      { maxLevel: 20, ranges: [[16, 1], [10, 1]] },
      { maxLevel: 25, ranges: [[20, 10], [20, 10]] },
      { maxLevel: 30, ranges: [[50, 20], [10, 2]] },
      { maxLevel: 35, ranges: [[100, 50], [10, 2]] },
      { maxLevel: 40, ranges: [[500, 100], [10, 2]] },
      { maxLevel: 45, ranges: [[750, 500], [10, 2]] },
      { maxLevel: 50, ranges: [[1000, 500], [10, 2]] },
      { maxLevel: Infinity, ranges: [[2000, 750], [10, 2]] },
    ],
    div: [
      { maxLevel: 5, ranges: [[10, 6], [5, 1]] },
      { maxLevel: 10, ranges: [[16, 6], [5, 2]] },
      { maxLevel: 15, ranges: [[24, 10], [8, 2]] },
      { maxLevel: 20, ranges: [[32, 15], [9, 1]] },
      { maxLevel: 25, ranges: [[50, 20], [10, 5]] },
      { maxLevel: 30, ranges: [[75, 20], [15, 5]] },
      { maxLevel: 35, ranges: [[100, 50], [10, 1]] },
      { maxLevel: 40, ranges: [[500, 100], [20, 10]] },
      { maxLevel: 45, ranges: [[750, 500], [20, 10]] },
      { maxLevel: 50, ranges: [[1000, 500], [30, 20]] },
      { maxLevel: Infinity, ranges: [[2000, 750], [50, 30]] },
    ],
    neg: [
      { maxLevel: 5, ranges: [[-10, 5], [-10, -2]] },
      { maxLevel: 10, ranges: [[25, -10], [-15, 5]] },
      { maxLevel: 15, ranges: [[-25, 10], [-15, 5], [10, 1]] },
      { maxLevel: 20, ranges: [[-30, 15], [-20, -10], [-15, 5]] },
      { maxLevel: 25, ranges: [[50, -20], [-30, 15], [-20, 10]] },
      { maxLevel: 30, ranges: [[75, -25], [-50, 20], [-30, -15]] },
      { maxLevel: 35, ranges: [[100, -50], [-75, 50], [-50, -25]] },
      { maxLevel: 40, ranges: [[-100, 50], [80, 60], [50, 40]] },
      { maxLevel: 45, ranges: [[200, -100], [-150, 75], [-100, 50]] },
      { maxLevel: 50, ranges: [[300, -200], [-200, 100], [-100, 75], [-50, -20]] },
      { maxLevel: Infinity, ranges: [[-1000, 500], [-500, -250], [-250, -150], [-150, 100]] },
    ],
    dec: [
      { maxLevel: 5, ranges: [[10, 5], [10, 1]] },
      { maxLevel: 10, ranges: [[25, 10], [15, 5]] },
      { maxLevel: 15, ranges: [[25, 10], [15, 5], [10, 1]] },
      { maxLevel: 20, ranges: [[30, 15], [20, 10], [15, 5]] },
      { maxLevel: 25, ranges: [[50, 20], [30, 15], [20, 10]] },
      { maxLevel: 30, ranges: [[75, 25], [50, 20], [30, 15]] },
      { maxLevel: 35, ranges: [[100, 50], [75, 50], [50, 25]] },
      { maxLevel: 40, ranges: [[100, 50], [80, 60], [50, 40]] },
      { maxLevel: 45, ranges: [[200, 100], [150, 75], [100, 50]] },
      { maxLevel: 50, ranges: [[300, 200], [200, 100], [100, 75], [50, 20]] },
      { maxLevel: Infinity, ranges: [[1000, 500], [500, 250], [250, 150], [150, 100]] },
    ],
    pot: [
      { maxLevel: 5, ranges: [[10, 1]] },
      { maxLevel: 10, ranges: [[10, 5]] },
      { maxLevel: 15, ranges: [[10, 8]] },
      { maxLevel: 20, ranges: [[10, 9]] },
      { maxLevel: 25, ranges: [[10, 5]] },
      { maxLevel: 30, ranges: [[12, 6]] },
      { maxLevel: 35, ranges: [[14, 7]] },
      { maxLevel: 40, ranges: [[16, 8]] },
      { maxLevel: 45, ranges: [[18, 9]] },
      { maxLevel: 50, ranges: [[20, 10]] },
      { maxLevel: Infinity, ranges: [[20, 16]] },
    ],
    raiz: [
      { maxLevel: 5, ranges: [[10, 1]] },
      { maxLevel: 10, ranges: [[10, 2]] },
      { maxLevel: 15, ranges: [[10, 3]] },
      { maxLevel: 20, ranges: [[10, 4]] },
      { maxLevel: 25, ranges: [[10, 5]] },
      { maxLevel: 30, ranges: [[12, 6]] },
      { maxLevel: 35, ranges: [[14, 7]] },
      { maxLevel: 40, ranges: [[16, 8]] },
      { maxLevel: 45, ranges: [[18, 9]] },
      { maxLevel: 50, ranges: [[20, 10]] },
      { maxLevel: Infinity, ranges: [[20, 16]] },
    ],
    comb: [
      { maxLevel: 5, ranges: [[10, 5], [10, 1], [5, 1]] },
      { maxLevel: 10, ranges: [[10, 5], [10, 1], [10, 1]] },
      { maxLevel: 15, ranges: [[20, 15], [20, 10], [15, 5]] },
      { maxLevel: 20, ranges: [[20, 10], [20, 10], [10, 1]] },
      { maxLevel: 25, ranges: [[50, 25], [30, 10], [10, 1]] },
      { maxLevel: 30, ranges: [[75, 30], [50, 15], [30, 10]] },
      { maxLevel: 35, ranges: [[100, 50], [75, 25], [50, 15]] },
      { maxLevel: 40, ranges: [[150, 75], [100, 50], [75, 25]] },
      { maxLevel: 45, ranges: [[200, 100], [150, 75], [100, 50]] },
      { maxLevel: 50, ranges: [[250, 125], [200, 100], [125, 50]] },
      { maxLevel: Infinity, ranges: [[500, 250], [300, 150], [250, 100]] }
    ],
    mcm: [
      { maxLevel: 5, ranges: [[10, 1], [10, 1]] },
      { maxLevel: 10, ranges: [[25, 10], [10, 1]] },
      { maxLevel: 15, ranges: [[30, 20], [10, 5]] },
      { maxLevel: 20, ranges: [[40, 20], [15, 10]] },
      { maxLevel: 25, ranges: [[50, 30], [20, 10]] },
      { maxLevel: 30, ranges: [[50, 30], [10, 5]] },
      { maxLevel: 35, ranges: [[75, 50], [20, 10]] },
      { maxLevel: 40, ranges: [[100, 75], [50, 20]] },
      { maxLevel: 45, ranges: [[300, 100], [75, 30]] },
      { maxLevel: 50, ranges: [[500, 250], [200, 100]] },
      { maxLevel: Infinity, ranges: [[1000, 600], [500, 200]] },
    ],
    mcd: [
      { maxLevel: 5, ranges: [[10, 1], [10, 1]] },
      { maxLevel: 10, ranges: [[25, 10], [10, 1]] },
      { maxLevel: 15, ranges: [[30, 20], [10, 5]] },
      { maxLevel: 20, ranges: [[40, 20], [15, 10]] },
      { maxLevel: 25, ranges: [[50, 30], [20, 10]] },
      { maxLevel: 30, ranges: [[50, 30], [10, 5]] },
      { maxLevel: 35, ranges: [[75, 50], [20, 10]] },
      { maxLevel: 40, ranges: [[100, 75], [50, 20]] },
      { maxLevel: 45, ranges: [[300, 100], [75, 30]] },
      { maxLevel: 50, ranges: [[500, 250], [200, 100]] },
      { maxLevel: Infinity, ranges: [[1000, 600], [500, 200]] },
    ],
  }

  // Operadores y funciones de cálculo
  const operations = {
    sum: { operator: ' + ', reducer: (a, b) => a + b },
    res: { operator: ' - ', reducer: (a, b) => a - b },
    mult: { operator: ' × ', reducer: (a, b) => a * b },
    div: { operator: ' ÷ ', reducer: (a, b) => Math.trunc(a / b) },
    neg: { operator: ' + ', reducer: (a, b) => a + b },
    dec: { operator: ' + ', reducer: (a = 0, b) => Number((a + b).toFixed(1)) },
    pot: { operator: '', reducer: (a) => a ** 2 },
    raiz: { operator: '', reducer: (a) => Math.trunc(Math.sqrt(a)) },
    mcm: { operator: ' y ', reducer: (a, b) => Math.abs(a * b) / calculateMCD(a, b) },
    mcd: { operator: ' y ', reducer: (a, b) => calculateMCD(a, b) },
    comb: { operator: null, reducer: null }
  };

  // Función para generar ejercicios
  const generate = (topic, level) => {
    const config = configs[topic]?.find(cfg => level <= cfg.maxLevel);
    if (!config) throw new Error('Configuración no encontrada para el tema o nivel');

    const { operator, reducer } = operations[topic];
    const values = config.ranges.map(([range, offset]) => {
      if (topic === 'dec')
        return Number((Math.random() * range + offset).toFixed(1));
      else
        return Math.floor(Math.random() * range) + offset;
    });

    let expression;
    let answer;

    // Generar expresión combinada
    if (topic === 'comb') {
      const operators = [' + ', ' - ', ' * ', ' ÷ '];
      expression = values.reduce((acc, value, index) => {
        const op = index < values.length - 1 ? operators[Math.trunc(Math.random() * operators.length)] : '';
        return acc + value + op;
      }, '');

      // Evaluar expresión respetando el orden de operaciones
      // eslint-disable-next-line no-eval
      answer = Math.round(eval(expression.replace(/÷/g, '/'))); // Reemplazar ÷ por / y evaluar
    } else if (topic === 'pot') {
      expression = values.map(v => `${v}²`).join(', '); // Representar las potencias
      answer = reducer(values);
    } else if (topic === 'raiz') {
      expression = values.map(v => `√${v}`).join(', '); // Representar las raíces
      answer = reducer(values);
    } else {
      expression = values.join(operator);
      answer = values.reduce(reducer);
    }

    return { exc: expression, ans: answer };
  };

  // Validar el tema
  if (!configs[topic]) throw new Error('Tema no válido');

  // Generar el ejercicio
  return generate(topic, level);
}

function calculateMCD(a, b) {
  while (b !== 0) {
    let temp = b;
    b = a % b;
    a = temp;
  }
  return a;
}