import { basePath, apiVersion } from '../config/api'
import { getAccessTokenApi } from './auth'

export function getNewsApi() {
  const url = `${basePath}/${apiVersion}/get-news`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function getNewByPath(path) {
  const url = `${basePath}/${apiVersion}/get-new-by-path/${path}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json"
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function createNewApi(data) {
  const url = `${basePath}/${apiVersion}/create-new`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function updateNewApi(path, data) {
  const url = `${basePath}/${apiVersion}/update-new/${path}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function deleteNewApi(id) {
  const url = `${basePath}/${apiVersion}/delete-new/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}