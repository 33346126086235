import { basePath, apiVersion, classic_path } from '../config/api'
import { getAccessTokenApi } from './auth'

export function createClassicGameApi(data) {
  const url = `${basePath}/${apiVersion}/create-classic-game`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getClassicGameByPinApi(pin) {
  const url = `${basePath}/${apiVersion}/get-classic-game-pin?pin=${pin}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getClassicGamesByNicknameApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-classic-games-nickname/${nickname}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getClassicGameCountApi(nickname) {
  const url = `${basePath}/${apiVersion}/get-classic-game-count/${nickname}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getClassicLeaderboardApi(nicknames = []) {
  const url = `${basePath}/${apiVersion}/get-classic-leaderboard`
  const params = {
    method: 'POST',
    body: JSON.stringify(nicknames),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function updateClassicGameApi(data, pin) {
  const url = `${basePath}/${apiVersion}/update-classic-game?pin=${pin}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function deleteClassicGameApi(pin) {
  const url = `${basePath}/${apiVersion}/delete-classic-game?pin=${pin}`
  const params = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}

export function getClassicServerStatusApi() {
  const url = `${classic_path}/api/status`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json"
    }
  }

  return fetch(url, params).then(response => {
    return response.json()
  }).then(result => {
    return result
  }, (err) => {
    return err.message
  })
}