import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Button, Box, Tooltip,
  useTheme
} from "@mui/material";
import useStyles from './useStyles';

//Componentes
import DefaultAvatar from '../../../user/DefaultAvatar';
//import SearchUserInput from '../SearchUserInput/SearchUserInput';

//Iconos
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import NotificationsIcon from '@mui/icons-material/Notifications';

//APIS
import { logout } from "../../../../api/auth";

//Hooks
import useAuth from '../../../../hooks/useAuth';
import RequestsList from '../../../lists/RequestList/RequestsList';
import SearchUserInput from '../../../common/SearchUserInput/SearchUserInput';

function Header(props) {
  const { title } = props
  const { user } = useAuth();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)

  //Estados del menú de solicitudes
  const [openRequestList, setOpenRequestList] = useState(false);
  const [openUserFinder, setOpenUserFinder] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <AppBar sx={classes.appBar} color="inherit" elevation={1}>
      <RequestsList open={openRequestList} handleClose={() => setOpenRequestList(false)} />
      <SearchUserInput open={openUserFinder} handleClose={() => setOpenUserFinder(false)} />
      <Toolbar>
        <IconButton edge="start" sx={classes.menuButton} color="inherit"
          aria-label="menu" onClick={() => props.OpenAction()}>
          <MenuIcon color='primary' />
        </IconButton>

        <Typography variant="h5" color="primary" sx={classes.title}>
          {title === '/admin' ? 'Panel de administración' : 'Inicio'}
        </Typography>

        <Box sx={{ ...classes.searchBox, display: { xs: 'none', md: 'flex' } }}>
          {/* <SearchUserInput /> */}
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
          <Box sx={classes.btnBox}>
            <Box sx={{ mx: theme.spacing(0.5) }}>
              <Tooltip title='Buscar jugadores'>
                <IconButton onClick={() => setOpenUserFinder(true)}>
                  <PersonSearchIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Tooltip title='Solicitudes'>
              <IconButton
                onClick={() => setOpenRequestList(!openRequestList)}>
                <NotificationsIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Tooltip title='Opciones'>
          <Button onClick={handleMenu}>
            <DefaultAvatar size="35px" fs="100%" nickname={user.nickname} />
            <Typography color='textPrimary' sx={{ marginInline: theme.spacing(1), display: { xs: 'none', md: 'block' } }}>
              {`${user.name} ${user.lastname}`}
            </Typography>
          </Button>
        </Tooltip>

        {/**Menú general */}
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}>

          <Link to={"/home/profile/@" + user.nickname} style={classes.link}>
            <MenuItem onClick={handleClose} sx={classes.alignIcon}>
              <PersonIcon sx={{ color: theme.palette.primary.main }} /> &nbsp; Mi perfil
            </MenuItem>
          </Link>

          <Link to={"/home/settings"} style={classes.link}>
            <MenuItem onClick={handleClose} sx={classes.alignIcon}>
              <SettingsIcon sx={{ color: theme.palette.primary.main }} /> &nbsp; Configuración
            </MenuItem>
          </Link>

          <MenuItem
            onClick={() => { logout(); window.location.href = '/'; }}>
            <div style={classes.alignIcon}>
              <PowerSettingsNewIcon color='error' /> &nbsp; Salir
            </div>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar >
  )
}

export default Header
