import React, { useState, useEffect, Fragment } from 'react'
import {
  Typography, Grid, IconButton, Button,
  useMediaQuery, Backdrop, CircularProgress,
  useTheme,
  Grid2,
  Box,
  Tooltip
} from '@mui/material'
import { useStyles } from './useStyles'
import { MATH_GRADIENTS } from '../../../styles/MathGradients';
import './arcade.styles.css';
import { Howl } from 'howler'
import { useSpring } from '@react-spring/web'

/**Componentes */
import Slot from '../Slot/Slot';

//Images
import arcadeIcon from '../../../assets/images/icons/arcade_icon_white.svg'

/**Iconos */
import SettingsIcon from '@mui/icons-material/Settings';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation'
import PaidIcon from '@mui/icons-material/Paid';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

//Musica y sonidos
import PlayerStatsCard from '../ArcadePlayerStatsCard/ArcadePlayerStatsCard';
import { /* arcade_vegies, */ arcade_gems, arcade_poker, arcade_vegies } from '../../../utils/SelectArrays';
import { formatTime } from '../../../utils/TimeFormat';

function ArcadeBoard(props) {
  const { game, isLoading, localUser, phase, handleRoll,
    sendRollPosition, handleChangePhase, clock = 0, handleOpenMenu, mode = 'private' } = props;
  //Instacia de estilos
  const theme = useTheme();
  const classes = useStyles(theme);

  //Media Query CSS
  const matches = useMediaQuery('(orientation: landscape)');

  //Valores del tablero
  const [position, setPosition] = useState(0);
  const [isRolling, setIsRolling] = useState(false);
  const [secondsToRoll, setSecondsToRoll] = useState(0);
  const [iconType, setIconType] = useState(arcade_gems);

  //Jugadores
  const player1 = game ? game.players.find(player => player.number === 1) : null;
  const player2 = game ? game.players.find(player => player.number === 2) : null;
  const player3 = game ? game.players.find(player => player.number === 3) : null;
  const player4 = game ? game.players.find(player => player.number === 4) : null;

  //Musica y sonidos
  const [sounds, setSounds] = useState({});
  useEffect(() => {
    document.title = 'Modo Arcade - Math Paradise'

    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'draw', url: '/sounds/games/arcade/draw.mp3' },
      { name: 'spin', url: '/sounds/lobby/join.mp3' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    //Musica
    const music = new Howl({
      src: ['/music/arcade_theme.ogg'],
      loop: true,
      volume: 0.25
    });

    music.play();

    return () => {
      // Limpiar la instancia de Howl cuando el componente se desmonte
      music.unload();
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  //Efecto de inicio de renderizado
  useEffect(() => {
    if (game) {
      if (game.distinctive === 'gems') {
        setIconType(arcade_gems)
      } else if (game.distinctive === 'vegies') {
        setIconType(arcade_vegies)
      } else if (game.distinctive === 'poker') {
        setIconType(arcade_poker)
      } else {
        setIconType(arcade_gems)
      }
    }
  }, [game])

  const [animatedSlot, apiSlot] = useSpring(() => ({
    position: 0
  }));

  const roll = () => {
    handleRoll();
    let randSeconds;

    // Asegurarse de que randSeconds sea diferente de secondsToRoll
    do {
      randSeconds = Math.floor(Math.random() * (100 - 30 + 1)) + 30;
    } while (randSeconds === secondsToRoll);

    setSecondsToRoll(randSeconds);
    handleChangePhase('rolling');
    sounds['draw'].play();

    // Inicia la animación con velocity
    apiSlot.start({
      position: Math.floor(animatedSlot.position.get()) + randSeconds,
      velocity: 5, // Controla la velocidad de la animación
      config: {
        duration: randSeconds * 50 // Ajuste final de duración
      },
      onChange: () => {
        const roundedPosition = Math.floor(animatedSlot.position.get());
        setPosition(roundedPosition);
      },
      onRest: () => {
        setIsRolling(false);
        sendRollPosition(Math.floor(animatedSlot.position.get()));  // Redondea antes de enviar
        sounds['draw'].play();
      },
    });

    setIsRolling(true);
  };

  useEffect(() => {
    if (position > 0)
      sounds['spin']?.play();
  }, [position, sounds])

  if (!matches) {
    return (
      <div style={{ ...classes.verticalScreen, ...classes.background }}>
        <Typography style={{
          fontSize: '7vw'
        }}>
          Gire su dispositivo para poder visualizar el tablero
        </Typography>
        <ScreenRotationIcon style={{ fontSize: '7vw', marginTop: '10px' }} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div style={classes.background}>
        <Backdrop open={true} sx={classes.loadingScreen}>
          <CircularProgress color="inherit" />
          <Typography variant="h5">Cargando tablero</Typography>
        </Backdrop>
      </div>
    )
  }

  return (
    <Fragment>
      <div style={classes.background}>
        <Grid container spacing={2} sx={classes.grid}>
          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%'
            }}>
              <Box sx={{ p: theme.spacing(2), color: 'white', width: '100%' }}>
                <Typography variant="h6" sx={classes.scoreTitle} gutterBottom>
                  Puntuaciones
                </Typography>
                <Box display={'flex'} justifyContent={'center'}>
                  <IconButton sx={{ mb: '1vh' }} onClick={handleOpenMenu}>
                    <SettingsIcon sx={{ fontSize: '3vh', color: 'white' }} />
                  </IconButton>
                </Box>
                <Grid2 container spacing={2}>
                  {
                    player1 && (
                      <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                        <PlayerStatsCard avatar={player1.avatar} points={player1.points} ç
                          nickname={player1.nickname} color={'player_1'} coins={player1.coins}
                          dropOff={player1.dropOff} />
                      </Grid2>
                    )
                  }
                  {
                    player2 && (
                      <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                        <PlayerStatsCard avatar={player2.avatar} points={player2.points} ç
                          nickname={player2.nickname} color={'player_2'} coins={player2.coins}
                          dropOff={player2.dropOff} />
                      </Grid2>
                    )
                  }
                  {
                    player3 && (
                      <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                        <PlayerStatsCard avatar={player3.avatar} points={player3.points} ç
                          nickname={player3.nickname} color={'player_3'} coins={player3.coins}
                          dropOff={player3.dropOff} />
                      </Grid2>
                    )
                  }
                  {
                    player4 && (
                      <Grid2 size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}>
                        <PlayerStatsCard avatar={player4.avatar} points={player4.points} ç
                          nickname={player4.nickname} color={'player_4'} coins={player4.coins}
                          dropOff={player4.dropOff} />
                      </Grid2>
                    )
                  }
                </Grid2>
                <Box sx={{
                  ...classes.messageContainer,
                  display: 'flex',
                  alignItems: 'center',
                  mt: theme.spacing(2)
                }}>
                  <SpeakerNotesIcon sx={{ fontSize: '2.5vh', mr: '1vh' }} />
                  <Typography variant='h6' sx={{ fontSize: '2vh' }}>{game.message}</Typography>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
            <Grid container sx={classes.grid}>
              {/**0 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={16} type="RETO" isSelected={position % 22 === 16 ? true : false} iconType={game.distinctive} subtopic={game.board[16]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} sx={classes.centerTiles}>
                <Slot pos={15} type="normal" isSelected={position % 22 === 15 ? true : false} iconType={game.distinctive} subtopic={game.board[15]} />
                <Slot pos={14} type="normal" isSelected={position % 22 === 14 ? true : false} iconType={game.distinctive} subtopic={game.board[14]} />
                <Slot pos={13} type="normal" isSelected={position % 22 === 13 ? true : false} iconType={game.distinctive} subtopic={game.board[13]} />
                <Slot pos={12} type="normal" isSelected={position % 22 === 12 ? true : false} iconType={game.distinctive} subtopic={game.board[12]} />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={11} type="RULETA-TOP" isSelected={position % 22 === 11 ? true : false} iconType={game.distinctive} subtopic={game.board[11]} />
              </Grid>
              {/**1 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={17} type="normal" isSelected={position % 22 === 17 ? true : false} iconType={game.distinctive} subtopic={game.board[17]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={10} type="normal" isSelected={position % 22 === 10 ? true : false} iconType={game.distinctive} subtopic={game.board[10]} />
              </Grid>
              {/**2 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={18} type="normal" isSelected={position % 22 === 18 ? true : false} iconType={game.distinctive} subtopic={game.board[18]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <div style={classes.logoContainer}>
                  <img src={arcadeIcon} alt="arcade_icon.svg" style={classes.logo} />
                  <Typography sx={classes.LogoTitleSize}>Modo Arcade</Typography>
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={9} type="normal" isSelected={position % 22 === 9 ? true : false} iconType={game.distinctive} subtopic={game.board[9]} />
              </Grid>
              {/**3 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={19} type="normal" isSelected={position % 22 === 19 ? true : false} iconType={game.distinctive} subtopic={game.board[19]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} display={'flex'} alignContent={'center'} justifyContent={'center'}>
                <div style={{ height: 0 }}>
                  <div style={{
                    ...classes.messageContainer,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white'
                  }}>
                    <WatchLaterIcon sx={classes.circularMess} style={{ fontSize: '4vh' }} />
                    <Typography sx={classes.roundTitle}>Tiempo</Typography>
                  </div>
                  <Typography sx={classes.roundLabel}>
                    {formatTime(clock)}
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={8} type="normal" isSelected={position % 22 === 8 ? true : false} iconType={game.distinctive} subtopic={game.board[8]} />
              </Grid>
              {/**4 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={20} type="normal" isSelected={position % 22 === 20 ? true : false} iconType={game.distinctive} subtopic={game.board[20]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} display={'flex'} alignContent={'center'} justifyContent={'center'}>
                <Box sx={{ height: 0 }}>
                  {
                    mode === 'private' && localUser.nickname === game.turn ?
                      (
                        <Button
                          style={{ background: MATH_GRADIENTS().default, fontSize: '2vh' }}
                          sx={classes.tirarButton}
                          startIcon={<PaidIcon style={{ fontSize: '3vh' }} />}
                          size='large'
                          onClick={() => {
                            roll();
                          }}
                          disabled={isRolling || phase === 'answering' ? true : false}>
                          {`TIRAR MONEDA (${game.players.find(player => player.nickname === localUser.nickname).coins})`}
                        </Button>
                      ) : mode === 'private' && localUser.nickname !== game.turn ?
                        (
                          <Button
                            style={{ background: MATH_GRADIENTS().disabled, fontSize: '2vh' }}
                            sx={classes.tirarButton}
                            startIcon={<DoNotDisturbIcon style={{ fontSize: '3vh' }} />}
                            size='large'>
                            ESPERANDO TURNO
                          </Button>
                        ) : mode === 'local' ? (
                          <Button
                            style={{ background: MATH_GRADIENTS().default, fontSize: '2vh' }}
                            sx={classes.tirarButton}
                            startIcon={<PaidIcon style={{ fontSize: '3vh' }} />}
                            size='large'
                            onClick={() => {
                              roll();
                            }}
                            disabled={phase === 'rolling' || phase === 'answering' ? true : false}>
                            {`TIRAR MONEDA`}
                          </Button>
                        ) : null
                  }
                </Box>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={7} type="normal" isSelected={position % 22 === 7 ? true : false} iconType={game.distinctive} subtopic={game.board[7]} />
              </Grid>
              {/**5 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={21} type="normal" isSelected={position % 22 === 21 ? true : false} iconType={game.distinctive} subtopic={game.board[21]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Box sx={{ height: 0 }}>
                  <Grid2 container spacing={'4vw'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    {
                      iconType.map((iconTypeElement, index) => (
                        <Grid2 key={index} size={1}>
                          <Box sx={{
                            mx: '1.2vh', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                          }}>
                            <Tooltip title={iconTypeElement.spanish_name} placement='top'>
                              <img src={iconTypeElement.path} style={{
                                width: '5vh', marginBottom: '1vh',
                                background: 'rgba(255, 255, 255, 0.6)', padding: '0.6vh', borderRadius: 6
                              }} alt={iconTypeElement.photo} />
                            </Tooltip>
                            <Typography sx={{ fontSize: '1.2vh', color: 'white' }}>{iconTypeElement.points} PTS.</Typography>
                          </Box>
                        </Grid2>
                      ))
                    }
                  </Grid2>
                </Box>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={6} type="normal" isSelected={position % 22 === 6 ? true : false} iconType={game.distinctive} subtopic={game.board[6]} />
              </Grid>
              {/**6 */}
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={0} type="RULETA-BOTTOM" isSelected={position % 22 === 0 ? true : false} iconType={game.distinctive} subtopic={game.board[0]} />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} sx={classes.centerTiles}>
                <Slot pos={1} type="normal" isSelected={position % 22 === 1 ? true : false} iconType={game.distinctive} subtopic={game.board[1]} />
                <Slot pos={2} type="normal" isSelected={position % 22 === 2 ? true : false} iconType={game.distinctive} subtopic={game.board[2]} />
                <Slot pos={3} type="normal" isSelected={position % 22 === 3 ? true : false} iconType={game.distinctive} subtopic={game.board[3]} />
                <Slot pos={4} type="normal" isSelected={position % 22 === 4 ? true : false} iconType={game.distinctive} subtopic={game.board[4]} />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Slot pos={5} type="EXCER. RANDOM" isSelected={position % 22 === 5 ? true : false} iconType={game.distinctive} subtopic={game.board[5]} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}

export default ArcadeBoard
