import { MATH_GRADIENTS } from '../../styles/MathGradients'

export const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperConfig: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  classicPaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS(135).classic
  },
  classicInfo: {
    width: '100%',
    background: theme.palette.classic.main,
    color: "#FFFFFF",
    '&:hover': {
      background: theme.palette.classic.dark
    }
  },
  ArcadePaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS(135).arcade
  },
  arcadeInfo: {
    width: '100%',
    background: theme.palette.arcade.main,
    color: "#FFFFFF",
    '&:hover': {
      background: theme.palette.arcade.dark
    }
  },
  RushPaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS(135).rush
  },
  DeathmatchPaper: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS(135).deathmatch
  },
  rushInfo: {
    width: '100%',
    background: theme.palette.rush.main,
    color: "#FFFFFF",
    '&:hover': {
      background: theme.palette.rush.dark
    }
  },
  disabled: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().disabled
  },
  grid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  box: {
    marginTop: theme.spacing(2),
  },
  playButton: {
    color: "#FFFFFF",
    background: MATH_GRADIENTS().default,
    textAlign: "center",
  },
  playButtonDisabled: {
    color: "#FFFFFF",
    background: MATH_GRADIENTS().disabled,
    textAlign: "center",
  },
  joinButton: {
    padding: theme.spacing(1),
    color: "#FFFFFF",
    background: MATH_GRADIENTS().event_tile,
    textAlign: "center"
  },
  pinInput: {
    width: '100%'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary
    }
  },
  circularJoin: {
    color: '#FFF'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'center',
  }
})