import React, { Fragment } from 'react'
import { Box, useTheme } from "@mui/material"
import { Route, Switch, Redirect } from 'react-router-dom'

/**Componentes */
import Login from '../pages/login/Login'

/**Elementos de navegación */
import Header from "../components/navigation/user/Header"
import NavMenu from "../components/navigation/user/NavMenu"

/**Hook para autenticación */
import useAuth from '../hooks/useAuth'

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
});

function LayoutUser(props) {
  const [open, setOpen] = React.useState(false)
  const { routes, match: { path } } = props
  const theme = useTheme();
  const classes = useStyles(theme);

  /**Menu lateral */
  const OpenAction = () => {
    setOpen(!open)
  }

  /**Si el usuario está logueado */
  const { user, isLoading } = useAuth()

  if (!user && !isLoading) {
    return (
      <Fragment>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Fragment>
    )
  }

  if (user && !isLoading) {
    return (
      <div style={classes.root}>
        <Header position="fixed" OpenAction={OpenAction} />
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <NavMenu
            variant="permanent"
            open={true} title={path}
            url={props.location.pathname} />
        </Box>
        <Box sx={{ display: { xd: 'block', sm: 'block', md: 'none' } }}>
          <NavMenu
            variant="temporary"
            open={open}
            onClose={OpenAction}
            url={props.location.pathname}
          />
        </Box>
        <div style={classes.content}>
          <div style={classes.toolbar}></div>
          <LoadRoute routes={routes} />
        </div>
      </div>
    )
  }

  return null
}

function LoadRoute({ routes }) {

  return (
    <Switch>
      {
        routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component} />
        ))
      }
    </Switch>
  )
}

export default LayoutUser
