import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth'
import PropTypes from 'prop-types'
import {
  AppBar, Dialog, IconButton, Toolbar, Typography, Slide,
  Box, useTheme, Grid2 as Grid, FormControlLabel, Checkbox, Button,
  Backdrop, CircularProgress, Container
} from '@mui/material';
import DefaultSnackbar from '../../common/DefaultSnackbar/DefaultSnackbar';

//Icons & Colors
import CloseIcon from '@mui/icons-material/Close';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { MATH_GRADIENTS } from '../../../styles/MathGradients';

//Api
import { rush_topics } from '../../../utils/SelectArrays'
import { createRushGameApi, getRushServerStatusApi } from '../../../api/rushGame';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RushConfigDialog(props) {
  const history = useHistory();
  const theme = useTheme();
  const { open = false, onClose = () => { }, mode } = props;

  const [selectedTopics, setSelectedTopics] = useState([]);
  const [topics] = useState(rush_topics);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const { user: localUser } = useAuth();
  const [isCreatingGame, setIsCreatingGame] = useState(false);

  //Efecto que guarda las materias seleccionadas
  const handleTopics = (e) => {
    const value = e.target.value;

    if (e.target.checked) {
      // Si está seleccionado, añadimos el valor al estado
      setSelectedTopics([...selectedTopics, value]);
    } else {
      // Si se desmarca, eliminamos el valor del estado
      setSelectedTopics(selectedTopics.filter((item) => item !== value));
    }
  }

  /**
   * Función para indicar el flujo de los ejercicios de las rondas
   * @param {*} selectedTopicImage 
   * @returns 
   */
  const mapTopicSelected = (selectedTopicImage) => {
    let tp = {};

    rush_topics.forEach(g => {
      tp[g.val] = () => <img alt={g.photo} src={g.path} style={{ width: 60 }} />
    });

    const SelectedTopicImageComponent = tp[selectedTopicImage] ? tp[selectedTopicImage]() : null;
    return SelectedTopicImageComponent;
  }

  //Funciòn para generar configuración de partida.
  const generateGame = () => {
    //Destructura los subtemas elegidos para generar la query
    const topicsQuery = selectedTopics;

    //Validaciones
    if (selectedTopics.length !== 4) {
      setMessage("Seleccione cuatro temas desde los filtros.");
      setOpenSnackbar(true);
      setIsCreatingGame(false);
      return;
    }

    //Incia carga
    setIsCreatingGame(true);

    //Genera la partida de los temas elejidos
    let config = {
      pin: null,
      host: localUser.nickname,
      topics: topicsQuery,
      players: [{
        nickname: localUser.nickname,
        avatar: localUser.avatar,
        number: 1,
        timeUp: false,
        level: 1,
        multiplier: 1,
        points: 0,
        excercises: 0,
        dropOff: false,
        result: 'none',
      }],
      status: 'game_generated',
      message: '',
    }

    if (mode === 'singleplayer') {
      createRushGameApi(config).then(response => {
        history.push(`/rush/singleplayer`, { game: response.game });
        setIsCreatingGame(false);
      }).catch(e => {
        setIsCreatingGame(false);
      });
    } else if (mode === 'private') {
      //Guardamos la partida en el servidor en la BD para registro
      getRushServerStatusApi().then(response => {
        if (response) {
          createRushGameApi(config).then(response => {
            //Redirige al lobby
            history.push(`/private-rush-lobby/${response.game.pin}`, { game: response.game });
            setIsCreatingGame(false);
          }).catch(e => {
            setIsCreatingGame(false);
          });
        } else {
          setIsCreatingGame(false);
          setOpenSnackbar(true);
          setMessage("Servidor no disponible, inténtalo de nuevo.");
        }
      }).catch(e => {
        setIsCreatingGame(false);
        setOpenSnackbar(true);
        setMessage("Servidor no disponible, inténtalo de nuevo.");
      })
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted>
      <Backdrop open={isCreatingGame} sx={{ zIndex: 5000 }} >
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <DefaultSnackbar open={openSnackbar} handleClose={() => setOpenSnackbar(false)} message={message} />
      <AppBar sx={{ position: 'relative', background: MATH_GRADIENTS().rush }}>
        <Toolbar
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close">
          <IconButton>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Crear partida
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: theme.spacing(3) }}>
        <Typography variant='h4' gutterBottom>Configuración del Modo Rush</Typography>
        <Container sx={{ mt: theme.spacing(4) }}>
          <Button variant='contained'
            fullWidth style={{ background: MATH_GRADIENTS().rush, color: 'white' }} startIcon={<SportsEsportsIcon />}
            onClick={() => { generateGame() }}>
            Generar partida
          </Button>
          <Box sx={{ mt: theme.spacing(4) }}>
            <Typography variant='h6'>Temas</Typography>
            <Typography color='textSecondary'>Selecciona 4 temas para la ronda de ejercicios</Typography>
            <Grid container spacing={2} sx={{ mt: theme.spacing(4) }}>
              {
                topics.map((topic, index) =>
                (
                  <Grid
                    size={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 6 }}
                    key={index}
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={topic.path} alt={`${topic.val}.svg`} style={{ width: 50 }} />
                    <FormControlLabel
                      labelPlacement="bottom"
                      sx={{ textAlign: 'center ' }}
                      control={
                        <Checkbox color='rush' onChange={handleTopics}
                          value={topic.val} />
                      }
                      label={topic.spanish_name} />
                  </Grid>
                )
                )
              }
            </Grid>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mt: theme.spacing(4)
          }}>
            <Grid container spacing={2}>
              {
                selectedTopics.map((st, index) => (
                  <Grid key={index}>
                    {mapTopicSelected(st)}
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Container>
      </Box>
    </Dialog>
  )
}

RushConfigDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default RushConfigDialog