const useStyles = (groupColor, theme) => ({
  root: {
    '& h5': {
      color: groupColor
    }
  },
  paper: {
    padding: theme.spacing(2),
    mb: theme.spacing(2)
  },
  btn: {
    background: groupColor,
    color: 'white',
    '&:hover': {
      background: groupColor
    }
  },
  link: {
    color: groupColor,
    '&:hover': {
      textDecoration: 'none',
      color: groupColor
    }
  }
});

export default useStyles;