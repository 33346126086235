import React, { useState, useEffect, Fragment } from 'react'
import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button, Grid, Typography, TextField,
  Box, LinearProgress, List, ListItem, ListItemText, ListItemAvatar,
  Paper, IconButton, ListItemSecondaryAction, CircularProgress,
  useTheme,
  ListItemButton
} from '@mui/material'
import PropTypes from 'prop-types';
import { useStyles } from './useStyles';

//Icons
import CloseIcon from '@mui/icons-material/Close';

//Api
import { searchUsersApi } from '../../api/user';
import { createRequestApi } from '../../api/requests';

//Components
import DefaultAvatar from '../user/DefaultAvatar/DefaultAvatar';
import DefaultSnackbar from '../common/DefaultSnackbar/DefaultSnackbar';

function AddGroupMember(props) {
  const { open, onClose, group } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSending, setIsSending] = useState(false);

  //Estados de snack
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    //Inicia carga
    setIsLoading(true);

    if (query.trim().length > 0) {
      searchUsersApi(query).then(response => {
        if (response.status === 1) {
          setUsers(response.users);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
    } else {
      setUsers([]);
      setIsLoading(false);
    }
  }, [query])

  //Función para añadir usuario
  const addItem = (student) => {
    if (!selectedUsers.find(sus => sus.nickname === student.nickname)) {
      setSelectedUsers(su => [...su, student]);
    }
  }

  //Función para remover usuario
  const removeItem = (studentIndex) => {
    setSelectedUsers(selectedUsers => selectedUsers.filter((_, index) => index !== studentIndex));
  }

  //Función para enviar solicitudes
  const sendRequest = () => {
    if (selectedUsers.length === 0) {
      setMessage("Seleccione uno o más estudiantes.");
      setOpenSnack(true);
      return;
    }

    //Inicia carga
    setIsSending(true);

    //Recorre los usuarios seleccionados
    selectedUsers.forEach(element => {
      const request = {
        userRequester: group.creator._id,
        userRequested: element._id,
        group: group._id,
        type: 'group',
        status: 'requested'
      };

      //Manda la solicitud
      createRequestApi(request).then(response => {
        if (response.status === 1) {
          setMessage(`Solicitudes enviadas`);
          setOpenSnack(true);

          //Para carga
          setIsSending(false);
          onClose();
        } else {
          //Para carga
          setIsSending(false);
          onClose();
        }
      })
    });
  }

  return (
    <div>
      <DefaultSnackbar
        open={openSnack}
        handleClose={() => setOpenSnack(false)}
        message={message} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        keepMounted={false}>
        <DialogTitle>Invitar miembro</DialogTitle>
        <DialogContent>
          <TextField
            variant='outlined'
            label='Buscar estudiante'
            size='small'
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)} />
          <Box sx={classes.box}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Paper sx={{ p: theme.spacing(2) }} elevation={3}>
                  <Typography gutterBottom>Resultados</Typography>
                  {
                    isLoading ?
                      <LinearProgress variant='indeterminate' /> :
                      <List>
                        {
                          users.length > 0 && query.trim().length > 0 ?
                            <Fragment>
                              {
                                users.map((value, index) => {
                                  if (value.role === 'student') {
                                    return (
                                      <ListItem
                                        dense
                                        disableGutters
                                        disabled={selectedUsers.some(elem => (JSON.stringify(value) === JSON.stringify(elem))) ? true : false}
                                        key={index}
                                        onClick={() => addItem(value)}>
                                        <ListItemButton>
                                          <ListItemAvatar>
                                            <DefaultAvatar nickname={value.nickname} />
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary={`${value.name} ${value.lastname}`}
                                            secondary={`@${value.nickname}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                              }
                            </Fragment> :
                            <Typography>
                              No se encontraron estudiantes
                            </Typography>
                        }
                      </List>
                  }
                </Paper>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Paper sx={{ p: theme.spacing(2) }} elevation={3}>
                  <Typography gutterBottom>Miembros seleccionados</Typography>
                  <List>
                    {
                      selectedUsers.map((value, index) => (
                        <ListItem
                          dense
                          key={index}>
                          <ListItemAvatar>
                            <DefaultAvatar nickname={value.nickname} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${value.name} ${value.lastname}`}
                            secondary={`@${value.nickname}`} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge='end'
                              onClick={() => removeItem(index)}>
                              <CloseIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    }
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {
            isSending ?
              <CircularProgress variant='indeterminate' /> :
              <Fragment>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={sendRequest}>
                  Añadir miembros
                </Button>
                <Button
                  variant='contained'
                  color='default'
                  onClick={onClose}>
                  Cancelar
                </Button>
              </Fragment>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

AddGroupMember.propTypes = {
  group: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reload: PropTypes.func,
  showConfirmation: PropTypes.func,
  children: PropTypes.element
}

export default AddGroupMember