import React, { useEffect } from 'react'
import { Box, Paper, Typography, useTheme } from '@mui/material'
import { player_colors } from '../../../utils/SelectArrays';
import { animated, useSpring } from '@react-spring/web'
import MathAvatar from '../../user/MathAvatar/MathAvatar';

function RushCompactedPlayerCard(props) {
  const { color, player } = props;
  const theme = useTheme();

  const [paperSpring, paperSpringApi] = useSpring(() => ({
    brightness: 1
  }))

  useEffect(() => {
    if (player.timeUp) {
      paperSpringApi.start({
        brightness: 0.25
      })
    }
  }, [player, paperSpringApi])

  const colors = () => {
    let allColors = {};
    player_colors.forEach(pc => {
      allColors[pc.name] = pc.val
    })
    return allColors;
  }

  return (
    <animated.div style={{ filter: paperSpring.brightness.to(b => `brightness(${b * 100}%)`) }}>
      <Paper sx={{
        width: '100%',
        p: theme.spacing(1),
        backgroundColor: colors()[color],
        color: 'white',
      }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center'
        }}>
          <MathAvatar avatar={player.avatar} size={'4vh'} />
          <Box ml={theme.spacing(1)}>
            <Typography fontSize={'1.5vh'} fontWeight={'bold'}>{player.nickname}</Typography>
            <Typography fontSize={'1.5vh'}>Puntos: {player.points.toLocaleString("en-US")}</Typography>
          </Box>
        </Box>
      </Paper>
    </animated.div>
  )
}

export default RushCompactedPlayerCard