import React, { useState, useEffect, useRef, Fragment } from 'react'
import { io } from 'socket.io-client'
import { useHistory, useLocation } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import { Howl } from 'howler'

//Componentes
import RushUI from '../../../../../components/game_elements/RushUI/RushUI'

//API
import { deleteRushGameApi, getRushGameByPinApi, updateRushGameApi } from '../../../../../api/rushGame';
import { wsPrivateRushServerBasePath } from '../../../../../config/api';
import { excerciseGenerator } from '../../../../../utils/rush/excerciseGenerator'
import RushResults from '../../../../../components/game_elements/results/RushResults/RushResults'
import { Backdrop, CircularProgress, Typography } from '@mui/material'
import { updateUserRushStatsApi } from '../../../../../api/user'
import Menu from '../../../../../components/game_elements/Menu/Menu'

function PrivateRushGame(props) {
  const { match: { params: { pin } } } = props;
  const history = useHistory();
  const location = useLocation();
  const staticGame = location.state?.game; //Juego enviado desde el Lobby (No Cambia)
  let socket = useRef(null); //Manejador del Socket
  const { user: localUser } = useAuth(); //Usuario local
  const [game, setGame] = useState(null); //Juego del socket

  //Estados para mostrar los ejercicios y respuestas
  const [excerciseToShow, setExcerciseToShow] = useState("");
  const [calculatedAnswer, setCalculatedAnswer] = useState(0);
  const [streak, setStreak] = useState(0); //racha de ejercicios correctos
  const [errorStreak, setErrorStreak] = useState(0); //Racha de errores para eliminar racha
  const [isCorrect, setIsCorrect] = useState(null); //Estado para manejar errores
  const [pointsToEarn, setPointsToEarn] = useState(0); //Estado para indicar cuantos puntos va aganar
  const [secondsToEarn, setSecondsToEarn] = useState(0); //estado para indicar cuantos segundos va a ganaer
  const [sendStats, setSendStats] = useState(false); //estado bandera para enviar datos al socket

  //Estados para el juego local, que serán subidos al socket cuando se responda correctamente
  const [topic, setTopic] = useState(staticGame.topics[0]); //Hook para el tema actual que se usará
  const [points, setPoints] = useState(0); //Puntos del jugador
  const [level, setLevel] = useState(1); //Nivel del jugador
  const [excercises, setExcercises] = useState(0); //Ejercicios del jugador
  const [multiplier, setMultiplier] = useState(1); //Multiplicador del jugador
  const [combo, setCombo] = useState(0); //Estado para controlar los combos, combos = streak;

  //Hooks para manejar el tiempo
  const [isClockRunning, setIsClockRunning] = useState(false); //Interruptor para el reloj
  const [isClockStreakRunning, setIsClockStreakRunning] = useState(false);
  const [seconds, setSeconds] = useState(60); //Reloj
  const [streakSeconds, setStreakSeconds] = useState(5); //Segundos para conservar la racha

  //Hooks para las cargas
  const [initialCountdown, setInitialCountdown] = useState(3);
  const [openCountdown, setOpenCountdown] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true); //Carga del juego de la BD
  const [isSavingHistory, setIsSavingHistory] = useState(false); //Indicación de guardado de resultados

  //Musica y sonidos
  const [sounds, setSounds] = useState(null);
  useEffect(() => {
    document.title = 'Modo Rush - Math Paradise'

    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'boom', url: '/sounds/games/rush/boom.mp3' },
      { name: 'combo', url: '/sounds/games/rush/combo.mp3' },
      { name: 'level_up', url: '/sounds/games/rush/level_up.wav' },
      { name: 'ticktock', url: '/sounds/games/rush/ticktock.mp3' },
      { name: 'game_over', url: '/sounds/games/general/game_over.mp3' },
      { name: 'correct', url: '/sounds/ui/correct.mp3' },
      { name: 'incorrect', url: '/sounds/ui/incorrect.mp3' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.3,
        loop: name === 'ticktock' ? true : false
      });

      soundInstances[name] = sound;
      soundInstances['boom'].play();
      setSounds(soundInstances);
    });

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, []);


  useEffect(() => {
    //Carga el juego de la BD la primera vez
    getRushGameByPinApi(pin).then(response => {
      //Si no se encontró partida en la BD
      if (response.status === 0) {
        history.push('/home/play');
      } else {
        const g = response.game;
        //Si el juego fue concluído, redirige al menú
        if (g.status === 'finished' || g.status === 'in_lobby') {
          history.push('/home/play');
        }
      }
    }).catch(e => {
      history.push('/home/play');
    })

    //Guardamos el staticGame del localStorage si es que se recarga la página
    if (!staticGame) {
      setGame(localStorage.getItem("game"));
    }

    //Conexión al WebSocker
    socket.current = io(`${wsPrivateRushServerBasePath}/${pin}`, {
      transports: ["websocket"],
      withCredentials: true
    });

    //empieza el juego con los invitados, enviando el juego estático por primera vez.
    //Solo el host envía el juego base para todos
    if (staticGame /* && localUser.nickname === staticGame.host */) {
      socket.current.emit('game', staticGame);
    }

    //Asigna el mensaje del socket al hook del juego
    socket.current.on('game', (data) => {
      localStorage.setItem("game", JSON.stringify(data));
      setIsLoading(false); //Detiene la carga
      setGame(data); //Guarda el mensaje del socket
    });

    return () => {
      socket.current.disconnect();
      socket.current.off('game');
    }
  }, [pin, history, staticGame])

  //Efecto que inicia cuenta regresiva del inicio
  useEffect(() => {
    if (!isLoading) {
      if (initialCountdown > 0) {
        const timerIdStreak = setInterval(() => {
          setInitialCountdown(previnit => previnit - 1); // Reduce en 1 cada segundo
          sounds['boom'].play();
        }, 600);

        return () => clearInterval(timerIdStreak); // Limpia el intervalo al desmontar el componente
      } else {
        setTimeout(() => {
          setOpenCountdown(false); //Cerramos cuenta regresiva
          setIsClockRunning(true); //Iniciamos reloj general de la partida
        }, 600);
      }
    }
  }, [initialCountdown, isLoading, sounds]);

  //Efecto para iniciar el reloj general y controlar cuando termina el juego
  useEffect(() => {
    if (isClockRunning) {
      if (seconds > 0) {
        const timerId = setInterval(() => {
          setSeconds(prevSeconds => prevSeconds - 1); // Reduce en 1 cada segundo
        }, 1000);

        return () => clearInterval(timerId); // Limpia el intervalo al desmontar el componente
      } else {
        setIsClockRunning(false); //Paramos el reloj si el reloj se detuvo
        setOpenResults(true); //Abrimos panel de resultados
        setOpenMenu(false); //Cerramos menú
        sounds['ticktock'].stop();
        sounds['game_over'].play();

        //Indicamos al servidor que el jugador perdió
        const updateGame = game;
        const players = updateGame.players;
        let playerToUpdate = players.find(player => player.nickname === localUser.nickname);

        playerToUpdate.timeUp = true;

        const updatedPlayers = players.map(player => player.nickname === localUser.nickname ? playerToUpdate : player);
        updateGame.players = updatedPlayers;

        sendMessageToSocket(updateGame);
      }
    }
  }, [isClockRunning, seconds, game, localUser, sounds]);

  //Efecto para iniciar o detener el reloj de la racha
  useEffect(() => {
    if (isClockStreakRunning) {
      if (streakSeconds > 0) {
        const timerIdStreak = setInterval(() => {
          setStreakSeconds(prevSeconds => prevSeconds - 1); // Reduce en 1 cada segundo
        }, 1000);

        return () => clearInterval(timerIdStreak); // Limpia el intervalo al desmontar el componente
      } else {
        setIsClockStreakRunning(false); //Paramos el reloj de la racha 
        setErrorStreak(0); //Reiniciamos streak de errores
        setStreak(0); //detenemos la racha el streak
        setCombo(0); //Limpiamos el combo
      }
    }
  }, [streakSeconds, isClockStreakRunning]);

  //Efecto que elimina la racha después de 3 errores
  useEffect(() => {
    if (errorStreak === 3) {
      setIsClockStreakRunning(false); //Paramos reloj de racha
      setErrorStreak(0); //limpiamos error streak
      setStreak(0); // limpiamos racha
      setCombo(0); //Limpiamos el combo
    }
  }, [errorStreak]);

  //Efecto que rota los temas y genera ejercicios
  useEffect(() => {
    const { exc, ans } = excerciseGenerator(topic, level);
    setExcerciseToShow(exc);
    setCalculatedAnswer(ans);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  //Efecto que limita el tiempo a 60
  useEffect(() => {
    if (seconds > 60)
      setSeconds(60);
  }, [seconds])

  //Efecto para reproducir el sonido de combo
  useEffect(() => {
    if (streak === 40) {
      sounds['combo'].rate(1);
      sounds['combo'].play();
    } else if (streak === 60) {
      sounds['combo'].rate(1.1);
      sounds['combo'].play();
    } else if (streak === 80) {
      sounds['combo'].rate(1.3);
      sounds['combo'].play();
    } else if (streak === 100) {
      sounds['combo'].rate(1.5);
      sounds['combo'].play();
    }
  }, [streak, combo, sounds])

  //Efectos para sonidos
  useEffect(() => {
    if (sounds) {
      if (seconds === 9) {
        sounds['ticktock'].pause();
        sounds['ticktock'].play();
      } else if (seconds >= 10) {
        sounds['ticktock'].stop();
      } else if (seconds === 0) {
        sounds['ticktock'].stop();
      }
    }
  }, [seconds, sounds]);

  //Efecto que manda las updates al socket cada vez que se cambia a verdadero el indicador de envío
  useEffect(() => {
    if (sendStats) {
      //Manda las puntuaciones al mensaje
      const updateGame = game;
      const players = updateGame.players;
      let playerToUpdate = players.find(player => player.nickname === localUser.nickname);

      playerToUpdate.level = level;
      playerToUpdate.multiplier = multiplier;
      playerToUpdate.points = points;
      playerToUpdate.excercises = excercises;

      const updatedPlayers = players.map(player => player.nickname === localUser.nickname ? playerToUpdate : player);
      updateGame.players = updatedPlayers;

      sendMessageToSocket(updateGame);
    }
  }, [sendStats, excercises, game, level, localUser, multiplier, points, pointsToEarn]);

  /**
   * Función para manejar la respuesta ingresada por el jugador
   * @param {*} answer 
   */
  const handleAnswer = (answer) => {
    let ptsToEarn = 5;
    let scndsToEarn = 10;

    //Evaluamos la respuesta
    if (Number(answer) === calculatedAnswer) {
      setIsCorrect(true); //Se indica que fue correcta
      setErrorStreak(0); //Limpiamos racha de errores
      setCombo(prevCombo => prevCombo += 1); //Aumentamos el combo, que se mostrará cuando llegue a 2
      sounds['correct'].play();

      //Aumentamos la racha
      if (streak === 100) { //Aumentamos el multiplicador si llega a 100
        setStreak(100);
        setMultiplier(prevMulti => prevMulti += 1);
      } else {
        setStreak(prevStreak => prevStreak += 20);
      }

      setStreakSeconds(5); //Reiniciamos los segundos
      setIsClockStreakRunning(true); //Iniciamos el reloj de racha

      setExcercises(prevExc => prevExc += 1); //Aumentamos los ejercicios
      setPointsToEarn(ptsToEarn * (multiplier)); //Indicamos cuantos puntos vamos a ganar

      setPoints(prevPoints => prevPoints += (ptsToEarn * (multiplier))); //Sumamos los puntos por el multiplicador

      //Aumentamos tema
      let topicIndex = game.topics.findIndex(t => t === topic);
      if (topicIndex === game.topics.length - 1) { //Si llegó al ultimo tema, reiniciamos ronda, aumentamos nivel y los segundos al reloj
        topicIndex = 0;
        setLevel(prevLevel => prevLevel += 1);

        //Aumentamos los segundos de acuerdo al nivel
        if (level <= 5) scndsToEarn = 5;
        else if (level <= 10) scndsToEarn = 10;
        else if (level <= 15) scndsToEarn = 20;
        else if (level <= 20) scndsToEarn = 25;
        else if (level <= 25) scndsToEarn = 30;
        else if (level <= 30) scndsToEarn = 30;
        else if (level <= 40) scndsToEarn = 45;
        else if (level <= 50) scndsToEarn = 60;
        else scndsToEarn = 60;

        setSecondsToEarn(scndsToEarn);
        setSeconds(prevSeconds => prevSeconds += scndsToEarn); //Asignamos segundos
        sounds['level_up'].play();
      }
      else {
        topicIndex += 1;
      }

      setTopic(game.topics[topicIndex]); //Cambiamos el tema
      setSendStats(true); //Enviamos los datos al scoket para visualizacion
    } else {
      setIsCorrect(false); //Indicamos que está incorrecto
      setErrorStreak(prevErs => prevErs += 1); // Aumentamos racha de errores
      sounds['incorrect'].play();
    }
  }

  /**
   * Función para enviar los datos al servidor cuando se acaba la partida
   * @param {*} position
   */
  const onEndGame = (position) => {
    const players = game.players;

    // Función que clasifica los resultados cada vez que alguien guarda la partida en el servidor
    function classifyPlayers() {
      // Encuentra la puntuación más alta
      const maxPoints = Math.max(...players.map(player => player.points));

      // Filtra a los jugadores con la puntuación más alta
      const ganadores = players.filter(player => player.points === maxPoints);

      // Clasifica los resultados de todos los jugadores
      return players.map(player => {
        if (player.points === maxPoints) {
          // Si hay más de un jugador con el puntaje más alto, es empate
          return ganadores.length > 1
            ? { ...player, result: 'draw' }
            : { ...player, result: 'victory' }; // Si es único, es victoria
        } else {
          return { ...player, result: 'defeat' }; // Los demás pierden
        }
      });
    }

    //Armamos estadísticas para ver si hay nuevos records
    const records = {
      points: points,
      excercises: excercises,
      multiplier: multiplier,
      level: level
    }

    //Inciamos carga
    setIsSavingHistory(true);

    //Guardamos los records para que se sobrescriban
    updateUserRushStatsApi(localUser.nickname, records).then(response => {
      updateRushGameApi({ ...game, players: classifyPlayers(), status: 'finished' }, pin).then(response => {
        disconnectEverything();
      }).catch(e => {
        disconnectEverything();
      })
    }).catch(e => {
      disconnectEverything();
    })
  }

  /**
   * Función para salirse del jeugo sin guardar historial
   */
  const onExitGame = () => {
    const updateGame = game;
    const players = updateGame.players;
    let playerToUpdate = players.find(player => player.nickname === localUser.nickname);

    playerToUpdate.timeUp = true;

    const updatedPlayers = players.map(player => player.nickname === localUser.nickname ? playerToUpdate : player);
    updateGame.players = updatedPlayers;

    // Verificamos si solo queda un jugador activo
    const activePlayers = players.filter(player => !player.timeUp);

    if (activePlayers.length > 0) {
      sendMessageToSocket(updateGame);
    } else {
      //Cancelamos o eliminamos el juego desde que queda un jugador
      deleteRushGameApi(pin).then(response => {

      }).catch(e => {

      })
    }

    disconnectEverything(); //Desconectamos todo
  }

  /**
   * Función para enviar mensajes generales al socket
   * @param {*} game 
   */
  const sendMessageToSocket = (gameToSend) => {
    socket.current.emit('game', gameToSend);
    setSendStats(false);
  }

  /**
   * Función para desconectar todo y regresar al menú
   */
  const disconnectEverything = () => {
    localStorage.removeItem("game");
    socket.current.off('game');
    socket.current.disconnect();
    history.push('/home/play/rush');
  }

  // Bloquear la recarga de página
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Prevenir la recarga y abrir el diálogo
      event.preventDefault();
      setOpenMenu(true); // Activa el diálogo sin recargar
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Bloquear el botón de "Atrás"
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        setOpenMenu(true); // Abre el Menu antes de navegar hacia atrás
        return false;
      }
    });

    return () => unblock();
  }, [history]);

  return (
    <Fragment>
      <Backdrop open={isSavingHistory} sx={{ zIndex: 3000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <Menu
        open={openMenu}
        handleClose={() => setOpenMenu(false)}
        onExit={onExitGame}
        turn={localUser ? localUser.nickname : ''}
        localUser={localUser} />
      <Backdrop open={openCountdown} sx={{ zIndex: 2000 }}>
        <Typography sx={{ color: 'white' }} variant='h1' textAlign={'center'}>{initialCountdown === 0 ? '¡A Jugar!' : initialCountdown}</Typography>
      </Backdrop>
      <RushResults
        open={openResults}
        game={game}
        seconds={seconds}
        localUser={localUser}
        endGame={onEndGame} />
      <RushUI
        game={game}
        topic={topic}
        points={points}
        level={level}
        excercises={excercises}
        multiplier={multiplier}
        excercise={excerciseToShow}
        seconds={seconds}
        streak={streak}
        streakSeconds={streakSeconds}
        combo={combo}
        isCorrect={isCorrect}
        pointsToEarn={pointsToEarn}
        secondsToEarn={secondsToEarn}
        isLoading={isLoading}
        handleAnswer={handleAnswer}
        handleOpenMenu={() => setOpenMenu(true)} />
    </Fragment>
  )
}

export default PrivateRushGame