import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'

/**Componentes */
import PublicHeader from '../components/navigation/public/PublicHeader/PublicHeader'
import Footer from '../components/navigation/public/Footer/Footer'


function PrivacyPolicies() {
  const theme = useTheme();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <Box flex={1}>
        <PublicHeader />
        <Container maxWidth="xl" sx={{ mt: theme.spacing(6) }}>
          <Typography variant="h4" color='primary' gutterBottom>Políticas de privacidad</Typography>
          <Typography>
            En Math Paradise, tu privacidad es nuestra prioridad. Por ello, nos comprometemos a proteger y manejar de manera
            responsable los datos personales que recabamos, en estricto apego a las leyes aplicables en México,
            como la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP),
            y con base en estándares internacionales. A continuación, detallamos cómo recolectamos, usamos
            y protegemos tu información.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Responsable del Tratamiento de Datos Personales</Typography>
          <Typography>
            Math Paradise (en adelante "la Plataforma") es responsable del tratamiento de tus datos personales y puede ser
            contactada a través del siguiente correo: <a href='mailto:mathparadise.juega@gmail.com'>mathparadise.juega@gmail.com</a>.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Datos Personales que Recabamos</Typography>
          <Typography>
            Para el correcto funcionamiento de la Plataforma, recabamos la siguiente información:
          </Typography>
          <br />
          <Typography gutterBottom>Obligatorios</Typography>
          <ul>
            <li><Typography>Nombre y apellido(s).</Typography></li>
            <li><Typography>Correo electrónico.</Typography></li>
            <li><Typography>Nombre de usuario.</Typography></li>
          </ul>
          <Typography gutterBottom>Opcionales</Typography>
          <ul>
            <li><Typography>Escuela, universidad, colegio o institución educativa.</Typography></li>
            <li><Typography>Grado escolar.</Typography></li>
            <li><Typography>Descripción personal (proporcionada voluntariamente por el usuario).</Typography></li>
          </ul>
          <br />
          <Typography variant="h6" gutterBottom>Finalidades del Tratamiento de Datos Personales</Typography>
          <Typography>
            Los datos recolectados serán utilizados para las siguientes finalidades:
          </Typography>
          <br />
          <Typography gutterBottom>Primarias (esenciales para el servicio):</Typography>
          <ul>
            <li><Typography>Creación y gestión de cuentas de usuario en la Plataforma.</Typography></li>
            <li><Typography>Personalización de la experiencia de usuario en Math Paradise.</Typography></li>
            <li><Typography>Comunicación con los usuarios sobre actualizaciones, notificaciones y cambios en los servicios.</Typography></li>
          </ul>
          <Typography gutterBottom>Secundarias (opcional):</Typography>
          <ul>
            <li><Typography>Generación de estadísticas internas para mejorar la Plataforma.</Typography></li>
            <li><Typography>Promoción de actividades educativas y programas relacionados.</Typography></li>
            <li><Typography>Descripción personal (proporcionada voluntariamente por el usuario).</Typography></li>
          </ul>
          <Typography>
            Si no deseas que tus datos sean utilizados para finalidades secundarias,
            puedes manifestarlo enviando un correo a <a href='mailto:mathparadise.juega@gmail.com'>mathparadise.juega@gmail.com</a>.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Protección de los Datos Personales</Typography>
          <Typography>
            Math Paradise implementa medidas de seguridad técnicas, administrativas y
            físicas para proteger tus datos contra pérdida, uso indebido, acceso no autorizado, alteración o destrucción.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)</Typography>
          <Typography>
            Como titular de tus datos personales, puedes ejercer en cualquier momento tus derechos ARCO:
          </Typography>
          <br />
          <ul>
            <li><Typography>Acceso: Solicitar información sobre tus datos en nuestra posesión.</Typography></li>
            <li><Typography>Rectificación: Corregir tus datos personales cuando sean inexactos o incompletos.</Typography></li>
            <li><Typography>Cancelación: Solicitar que eliminemos tus datos personales cuando así lo desees,
              salvo que existan obligaciones legales que requieran su conservación.</Typography></li>
            <li><Typography>Oposición: Rechazar el tratamiento de tus datos para fines específicos.</Typography></li>
          </ul>
          <Typography>
            Para ejercer tus derechos, envía una solicitud al correo <a href='mailto:mathparadise.juega@gmail.com'>mathparadise.juega@gmail.com</a>, indicando tu nombre completo,
            la descripción clara del derecho que deseas ejercer y una copia de un documento oficial de identificación.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Uso de Cookies y Tecnologías Similares</Typography>
          <Typography>
            La Plataforma utiliza cookies y tecnologías similares para mejorar la experiencia del usuario. Puedes desactivar las cookies en la configuración de tu navegador;
            sin embargo, esto podría limitar ciertas funcionalidades de Math Paradise.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Transferencias de Datos Personales</Typography>
          <Typography>
            Math Paradise no compartirá tus datos personales con terceros sin tu consentimiento expreso,
            salvo en los casos requeridos por ley o autoridad competente.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Modificaciones a este Aviso de Privacidad</Typography>
          <Typography>
            Nos reservamos el derecho de actualizar este Aviso de Privacidad en cualquier momento.
            Cualquier cambio será notificado a través de la Plataforma, por correo electrónico o
            por nuestras redes sociales. Te recomendamos revisarlo periódicamente.
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>Contacto</Typography>
          <Typography>
            Para dudas o aclaraciones sobre este Aviso de Privacidad, contáctanos en <a href='mailto:mathparadise.juega@gmail.com'>mathparadise.juega@gmail.com</a>.
          </Typography>
          <Typography>
            Fecha de última actualización: <strong>26 de noviembre del 2024</strong>.
          </Typography>
        </Container>
      </Box>
      <Footer />
    </div>
  )
}

export default PrivacyPolicies
