import React from 'react'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material';

const useStyles = (theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottom: {
    color: 'rgba(255, 255, 255, 0.4)'
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
});

function CircularMultiplier(props) {
  const { value, size, multiplier } = props
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div style={classes.root}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#51c26f" />
            <stop offset="50%" stopColor="#dce956" />
            <stop offset="100%" stopColor="#e98fc0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        variant="determinate"
        sx={classes.bottom}
        size={size}
        thickness={4}
        value={100}
      />
      <Typography sx={{ position: 'absolute', color: 'white', textAlign: 'center', fontSize: '110%' }} >
        &times;{multiplier}
      </Typography>
      <CircularProgress
        variant="determinate"
        sx={{ ...classes.top, 'svg circle': { stroke: 'url(#my_gradient)' } }}
        size={size}
        thickness={4}
        value={value}
      />
    </div>
  )
}

export default CircularMultiplier
