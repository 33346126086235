import React, { useState, useEffect, Fragment } from 'react'
import {
  AppBar, Box, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, Toolbar, Typography, useTheme,
  useMediaQuery, Grid2 as Grid,
  Button,
  CircularProgress, Slide
} from '@mui/material'
import { Howl } from 'howler'
import { MATH_GRADIENTS } from '../../../styles/MathGradients'
import { BlockMath, InlineMath } from 'react-katex';
import { formatTime } from '../../../utils/TimeFormat';

//Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CalculateIcon from '@mui/icons-material/Calculate';
import CancelIcon from '@mui/icons-material/Cancel';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import StarIcon from '@mui/icons-material/Star';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ExcercisePanelClassic(props) {
  const {
    open = false,
    handleClose = () => { },
    excercise = {
      _id: "",
      label: "\\frac{s}{t}+u+v+w+x+y+z=0",
      option_a: "x+y+z=a",
      option_b: "x+y+z=b",
      option_c: "x+y+z=c",
      option_d: "x+y+z=d",
      answer: "x+y+z=a",
      area: "Area",
      topic: "Tema",
      subtopic: "Subtema",
      difficulty: "normal",
      create_date: new Date(),
      active: true
    },
    time = 10,
    isChallenge = false,
    isRandom = false,
    sendExcerciseResults = () => null,
  } = props;

  const theme = useTheme();
  const [options, setOptions] = useState(['a', 'b', 'c', 'd']);
  const [seconds, setSeconds] = useState(time);
  const [selectedAnswer, setSelectedAnswer] = useState(""); //Variable para guardar la respuesta seleccionada
  const [hasAnswered, setHasAnswered] = useState(false); //Variable para indicar si conestó o no
  const [isCorrect, setIsCorrect] = useState(false); //Variable para indicar si fue correcto o no lo respondido
  const [earnedPoints, setEarnedPoints] = useState(0); //Variable para indicar cuántos puntos se ganó o se ganaría
  const [isClockRunning, setIsClockRunning] = useState(false); //Variable para detener o iniciar el reloj
  const [timeDifference, setTimeDifference] = useState(0); //Variable para saber en cuánto tiempo contestó el ejercicio
  const [hasGivenUp, setHasGivenUp] = useState(false); //Variable para detectar si se ha rendido
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg')); //Variable para detectar la pantalla

  //Musica y sonidos
  const [sounds, setSounds] = useState({});

  useEffect(() => {
    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'question', url: '/sounds/ui/question.mp3' },
      { name: 'correct', url: '/sounds/ui/correct.mp3' },
      { name: 'incorrect', url: '/sounds/ui/incorrect.mp3' },
      { name: 'give_up', url: '/sounds/ui/give_up.mp3' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  //Effecto para shuffle las opciones
  useEffect(() => {
    const optsToShuffle = [excercise.option_a, excercise.option_b, excercise.option_c, excercise.option_d]

    const shuffled = [...optsToShuffle];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    setOptions(shuffled);
  }, [excercise]);

  //Efecto para iniciar el reloj
  useEffect(() => {
    if (isClockRunning) {
      if (seconds > 0) {
        const timerId = setInterval(() => {
          setSeconds(prevSeconds => prevSeconds - 1); // Reduce en 1 cada segundo
        }, 1000);

        return () => clearInterval(timerId); // Limpia el intervalo al desmontar el componente
      } else {
        setIsClockRunning(false); //Paramos el reloj si el reloj se detuvo
        setHasAnswered(true); //Ha contestado aunque el tiempo se haya acabado
        setIsCorrect(false); //No fue correcto
        setEarnedPoints(0); //Asignamos puntos
      }
    }
  }, [seconds, isClockRunning]);

  /**
   * Función para iniciar el reloj
   */
  const startClock = () => {
    sounds['question'].play();
    setSeconds(time);
    setIsClockRunning(true);
    setEarnedPoints(0);
    setHasAnswered(false);
    setSelectedAnswer("");
    setHasGivenUp(false);
  }

  /**
   * Función que verificar la respuesta electa
   */
  const checkAnswer = () => {
    setHasAnswered(true);
    setIsClockRunning(false);

    if (selectedAnswer !== excercise.answer) {
      sounds['incorrect'].play();
      setIsCorrect(false);
      if (isChallenge) {
        setEarnedPoints(-100);
      }
    } else {
      sounds['correct'].play();
      setIsCorrect(true); //La respuesta coincide
      //Verificamos bonus de tiempo para asignar puntos
      const diffTime = time - seconds;
      setTimeDifference(diffTime);
      if (diffTime < 15) {
        setEarnedPoints(1000);
      } else if (diffTime < 25) {
        setEarnedPoints(500);
      } else {
        setEarnedPoints(200);
      }
    }
  }

  /**
   * Función para rendirse
   */
  const handleGiveUp = () => {
    sounds['give_up'].play();
    setIsCorrect(false);
    setHasAnswered(true);
    setIsClockRunning(false);
    setHasGivenUp(true);
    if (isChallenge) {
      setEarnedPoints(-100);
    } else {
      setEarnedPoints(0);
    }
  }

  /**
   * Función para enviar los resultados
   */
  const handleAccept = () => {
    const results = {
      isCorrect: isCorrect,
      earnedPoints: earnedPoints,
    }

    sendExcerciseResults(results); //enviamos resultados al padre
    handleClose(); //Cerramos el panel
  }

  /**
   * Función para mapear los diferentes mensajes
   * @returns 
   */
  const Message = () => {
    return {
      'correct': 'Has ganado 200 pts.',
      'correct_less_25': 'Has ganado 500 pts. por contestar en menos de 25 segundos.',
      'correct_less_15': 'Has ganado 1000 pts. por contestar en menos de 25 segundos.',
      'incorrect': 'No has ganando puntos.',
      'incorrect_challenge': 'Has perdido 100 pts.',
      'given_up': 'Te has rendido.',
      'time_out': 'El tiempo se ha acabado.'
    }
  }

  return (
    <Dialog
      open={open}
      keepMounted={false}
      fullScreen={fullScreen}
      maxWidth='lg'
      fullWidth={true}
      TransitionComponent={Transition}
      TransitionProps={{
        onEnter: startClock
      }}>
      <AppBar sx={{
        position: 'relative',
        background: isChallenge ? MATH_GRADIENTS().error :
          isRandom ? MATH_GRADIENTS(-90).random_exc_tile : MATH_GRADIENTS().default
      }}>
        <Toolbar>
          {
            isRandom ? <ShuffleOnIcon sx={{ fontSize: 40 }} />
              : isChallenge ? <StarIcon sx={{ fontSize: 40 }} /> : <CalculateIcon sx={{ fontSize: 40 }} />
          }
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ ml: 2 }} variant="h5" component="div">
              {excercise.subtopic.toString().toUpperCase().replace(/[_]/g, " ")}
            </Typography >
            <Typography sx={{ ml: 2 }} variant='subtitle2'>
              {excercise.area.toString().toUpperCase().replace(/[_]/g, " ")} - {excercise.topic.toString().toUpperCase().replace(/[_]/g, " ")}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid size={9}>
          <Box sx={{ p: theme.spacing(3) }}>
            <Typography color='textSecondary' variant='h6'>
              Responde el siguiente ejercicio:
            </Typography>
            {
              isChallenge ? <Typography color='error' variant='subtitle2'>Perderás 100 puntos si fallas la respuesta.</Typography> :
                isRandom ? <Typography color='warning' variant='subtitle2'>Ejercicio aleatorio.</Typography> : null
            }
            <Box sx={{ fontSize: '4.5vh', color: theme.palette.primary.main }}>
              <BlockMath math={excercise.label} />
            </Box>
            <Typography color='textSecondary' variant='h6' gutterBottom>
              Selecciona la respuesta correcta:
            </Typography>
            <Box>
              <FormControl fullWidth>
                <RadioGroup row value={selectedAnswer} onChange={(e) => setSelectedAnswer(e.target.value)}>
                  <Grid container sx={{ width: '100%' }}>
                    <Grid size={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 6 }}>
                      <FormControlLabel control={<Radio value={options[0]} />} label={<InlineMath math={options[0]} />} />
                    </Grid>
                    <Grid size={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 6 }}>
                      <FormControlLabel control={<Radio value={options[1]} />} label={<InlineMath math={options[1]} />} />
                    </Grid>
                    <Grid size={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 6 }}>
                      <FormControlLabel control={<Radio value={options[2]} />} label={<InlineMath math={options[2]} />} />
                    </Grid>
                    <Grid size={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 6 }}>
                      <FormControlLabel control={<Radio value={options[3]} />} label={<InlineMath math={options[3]} />} />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid size={3}>
          <Box sx={{ p: theme.spacing(3) }}>
            <Box sx={{
              textAlign: 'center',
              mb: theme.spacing(1)
            }}>
              <CircularProgress variant='determinate' value={seconds / time * 100} />
              <Typography gutterBottom fontSize={20}>Tiempo: {formatTime(seconds)}</Typography>
            </Box>
            {
              (!hasAnswered) &&
              (
                <Fragment>
                  <Button variant='contained' sx={{ background: MATH_GRADIENTS().classic, color: 'white', mb: theme.spacing(1) }}
                    fullWidth
                    startIcon={<CheckCircleIcon />}
                    onClick={checkAnswer}>
                    Responder
                  </Button>
                  <Button variant='contained' sx={{ background: MATH_GRADIENTS().event_tile, color: 'white', mb: theme.spacing(1) }}
                    fullWidth
                    startIcon={<DoNotDisturbOnIcon />}
                    onClick={handleGiveUp}>
                    Rendirse
                  </Button>
                </Fragment>
              )
            }
            {
              (hasAnswered) && (
                <Fragment>
                  <Button variant='contained' sx={{ background: MATH_GRADIENTS().default, color: 'white', mb: theme.spacing(1) }}
                    fullWidth
                    startIcon={<CheckCircleIcon />}
                    onClick={handleAccept}>
                    Aceptar
                  </Button>
                  {
                    isCorrect ?
                      (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                          <CheckCircleIcon color='success' sx={{ my: theme.spacing(1) }} />
                          <Typography color='success'>
                            ¡Respuesta correcta!
                          </Typography>
                        </Box>
                      ) :
                      (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                          <CancelIcon color='error' sx={{ my: theme.spacing(1) }} />
                          <Typography color='error'>
                            ¡Respuesta incorrecta!
                          </Typography>
                        </Box>
                      )
                  }
                  <Typography textAlign={'center'} variant='subtitle2'>
                    {Message()[
                      seconds === 0 ? 'time_out' :
                        isCorrect && timeDifference < 15 ? 'correct_less_15' :
                          isCorrect && timeDifference < 25 ? 'correct_less_25' :
                            isCorrect ? 'correct' :
                              !isCorrect && isChallenge ? 'incorrect_challenge' :
                                !isCorrect && hasGivenUp ? 'given_up' :
                                  !isCorrect ? 'incorrect' : 'incorrect'
                    ]}
                  </Typography>
                </Fragment>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </Dialog >
  )
}

export default ExcercisePanelClassic