const useStyles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary
    }
  }
});

export default useStyles;