import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { io } from 'socket.io-client'
import { useHistory, useLocation } from 'react-router-dom'

import useAuth from '../../../../../hooks/useAuth'

import { deleteArcadeGameApi, getArcadeGameByPinApi, updateArcadeGameApi } from '../../../../../api/arcadeGame';
import { wsPrivateArcadeServerBasePath } from '../../../../../config/api';
import { getRandomExcerciseApi } from '../../../../../api/excercises'
import { updateUserArcadeStatsApi } from '../../../../../api/user'

import ArcadeBoard from '../../../../../components/game_elements/ArcadeBoard/ArcadeBoard'
import ArcadeResults from '../../../../../components/game_elements/results/ArcadeResults/ArcadeResults'
import ExcercisePanel from '../../../../../components/game_elements/ExcercisePanelArcade'
import Roulette from '../../../../../components/game_elements/Roulette'
import Menu from '../../../../../components/game_elements/Menu/Menu'
import { Howl } from 'howler'

function PrivateArcadeGame(props) {
  const { match: { params: { pin } } } = props;
  const history = useHistory();
  const location = useLocation();
  const staticGame = location.state?.game; //Juego enviado desde el Lobby (No Cambia)
  let socket = useRef(null); //Manejador del Socket
  const { user: localUser } = useAuth(); //Usuario local
  const [game, setGame] = useState(null); //Juego del socket
  const [clock, setClock] = useState(staticGame.time); //Variable para mostrar los segundos del cronómetro del servidor
  const [gameFinished, setGameFinished] = useState(false);
  const [excercise, setExcercise] = useState({
    _id: "",
    label: "\\frac{s}{t}+u+v+w+x+y+z=0",
    option_a: "x+y+z=a",
    option_b: "x+y+z=b",
    option_c: "x+y+z=c",
    option_d: "x+y+z=d",
    answer: "x+y+z=a",
    area: "Area",
    topic: "Tema",
    subtopic: "Subtema",
    difficulty: "normal",
    create_date: new Date(),
    active: true
  }); //Ejercicio buscado (Con place holder)
  const [position, setPosition] = useState(0); // Variable para detectar la posición en la que cayó el roll

  //Variables para paneles
  const [openExcercisePanel, setOpenExcercisePanel] = useState(false); //Variable para abrir y cerrar el pandel de ejercicios
  const [openRoulette, setOpenRoulette] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  /**
   * draw: esperando tirar
   * rolling: girando tablero
   * answering: contestando un ejercicio
   * waiting: esperando turno
   */
  const [phase, setPhase] = useState('draw');
  const [isChallenge, setIsChallenge] = useState(false); //Variable para indicar si es reto o no
  const [isRandom, setIsRandom] = useState(false); //Variable  para indicar si es ejercicio random o no
  // eslint-disable-next-line no-unused-vars
  const [isRoulette, setIsRoulette] = useState(false) //Variable para indicar si es panel de ruleta
  const [isLoading, setIsLoading] = useState(true); //Carga del juego de la BD
  const [isSavingHistory, setIsSavingHistory] = useState(false); //Indicación de guardado de resultados

  const [sounds, setSounds] = useState(null);
  useEffect(() => {
    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'game_start', url: '/sounds/games/general/game_start.wav' },
      { name: 'game_finished', url: '/sounds/games/general/game_finished.wav' },
      { name: 'my_turn', url: '/sounds/games/general/my_turn.mp3' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.5,
      });

      soundInstances[name] = sound;
      setSounds(soundInstances);
    });

    soundInstances['game_start'].play();

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, [])

  useEffect(() => {
    //Carga el juego de la BD la primera vez
    getArcadeGameByPinApi(pin).then(response => {
      //Si no se encontró partida en la BD
      if (response.status === 0) {
        history.push('/home/play');
      } else {
        const g = response.game;
        //Si el juego fue concluído, redirige al menú
        if (g.status === 'finished' || g.status === 'in_lobby') {
          history.push('/home/play');
        }
      }
    }).catch(e => {
      history.push('/home/play');
    })

    //Guardamos el staticGame del localStorage si es que se recarga la página
    if (!staticGame) {
      setGame(localStorage.getItem("game"));
    }

    //Conexión al WebSocker
    socket.current = io(`${wsPrivateArcadeServerBasePath}/${pin}`, {
      transports: ["websocket"],
      withCredentials: true
    });

    //empieza el juego con los invitados, enviando el juego estático por primera vez.
    //Solo el host envía el juego base para todos
    if (staticGame /* && localUser.nickname === staticGame.host */) {
      socket.current.emit('game', staticGame);
    }

    //Asigna el mensaje del socket al hook del juego
    socket.current.on('game', (data) => {
      localStorage.setItem("game", JSON.stringify(data));
      setIsLoading(false); //Detiene la carga
      setGame(data); //Guarda el mensaje del socket
    });

    //Asigna el mensaje del socket al hook del reloj
    socket.current.on('clock', (sconds) => {
      setClock(sconds); //Guarda el mensaje del socket tiempo
    });

    return () => {
      socket.current.disconnect();
      socket.current.off('game');
      socket.current.off('clock');
    }

  }, [pin, history, staticGame])

  /**
 * Función para cambiar turno, rotando entre los jugadores por número de jugador
 */
  const changeTurn = useCallback(() => {
    const updateGame = { ...game };
    const players = [...updateGame.players];
    const totalPlayers = players.length;
    let currentTurnPlayer = players.find(player => player.nickname === game.turn);

    // Quitamos una moneda al jugador actual
    currentTurnPlayer.coins -= 1;

    // Guardamos datos en el arreglo de players
    const newPlayers = players.map(player =>
      player.nickname === currentTurnPlayer.nickname ? currentTurnPlayer : player
    );

    // Calculamos el total de monedas de los jugadores activos
    const totalCoins = players.reduce((acc, player) => acc + (player.dropOff ? 0 : player.coins), 0);

    // Cambia de turno
    do {
      let nextPlayerNumber = currentTurnPlayer.number;

      // Rotamos al siguiente jugador
      nextPlayerNumber = (nextPlayerNumber % totalPlayers) + 1;
      currentTurnPlayer = players.find(player => player.number === nextPlayerNumber);
    } while (
      (currentTurnPlayer.coins === 0 || currentTurnPlayer.dropOff) &&
      totalCoins > 0
    );

    updateGame.players = newPlayers;
    updateGame.turn = currentTurnPlayer.nickname;
    updateGame.message = `Es el turno de @${currentTurnPlayer.nickname}`;

    sendMessageToSocket(updateGame);
    setPhase('waiting');
  }, [game]);

  /**
   * Efecto que detecta si los jugadores no tienen monedas o si se acabó el tiempo
   * para finalizar la partida
   */
  useEffect(() => {
    if (game) {
      const players = game.players;
      let allCoins = 0;

      players.forEach(pl => {
        allCoins += pl.coins;
      })

      //Si se acabó el tiempo o ya nadie tiene monedas
      if (clock === 0 || allCoins === 0) {
        setGameFinished(true);
        setOpenExcercisePanel(false);
        setOpenRoulette(false);
        setOpenResults(true);
      }
    }
  }, [clock, game]);

  useEffect(() => {
    if (game?.turn === localUser?.nickname && phase === 'waiting') {
      sounds['my_turn']?.play();
    }
  }, [game?.turn, sounds, localUser, phase]);

  useEffect(() => {
    if (gameFinished) {
      sounds['game_finished'].play();
    }
  }, [gameFinished, sounds])

  /**
   * Función para modificar el mensaje cuando alguien tira
   */
  const handleRoll = () => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToRoll = players.find(player => player.nickname === game.turn);

    updateGame.message = `@${playerToRoll.nickname} a tirado una moneda.`;

    //Enviamos uso de moneda
    sendMessageToSocket(updateGame);
  }

  /**
   * Función para detectar cuando el roll se detuvo desde el tablero
   * @param {*} pos 
   */
  const getRollPosition = (pos) => {
    setPosition(pos);
    setPhase('answering');

    //Detectamos casillas especiales
    const slot = pos % 22;
    const randomSlot = Math.floor(Math.random() * staticGame.board.length);

    if (slot === 0 || slot === 11) { //Ruletas
      setIsRandom(false);
      setIsChallenge(false);
      setIsRoulette(true);

      setOpenRoulette(true);
    } else if (slot === 5) { //Ejercicio Random
      setIsRandom(true);
      setIsChallenge(false);
      setIsRoulette(false);

      //Buscamos un subtema aleatorio del tablero
      const subtopic = staticGame.board[randomSlot];

      //Buscamos el ejercicio en la BD y lo mostramos en el panel
      getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
        if (response.excercise.length > 0) {
          setExcercise(response.excercise[0]);
          setTimeout(() => {
            setOpenExcercisePanel(true);
          }, 1000);
        } else {
          changeTurn();
        }
      }).catch(e => {
        changeTurn();
      })
    } else if (slot === 16) { //Reto
      setIsRandom(false);
      setIsChallenge(true);
      setIsRoulette(false);

      //Buscamos un subtema aleatorio del tablero
      const subtopic = staticGame.board[randomSlot];

      //Buscamos el ejercicio en la BD y lo mostramos en el panel
      getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
        if (response.excercise.length > 0) {
          setExcercise(response.excercise[0]);
          setTimeout(() => {
            setOpenExcercisePanel(true);
          }, 1000);
        } else {
          changeTurn();
        }
      }).catch(e => {
        changeTurn();
      })
    } else if (slot !== 0 || slot !== 11) { //Casilla normal y NO ruletas
      setIsRandom(false);
      setIsChallenge(false);
      setIsRoulette(false);

      //Buscamos el subtemas entre el tablero
      const subtopic = staticGame.board[slot];

      //Buscamos el ejercicio en la BD y lo mostramos en el panel
      getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
        if (response.excercise.length > 0) {
          setExcercise(response.excercise[0]);
          setTimeout(() => {
            setOpenExcercisePanel(true);
          }, 1000);
        } else {
          changeTurn();
        }
      }).catch(e => {
        changeTurn();
      })
    }
  }

  /**
   * Función para obtener los resultados del panel de ejercicios
   * @param {*} result 
   */
  const getExcerciseResults = (results) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToRoll = players.find(player => player.nickname === game.turn);

    //sumanmos los puntos, el ejercicio y si fue correcto o no
    playerToRoll.points += results.earnedPoints;
    playerToRoll.answered += 1;
    if (results.isCorrect) {
      playerToRoll.correct += 1;
    } else {
      playerToRoll.errors += 1;
    }

    changeTurn();
    setPhase('waiting');
  }

  /**
   * Función para obtener el dato de la ruleta
   * @param {*} result 
   */
  const getRouletteItem = (result) => {
    setOpenRoulette(false);

    const updateGame = game;
    const players = updateGame.players;
    const playerToRoll = players.find(player => player.nickname === game.turn);

    switch (result) {
      case "+10 puntos":
        playerToRoll.points += 10;
        changeTurn();
        break;
      case "Ejercicio":
        setIsRandom(true);
        getAdditionalExcercise();
        break;
      case "Reto":
        setIsChallenge(true);
        getAdditionalExcercise();
        break;
      case "Moneda Extra":
        playerToRoll.coins += 1;
        setPhase('draw');
        break;
      case "+2 Monedas Extra":
        playerToRoll.coins += 2;
        setPhase('draw');
        break;
      case "-10 puntos":
        playerToRoll.points -= 10;
        changeTurn();
        break;
      default:
        changeTurn();
        break;
    }
  }

  /**
   * Función para buscar un ejercicio a parte de la posición
   */
  const getAdditionalExcercise = (random = false) => {
    let subtopic = null;
    if (random) { //Si es random el ejercicio
      subtopic = staticGame.board[Math.floor(Math.random() * staticGame.board.length)]
    } else { //Ejercicioo de acuerdo a la posición
      subtopic = staticGame.board[position % staticGame.board.length];
    }

    //Buscamos el ejercicio en la BD y lo mostramos en el panel
    getRandomExcerciseApi(subtopic.area, subtopic.code, staticGame.difficulty).then(response => {
      if (response.excercise.length > 0) {
        setExcercise(response.excercise[0]);
        setTimeout(() => {
          setOpenExcercisePanel(true);
        }, 500);
      } else {
        changeTurn();
      }
    }).catch(e => {
      changeTurn();
    });
  }

  /**
   * Función para guardar los resultados de la partida en la BD
   * @param {*} drawedPlayers 
   * @param {*} sortedPlayers 
   */
  const onEndGame = (drawedPlayers, sortedPlayers) => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToUpdate = players.find(player => player.nickname === localUser.nickname);
    let result = '';

    //Verificamos si hay un ganador
    if (sortedPlayers[0].points > sortedPlayers[1].points) {
      const winner = sortedPlayers[0];

      if (winner.nickname === localUser.nickname) { //Verificamos si es ganador o no el jugador
        result = 'victory';
      } else {
        result = 'defeat';
      }
    } else { //Hay un empate
      if (drawedPlayers.length === players.length) { //Verificamos si todos empataron
        result = 'draw';
      } else if (drawedPlayers.length > 0) { //Verificamos si hay empates entre menos jugadores a los totales
        if (drawedPlayers.some(tied => tied.nickname === localUser.nickname)) { //Verificamos si el jugador está entre los empates
          result = 'draw';
        } else {
          result = 'defeat';
        }
      }
    }

    // Función que clasifica los resultados
    function classifyPlayers() {
      // Encuentra la puntuación más alta
      const maxPoints = Math.max(...sortedPlayers.map(player => player.points));

      // Filtra a los jugadores con la puntuación más alta
      const winners = sortedPlayers.filter(player => player.points === maxPoints);

      // Clasifica los resultados de todos los jugadores
      return sortedPlayers.map(player => {
        if (player.points === maxPoints) {
          // Si hay más de un jugador con el puntaje más alto, es empate
          return winners.length > 1
            ? { ...player, result: 'draw' }
            : { ...player, result: 'victory' }; // Si es único, es victoria
        } else {
          return { ...player, result: 'defeat' }; // Los demás pierden
        }
      });
    }

    const playerStats = {
      ...playerToUpdate,
      result: result
    }

    //Iniciamos carga
    setIsSavingHistory(true);

    //Actualizamos las estadísticas
    updateUserArcadeStatsApi(playerToUpdate.nickname, playerStats).then(response => {
      //Cambiamos el status del juego
      updateArcadeGameApi({ ...game, players: classifyPlayers(), status: 'finished' }, pin).then(response => {
        disconnectEverything();
      }).catch(e => {
        disconnectEverything();
      })
    }).catch(e => {
      disconnectEverything();
    })

    //Desconectamos los web sockets y regresamos al menú
    disconnectEverything();
  }

  /**
   * Función para sacar jugadores del juego
   */
  const onExit = () => {
    const updateGame = game;
    const players = updateGame.players;
    const playerToDropOff = players.find(player => player.nickname === localUser.nickname);

    // Cambiamos la propiedad a dropOff, que indica que salió del juego
    playerToDropOff.dropOff = true;

    // Verificamos si solo queda un jugador activo
    const activePlayers = players.filter(player => !player.dropOff && player.coins > 0);

    // Si hay más de un jugador activo, ejecutamos changeTurn
    if (activePlayers.length > 0) {
      if (game.turn === localUser.nickname) {
        playerToDropOff.coins = 1;
        changeTurn();
      } else {
        playerToDropOff.coins = 0;
        sendMessageToSocket(game);
      }
    } else {
      //Cancelamos o eliminamos el juego desde que queda un jugador
      deleteArcadeGameApi(pin).then(response => {

      }).catch(e => {

      })
    }

    disconnectEverything();
  }

  /**
   * Función para desconectar todo y regresar al menú
   */
  const disconnectEverything = () => {
    localStorage.removeItem("game");
    socket.current.off('game');
    socket.current.off('clock');
    socket.current.disconnect();
    history.push('/home/play/arcade');
  }

  /**
   * Función para enviar mensajes generales al socket
   * @param {*} game 
   */
  const sendMessageToSocket = (gameToSend) => {
    socket.current.emit('game', gameToSend);
  }

  // Bloquear la recarga de página
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Prevenir la recarga y abrir el diálogo
      event.preventDefault();
      setOpenMenu(true); // Activa el diálogo sin recargar
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Bloquear el botón de "Atrás"
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        setOpenMenu(true); // Abre el Menu antes de navegar hacia atrás
        return false;
      }
    });

    return () => unblock();
  }, [history]);

  return (
    <Fragment>
      <Backdrop open={isSavingHistory} sx={{ zIndex: 100000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <Menu open={openMenu} handleClose={() => setOpenMenu(false)} onExit={onExit} turn={game ? game.turn : ''} localUser={localUser} />
      <ExcercisePanel open={openExcercisePanel}
        handleClose={() => setOpenExcercisePanel(false)}
        excercise={excercise}
        sendExcerciseResults={getExcerciseResults}
        isRandom={isRandom}
        isChallenge={isChallenge}
        iconType={game ? game.distinctive : 'gems'}
        icon={game ? game.board[position % 22].icon : 'amethyst'}
        time={
          game && game.difficulty === 'easy' ? 150 : game && game.difficulty === 'normal' ? 120 : game && game.difficulty === 'hard' ? 75 : 120
        } />
      <Roulette
        open={openRoulette}
        rouletteItems={["+10 puntos", "Ejercicio", "Reto", "Moneda Extra", "Gira Otra Vez", "Ejercicio", "+2 Monedas Extra", "-10 puntos"]}
        sendRouletteItem={getRouletteItem} />
      <ArcadeResults open={openResults} game={game} endGame={onEndGame} gameFinished={gameFinished} />
      <ArcadeBoard
        isLoading={isLoading}
        game={game}
        phase={phase}
        localUser={localUser}
        handleRoll={handleRoll}
        sendRollPosition={getRollPosition}
        clock={clock}
        handleOpenMenu={() => setOpenMenu(!openMenu)}
        handleChangePhase={(ph) => {
          setPhase(ph);
        }}
      />
    </Fragment>
  )
}

export default PrivateArcadeGame