import { MATH_GRADIENTS } from '../../../../../styles/MathGradients'

const useStyles = (groupColor, theme) => ({
  root: {
    '& h5': {
      color: groupColor
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  btn: {
    background: groupColor,
    color: 'white',
    '&:hover': {
      background: groupColor
    }
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  headerBox: {
    display: 'block',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    marginBottom: theme.spacing(2)
  },
  classic_icon: {
    width: '42px'
  },
  rush_icon: {
    width: '30px'
  },
  classicPaper: {
    background: MATH_GRADIENTS().classic,
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1.2),
    color: 'white'
  },
  arcadePaper: {
    background: MATH_GRADIENTS().arcade,
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1.2),
    color: 'white'
  },
  rushPaper: {
    background: MATH_GRADIENTS().rush,
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1.2),
    color: 'white'
  }
});

export default useStyles;