import React, { useEffect, useState } from 'react'
import PublicHeader from '../../components/navigation/public/PublicHeader/PublicHeader'
import Footer from '../../components/navigation/public/Footer/Footer'
import { Box, Container, LinearProgress, Typography, useTheme } from '@mui/material'
import { getNewByPath } from '../../api/news';

function Article(props) {
  const { match: { params: { path } } } = props;
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    getNewByPath(path).then(response => {
      if (response.newData) {
        if (response.newData.active) {
          setIsLoading(false);
          setArticle(response.newData);
        } else {
          window.location.href = '/news';
        }
      }
    }).catch(e => {
      window.location.href = '/news';
    })
    return () => {
      setArticle({});
    }
  }, [path]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <Box flex={1}>
        <PublicHeader />
        <Container maxWidth="xl" sx={{ mt: theme.spacing(6) }}>
          <Box sx={{ minHeight: '100vh' }}>
            {
              isLoading ?
                <LinearProgress variant='indeterminate' /> :
                <Box>
                  <Typography variant="h2" color='primary' gutterBottom>{article.name}</Typography>
                  <div
                    dangerouslySetInnerHTML={{ __html: article.article }}>
                  </div>
                </Box>
            }
          </Box>
        </Container>
        <Footer />
      </Box>
    </div>
  )
}

export default Article