import { basePath, apiVersion } from '../config/api'
import { getAccessTokenApi } from './auth'

export function createRequestApi(data) {
  const url = `${basePath}/${apiVersion}/create-request`
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function getReceivedRequestsApi(id) {
  const url = `${basePath}/${apiVersion}/get-received-requests/${id}`
  const params = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}

export function updateRequestApi(id, data) {
  const url = `${basePath}/${apiVersion}/update-request/${id}`
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Authorization": getAccessTokenApi()
    }
  }

  /**Petición fetch */
  return fetch(url, params).then(response => {
    return response.json()
  })
    .then(result => {
      return result
    })
    .catch(err => {
      return err.message
    })
}