import React from 'react'
import { Typography, Grid2 as Grid, useTheme, Paper, Box, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Container } from '@mui/material'

/**Components */
import UpdateProfile from '../../../components/forms/UpdateProfile'
import UpdatePassword from '../../../components/forms/UpdatePassword'
import Options from '../../../components/forms/Options'

import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PaletteIcon from '@mui/icons-material/Palette';

function Settings() {
  const theme = useTheme();

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid size={{ xl: 4, lg: 4, md: 12, sm: 12, xs: 12 }}>
          <Paper sx={{ p: theme.spacing(4) }}>
            <Typography variant='h4' gutterBottom color='primary'>
              Configuración
            </Typography>
            <Box sx={{ width: '100%' }}>
              <List>
                <a href='#update-profile' style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon><EditIcon color='primary' /></ListItemIcon>
                      <ListItemText>Editar perfil</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </a>
                <a href='#update-password' style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon><VpnKeyIcon color='primary' /></ListItemIcon>
                      <ListItemText>Cambiar contraseña</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </a>
                <a href='#options' style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon><PaletteIcon color='primary' /></ListItemIcon>
                      <ListItemText>Opciones y tema de color</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </a>
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid size={{ xl: 8, lg: 8, md: 12, sm: 12, xs: 12 }}>
          <div id='update-profile'>
            <UpdateProfile />
          </div>
          <div id='update-password'>
            <UpdatePassword />
          </div>
          <div id='options'>
            <Options />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Settings
