import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import { Howl } from 'howler'

//Componentes
import RushUI from '../../../../../components/game_elements/RushUI/RushUI'

//API
import { excerciseGenerator } from '../../../../../utils/rush/excerciseGenerator'
import RushResults from '../../../../../components/game_elements/results/RushResults/RushResults'
import { Backdrop, CircularProgress, Typography } from '@mui/material'
import { updateUserRushStatsApi } from '../../../../../api/user'
import Menu from '../../../../../components/game_elements/Menu/Menu'
import { deleteRushGameApi, updateRushGameApi } from '../../../../../api/rushGame'

function SinglePlayerRushGame(props) {
  const history = useHistory();
  const location = useLocation();
  const staticGame = location.state?.game; //Juego enviado desde el Lobby (No Cambia)
  const { user: localUser } = useAuth(); //Usuario local
  const [game, setGame] = useState(null); //Juego del socket

  //Estados para mostrar los ejercicios y respuestas
  const [excerciseToShow, setExcerciseToShow] = useState("");
  const [calculatedAnswer, setCalculatedAnswer] = useState(0);
  const [streak, setStreak] = useState(0); //racha de ejercicios correctos
  const [errorStreak, setErrorStreak] = useState(0); //Racha de errores para eliminar racha
  const [isCorrect, setIsCorrect] = useState(null); //Estado para manejar errores
  const [pointsToEarn, setPointsToEarn] = useState(0); //Estado para indicar cuantos puntos va aganar
  const [secondsToEarn, setSecondsToEarn] = useState(0); //estado para indicar cuantos segundos va a ganaer

  //Estados para el juego local, que serán subidos al socket cuando se responda correctamente
  const [topic, setTopic] = useState(staticGame.topics[0]); //Hook para el tema actual que se usará
  const [points, setPoints] = useState(0); //Puntos del jugador
  const [level, setLevel] = useState(1); //Nivel del jugador
  const [excercises, setExcercises] = useState(0); //Ejercicios del jugador
  const [multiplier, setMultiplier] = useState(1); //Multiplicador del jugador
  const [combo, setCombo] = useState(0); //Estado para controlar los combos, combos = streak;

  //Hooks para manejar el tiempo
  const [isClockRunning, setIsClockRunning] = useState(false); //Interruptor para el reloj
  const [isClockStreakRunning, setIsClockStreakRunning] = useState(false);
  const [seconds, setSeconds] = useState(60); //Reloj
  const [streakSeconds, setStreakSeconds] = useState(5); //Segundos para conservar la racha

  //Hooks para las cargas
  const [initialCountdown, setInitialCountdown] = useState(3);
  const [openCountdown, setOpenCountdown] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true); //Carga del juego de la BD
  const [isSavingHistory, setIsSavingHistory] = useState(false); //Indicación de guardado de resultados

  //Musica y sonidos
  const [sounds, setSounds] = useState(null);
  useEffect(() => {
    document.title = 'Modo Rush - Math Paradise'

    //Sonidos
    const soundInstances = {};
    const soundUrls = [
      { name: 'boom', url: '/sounds/games/rush/boom.mp3' },
      { name: 'combo', url: '/sounds/games/rush/combo.mp3' },
      { name: 'level_up', url: '/sounds/games/rush/level_up.wav' },
      { name: 'ticktock', url: '/sounds/games/rush/ticktock.mp3' },
      { name: 'game_over', url: '/sounds/games/general/game_over.mp3' },
      { name: 'correct', url: '/sounds/ui/correct.mp3' },
      { name: 'incorrect', url: '/sounds/ui/incorrect.mp3' },
    ];

    soundUrls.forEach(({ name, url }) => {
      const sound = new Howl({
        src: [url],
        volume: 0.3,
        loop: name === 'ticktock' ? true : false
      });

      soundInstances[name] = sound;
      soundInstances['boom'].play();
      setSounds(soundInstances);
    });

    return () => {
      Object.values(soundInstances).forEach((sound) => {
        sound.unload();
      });
    };
  }, []);

  //Efecto para parar la carga
  useEffect(() => {

  }, [isLoading, staticGame]);

  //Efecto que inicia cuenta regresiva del inicio
  useEffect(() => {
    if (isLoading) {
      //Guardamos la configuración del juego
      setGame(staticGame);
      setIsLoading(false);
      updateRushGameApi({ ...staticGame, status: 'in_game' }, staticGame.pin).then(response => {
      }).catch(e => {
      });
    } else {
      if (initialCountdown > 0) {
        const timerIdStreak = setInterval(() => {
          sounds['boom'].play();
          setInitialCountdown(previnit => previnit - 1); // Reduce en 1 cada segundo
        }, 500);

        return () => clearInterval(timerIdStreak); // Limpia el intervalo al desmontar el componente
      } else {
        setTimeout(() => {
          setOpenCountdown(false); //Cerramos cuenta regresiva
          setIsClockRunning(true); //Iniciamos reloj general de la partida
        }, 600);
      }
    }
  }, [initialCountdown, isLoading, game, staticGame, sounds]);

  //Efecto para iniciar el reloj general y controlar cuando termina el juego
  useEffect(() => {
    if (isClockRunning) {
      if (seconds > 0) {
        const timerId = setInterval(() => {
          setSeconds(prevSeconds => prevSeconds - 1); // Reduce en 1 cada segundo
        }, 1000);

        return () => clearInterval(timerId); // Limpia el intervalo al desmontar el componente
      } else {
        setIsClockRunning(false); //Paramos el reloj si el reloj se detuvo
      }
    }
  }, [isClockRunning, seconds]);

  useEffect(() => {
    if (seconds === 0) {
      setOpenResults(true); //Abrimos panel de resultados
      sounds['ticktock'].stop();
      sounds['game_over'].play();

      //Modificamos el estado para mostrar los resultados
      setGame({
        ...game,
        players: [{
          nickname: localUser.nickname,
          avatar: localUser.avatar,
          number: 1,
          timeUp: true,
          level: level,
          multiplier: multiplier,
          points: points,
          excercises: excercises,
        }]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excercises, level, localUser, multiplier, points, seconds, sounds]);

  //Efecto para iniciar o detener el reloj de la racha
  useEffect(() => {
    if (isClockStreakRunning) {
      if (streakSeconds > 0) {
        const timerIdStreak = setInterval(() => {
          setStreakSeconds(prevSeconds => prevSeconds - 1); // Reduce en 1 cada segundo
        }, 1000);

        return () => clearInterval(timerIdStreak); // Limpia el intervalo al desmontar el componente
      } else {
        setIsClockStreakRunning(false); //Paramos el reloj de la racha 
        setErrorStreak(0); //Reiniciamos streak de errores
        setStreak(0); //detenemos la racha el streak
        setCombo(0); //Limpiamos el combo
      }
    }
  }, [streakSeconds, isClockStreakRunning, sounds]);

  //Efecto que elimina la racha después de 3 errores
  useEffect(() => {
    if (errorStreak === 3) {
      setIsClockStreakRunning(false); //Paramos reloj de racha
      setErrorStreak(0); //limpiamos error streak
      setStreak(0); // limpiamos racha
      setCombo(0); //Limpiamos el combo
    }
  }, [errorStreak]);

  //Efecto que rota los temas y genera ejercicios
  useEffect(() => {
    const { exc, ans } = excerciseGenerator(topic, level);
    setExcerciseToShow(exc);
    setCalculatedAnswer(ans);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  //Efecto que limita el tiempo a 60
  useEffect(() => {
    if (seconds > 60)
      setSeconds(60);
  }, [seconds])

  //Efecto para reproducir el sonido de combo
  useEffect(() => {
    if (streak === 40) {
      sounds['combo'].rate(1);
      sounds['combo'].play();
    } else if (streak === 60) {
      sounds['combo'].rate(1.1);
      sounds['combo'].play();
    } else if (streak === 80) {
      sounds['combo'].rate(1.3);
      sounds['combo'].play();
    } else if (streak === 100) {
      sounds['combo'].rate(1.5);
      sounds['combo'].play();
    }
  }, [streak, combo, sounds])

  //Efectos para sonidos
  useEffect(() => {
    if (sounds) {
      if (seconds === 9) {
        sounds['ticktock'].pause();
        sounds['ticktock'].play();
      } else if (seconds >= 10) {
        sounds['ticktock'].stop();
      } else if (seconds === 0) {
        sounds['ticktock'].stop();
      }
    }
  }, [seconds, sounds]);

  /**
   * Función para manejar la respuesta ingresada por el jugador
   * @param {*} answer 
   */
  const handleAnswer = (answer) => {
    let ptsToEarn = 5;
    let scndsToEarn = 10;

    //Evaluamos la respuesta
    if (Number(answer) === calculatedAnswer) {
      setIsCorrect(true); //Se indica que fue correcta
      setErrorStreak(0); //Limpiamos racha de errores
      setCombo(prevCombo => prevCombo += 1); //Aumentamos el combo, que se mostrará cuando llegue a 2
      sounds['correct'].play();

      //Aumentamos la racha
      if (streak === 100) { //Aumentamos el multiplicador si llega a 100
        setStreak(100);
        setMultiplier(prevMulti => prevMulti += 1);
      } else {
        setStreak(prevStreak => prevStreak += 20);
      }

      setStreakSeconds(5); //Reiniciamos los segundos
      setIsClockStreakRunning(true); //Iniciamos el reloj de racha

      setExcercises(prevExc => prevExc += 1); //Aumentamos los ejercicios
      setPointsToEarn(ptsToEarn * (multiplier)); //Indicamos cuantos puntos vamos a ganar

      setPoints(prevPoints => prevPoints += (ptsToEarn * (multiplier))); //Sumamos los puntos por el multiplicador

      //Aumentamos tema
      let topicIndex = game.topics.findIndex(t => t === topic);
      if (topicIndex === game.topics.length - 1) { //Si llegó al ultimo tema, reiniciamos ronda, aumentamos nivel y los segundos al reloj
        topicIndex = 0;
        setLevel(prevLevel => prevLevel += 1);

        //Aumentamos los segundos de acuerdo al nivel
        if (level <= 5) scndsToEarn = 5;
        else if (level <= 10) scndsToEarn = 10;
        else if (level <= 15) scndsToEarn = 20;
        else if (level <= 20) scndsToEarn = 25;
        else if (level <= 25) scndsToEarn = 30;
        else if (level <= 30) scndsToEarn = 30;
        else if (level <= 40) scndsToEarn = 45;
        else if (level <= 50) scndsToEarn = 60;
        else scndsToEarn = 60;

        setSecondsToEarn(scndsToEarn);
        setSeconds(prevSeconds => prevSeconds += scndsToEarn); //Asignamos segundos
        sounds['level_up'].play();
      }
      else {
        topicIndex += 1;
      }

      setTopic(game.topics[topicIndex]); //Cambiamos el tema
    } else {
      setIsCorrect(false); //Indicamos que está incorrecto
      setErrorStreak(prevErs => prevErs += 1); // Aumentamos racha de errores
      sounds['incorrect'].play();
    }
  }

  /**
   * Función para enviar los datos al servidor cuando se acaba la partida
   * @param {*} position
   */
  const onEndGame = (position) => {
    //Armamos estadísticas para ver si hay nuevos records
    const records = {
      points: points,
      excercises: excercises,
      multiplier: multiplier,
      level: level
    }

    //Inciamos carga
    setIsSavingHistory(true);

    //Actualizamos las stats
    updateUserRushStatsApi(localUser.nickname, records).then(sts => {
      updateRushGameApi({ ...game, status: 'finished' }, game.pin).then(response => {
        disconnectEverything();
      }).catch(e => {
        disconnectEverything();
      });
    }).catch(e => {
      disconnectEverything();
    })
  }

  /**
   * Función para eliminar la partida cuando se sale de ella
   */
  const onExitGame = () => {
    //Iniciamos carga
    setIsSavingHistory(true);

    deleteRushGameApi(staticGame.pin).then(response => {
      disconnectEverything();
    }).catch(e => {
      disconnectEverything();
    })
  }

  /**
   * Función para desconectar todo y regresar al menú
   */
  const disconnectEverything = () => {
    history.push('/home/play/rush');
  }

  return (
    <Fragment>
      <Backdrop open={isSavingHistory} sx={{ zIndex: 3000 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
      <Menu
        open={openMenu}
        handleClose={() => setOpenMenu(false)}
        onExit={onExitGame}
        turn={localUser ? localUser.nickname : ''}
        localUser={localUser} />
      <Backdrop open={openCountdown} sx={{ zIndex: 2000 }}>
        <Typography sx={{ color: 'white' }} variant='h1' textAlign={'center'}>{initialCountdown === 0 ? '¡A Jugar!' : initialCountdown}</Typography>
      </Backdrop>
      <RushResults
        open={openResults}
        game={game}
        seconds={seconds}
        localUser={localUser}
        endGame={onEndGame} />
      <RushUI
        game={game}
        topic={topic}
        points={points}
        level={level}
        excercises={excercises}
        multiplier={multiplier}
        excercise={excerciseToShow}
        seconds={seconds}
        streak={streak}
        streakSeconds={streakSeconds}
        combo={combo}
        isCorrect={isCorrect}
        pointsToEarn={pointsToEarn}
        secondsToEarn={secondsToEarn}
        isLoading={isLoading}
        handleAnswer={handleAnswer}
        handleOpenMenu={() => setOpenMenu(true)} />
    </Fragment>
  )
}

export default SinglePlayerRushGame