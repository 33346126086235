import React, { useState, useEffect } from 'react'
import { Redirect } from "react-router-dom"
import { styled } from '@mui/material/styles'
import {
  Typography, Paper, Grid, Container, Button, Box, TextField,
  FormControl, FormControlLabel, Radio, RadioGroup, Snackbar, IconButton,
  Checkbox, CircularProgress, useTheme
} from '@mui/material'
import useAuth from '../../hooks/useAuth'

//Icons
import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'

//Components
import PublicHeader from '../../components/navigation/public/PublicHeader/PublicHeader'

//utils
import { emailValidation, minLenghtValidation, nicknameValidation } from '../../utils/FormValidation'
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../utils/Constants";

/**APIs */
import { loginApi, signUpApi } from '../../api/user'
import { MATH_GRADIENTS } from '../../styles/MathGradients'

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    textAlign: 'center'
  },
  paper: {
    color: theme.palette.paper,
    padding: theme.spacing(4),
  },
  boxForm: {
    paddingTop: theme.spacing(3),
  },
  formTitle: {
    paddingBottom: theme.spacing(2)
  },
  button: {

  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  select: {
    width: '100%'
  },
  centerControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footer: {
    position: 'relative'
  }
}))

function SignUp() {
  const classes = useStyles()
  const theme = useTheme();
  const [alert, setAlert] = React.useState(false) //Alerta superior
  const [message, setMessage] = useState('') //Mensaje de alertas
  const [privacyPolicies, setprivacyPolicies] = useState(false) //Aceptación de las porlitcas
  //Valoresd de los campos
  const [inputs, setInputs] = useState({
    name: '',
    lastname: '',
    nickname: '',
    email: '',
    password: '',
    institution: '',
    school_grade: '',
    repeatPassword: '',
    role: 'student',
    avatar: 'default.svg'
  })
  const [isLoading, setIsLoading] = useState(false)

  //Contornos de error de los campos
  const [isError, setIsError] = useState({
    name: false,
    lastname: false,
    nickname: false,
    email: false,
    password: false,
    repeatPassword: false,
  })

  //Validación de los campos
  const [formValid, setformValid] = useState({
    name: false,
    lastname: false,
    nickname: false,
    email: false,
    institution: false,
    school_grade: false,
    password: false,
    repeatPassword: false,
    role: true
  })

  //Si el usuario está logueado
  const { user } = useAuth()

  useEffect(() => {
    document.title = 'Registrarse - Math Paradise'
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(false)
  }

  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }

  const inputValidation = (e) => {
    const { type, name, value } = e.target

    /**Validaciones */
    if (type === "text" && name !== "nickname") {
      setformValid({ ...formValid, [name]: minLenghtValidation(value, 1) })
    }
    if (name === "nickname") {
      setformValid({ ...formValid, nickname: nicknameValidation(e.target) })
      setIsError({ ...isError, nickname: !nicknameValidation(e.target) })
    }
    if (type === "email") {
      setformValid({ ...formValid, [name]: emailValidation(e.target) })
      setIsError({ ...isError, [name]: !emailValidation(e.target) })
    }
    if (type === "password") {
      setformValid({ ...formValid, [name]: minLenghtValidation(value, 8) })
      setIsError({ ...isError, [name]: !minLenghtValidation(value, 8) })
    }
    if (type === "radio") {
      setformValid({ ...formValid, [name]: e.target.checked })
    }
  }

  //Registro
  const signUp = async e => {
    e.preventDefault()
    const { name, lastname, nickname, email, password, repeatPassword, role } = formValid
    //const { institution, school_grade } = inputs
    const passwordValue = inputs.password
    const repeatPasswordValue = inputs.repeatPassword

    //Si los campos devuelve -false-
    if (!name || !lastname || !nickname || !email || !password || !repeatPassword ||
      !role || !privacyPolicies) {
      setMessage('Todos los campos son requeridos.')
      setAlert(true)
    } else {
      if (passwordValue !== repeatPasswordValue) {
        setMessage('Las contraseñas deben ser iguales.')
        setAlert(true)
      } else {
        //Inicia la carga
        setIsLoading(true)

        const result = await signUpApi(inputs)

        if (!result.ok) {
          setMessage(result.message)
          setAlert(true)
          setIsLoading(false)
        } else {
          setMessage(result.message)
          setAlert(true)

          //Iniciamos sesión
          try {
            const result = await loginApi({ credential: inputs.email, password: passwordValue, credentialType: 'email' });
            if (result) {
              if (result.status === 0) {
                setAlert(true);
                setMessage(result.message);
              } else if (result.status === 1) {
                const { accessToken, refreshToken } = result;
                if (!accessToken || !refreshToken) {
                  setAlert(true);
                  setMessage('Error del servidor, vuelva a intentarlo.');
                } else {
                  localStorage.setItem(ACCESS_TOKEN, accessToken);
                  localStorage.setItem(REFRESH_TOKEN, refreshToken);
                  window.location.href = '/home'; // Redireccionar al home
                }
              }
            } else {
              setAlert(true);
              setMessage('Error del servidor, vuelva a intentarlo.');
            }
          } catch (e) {
            setAlert(true);
            setMessage('Error del servidor, vuelva a intentarlo.');
          } finally {
            setIsLoading(false);
          }
        }
      }
    }
  }

  /**Si el usuario está logueado */
  if (user) {
    return <Redirect to="/home" />
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <Box flex={1}>
        <PublicHeader />
        <Container maxWidth={"lg"} sx={{ mt: theme.spacing(6) }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={alert}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
          <form onChange={changeForm} onSubmit={signUp}>
            <Paper sx={{ p: theme.spacing(4) }}>
              <Typography variant="h4" color="primary" gutterBottom>Crear cuenta</Typography>
              <Typography gutterBottom>*Todos los campos son requeridos</Typography>
              <Box sx={{ my: theme.spacing(2) }} onSubmit={signUp}>
                <Typography variant="h6" gutterBottom>Datos personales</Typography>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField type="text" name="name" label="*Nombres(s)" variant="outlined" error={isError.name} fullWidth onChange={inputValidation}></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField type="text" name="lastname" label="*Apellido(s)" variant="outlined" error={isError.lastname} fullWidth onChange={inputValidation}></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField type="text" name="nickname" label="*Alias (Ejemplo: axe_123)" variant="outlined" error={isError.nickname} fullWidth onChange={inputValidation}></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField type="email" name="email" label="*Correo electrónico" variant="outlined" error={isError.email} fullWidth onChange={inputValidation}></TextField>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <TextField type="password" name="password" label="*Contraseña (8 caracteres)" variant="outlined" error={isError.password} fullWidth onChange={inputValidation}></TextField>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <TextField type="password" name="repeatPassword" label="*Confirmar contraseña" variant="outlined" error={isError.repeatPassword} fullWidth onChange={inputValidation}></TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={classes.boxForm}>
                <Typography variant="h6" gutterBottom>Unirme a Math Paradise como</Typography>
                <RadioGroup name="role">
                  <FormControlLabel value="student" control={<Radio color="primary" checked={inputs.role === 'student'} onChange={inputValidation} />} label="Estudiante" />
                  <FormControlLabel value="tutor" control={<Radio color="primary" checked={inputs.role === 'tutor'} onChange={inputValidation} />} label="Tutor/Profesor" />
                </RadioGroup>
              </Box>
              <Box sx={classes.centerControl}>
                <FormControlLabel
                  control={<Checkbox
                    checked={privacyPolicies}
                    onChange={() => { setprivacyPolicies(!privacyPolicies) }}
                    color="primary" />}
                  label="Acepto las políticas de privacidad" />
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: theme.spacing(2)
              }}>
                {
                  isLoading ?
                    <CircularProgress variant='indeterminate' /> :
                    <FormControl>
                      <Button type="submit" sx={{
                        width: '100%',
                        padding: theme.spacing(2),
                        color: "#FFFFFF",
                        background: MATH_GRADIENTS().default,
                        textAlign: "center",
                      }} startIcon={<CreateIcon />}>
                        <Typography variant="h6">Crear cuenta</Typography>
                      </Button>
                    </FormControl>
                }
              </Box>
            </Paper>
          </form>
        </Container>
      </Box>
    </div>
  )
}


export default SignUp