import React, { Fragment } from 'react'
import useAuth from '../../../../hooks/useAuth';
import { Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Divider, useTheme, ListItemButton } from "@mui/material"
import useStyles from './useStyles';

/**Icons */
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
//import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ApartmentIcon from '@mui/icons-material/Apartment'
import GamesIcon from '@mui/icons-material/Games';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FunctionsIcon from '@mui/icons-material/Functions'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarksIcon from '@mui/icons-material/Bookmarks'

function MenuList(props) {
  const theme = useTheme();
  const classes = useStyles(theme)
  const { url } = props
  const { user } = useAuth();

  return (
    <div style={classes.root}>
      <List component="nav">

        <Link to="/admin" style={classes.link}>
          <ListItem disablePadding selected={url === '/admin' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Divider variant='middle' />

        {
          user.role === 'admin' ?
            <Fragment>
              <Link to="/admin/users" style={classes.link}>
                <ListItem disablePadding selected={url === '/admin/users' ? true : false}>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Usuarios" />
                  </ListItemButton>
                </ListItem>
              </Link>
              {
                /* <Link to="/admin/groups" className={classes.link}>
                    <ListItem disablePadding selected={url === '/admin/groups' ? true : false}>
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Grupos" />
                    </ListItem>
                </Link>
                */
              }
              <Link to="/admin/institutions" style={classes.link}>
                <ListItem disablePadding selected={url === '/admin/institutions' ? true : false}>
                  <ListItemButton>
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Instituciones" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Divider variant='middle' />
              <Link to="/admin/gamemodes" style={classes.link}>
                <ListItem disablePadding selected={url === '/admin/gamemodes' ? true : false}>
                  <ListItemButton>
                    <ListItemIcon>
                      <GamesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Modos de juego" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to="/admin/news" style={classes.link}>
                <ListItem disablePadding selected={url === '/admin/news' ? true : false}>
                  <ListItemButton>
                    <ListItemIcon>
                      <NewspaperIcon />
                    </ListItemIcon>
                    <ListItemText primary="Noticias" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Fragment> : null
        }

        <Divider variant='middle' />

        <Link to="/admin/areas" style={classes.link}>
          <ListItem disablePadding selected={url === '/admin/areas' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Áreas" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/admin/topics" style={classes.link}>
          <ListItem disablePadding selected={url === '/admin/topics' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <BookmarkIcon />
              </ListItemIcon>
              <ListItemText primary="Temas" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/admin/subtopics" style={classes.link}>
          <ListItem disablePadding selected={url === '/admin/subtopics' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Subtemas" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/admin/excercises" style={classes.link}>
          <ListItem disablePadding selected={url === '/admin/excercises' ? true : false}>
            <ListItemButton>
              <ListItemIcon>
                <FunctionsIcon />
              </ListItemIcon>
              <ListItemText primary="Ejercicios" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Divider variant='middle' />

        <Link to="/home" style={classes.link}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SpaceDashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Tablero principal" />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </div>
  )
}

export default MenuList
