import React, { useState, useEffect } from 'react';
import { Avatar } from "@mui/material";
import PropTypes from 'prop-types';

/** APIs */
import { getUserByNicknameApi, getAvatarApi } from '../../../api/user';

function DefaultAvatar(props) {
  // Props
  const { nickname, size, sx } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  // Fetch avatar based on nickname
  useEffect(() => {
    if (nickname) {
      const fetchAvatar = async () => {
        try {
          const response = await getUserByNicknameApi(nickname);
          if (response.user.avatar) {
            const avatarResponse = await getAvatarApi(response.user.avatar);
            if (avatarResponse.status !== 0) {
              setAvatarUrl(avatarResponse); // Update state with the new URL
            } else {
              setAvatarUrl(null); // Set to null if no avatar is found
            }
          } else {
            setAvatarUrl(null); // No avatar found for the user
          }
        } catch (error) {
          console.error("Error fetching avatar:", error);
          setAvatarUrl(null); // Handle error by setting to null
        }
      };

      fetchAvatar();
    }
  }, [nickname]); // Only re-run if nickname changes

  return (
    <Avatar
      src={avatarUrl}
      style={{ width: size, height: size, background: "#FFF" }}
      sx={sx}
    />
  );
}

DefaultAvatar.propTypes = {
  size: PropTypes.string,
  sx: PropTypes.object,
  nickname: PropTypes.string
};

export default DefaultAvatar;
