const useStyles = (groupColor, theme) => ({
    paper: {

    },
    infoBox: {
        padding: theme.spacing(2),
        '& h4': {
            color: groupColor
        },
        '& p': {
            textAlign: 'justify',
            textJustify: 'inter-word'
        }
    },
    icon: {
        color: groupColor,
    }
});

export default useStyles;