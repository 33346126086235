/**Layouts */
import LayoutUser from '../layouts/LayoutUser'
import LayoutAdmin from '../layouts/LayoutAdmin'

/**Admin Pages */
import AdminDashboard from '../pages/admin/Dashboard'

//Grupos
import AdminGroupsMenu from '../pages/admin/groups/MenuGroups'
import AdminGroupsCreate from '../pages/admin/groups/CreateGroup'

//Usuarios
import AdminUsersMenu from '../pages/admin/users/MenuUsers'
import AdminUserCreate from '../pages/admin/users/CreateUser'
import AdminUserUpdate from '../pages/admin/users/UpdateUser'

//Instituciones
import AdminInstitutionsMenu from '../pages/admin/institutions/MenuInstitutions'
import AdminInstitutionsCreate from '../pages/admin/institutions/CreateIInstitution'
import AdminInstitutionsUpdate from '../pages/admin/institutions/UpdateInstitution'

//Modos de juego
import AdminGameModesMenu from '../pages/admin/gamemodes/MenuGameModes';
import AdminGameModeCreate from '../pages/admin/gamemodes/CreateGameMode';
import AdminGameModeUpdate from '../pages/admin/gamemodes/UpdateGamemode';

//Modos de juego
import AdminNewsMenu from '../pages/admin/news/MenuNews';
import AdminNewCreate from '../pages/admin/news/CreateNew';
import AdminNewUpdate from '../pages/admin/news/UpdateNew';

//Ejercicios
import AdminExcercisesMenu from '../pages/admin/excercises/MenuExcercises'
import AdminExcercisesCreate from '../pages/admin/excercises/CreateExcercise'
import AdminExcerciseUpdate from '../pages/admin/excercises/UpdateExcercise'

//Areas
import AdminAreasMenu from '../pages/admin/areas/MenuAreas'
import AdminAreasCreate from '../pages/admin/areas/CreateArea'
import AdminAreaUpdate from '../pages/admin/areas/UpdateArea'

//Temas
import AdminTopicsMenu from '../pages/admin/topics/MenuTopics'
import AdminTopicsCreate from '../pages/admin/topics/CreateTopic'
import AdminTopicUpdate from '../pages/admin/topics/UpdateTopic'

//Subtemas
import AdminSubtopicsMenu from '../pages/admin/subtopics/MenuSubtopics'
import AdminSubtopicsCreate from '../pages/admin/subtopics/CreateSubtopic'
import AdminSubtopicUpdate from '../pages/admin/subtopics/UpdateSubtopic'

/** User Pages */
import Dashboard from '../pages/user/Dashboard'
import PlayMenu from '../pages/user/play_menu'
import Groups from '../pages/user/groups'
import LayoutGroup from '../pages/user/groups/layout_group'
import Leaderboard from '../pages/user/leaderboard/Leaderboard'
import History from '../pages/user/history/History'
import Settings from '../pages/user/setting/Settings'
import Profile from '../pages/user/profile/Profile'

/**Gamemode pages */
import PrivateClassicGame from '../pages/user/games/classic/PrivateClassicGame';
import LocalClassicGame from '../pages/user/games/classic/LocalClassicGame'
import PrivateArcadeGame from '../pages/user/games/arcade/PrivateArcadeGame/PrivateArcadeGame'
import LocalArcadeGame from '../pages/user/games/arcade/LocalArcadeGame/LocalArcadeGame'
import SinglePlayerRushGame from '../pages/user/games/rush/SinglePlayerRushGame/SinglePlayerRushGame'
import PrivateRushGame from '../pages/user/games/rush/PrivateRushGame/PrivateRushGame'

//Lobbies
import PrivateClassicLobby from '../pages/user/lobbies/classic_lobbies/PrivateClassicLobby'
import LocalClassicLobby from '../pages/user/lobbies/classic_lobbies/LocalClassicLobby'
import PrivateArcadeLobby from '../pages/user/lobbies/arcade_lobbies/PrivateArcadeLobby'
import LocalArcadeLobby from '../pages/user/lobbies/arcade_lobbies/LocalArcadeLobby'
import PrivateRushLobby from '../pages/user/lobbies/rush_lobbies/PrivateRushLobby'
import JoinByQr from '../pages/user/lobbies/JoinByQr'

/**General pages */
import Home from '../pages/home/Home'
import Login from '../pages/login/Login'
import SignUp from '../pages/signup/SignUp'
import Error from '../pages/Error404'
import PrivacyPolicies from '../pages/PrivacyPolicies'
import Help from '../pages/help/Help'
import Credits from '../pages/Credits'
import News from '../pages/news/News'
import Article from '../pages/news/Article'

const routes = [
  /**Página principal */
  {
    path: '/',
    component: Home,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Ruta de políticas de privacidad */
  {
    path: '/privacy-policies',
    component: PrivacyPolicies,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Ruta de ayuda */
  {
    path: '/help',
    component: Help,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Ruta de creditos */
  {
    path: '/credits',
    component: Credits,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/news',
    component: News,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/news/:path',
    component: Article,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Rutas de usuario (Tutor y estudiante) */
  {
    path: '/home',
    component: LayoutUser,
    exact: false,
    routes: [
      {
        path: '/home',
        component: Dashboard,
        exact: true,
      },
      {
        path: '/home/play',
        component: PlayMenu,
        exact: true
      },
      {
        path: '/home/play/:gamemode',
        component: PlayMenu,
        exact: false
      },
      {
        path: '/home/groups',
        component: Groups,
        exact: true,
      },
      {
        path: '/home/groups/:id',
        component: LayoutGroup,
        exact: true
      },
      {
        path: '/home/leaderboard',
        component: Leaderboard,
        exact: true,
      },
      {
        path: '/home/history',
        component: History,
        exact: true,
      },
      {
        path: '/home/settings',
        component: Settings,
        exact: true,
      },
      {
        path: '/home/profile/@:nickname',
        component: Profile,
        exact: true
      },
      {
        component: Error
      }
    ]
  },
  /**
   *  Lobbies */
  {
    path: '/private-classic-lobby/:pin',
    component: PrivateClassicLobby,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/local-classic-lobby',
    component: LocalClassicLobby,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/private-arcade-lobby/:pin',
    component: PrivateArcadeLobby,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/local-arcade-lobby',
    component: LocalArcadeLobby,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/private-rush-lobby/:pin',
    component: PrivateRushLobby,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    path: '/join-by-qr/:mode/:pin',
    component: JoinByQr,
    exact: false,
  },
  {
    path: '/join-by-qr',
    exact: true,
    component: JoinByQr,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Modos de juego */
  {
    //Modo Clásico
    path: '/classic/private/:pin',
    component: PrivateClassicGame,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    //Modo Clásico local
    path: '/classic/local',
    component: LocalClassicGame,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    //Modo Arcade
    path: '/arcade/private/:pin',
    component: PrivateArcadeGame,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    //Modo Arcade local
    path: '/arcade/local',
    component: LocalArcadeGame,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    //Modo Rush Single Player
    path: '/rush/singleplayer',
    component: SinglePlayerRushGame,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  {
    //Modo Rush Multiplayer
    path: '/rush/private/:pin',
    component: PrivateRushGame,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Ruta de login */
  {
    path: '/login',
    component: Login,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Ruta de registro */
  {
    path: '/sign-up',
    component: SignUp,
    exact: true,
    routes: [
      {
        component: Error
      }
    ]
  },
  /**Rutas de administrador */
  {
    path: '/admin',
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: '/admin',
        component: AdminDashboard,
        exact: true,
      },
      {
        path: '/admin/users',
        component: AdminUsersMenu,
        exact: true,

      },
      {
        path: '/admin/users/create',
        component: AdminUserCreate,
        exact: true,
      },
      {
        path: '/admin/gamemodes',
        component: AdminGameModesMenu,
        exact: true,
      },
      {
        path: '/admin/gamemodes/create',
        component: AdminGameModeCreate,
        exact: true,
      },
      {
        path: '/admin/gamemodes/update/:name',
        component: AdminGameModeUpdate,
        exact: true,
      },
      {
        path: '/admin/news',
        component: AdminNewsMenu,
        exact: true,
      },
      {
        path: '/admin/news/create',
        component: AdminNewCreate,
        exact: true,
      },
      {
        path: '/admin/news/update/:path',
        component: AdminNewUpdate,
        exact: true,
      },
      {
        path: '/admin/users/update/:id',
        component: AdminUserUpdate,
        exact: true,
      },
      {
        path: '/admin/groups',
        component: AdminGroupsMenu,
        exact: true,
      },
      {
        path: '/admin/groups/create',
        component: AdminGroupsCreate,
        exact: true,
      },
      {
        path: '/admin/institutions',
        component: AdminInstitutionsMenu,
        exact: true
      },
      {
        path: '/admin/institutions/create',
        component: AdminInstitutionsCreate,
        exact: true
      },
      {
        path: '/admin/institutions/update/:id',
        component: AdminInstitutionsUpdate,
        exact: true
      },
      {
        path: '/admin/excercises',
        component: AdminExcercisesMenu,
        exact: true
      },
      {
        path: '/admin/excercises/create',
        component: AdminExcercisesCreate,
        exact: true,
      },
      {
        path: '/admin/excercises/update/:id',
        component: AdminExcerciseUpdate,
        exact: true,
      },
      {
        path: '/admin/areas',
        component: AdminAreasMenu,
        exact: true,
      },
      {
        path: '/admin/areas/create',
        component: AdminAreasCreate,
        exact: true,
      },
      {
        path: '/admin/areas/update/:id',
        component: AdminAreaUpdate,
        exact: true,
      },
      {
        path: '/admin/topics',
        component: AdminTopicsMenu,
        exact: true,
      },
      {
        path: '/admin/topics/create',
        component: AdminTopicsCreate,
        exact: true,
      },
      {
        path: '/admin/topics/update/:id',
        component: AdminTopicUpdate,
        exact: true,
      },
      {
        path: '/admin/subtopics',
        component: AdminSubtopicsMenu,
        exact: true,
      },
      {
        path: '/admin/subtopics/create',
        component: AdminSubtopicsCreate,
        exact: true,
      },
      {
        path: '/admin/subtopics/update/:id',
        component: AdminSubtopicUpdate,
        exact: true,
      },
      {
        component: Error
      }
    ]
  },
]

export default routes
